import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  // default locale
  locale: 'EN',
  pluralizationRules: {
    /**
     * @param choice {number} индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} общее количество доступных вариантов
     * @returns финальный индекс для выбора соответственного варианта слова
     */
    'UA': function(choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }
      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;
      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }
      return (choicesLength < 4) ? 2 : 3;
    },
    'RU': function(choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }
      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;
      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }
      return (choicesLength < 4) ? 2 : 3;
    }
  },
  // translations
  messages: {
    UA: {
      madeInUkr: "made in Ukraine",
      upProVersion: "Перейти на PRO",
      getLeads: "Купити Ліди",
      poperBillings: "Платежі",
      poperTemplates: "Шаблони",
      poperPartnership: "Партнерська програма",
      poperProfile: "Профайл",
      poperLogout: "Вихід",
      defaultNewQuestion: "Нове запитання",
      defaultNewQuestionDescription: "Сюди ви можете вставити короткий опис запитання (до 80 символів)",
      defaultNewVariant: "Новий варіант",
      defaultBtnText: "Розпочати",
      navBillings: "Платежі",
      navPartnership: "Партнерська програма",
      navProfile: "Профайл",
      navTariffs: "Тарифи",
      welcomeBack: "З поверненням!",
      loginHeader: "Увійти",
      loginHeader2Part: "щоб продовжити",
      loginEmailPlaceholder: "E-mail",
      loginPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      loginBtnValue: "Увійти",
      loginWith: "Увійти за допомогою",
      loginSignup: "Зареєструватись",
      passwordAreForgetPassword: "Забули пароль?",
      passwordResetPasswordHeader: "Відновлення паролю",
      passwordResetPassword: "Відновити пароль",
      passwordWeSentReset: "Ми надіслали вам посилання на відновлення паролю на електронну пошту.",
      passwordInputNewPassword: "Введіть новий пароль",
      passwordConfirmPassword: "Підтвердіть пароль",
      passwordChangePassword: "Змінити пароль",
      passwordResetedPasswordCompleted: "Ваш пароль успішно змінено!",
      passwordGoToWorkboard: "Перейти в акаунт",
      helloHeader: "Вітаємо!",
      signupHeader: "Зареєструватись",
      signupHeader2Part: "щоб продовжити",
      signupNamePlaceholder: "Олександр",
      signupPhonePlaceholder: "номер телефону",
      signupEmailPlaceholder: "E-mail",
      signupPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      signupBtnValue: "Створити аккаунт",
      signupWith: "Зареєструватись за допомогою",
      signupLogin: "Увійти в мій аккаунт",
      signupTariffHeader: "Тарифи",
      signupTariffFree: "Free.",
      signupTariffPro: "Pro.",
      signupTariffFreeDescription: "Безлімітна кількість створення нових квізів (веб сторінок). Можливість генерувати до 300 заявок в місяць безкоштовно.",
      signupTariffProPrice: "$20 / місяць",
      signupTariffProDescription: "Безлімітна кількість створення нових квізів (веб сторінок). Можливість генерувати",
      signupTariffProDescriptionBold: "понад 300 заявок.",
      userBackToQuiz: "Повернутись на воркборд",
      userBackToSettings: "Повернутись до налаштувань",
      billingYourTariff: "Ваш тарифний план",
      billingYourPro: "Pro",
      billingYorFree: "Free",
      billingFreeLeads: "Безкоштовних заявок",
      billingInfinity: "безліміт",
      billingTariffRenewData: "Дата оновлення тарифу",
      billingTariffEndData: "Дата закінчення тарифу",
      billingOtherTariffFree: "Free.",
      billingOtherTariffPro: "Pro.",
      billingTariffFreeDescription: "Безлімітна кількість створення нових квізів (веб сторінок). Можливість генерувати до 300 заявок безкоштовно.",
      billingYouHavePro: "Зараз ви на Pro версії. Навіщо робити крок назад?",
      billingYouHasFreeLeads: "Залишилось безкоштовних заявок",
      billingYouHasLeads: "Залишилось заявок",
      billingTariffProDescription: "Безлімітна кількість створення нових квізів (веб сторінок). Можливість генерувати",
      billingTariffProDescriptionBold: "понад 300 заявок.",
      billingYourFutureTariff: "Ваш майбутній тариф",
      billingBtnChangeToPro: "Перейти",
      billingBtnRenewPro: "Оновити",
      billingBillings: "Оплати",
      billingYouHaveNotBillings: "У вас поки що немає оплат",
      billingPaymentsMethod: "Метод оплати",
      billingPaymentCard: "Карта",
      billingChangeData: "Змінити дані",
      billingDeletePayMethod: "Видалити",
      billingModalDeleteShure: "Ви дійсно хочете видалити цей спосіб оплати?",
      billingModalDeleteCancel: "Відмінити",
      billingModalDeleteOk: "Видалити",
      billingModalDeleteSuccess: "Ви успішно видалили свою карту",
      billingModalRegularPaymentDeleteSuccess: "Регулярні платежі відмінено",
      billingModalRegularPaymentDeleteOk: "ОК",
      billingYourMonthPlan: "Ваш щомісячний план",
      billingLeads: "заявок",
      billingChangePlan: "Змінити план",
      billingChangePlanDescription: "Прайс буде відповідним до бажаної кількості заявок з усіх квізів у вашому аккаунті.",
      billingSelectPeriod: "Обрати період",
      billingChangePlanDescriptionImportant: "Важливо:",
      billingChangePlanDescriptionImportantMonth: "Ліди можна використовувати 1 місяць після покупки",
      billingChangePlanDescriptionImportantMonthAfterBuy: " після покупки.",
      subscriptionTariffLable: "Підпишіться на нашу автоматичну підписку.",
      billingChangePlanWarning: "Придбані ліди будуть ",
      billingTariffMonth: "міс.",
      billingChangePlanPay: "Придбати",
      profileName: "Ім’я",
      profilePhone: "Номер",
      profileEmail: "E-mail",
      profileStatus: "Статус",
      profileStatusAgency: "Агенція",
      profileStatusPlaceholder: "Виберіть що вас найкраще описує",
      profileStatusFreelancer: "Фрілансер",
      profileStatusBuisines: "Власник бізнесу",
      profilePassword: "Пароль",
      profileChangePassword: "Змінити пароль",
      profileNewPassword: "Новий пароль",
      profileSaveNewData: "Оновити дані",
      workboardAddNewQuiz: "Створити проєкт",
      workboardModalGiveQuizName: "Дайте назву квізу",
      workboardModalQuizNamePlaceholder: "Новий квіз",
      workboardModalNewQuizBtnValue: "додати",
      workboardAlertGiveAnyName: "Введіть хоч якусь назву",
      workboardAlertNextTimeAddQuiz: "Що ж, наступного разу наважитесь)",
      workboardChangeQuizName: "Змінити назву",
      workboardQuizLink: "лінк",
      workboardQuizLinkCopied: "Посилання скопійовано в буфер",
      workboardEditQuiz: "Редагувати",
      workboardCopyQuiz: "Скопіювати квіз",
      workboardQuizCopied: "Квіз успішно скопійовано",
      workboardDeleteQuiz: "Видалити",
      workboardModalRemoveQuizTrash: "Цей квіз буде переміщена до кошика і остаточно видалено через 1 місяць.",
      workboardModalRemoveQuizSure: "Ви впевнені, що хочете перемістити цей квіз до кошика?",
      workboardModalDeleteQuizCancel: "Відмінити",
      workboardModalDeleteQuizDelete: "Видалити",
      workboardModalDeleteQuizSuccess: "Квіз успішно видалено",
      workboardModalQuizDeleteOk: "ОК",
      workboardStatsTitle: "Статистика",
      workboardModalLeadsAnswersCount: "Відповіді на квіз ",
      workboardModalLeadsLeadSerial: "№",
      workboardModalLeadsLeadDate: "Дата",
      workboardModalLeadsLeadEmail: "Email",
      workboardModalLeadsLeadName: "Ім'я",
      workboardModalLeadsLeadPhone: "Телефон",
      workboardModalLeadsLeadActions: "Дії",
      workboardModalLeadsLeadShowMore: "Показати більше",
      workboardModalLeadsLeadHideMore: "Приховати",
      workboardModalLeadsLeadAnswerScipped: "Пропущено",
      workboardModalNoLeads: "Поки що заявок немає",
      constructorLink: "лінк",
      constructorLinkCopied: "Скопійовано в буфер обміну:",
      constructorNavSteps: "Кроки",
      constructorNavDesign: "Дизайн",
      constructorNavSettings: "Налаштування",
      constructorStep: "крок",
      cancel: "Відмінити",
      constructorCancel: "Відмінити",
      constructorSave: "Зберегти",
      constructorextraNavStart: "С",
      constructorextraNavContact: "К",
      constructorextraNavFinish: "Ф",
      cstepsSection: "Секції",
      cstepsStartPage: "Стартова сторінка",
      cstepsContactPage: "Форма контактів",
      cstepsFinishPage: "Фінішна сторінка",
      cstepsEdit: "Редагувати",
      cstepsMove: "Перемістити",
      cstepsDeleteStep: "Видалити крок",
      cstepsAddSection: "Додати секцію",
      cdesignColorTheme: "Схема кольорів",
      cdesignCustomTheme: "Власна схема кольорів",
      cdesignDefaultThemes: "Готові схеми кольорів",
      cdesignThemeStandart: "Стандарт",
      cdesignThemeDark: "Дарк",
      cdesignThemeNud: "Нюд",
      cdesignThemeGradient: "Градієнт",
      cdesignShapeDesign: "Форма дизайну",
      cdesignShapeRound: "Заокруглена",
      cdesignShapeSquare: "Кутова",
      csettingsIntegration: "Інтеграції",
      csettingsOweDomain: "Підключення власного домену",
      csettingsDocs: "Юридичні документи",
      cintegrationIntegration: "Інтеграції",
      cintegrationShareLeads: "Передача заявок",
      cintegrationTitleEmail: "E-MAIL",
      cintegrationInstruction: "Інструкція",
      cintegrationInstructionDetail: "Деталі",
      cintegrationEmailInstruction: "Вставте в поле знизу потрібну електронну адресу, на яку необхідно відправляти інформацію. Кожна заявка з цього квіза буде дублюватися на вказану вами пошту.",
      cintegrationPublishAfterEdit: "Збережіть квіз після редагування",
      cintegrationSendNewLeadsTo: "Надсилати повідомлення про нові заявки на:",
      cintegrationInputEmail: "Введіть адресу електронної скриньки",
      cintegrationAddEmail: "Додати email",
      cintegrationEmailSaved: "Дані Email інтеграції збережено",
      cintegrationEmailDeleted: "Дані Email інтеграції видалено",
      cintegrationTitleFB: "FB pixel",
      cintegrationIDPixel: "Pixel ID",
      cintegrationFBInstruction1: "Зайдіть до рекламного кабінету Facebook Ads і у розділі Event Manager скопіюйте ID Facebook Pixel.",
      cintegrationFBInstruction2: "Не копіюйте весь код, лише ідентифікатор із 15-16 цифр.",
      cintegrationFBInstruction3: "Скопіюйте та вставте в поле нижче.",
      cintegrationFBInstruction: 'Подія на яку потрібно налаштовувати конверсію це "Lead". Ця подія буде викликана як тільки користувач усішно відправить заявку в квізі.',
      cintegrationFBInstructionTextTitle: "Перелік усіх подій, які ми надсилаємо:",
      cintegrationFBInstructionTextTextStart: " – відвідування стартової сторінки;",
      cintegrationFBInstructionTextQuestionN: " – відвідування кроку N;",
      cintegrationFBInstructionTextOpenContactPage: " – відвідування сторінки контактів;",
      cintegrationFBInstructionTextLead: " – успішне відправлення форми з контактними даними;",
      cintegrationFBInstructionTextThankYou: " – відвідування фінальної сторінки.",
      bold_adsquiz_Start: "adsquiz_Start",
      bold_adsquiz_QuestionN: "Question_N",
      bold_adsquiz_OpenContactPage: "Open_Contact-Page",
      bold_adsquiz_Lead: "Lead",
      bold_adsquiz_ThankYou: "adsquiz_ThankYou",
      cintegrationInputIDPixel: "Введіть ID пікселя",
      cintegrationDataFBSaved: "Дані FB пікселя збережено",
      cintegrationAccesToken: "ACCES TOKEN",
      cintegrationTitleGA: "Google analytics",
      cintegrationGAInstructionDescription: "Дізнайтеся, як люди знаходять ваш квіз та взаємодіють з ним. Отримайте дані, необхідні для аналізу кампаній, покращення конверсій тощо. ",
      cintegrationGAInstruction: 'При отриманні заявки в квізі в Google Analitics буде викликана подія "generate_lead".',
      cintegrationIDFolow: "ID відстеження",
      cintegrationInputID: "Введіть ID",
      cintegrationDataGASaved: "Дані Google analytics збережено",
      cintegrationTitleGTM: "Google Tag Manager",
      cintegrationGTMInstructionDescription: "Опис для Google Tag Manager. (не використовується)",
      cintegrationGTMInstruction: "Скопіюйте індификатор Google Tag Manager та вставте в поле нижче.",
      cintegrationDataGTMSaved: "Дані Google Tag Manager збережено",
      cintegrationTitleTG: "Telegram",
      cintegrationHowReciveTGLeadBot: "Як отримати заявки в Телеграм бот?",
      cintegrationTGInstructionBot1: "1. Скопіюйте token у полі нижче.",
      cintegrationTGInstructionBot2: "2. Перейдіть за посиланням в бот, натисніть кнопку «ПОЧАТИ».",
      cintegrationTGInstructionBot3: "3. Скопійований token надішліть повідомленням в наш бот.",
      cintegrationHowReciveTGLeadChat: "Як отримати заявки в чат-телеграм?",
      cintegrationTGInstructionChat1: "1. Додайте наш бот в потрібний чат та виставте боту статус адміністратора.",
      cintegrationTGInstructionChat2: "2. Скопіюйте token у полі нижче і відправте його в потрібний чат.",
      cintegrationTGInstructionChat3: "3. Дочекайтесь відповіді `OK! Your quiz token was saved!”",
      cintegrationTGInstructionChat4: "4. Тепер заявки будуть надходити в цей чат.",
      cintegrationTGInstructionWarning: "Важливо:",
      cintegrationTGInstructionWarningText: "Зверніть увагу що це повинна бути саме група, а не Telegram канал. В Telegram канал заявки надходити не будуть.",
      cintegrationTGBotLink: "лінк на чатбот",
      cintegrationTGTitleToken: "TOKEN",
      cintegrationInputTGToken: "Введіть телеграм токен чатботу",
      cintegrationAddIntegration: "Додати інтеграцію",
      cintegrationDataTGSaved: "Дані Telegram інтеграції збережено",
      cintegrationTitleTT: "TT pixel",
      cintegrationTTInstruction: "Для відстеження подій у рекламному кабінеті Tik-Tok Ads скопіюйте ідентифікатор пікселя та вставте в поле нижче.",
      cintegrationDataTTSaved: "Дані TT пікселя збережено",
      cintegrationTitleWH: "WebHook",
      cintegrationWHInstruction1: "Ви можете сповіщати сторонні додатки про заяви, що надійшли. Для цього вкажіть URL, на який буде надіслано WebHook.",
      cintegrationWHInstruction2: "Ми підготували",
      cintegrationWHInstruction3: "відео інструкцію",
      cintegrationWHInstruction4: "з докладним описом даного функціоналу.",
      cintegrationWHInstruction5: "Переглянути відео.",
      cintegrationWHInstruction6: "У цьому відео ми розглянемо передачу хука на основі відправлення даних до Google таблиці та відправлення даних до CRM-системи Trello.",
      cintegrationWHInstruction7: "Ми рекомендуємо використовувати для цього сервіс",
      cintegrationWHInstruction8: "При реєстрації за цим посиланням, для наших користувачів діють бонус - 10 000 безкоштовних дій для будь-яких інтеграцій.",
      cintegrationWHInstruction9: "Посилання",
      cintegrationWHInstruction10: "для реєстрації з бонусом.",
      cintegrationWHUrl: "Адреса вебхуку",
      cintegrationInputWHUrl: "Введіть адресу вебхуку",
      cintegrationAddWH: "Додати Web-hook",
      cintegrationWebHookDataSaved: "WebHook дані збережено",


      cintegrationComonWHInstructionShort: "Ви можете сповіщати сторонні програми про надходження лідів. Вкажіть URL-адресу вебхука для відправки даних.",
      cintegrationComonWHInstruction1Title: "1. Вставте URL-адресу вебхука в поле для введення:",
      cintegrationComonWHInstruction1Description: "Вставте URL-адресу вебхука, наданого вашим сервісом або додатком, в поле для введення.",
      cintegrationComonWHInstruction2Title: "2. Збережіть зміни:",
      cintegrationComonWHInstruction2Description: 'Натисніть кнопку "Зберегти зміни", щоб зберегти налаштування вебхука.',
      cintegrationComonWHInstruction3Title: "3. Готово:",
      cintegrationComonWHInstruction3Description: "Тепер ваш вебхук налаштований для відправки даних на обраний вами сервіс.",
      cintegrationComonWHInstructionAdditional: "Дані будуть відсилатися на вебхук при кожному отриманні ліда. Метод відправки - POST. Приклад даних, які будуть відправлені, розташований ",
      cintegrationComonWHInstructionAdditionalLink: "тут",
      cintegrationComonWHInstructionVideo: "Детальна відео-інструкція",
      
      cintegrationApixDriveWHInstructionShort: "Ви можете сповіщати ApixDrive про надходження лідів. Для цього вкажіть URL-адресу, на яку буде відправлено вебхук.",
      cintegrationApixDriveWHInstructionShort1: "При реєстрації за ",
      cintegrationApixDriveWHInstructionShort2: "цим посиланням",
      cintegrationApixDriveWHInstructionShort3: " наші користувачі отримують бонус - 10 000 безкоштовних дій для будь-яких інтеграцій.",
      cintegrationApixDriveWHInstruction1Title: "1. Зареєструйтеся або відкрийте свій акаунт ApixDrive:",
      cintegrationApixDriveWHInstruction1Description1: "Посилання ",
      cintegrationApixDriveWHInstruction1Description2: "для реєстрації з бонусом.",
      cintegrationApixDriveWHInstruction2Title: "2. Отримайте URL-адресу для вебхука:",
      cintegrationApixDriveWHInstruction2Description1: "Зайдіть в налаштування необхідного зв'язку і скопіюйте адресу для відправки вебхука:",
      cintegrationApixDriveWHInstruction2Description2: 'Ви можете створити новий зв\'язок, натисканням кнопки "Створити зв\'язок", і на кроці вибору системи оберіть "ADSQuiz".',
      cintegrationApixDriveWHInstruction3Title: "3. Вставте URL-адресу вебхука:",
      cintegrationApixDriveWHInstruction3Description: "Вставте URL-адресу вебхука, наданого ApixDrive, в поле для введення.",
      cintegrationApixDriveWHInstruction4Title: "4. Збережіть зміни:",
      cintegrationApixDriveWHInstruction4Description: 'Натисніть кнопку "Зберегти зміни", щоб зберегти налаштування вебхука.',
      cintegrationApixDriveWHInstruction5Title: "5. Готово:",
      cintegrationApixDriveWHInstruction5Description: "Тепер ваш вебхук налаштований для відправки даних на ApixDrive.",
      cintegrationApixDriveWHInstructionAdditional: "Дані будуть відсилатися на вебхук при кожному отриманні ліда. Метод відправки - POST. Приклад даних, які будуть відправлені, розташований ",
      cintegrationApixDriveWHInstructionAdditionalLink: "тут",
      cintegrationApixDriveWHInstructionVideo: "Детальна відео-інструкція",
      



      cintegrationConectOweDomain: "Підключення власного домену",
      cintegrationOweDomainInstruction1: "1. Щоб розмістити ваш квіз на необхідному домені, зайдіть в обліковий запис вашого хостингу. Перейдіть в DNS-налаштування та створіть A-запис з параметром IP: 185.25.117.21",
      cintegrationOweDomainInstruction2: "2. В полі вищще вкажіть домен і збережіть квіз.",
      cintegrationDomain: "Домен",
      cintegrationInputYourDomain: "Введіть ваш домен",
      cintegrationCheckDomenIsCorrect: "Перевірьте правильність введеного домену",
      cintegrationDomainWhichCorrect: "Введіть домен без протоколів. наприклад: some.site.com",
      cintegrationInsertedDefaultDomain: "Домен встановлено за замовчуванням",
      cintegrationDomainDataSaved: "Дані домену збережено",
      cintegrationPrivacyPolicy: "Політика конфіденційності",
      cintegrationPrivacyPolicyByDefault: "За умовчанням ми використовуємо нашу стандартну політику конфіденційності. Ми рекомендуємо додати вашу політику конфіденційності, яка буде актуальною для вашого бізнесу.",
      cintegrationPrivacyPolicyAdventage: "Це дасть зрозуміти вашим потенційним клієнтам, як ви використовуєте отримані контактні дані. Також цього вимагають деякі рекламні майданчики.",
      cintegrationPrivacyPolicyUrl: "Посилання на ваші документи",
      cintegrationPrivacyPolicyInputYourDocsLink: "Вставте посилання на документи",
      cintegrationDocsDataSaved: "Посилання на ваші документи збережено",
      allstepsUpploadPhoto: "Завантажити фото",
      allstepsChangePhoto: "Змінити фото",
      allstepsDeletePhoto: "Видалити фото",
      allstepsTitle: "Заголовок",
      allstepsDescription: "Опис",
      allstepsButtonText: "Текст кнопки",
      allstepsOtherSettings: "Інші налаштування",
      allstepsIsSaveBeforeRoute: "Зберегти данні перед переходом?",
      cfirstFormat: "Формат",
      cfirstHeaderStartText: "Ваш заголовок має бути тут. Мотивуйте користувачів пройти квіз до кінця",
      cfirstAddMediafile: "Додати медіафайл (фото)",
      cfirstBonus: "Бонус",
      cfirstAddBonus: "Додати бонус",
      cfirstStartPageDataUpdated: "Дані стартової сторінки оновлені",
      csanyAnswerType: "Тип запитання",
      csanyATCard: "картка",
      csanyATList: "список",
      csanyATCustom: "вільне поле",
      csanyQuestion: "Запитання",
      csanyAnswers: "Відповіді",
      csanyAddAnswer: "додати",
      csanyTitleForModalConfirm: "Ця дія незворотна!",
      csanyQuestionForModalConfirm: "Ви дійсно хочете видалити це питання?",
      csanyRequiredStep: "Обов'язковий крок",
      csanyAlowMultiple: "Дозволити декілька відповідей",
      csanyAutoRedirect: "Автоматичний перехід далі",
      csanyQuestionLogic: "Налаштування логіки питань",
      csanyDataStepUpdated: "Дані сторінки з запитанням оновлені",
      cformForm: "Форма",
      cformValueName: "Ім'я",
      cformValueSurname: "Прізвище",
      cformValuePhone: "Номер телефону",
      cformValueEmail: "E-mail",
      cformDataContactsPageUpdated: "Дані контактної сторінки оновлені",
      cformRequiredInput: "Обов'язкове",
      cfinishFinishPageTitle: "Фінішна сторінка",
      cfinishText: "Текст",
      cfinishDescription: "Опис",
      cfinishAddImage: "Додати зображення",
      cfinishImageAdded: "Зображення додано",
      cfinishURL: "URL",
      cfinishButtonText: "Текст кнопки",
      cfinishInsertNikname: "Вставте сюди ваш нікнейм",
      cfinishCheckDataIsCorrect: "Перевірьте правильність введеного ідентифікатора",
      cfinishFbWhichCorrect: "Введіть ідентифікатор (нікнейм) без адреси. Наприклад: some.name",
      cfinishIgWhichCorrect: "Введіть ідентифікатор (нікнейм) без адреси. Наприклад: some.name",
      cfinishTgWhichCorrect: "Введіть ідентифікатор (нікнейм) без адреси. Наприклад: some.name",
      cfinishFinishPageDataUpdated: "Дані фінішної сторінки оновлені",
      adminUsersReg: "Користувачів в системі",
      adminNewUsers: "Нових користувачів за місяць",
      adminProAccounts: "PRO accounts",
      adminLTV: "LTV",
      adminSharePro: "PRO відносно усіх",
      adminReciveLeads: "Отримано заявок",
      adminLeadsForOne: "На одного юзера",
      adminMadeQuiz: "Зроблено квізів",
      adminQuizForOne: "На одного юзера",
      adminAvarageUsed: "Середня кількість місяців використання",
      adminProfitMonth: "Зароблено за місяць",
      adminProfitAlltime: "Зароблено за весь час",
      adminUsersList: "База користувачів",
      notificationErrorLogin: "Помилка авторизації:",
      notificationLogin: "Вхід...",
      notificationWrongLoginData: "Неправильні дані для входу",
      notificationNetworkError: "Перевірте з'єднання з інтернетом",
      notificationInputLoginData: "Введіть данні для входу",
      notificationCheckInputEmail: "Перевірте правильність електронної пошти",
      notificationCheckInputPhone: "Перевірте правильність номеру телефону",
      notificationInputAllInputs: "Заповніть всі поля для реєстрації",
      notificationAgreePrivacyPolicy: "Погодьтесь з політикою конфіденційності",
      adminEditorForPartnership: "Редактор партнерської програми",
      developmentPage: "Розділ знаходиться в розробці",
      signupAgreeIAgreeWith: "Я погоджуюсь з",
      signupAgreePolicy: "політикою конфіденційності",
      signupAgreeAnd: "та",
      signupAgreeOffer: "публічною офертою",
      signupAgreeService: "сервісу",
      workboardModalLeadsLeadReferrer: "Не визначено",
      workboardModalLeadsLeadReferrerClarification: "Дані про джерело переходу заблоковані або не визначені",
      workboardModalLeadsLeadEmailCopied: "Email скопійовано до буферу",
      workboardModalLeadsLeadPhoneCopied: "Номер телефону скопійовано до буферу",
      workboardModalLeadsHaveNotSended: "Ви маєте неотримані ліди",
      workboardModalLeadsLeadReload: "Отримати",
      workboardModalLeadsLeadReloadedTitle: "Запит отримано",
      workboardModalLeadsLeadReloaded: "Якщо у вас залишились ліди, неотримані ліди будуть завантажені зверху списку. Також, вони будуть відправлені в додані інтеграції",
      cfirstStartPagePhotoUpdated: "Фото стартової сторінки оновлене",
      allstepsPhotoTooBig: "Файл занадто великий!",
      allstepsPhotoMustBeLess: "Фото повинно бути меньше",
      cfirstStartPageBonusPhotoUpdated: "Фото бонусу оновлене",
      cstepsQuestionsOrderSaved: "Порядок запитань збережено",
      csanyAnswerOrderSaved: "Порядок відповідей збережено",
      allstepsPhotoAdaptation: "Адаптувати фото під пристрої",
      allstepsPhotoFit: "Співвідношення сторін",
      constructorYouHideStep: "Ви сховали цей крок",
      cstepsHiddenStep: "Приховати крок",
      cstepsShowStep: "Відобразити крок",
      cfinishFinishPageHiddenUpdated: "Фінішна сторінка прихована.",
      cfinishFinishPageHiddenUpdatedGoContact: "Перейдіть на сторінку Форми контактів",
      cfinishFinishPageHiddenUpdatedSetRedirect: "та налаштуйте редирект після відправки даних",
      cformActionAfterContactRecive: "Дії після отримання контактів",
      cformActionAfterContactThankyou: "Сторінка подяки",
      cformActionAfterContactRedirect: "Переадресація",
      cformAdditionalSettings: "Додаткові налаштування",
      cformRedirectURL: "URL",
      cintegrationOweDomainInstructionImportant: "Важливо: Адреса у вашому хостинг обліковому записі і полі нижче, повинні збігатися. Час застосування змін DNS-налаштуваннь, та виписки SSL сертифікату - до 3-х годин після збереження.",
      cintegrationOweDomainInstructionVideo: "Переглянути відео інструкцію",
      workboardModalLeadsLeadLimit: "Ліміт ваших лідів вичерпаний.",
      workboardModalLeadsLeadLimitInstruction1: "Щоб побачити нові ліди,",
      workboardModalLeadsLeadLimitInstruction2: "перейдіть на інший тариф",
      workboardModalLeadsLeadLimitInstruction3: "оновіть поточній тариф",
      workboardModalLeadsLeadLimitInstruction4: '(не стосується "Free") або дочекайтеся оновлення тарифу, якщо у вас підключене автопродовження тарифу',
      billingBonusesTitle: 'Промокод',
      billingBonusesDescription: 'Введіть отриманий промокод у полі поруч',
      billingBonusesPlaceholder: 'Промокод',
      billingBonusesBtn: 'Активувати',
      billingBonusesActivated: 'Промокод активовано!',
      billingBonusesLeadIncreased: 'Кількість доступних лідів збільшено.',
      billingBonusesInvalid: 'Неправильний промокод',
      billingBonusesNotAvailable: 'Ви вже активували цей промокод',
      billingBonusesAlreadyActivated: 'Даний купон недійсний',
      createQuizSPDescription: "Це місце для короткого опису",
      createQuizCPTitle: "Як ми можемо з вами зв'язатися?",
      createQuizCPDescription: "Будь ласка, заповніть форму нижче",
      createQuizCPButton: "Готово!",
      createQuizFPTitle: "Дякуємо!",
      createQuizFPDescription: "Тепер ми підберемо найкраще рішення для вас!",
      createQuizFPButton: "Покажи мені щось!",
      selectInternationalPrefix: "Обрати міжнародний формат",
      autoInternationalPrefix: "Визначати країну автоматично",
      dovnloadCsvTitle: "Експортувати данні за вибраний період",
      dovnloadCsvSelectAllPeriod: "Обрати за весь період",
      dovnloadCsvCancel: "Відмінити",
      dovnloadCsvExport: "Експортувати",
      
      


      widgetEmbedVidgetToSite: "Встановлення квіза на сайті",
      widgetEmbedVidgetSelectEmbedVariant: "Оберіть варіант інтеграції",
      widgetEmbedVidgetSitecode: "В коді сайта",
      widgetEmbedVidgetPopup: "Поп-ап",
      widgetEmbedVidgetChat: "Квіз-кнопка",
      widgetEmbedVidgetInProgres: "В розробці",
      widgetEmbedVidgetToSiteCode: "Встановлення квіза на сайті",
      widgetSiteCodeInstruction0: "Щоб встановити квіз на вашому сайті, додайте код з першого поля в тег `<head>` на вашому сайті або Google Tag Manager, якщо він інтегрований у ваш сайт. Якщо після того, як ви вже вставите квіз на сайт, ви зміните домен у квіза Вам потрібно буде оновити код інтеграції на вашому сайті.",
      widgetSiteCodeInstruction1: "В місце куди ви хочете інтегрувати квіз вставте код з другого поля.",
      widgetSiteCodeInstruction2: "Квіз можна інтегрувати в контейнер будь-якого розміру, навіть, в сайдбари! Максимальный розмір квізу, так само як і при проходженні на окремому домені - 900х552 пікселя. Його можна додавати в контейнери і більшого розміру, в такому випадку квіз буде відцентровано.",
      widgetSiteCodeInstruction3: "Не соромтеся - експерементуйте!",
      widgetSiteCodeCopyToHead: "Скопіюйте та вставьте в `<head>` вашого сайту",
      widgetSiteCodeCopyToBody: "Вставте код в те місце, де повинен бути квіз",
      workboardModalAddQuizHeader: "Створення нового Quiz",
      workboardModalGiveQuizNameAndMethod: "Придумайте назву вашого квізу та виберіть бажаний спосіб створення",
      workboardModalNewQuizFromStart: "Створити з нуля",
      workboardModalNewQuizFromTemplates: "Готові шаблони",
      QuizTemplatesSearchInput: "Назва шаблону та/або категорій",
      QuizTemplatesSearchBtn: "Пошук",
      QuizTemplatesReadyTemplates: "Готові шаблони",
      QuizTemplateView: "Переглянути",
      QuizTemplateSelect: "Обрати",
      widgetPopupInstruction0: "Щоб встановити квіз на вашому сайті, додайте код з поля з кодом в тег `<head>` на вашому сайті або Google Tag Manager, якщо він інтегрований у ваш сайт. Якщо після того, як ви вже вставите квіз на сайт, ви зміните домен у квіза або тригери відкриття (відсоток скрола або таймінг), вам потрібно буде оновити код інтеграції на вашому сайті.",
      widgetPopupInstruction1: "Відразу перед закриттям тега `</footer>` вставте код з другого поля.",
      widgetPopupInstruction2: "Для програмістів чи просунутих користувачів",
      widgetPopupInstruction3: "Якщо вам з якихось причин незручно вставляти квіз перед закриваючим тегом `</footer>` ви можете вставити код з другого поля в будь-яке зручне місце, але враховуйте, що контейнер, в який ви вставляєте код з другого поля, повинен бути поверх всіх вікон і вкрай бажано повинен мати висоту та ширину максимально можливу для дисплея.",
      widgetPopupCopyToHead: "Скопіюйте та вставте в `<head>` вашого сайту",
      widgetPopupCopyToBody: "Скопіюйте та вставте код перед закриваючим тегом `</footer>`",
      widgetPopupTimeToOpen: "Проміжок часу",
      secondReduction: "сек",
      widgetPopupTimeClarification: "Попап з’явиться з затримкою зазначеного вище часу у секундах",
      widgetPopupScrollToOpen: "Відсоток прогортування сайту",
      widgetPopupScrollClarification: "Попап з’явиться після зазначеного вище відсотку прогортування вашого сайту",
      quizzesTemplatesAllTags: "Всі шаблони",
      widgetEmbedVidgetButton: "Конструктор кнопки",
      widgetButtonInstruction0: "Ви можете встановити на вашому сайті квіз який буде відкриватись при кліку по кнопці.",
      widgetButtonInstruction1: "Додайте заокруглення, тінь, та текст кнопки. Також, ви можете змінити колір кнопки та тексту всередині.",
      widgetButtonInstruction2: "Скопіюйте скрипт розміщений в першому полі з кодом та вставте його перед закриваючим тегом `</head>`.",
      widgetButtonInstruction3: "Скопіюйте згенерований код кнопки, з другого поля з кодом та вставте в будь-яке місце вашого сайту.",
      widgetButtonInstruction4: 'Ви можете не використовувати код з другого поля з кнопкою а просто додати `id="button_open_quiz_popup"` до будь-якого єлемента `<input type="button">` або єлемента `<button></button>` на сайті.',
      widgetRealTimeChange: "Ви можете бачити зміни в реальному часі у правій частині екрану",
      widgetButtonCornerRound: "Заокруглення кнопки",
      widgetButtonShadow: "Тінь кнопки",
      widgetButtonBgColor: "Колір кнопки",
      widgetButtonTextColor: "Колір тексту",
      widgetButtonTextValue: "Текст кнопки",
      openPopupQuizText: "Розпочати квіз",
      widgetButtonInsertBeforeAfterBody: "Вставте код квіза одразу після відкриваючого тега `<body>` або перед одразу перед закриваючим тегом `</body>`",
      widgetSiteCodeCopyButton: "Вставте код кнопки в будь-яке місце на сайті",
      footerContactUs: "Маєте питання? Можете з нами зв'язатись:",
      enterYourName: "Введіть ваше ім'я",
      enterYourPhone: "Введіть номер телефону",
      enterYourEmail: "Введіть ваш email",
      enterYourPassword: "Введіть ваш пароль",
      selectOption: "Оберіть варіант",
      createNewGroup: "Створити нову групу",
      dontHaveAccount: "Не маєте облікового запису?",
      resetPasswordTip: "Якщо у вас виникли додаткові проблеми чи запитання, будь ласка, не соромтеся зв’язатися з нашою командою підтримки клієнтів. Ми тут, щоб допомогти.",
      getBackAccount: "Поверніться до свого облікового запису",
      inMinutes: "за хвилину",
      alreadyHaveAccount: "Вже маєте обліковий запис?",
      quizSlotLastEdit: "Остання зміна",
      workboardQuizzesTitle: "Мої квізи",
      allstepsBack: "Назад",
      constructorGetLink: "Посилання",
      allOr: "або",
      allAnd: "і",
      acceptedFileType: "Прийнятні формати: ",
      conjunctionAnd: "та",
      acceptedFileSize: "Максимальний розмір: ",
      acceptedFileSizeMeasure: "МБ",
      cfirstBonusDisabled: "Вимкнено",
      cfirstBonusAvailable: "Доступно",
      allstepsResetInHeader: "Скинути",
      placeholderText: "Введіть тут",
      constructorSaveChanges: "Зберегти",
      cformInputAvailable: "Доступно",
      cformInputDisabled: "Вимкнено",
      constructorBack: "Назад",
      videoInstruction: "Відеоінструкція",
      csettingsIntegrationFB: "Інтеграція - Facebook Pixel",
      csettingsIntegrationTT: "Інтеграція - TikTok",
      csettingsIntegrationGA: "Інтеграція - Google Analytics",
      csettingsIntegrationGTM: "Інтеграція - Google Tag Manager",
      csettingsIntegrationWH: "Інтеграція - WebHook",
      csettingsIntegrationTG: "Інтеграція - Telegram",
      csettingsIntegrationApixDrive: "Інтеграція - APIxDrive",
      csettingsIntegrationEmail: "Інтеграція - Email",
      csettingsIntegrationAbout: "Докладно",
      csettingsIntegrationFBAbout: "Перейдіть в рекламний кабінет Facebook Ads та скопіюйте ідентифікатор Facebook Pixel в розділі Менеджер подій. Скопіюйте лише ідентифікатор, а не весь код, та вставте його у поле нижче.",
      csettingsIntegrationTTAbout: "Для відстеження подій у вашому рекламному обліковому записі Tik-Tok скопіюйте ідентифікатор пікселя та вставте його у поле нижче.",
      csettingsIntegrationGAAbout: "Скопіюйте ідентифікатор Google Analytics та вставте його у поле нижче.",
      csettingsIntegrationGTMAbout: "Скопіюйте ідентифікатор Google Tag Manager та вставте його у поле нижче.",
      csettingsIntegrationWHAbout1: "Ви можете сповіщати сторонні програми про надходження потенційних клієнтів. Для цього вкажіть URL-адресу, на яку буде відправлено вебхук. Ми рекомендуємо використовувати цю послугу. ",
      csettingsIntegrationWHAboutApix: "Apix-Drive ",
      csettingsIntegrationWHAbout2: "При реєстрації за цим посиланням нашим користувачам надається бонус - 10 000 безкоштовних дій для будь-яких інтеграцій.",
      csettingsIntegrationTGAbout: "Важливо: зауважте, що це повинна бути група, а не канал у Telegram. Потенційні клієнти не будуть надсилатися до каналу в Telegram.",
      csettingsIntegrationADAbout: "Щось про APIxDrive",
      csettingsIntegrationEmailAbout: "Активуйте електронний лист для вашого респондента та повідомте свою команду. Співпрацюйте зі своєю командою.",
      cSettingsIntegrationNeedHelp: "Потрібна допомога?",
      cSettingsIntegrationRefreshToken: "Оновити токен",
      csettingsSettingASettings: "Додаткові налаштування",
      cSettingsSettingQuizLangTitle: "Мова квізу",
      cSettingsSettingQuizLangDescription: "Щоб забезпечити вам найкращий досвід, будь ласка, виберіть потрібну мову квізу з варіантів нижче. Цією мовою будуть відображатись системні сповіщення та деякі інші незмінні тексти при проходженні квізу.",
      selectQuizLanguage: "Оберіть мову квізу",
      cSettingsSettingQuizHideLabelTitle: "Приховати мітку",
      cSettingsSettingQuizHideLabelDescription: 'Увімкнувши цей параметр, ви зможете насолоджуватися вільним від мітки "made by adsquiz" інтерфейсом, що дозволить вам та вашим відвідувачам зосередитися виключно на питаннях.',
      cSettingDataSaved: "Додаткові налаштування збережено",
      userAccountInfo: "Інформація про обліковий запис",
      userGeneralInfoHeader: "Загальна інформація",
      userGeneralInfoDescription1: "Ваш профіль користувача - це місце, де ви можете керувати своїм обліковим записом та налаштуваннями.",
      userGeneralInfoDescription2: "Оновлюйте свою особисту інформацію, змінюйте пароль та налаштовуйте свої відомості про сповіщення.",
      profileNameFirst: "Ім'я",
      profileNameLast: "Прізвище",
      userSecuritySettingsHeader: "Налаштування безпеки",
      userSecuritySettingsDescription: "Для зміни пароля просто двічі введіть новий пароль для підтвердження.",
      profileEmailCurent: "Поточна адреса електронної пошти",
      profileEmailNew: "Нова адреса електронної пошти",
      profileNewPasswordConfirm: "Підтвердіть новий пароль",
      profileDeleteAccount: "Видалити обліковий запис",
      profileDeleteAccountPopupHeader: "Видалити обліковий запис?",
      profileDeleteAccountPopupDescription: 'Ви впевнені, що хочете видалити свій обліковий запис? Ця дія неможлива до скасування. Для підтвердження видалення, будь ласка, введіть слово "ВИДАЛИТИ" у поле нижче.',
      profileDeleteAccountCheckedWord: "ВИДАЛИТИ",
      profileDeleteAccountPopupPlaceholder: "Введіть тут",
      notificationSuccess: "Успішно!",
      notificationSuccessSaveAccount: "Ваш пароль успішно змінено! Використовуйте новий пароль для входу",
      notificationSuccessDeleteAccount: "Ваш обліковий запис успішно видалено! Використовуйте новий пароль для входу",
      cardPanUnspecified: "Не вказано",
      userBillingsDate: "Дата",
      userBillingsCost: "Вартість",
      userBillingsId: "ID",
      userBillingsMethod: "Спосіб оплати",
      paymentSwitchToProHeader: "Перейти на Pro",
      paymentSwitchToProDescription: "Знайдіть план, який підходить саме вам! Оновлюйте або знижуйте план у будь-який час - ми полегшуємо вибір потрібного плану.",
      paymentYourCurrentPlan: "Ваш поточний план:",
      paymentYourFuturePlan: "Ваш новий план:",
      paymentPlanLeadsLimit: "Ліміт лідів на місяць:",
      paymentPlanLeadsLimitForTest: "Ваш ліміт для тесту квізів:",
      paymentPlanLeadsLeft: "Залишилося лідів:",
      paymentPlanPrice: "Ціна:",
      paymentPlanRenew: "Дата продовження тарифу:",
      addNewSectionHeader: "Додати новий розділ",
      addNewSectionList: "Список",
      addNewSectionCustom: "Поле вводу",
      addNewSectionCard: "Картки з фото",
      addNewSectionWithEmoji: "Список з емодзі",
      addNewSectionTags: "Теги",
      addNewSectionSlider: "Повзунок",
      templatesBackToQuiz: "Повернутися до воркборда",
      constructorStepsSaved: "Всі дані квіза успішно збережено",
      constructorDesignSaved: "Дизайн квіза успішно збережено",
      azureBlueWhite: "Стандартний",
      azureBlue: "Зимова ніч",
      moderatePurplishRed: "Червоний оксамит",
      violetBlueCrayola: "Зимовий бриз ",
      wetRainforest: "Літній клен",
      lavender: "Космос",
      blueLilac: "Темна лаванда",
      opalGreen: "Карпатській ліс",
      dustyBlue: "Тіффані",
      almondCrayola: "Кораловий риф",
      periwinkleCrayolaLilac: "Бузок",
      periwinkleCrayolaAqua: "Синевір",
      jazzJam: "Джаз",
      slateGrayBlue: "Мгла",
      turquoiseBlueCrayola: "Дія",
      blackPearlescentOpal: "Попіл",
      pearlescentGentianBlue: "Сапфір",
      sapphireBlue: "Темний сапфір",
      errorUserDataUpdate: "Помилка в оновлені даних: ",
      completeUserDataUpdate: "Дані користувача оновлені",
      passwordDoNotCoincidence: "Паролі не співпадають",
      passwordDoNotCoincidenceAgain: "Спробуйте ще раз",
      nonBillingsYet: "У вас ще немає історії платежів.",
      youCanChangePlan: "Ви можете змінити поточний план, натиснувши кнопку «Перейти на PRO»",
      rangeMin: "Мін",
      rangeMax: "Макс",
      rangePositionStart: "Початкова позиція",
      rangePositionEnd: "Кінцева позиція",
      rangeStep: "Крок",
      rangeIsRange: "Діапазон",
      rangeIsRangeTrue: "Увімкнено",
      rangeIsRangeFalse: "Вимкнено",
      recivePromotionNewsletter: "Отримувати імейл розсилки",
      reciveEmailCheckbox: "Чекбокс отримання Імейлів",
      additionalCheckbox: "Додатковий чекбокс",
      contactPrivacyPolicyCheckbox: "Чекбокс згоди з приватністю",
      contactAgreeIAgreeWith: "Я погоджуюсь з",
      contactAgreePolicy: "політикою конфіденційності",
      contactAgreeAnd: "та",
      contactAgreeTermsOfUse: "правилами використання",
      contactAgreeAgreementAdditionalWord: "",
      langEN: "Англійська",
      langDE: "Німецька",
      langSP: "Іспанська",
      langPL: "Польська",
      langIT: "Італійська",
      langKK: "Казахська",
      langRU: "Російська",
      langUA: "Українська",
      langLT: "Литовська",
      langLV: "Латвійська",
      langET: "Естонська",
      langRO: "Румунська",
      leadsLeft: "Залишилось лідів",
      generateDomainTitle: "Введіть ім'я домену",
      generateDomainDescription: "Це унікальна адреса, за якою буде доступна ваша сторінка. Ви можете будь-коли змінити адресу або підключити свою.",
      generateDomainDomainNameLabel: "Доменне ім'я",
      cancelAllAction: "Відмінити",
      confirmAllAction: "Підтвердити",
      falseHasValidCharacters: "Недопустимі символи в домені. Допустимими символами є літери латинської абетки, цифри і дефіси.",
      falseHasValidLength: "Довжина домену повинна бути від 6 до 20 буквено-цифрових символів.",
      falseDoesNotStartOrEndWithSpecialChar: "Домен не повинен починатися або закінчуватися спеціальним символом.",
      falseDoesNotHaveConsecutiveSpecialChar: "Домен не повинен містити два або більше підряд спеціальних символа.",
      falseDoesNotHaveConsecutiveSameChar: "Домен не повинен містити три або більше підряд однакових символа.",
      falseDoesNotContainForbiddenSubstrings: "Домен не повинен містити заборонені підстроки.",
      generateDomainTaken: "Цей домен вже зайнято. Спробуйте інший",
      temporarilyUnavailable: "Тимчасово недоступно",
      csettingsAdmin: "Налаштування для адмінів",
      questionLogicSetupTitle: "Налаштування логіки питання",
      questionLogicSetupDescription: "Додайте умови, щоб налаштувати опитування для кожного респондента і збирати більш точні дані.",
      questionLogicConditionTitle: "Умова",
      questionLogicQuestion: "Питання",
      questionLogicSetupQuestionDisableOption: "Оберіть питання",
      questionLogicSetupAnswer: "Відповідь",
      questionLogicSetupLogicType: "Тип",
      questionLogicSetupAnswerDisableOption: "Оберіть тип умови",
      questionLogicSetupRangeBetween: "Між",
      questionLogicSetupRangeLess: "Менше",
      questionLogicSetupRangeMore: "Більше",
      questionLogicSetupRangeEqualTo: "Дорівнює",
      questionLogicSetupRangeFrom: "Від",
      questionLogicSetupRangeTo: "До",
      questionLogicSetupVariantAll: "Обрані всі",
      questionLogicSetupVariantAny: "Обрано один з",
      questionLogicSetupVariantNot: "Жоден з обраних",
      questionLogicSetupCustomEqual: "Дорівнює",
      questionLogicSetupCustomContains: "Містить",
      questionLogicSetupCustomNotContains: "Не містить",
      questionLogicSetupRangeValue: "Значення",
      questionLogicSetupExpectedAnswer: "Очікувані відповіді",
      questionLogicSetupPlaceholder: "Введіть тут",
      questionLogicAllMandatory: "Всі наведені умови обов'язкові",
      questionLogicAddConditionBtn: "Додати умову",
      questionLogicNotAvailable: "Налаштування логіки недоступні для першого питання",
      questionLogicSaved: "Налаштування логіки відображення кроку збережені",
      billingPaymentInfoTitle: "Тарифна інформація",
      billingPaymentInfoChangeTarrif: "Змінити тариф",
      billingPaymentInfoDescription: "При оплаті  будь-якого платного тарифу ви отримуєте певну кількість заявок які входить в обраний вами пакет. Ці заявки надаються на місяць. Якщо ви не витратили вашу кількість заявок на через місяць  моменту оплати - заявки анулюються.",
      billingGeneralName: "Ваш тариф",
      billingGeneralCost: "Вартість",
      billingMonth: "місяць",
      billingGeneralExpired: "Дійсний до",
      billingGeneralLeadsLeft: "Заявок залишилось",
      billingGeneralAllTarifExpired: "Термін дії тарифів закінчено",
      billingGeneralBonus: "Лідів по промокоду",
      billingGeneralLeadsFromPastTarifs: "Залишок лідів з минулих тарифів",
      billingAcordeonPaymentInfoTitle: "Платіжна інформація",
      billingAcordeonPaymentInfoFormat: "Формат",
      billingAcordeonPaymentInfoSubscription: "Підписка",
      billingAcordeonPaymentInfoPay: "Оплата",
      billingAcordeonPaymentInfoPayMethod: "Платіжний метод",
      billingAcordeonPaymentInfoCancelSubscription: "Скасувати підписку",
      billingAcordeonPromocode: "Промокод",
      billingAcordeonPromocodeLabel: "Введіть промокод",
      billingAcordeonPayHistory: "Історія транзакцій",
      paymentAllTarifInactive: "тариф неактивний",
      subscriptionUnsubscribeModalTitle: "Відписатись",
      subscriptionUnsubscribeModalDescription: "Ви впевнені що хочете відписатись? Нараховані за підпискою ліди ви зможете використовувати протягом місяця з дня останнього списання коштів за підпискою.",
      subscriptionIsNotDeleteTitle: "Підписка не була видалена",
      subscriptionIsNotDeleteDescription: "Зверніться в службу підтримки",
      subscriptionCanceledTitle: "Підписка анулована",
      modalSureUnsavedChangesTitle: "У вас є незбережені зміни",
      modalSureUnsavedChangesDescription: "Перед тим як покинути поточну сторінку, будь ласка, вирішіть що зробити з незбереженими змінами",
      modalSureUnsavedChangesDontSave: "Не зберігати",
      modalSureUnsavedChangesSave: "Зберегти",
      telegramTokenCopied: "Токен квізу скопійовано в буфер обміну",
      cSettingsSettingQuizBackButtonTitle: "Кнопка назад",
      cSettingsSettingQuizBackButtonDescription: "Ви можете увімкнути або вимкнути кнопку повернення на попередній крок. Це надасть можливість змінювати дану раніше відповідь.",
      cSettingsCustomThemePrimaryColor: "Колір акцентів",
      cSettingsCustomThemeSecondaryColor: "Фоновий колір",
      cSettingsCustomThemeAdditionalColor: "Колір тексту",
      cSettingsCustomThemeButtonTextColor: "Текст в кнопках",
      cSettingsCustomThemeModeDark: "Темна",
      cSettingsCustomThemeModeLight: "Світла",
      allstepsPlaceholder: "Плейсхолдер",
      allstepsCustomInput: "Свій варіант",
      allstepsCustomInputOn: "Увімкнути",
      allstepsCustomPlaceholder: "Власний варіант ",
      trashcanTitle: "Кошик",
      trashcanImportant: "Важливо!",
      trashcanDescription: "Квіз буде автоматично видалено назавжди через 30 днів після переміщення в кошик.",
      trashcanClearAll: "Очистити все",
      quizSlotDeletedData: "Видалено",
      workboardLeads: "Ліди",
      workboardDuplicate: "Дублювати",
      leadsTable: "Список лідів",
      exportInfo: "Експорт лідів",
      leadHide: "Сховати",
      leadShowMore: "Детальніше",
      poperTrashcan: "Кошик",
      restoreQuizSendedTitle: "Запущена задача відновлення",
      restoreSuizSendedImportant: "Важливо",
      restoreQuizSendedDescription: "В залежності від того, як багато лідів було у вашому квізі, відновлення квізу може зайняти від декількох секунд до 2-х хвилин. Після відновлення квіз з'явиться на вашому основному воркборді.",
      ok: "Зрозуміло",
      month: "0 місяців | {n} місяць | {n} місяці | {n} місяців",
      discount: "Знижка до",
      saveMoney: "Підписка на рік",
      upProVersionMobile: "На PRO",
      upProVersionDesktop: "Перейти на PRO",



      
      csettingsIntegrationSendPulse: "Інтеграція - Send Pulse",
      csettingsIntegrationMake: "Інтеграція - Make",
      csettingsIntegrationZapier: "Інтеграція - Zapier",
      csettingsIntegrationCRMBitrix: "Інтеграція - CRM Bitrix",
      csettingsIntegrationPipeDrive: "Інтеграція - Pipe Drive",
      csettingsIntegrationRemOnline: "Інтеграція - Rem Online",
      csettingsIntegrationKeyCRM: "Інтеграція - Key CRM",
      
      csettingsIntegrationZapierAbout: "Вставте URL-адресу для відправки данних, надану Zapier, в поле для вводу.",
      csettingsIntegrationMakeAbout: "Вставте URL-адресу для відправки данних, надану Make, в поле для вводу.",
      csettingsIntegrationMakeAboutTextStep1: "Для налаштування інтеграції з",
      csettingsIntegrationMakeAboutTextStep2: "перегляньте",
      csettingsIntegrationMakeAboutTextStep3: "Для налаштування виберіть серед інтеграцій на стороні Make – “Webhook”, далі “Webhook response”.",
      csettingsIntegrationMakeAboutTextStep4: "Скопіюйте отримане посилання та вставте в поле нижче в ADS Quiz.",
      csettingsIntegrationMakeAboutTextStep5: "Для тестування поверніться в Make, натисніть кнопку “Run” і пройдіть квіз, надіславши одну заявку.",
      csettingsIntegrationMakeAboutTextStep6: "У разі успішного тестування збережіть інтеграцію та не забудьте активувати оновлення.",
      csettingsIntegrationMakeAboutLink: "https://www.make.com/en/register?pc=adschool",
      csettingsIntegrationMakeAboutLinkVideo: "https://www.youtube.com/embed/3ZyagaBQmiI",
      csettingsIntegrationMakeAboutLinkVideoText: "відео",
      csettingsIntegrationCRMBitrixAbout: "Вставте URL-адресу для відправки данних, надану CRM Bitrix, в поле для вводу.",

      csettingsIntegrationSendPulseAboutStart: "Ви можете налаштувати інтеграцію з сервісом ",
      csettingsIntegrationSendPulseAboutMiddle: " Для цього перейдіть у налаштування вашого облікового запису та відкрийте вкладку ",
      csettingsIntegrationSendPulseAboutEnd: " Скопіюйте значення параметрів 'ID' і 'Secret', а потім вставте їх у відповідні поля в сервісі ADS Quiz (у поля нижче). Далі потрібно вставити ID адресної книги. Для цього перейдіть до потрібної адресної книги та скопіюйте з URL-адреси сторінки значення після id/. Потрібні лише цифри без додаткових символів. Збережіть зміни. Більш детальна інструкція у ",
      csettingsIntegrationSendPulseAboutLinkSendPulse: "https://sendpulse.ua/#60c9ccfd55f4b",
      csettingsIntegrationSendPulseAboutLinkAPI: "https://login.sendpulse.com/login/#api",
      csettingsIntegrationSendPulseAboutLinkVideo: "https://www.youtube.com/watch?v=4mnhpbKkePs",
      csettingsIntegrationSendPulseAboutLinkVideoText: "відео",

      csettingsIntegrationKeyCRMAbout: "Вставте API ключ та PipelineID, у відповідні поля для вводу.",
      csettingsIntegrationPipeDriveAbout: "Вставте токен та домен компанії, у відповідні поля для вводу.",
      csettingsIntegrationRemOnlineAbout: "Вставте API ключ Rem Online у відповідне поле для вводу.",
      csettingsIntegrationActiveIntegration: "Активна інтеграція",
      cintegrationIntegrationData: "Дані інтеграції",
      cintegrationIntegrationUrl: "URL інтеграції",
      cintegrationWriteUrl: "Введіть URL",
      cintegrationDataFBError: "Неправильний ідентифікатор пікселя",
      cintegrationDataWebHookError: "Неможлива адреса вебхуку",
      cintegrationZapierDataSaved: "Дані інтеграції з Zapier збережено",
      cintegrationDataZapierError: "Неможлива адреса інтеграції",
      cintegrationAPIxDriveDataSaved: "Дані інтеграції з APIxDrive збережено",
      cintegrationDataAPIxDriveError: "Рядок не є адресою інтеграції APIxDrive ",
      cintegrationMakeDataSaved: "Дані інтеграції з Make збережено",
      cintegrationDataMakeError: "Неможлива адреса інтеграції",
      cintegrationSendPulseDataSaved: "Дані інтеграції з SendPulse збережено",
      cintegrationDataSendPulseError: "Неможливі або порожні дані інтеграції",
      cintegrationPipeDriveDataSaved: "Дані інтеграції з PipeDrive збережено",
      cintegrationDataPipeDriveError: "Неможливі або порожні дані інтеграції",
      cintegrationRemOnlineDataSaved: "Дані інтеграції з RemOnline збережено",
      cintegrationDataRemOnlineError: "Неможливі або порожні дані ключ",
      cintegrationKeyCRMDataSaved: "Дані інтеграції з KeyCRM збережено",
      cintegrationDataKeyCRMError: "Неможливі або порожні дані інтеграції",
      cintegrationInputEmailUrl: "Введіть Email",
      cintegrationEmailDataSaved: "Email збережено",
      cintegrationDataEmailError: "Введений рядок не є Імейлом",
      cintegrationCRMBitrixDataSaved: "Email збережено",
      cintegrationDataCRMBitrixError: "Введений рядок не є Імейлом",
      cintegrationTelegramBotDataSaved: "Налаштування Telegram інтеграції збережено",
      cintegrationDataTelegramBotError: "Помилка збереження",
      cintegrationGTMDataSaved: "Ідентифікатор Google Tag Manager збережено",
      cintegrationDataGTMError: "Невірний ідентифікатор",
      cintegrationDataGTMErrorDescription: "Ідентифікатор повинен мати вигляд GTM-XXXXXX",
      cintegrationGoogleAnalyticsDataSaved: "Ідентифікатор Google Analytics збережено",
      cintegrationDataGoogleAnalyticsError: "Неправильний ідентифікатор",
      cintegrationDataGoogleAnalyticsErrorDescription: 'Ідентифікатор повинен мати вигляд "UA-XXXXXXX-X" або "G-XXXXXXXXXX-XX"',
      cintegrationTikTokDataSaved: "Tik-Tok піксель збережено",
      cintegrationDataTikTokError: "Введений рядок не є Tik-Tok пікселем",
      cintegrationIntegrationToken: "Токен інтеграції",
      cintegrationWriteToken: "Введіть токен інтеграції",
      cintegrationDataYourAPIKey: "Введіть ваш API ключ",
      viewQuizInMobile: "Переглянути квіз",
      notificationCSRFTokenMismatch: "Невідповідність CSRF токену",
      notificationReloadPage: "Перезавантажте сторінку та спробуйте ще раз",
      csettingsIntegrationKeyRemOnline: "Вставте токен та ідентифікатор каналу у відповідні поля",
      cintegrationDataYourIntegrationToken: "API ключ",
      cintegrationDataLeadTypeId: "Ідентифікатор каналу",
      send_utm: "Відправляти UTM мітки",
      send_quiz: "Відправляти дані квізу",
      send_answers: "Відправляти питання і відповіді",
      csettingsIntegrationKeyCrm: "Інтеграція KeyCRM",
      cintegrationSendPulseClientId: "ID клієнта",
      cintegrationSendPulseClientSecret: "Secret",
      cintegrationSendPulseAddressBookId: "ID адресної книги",
      cintegrationSendPulseAction: "Дія з контактом",
      sendpulseActionAddContact: "Додати контакт",
      sendpulseActionDeleteContact: "Видалити контакт",
      cintegrationPipeDriveToken: "API токен",
      cintegrationPipeDriveDomain: "Ваш унікальний субдомен",
      cintegrationKeyCrmApiKey: "API ключ",
      cintegrationKeyCrmPipelineId: "Ідентифікатор воронки",
      cintegrationMenuGoogleTagManager:"Google Tag Manager",
      cintegrationMenuGoogleAnalytics:"Google Analytics",
      cintegrationMenuFacebookPixel:"Facebook pixel",
      cintegrationMenuTikTokPixel:"TikTok Pixel",
      cintegrationMenuWebHook:"Webhook",
      cintegrationMenuZapier:"Zapier",
      cintegrationMenuAPIxDrive:"APIx Drive",
      cintegrationMenuMake:"Make",
      cintegrationMenuSendPulse:"Send Pulse",
      cintegrationMenuEmail:"Email",
      cintegrationMenuTelegramBot:"Telegram-бот",
      cintegrationMenuPipeDrive:"PipeDrive",
      cintegrationMenuRemOnline:"Rem Online",
      cintegrationMenuKeyCRM:"Key CRM",
      cintegrationMenuCRMBitrix:"CRM Bitrix",
      trashcanRestoreQuiz: "Відновити квіз",
      cformValuePromotionAccepted: "Погоджуюсь на емейл розсилку",


      constructorAddIntegration: "Додати нову інтеграцію",
      cintegrationKeyCrmSourceId: "Ідентифікатор джерела (необов'язково)",
      cintegrationKeyCrmManagerId: "Ідентифікатор менеджера (необов'язково)",
      cSettingsSettingQuizSpamFilterTitle: "Захист від спаму",
      cSettingsSettingQuizSpamFilterDescription: "Функціонал за замовчуванням вимкнутий. Якщо ви зіштовхнулись з проблемою спамових лідів - увімкніть захист. Після цього, один користувач не зможе відправити більше вказаної кількості лідів на цей квіз.",
      cSettingsSettingQuizSpamFilterLimitPlaceholder: "Кількість лідів",
      langNO: "Норвезька",
      loadMoreLeads: "Переглянути більше лідів",
      userAdditionalSettingsHeader: "Додаткові налаштування",
      userAdditionalSettingsDescription1: "Тут ви можете налаштувати нові додаткові параметри профілю.",
      userAdditionalSettingsPagination: "Пагінація на воркборді",
      
      benefitType: "Тип бонусу",
      benefitLabelPlaceholder: "Текст бонусу",
      benefitDescriptionPlaceholderDiscount: "Промокод або інструкція",
      benefitDescriptionPlaceholderVideo: "Посилання на відео",
      benefitDescriptionPlaceholderTextfile: "Посилання на текстовий файл",
      benefitDescriptionPlaceholderGift: "Розшифровка бонусу",
      benefitDescriptionPlaceholderPDF: "Посилання на PDF",
      benefitDescriptionPlaceholderLink: "Посилання",
      benefitDescriptionPlaceholderFile: "Посилання на файл",
      benefitLabelDiscount: "Знижка",
      benefitLabelVideo: "Відео",
      benefitLabelTextfile: "Текстовий файл",
      benefitLabelGift: "Подарунок",
      benefitLabelPDF: "PDF файл",
      benefitLabelLink: "Посилання",
      benefitLabelFile: "Файл",
      addBenefit: "Додати бонус",
      saveBenefit: "Зберегти бонус",
      cfinishSocialNetworks: "Соціальні мережі",
      
      saveSocialNet: "Зберегти соціальну мережу",
      networkLabelFacebook: "Facebook",
      networkLabelInstagram: "Instagram",
      networkLabelTelegram: "Telegram",
      networkLabelTiktok: "Tik-Tok",
      networkLabelYoutube: "Youtube",
      networkLabelLinkedin: "LinkedIn",
      networkLabelWhatsapp: "WhatsApp",
      socialNetDescriptionPlaceholderFacebook: "нікнейм",
      socialNetDescriptionPlaceholderInstagram: "нікнейм",
      socialNetDescriptionPlaceholderTelegram: "нікнейм / канал",
      socialNetDescriptionPlaceholderTiktok: "нікнейм",
      socialNetDescriptionPlaceholderYoutube: "нікнейм_каналу",
      socialNetDescriptionPlaceholderLinkedin: '"in/name" або "company/name"',
      socialNetDescriptionPlaceholderWhatsapp: "+15555551234",

      addNewSectionEmptyPage: "Текстова сторінка",
      csanyMakeImpasse: "Зробити тупиковим кроком",
      impaseIsImpaseTrue: "Увімкнено",
      impaseIsImpaseFalse: "Вимкнено",
      defaultNewEmptyQuestionDescription: "Тут можна вставляти та форматувати довгий текст (ми не рекомендуємо вставляти більше 3000 символів).",
      cSettingsSettingQuizTimezoneTitle: "Таймзона квізу",
      cSettingsSettingQuizTimezoneDescription: "Впишіть таймзону відповідно якої буде вказуватись час заповнення лідів. Це зручно використовувати, особливо тоді, коли ви знаходитесь в одному часовому поясі, а клієнт в іншому. В такому разі, ви можете вказати часовий пояс клієнта і в лідах які будуть приходити, буде вказано час згідно часового поясу клієнта.",
      cSettingsSettingQuizTimezonePlaceholder: "Часовий пояс",
      userAdditionalSettingsTimezone: "Часовий пояс користувача",
      userAdditionalSettingsTimezonePlaceholder: "Часовий пояс",
      langHE: "Іврит",
      langCS: "Чеська",
      cintegrationCAPIFBInstruction: "Щоб налаштувати інтеграцію з Facebook Conversion API, вам необхідно ввести ідентифікатор пікселя та маркер доступу. Ці дані можна отримати в налаштуваннях Facebook Business Manager. Після введення необхідної інформації збережіть інтеграцію.",
      cintegrationCAPIFBInstructionTextTitle: "Назви подій для FB Conversions:",
      cintegrationCAPIFBInstructionTextStart: " – відвідування стартової сторінки;",
      cintegrationCAPIFBInstructionTextQuestionN: "– відвідування кроку N (номер кроку підставляється динамічно);",
      cintegrationCAPIFBInstructionTextOpenContactPage: " – відвідування сторінки контактів;",
      cintegrationCAPIFBInstructionTextLead: " – успішне відправлення форми з контактними даними;",
      cintegrationCAPIFBInstructionTextThankYou: "– відвідування фінальної сторінки.",
      cintegrationCAPIFBInstructionTextOptimizationAdvice: "Ми рекомендуємо запускати рекламу з оптимізацією на подію",
      cintegrationCAPIFBInstructionTextLeadTriggerCondition: "Это событие отправляется",
      bold_capi_adsquiz_Start: "capi_adsquiz_Start",
      bold_capi_adsquiz_QuestionN: "capi_adsquiz_Question-N",
      bold_capi_adsquiz_OpenContactPage: "capi_adsquiz_OpenContactPage",
      bold_capi_adsquiz_Lead: "capi_adsquiz_Lead",
      bold_capi_adsquiz_ThankYou: "capi_adsquiz_ThankYou",
      bold_capi_adsquiz_OptimizationAdvice: "“capi_adsquiz_Lead”.",
      bold_capi_adsquiz_LeadTriggerCondition: "тільки у разі успішного отримання заявки від користувача.",
      csettingsIntegrationFbCapiAbout: "Інтеграція з Facebook Conversion API дозволяє передавати дані про взаємодії користувачів безпосередньо в Facebook для підвищення ефективності рекламних кампаній.",
      cintegrationInputCapiAccessToken: "Введіть маркер доступу",
      cintegrationInputCapiCustomparameter: "Введіть кастомні параметри подій",
      cintegrationDataFbCapiSaved: "Інтеграція з Facebook Conversion API успішно збережена",
      cintegrationComonRenovateHubInstructionTitle: "Інструкція з налаштування інтеграції з RenovateHub",
      cintegrationComonRenovateHubInstructionDescription: "Для налаштування інтеграції з RenovateHub вкажіть URL вебхука, на який будуть надсилатися дані. Переконайтеся, що URL правильний і доступний для прийому даних.",
      cintegrationComonRenovateHubInstructionShort: "Інтеграція з RenovateHub дозволяє передавати дані в CRM через вебхук для автоматизації процесів.",
      cintegrationRenovateHubUrl: "Введіть URL RenovateHub",
      cintegrationInputRenovateHubUrl: "Вкажіть URL вебхука для RenovateHub",
      cintegrationRenovateHubDataSaved: "Інтеграція з RenovateHub успішно збережена",
      cintegrationDataRenovateHubError: "Помилка при збереженні інтеграції з RenovateHub. Перевірте правильність введених даних.",
      cintegrationMenuFacebookConvAPI: "Facebook Conversions API",
      cintegrationMenuRenovateHub: "RenovateHub",
      csettingsIntegrationRenovateHub: "Інтеграція з RenovateHub",
      csettingsIntegrationCAPIFB: "Інтеграція з Facebook Conversions API",
      paymentPlanEnd: "Дата закінчення тарифу",
      paymentPlanLeadsLimitForYear: "Ліміт лідів на рік:",
      paymentPlanLeadsLimitForPeriod: "Ліміт лідів на період:",
      EditTags: "Змінити теги",
      quizzesTemplatesNoTags: "Без тегів",
      Monday: "Понеділок",
      Tuesday: "Вівторок",
      Wednesday: "Середа",
      Thursday: "Четвер",
      Friday: "П’ятниця",
      Saturday: "Субота",
      Sunday: "Неділя",
      datetimeDisplayMode: "Тип вибору",
      datetimeDisplayModeSelect: "Оберіть тип",
      datetimeDisplayModeDate: "Дата",
      datetimeDisplayModeTime: "Час",
      datetimeDisplayModeDatetime: "Дата і час",
      datetimeMinDate: "Мін. дата",
      datetimeMinDateCustom: "Вкажіть мін. дату",
      datetimeMaxDate: "Макс. дата",
      datetimeMaxDateCustom: "Вкажіть макс. дату",
      datetimeProhibitDaysOfWeek: "Обмежити дні",
      datetimeIsRange: "Діапазон",
      datetimeIsRangeTrue: "Діапазон увімкнено",
      datetimeIsRangeFalse: "Діапазон вимкнено",
      datetimeMinTime: "Мін. час",
      datetimeMaxTime: "Макс. час",
      datetimeIs24: "Формат 24 години",
      datetimeIs24True: "Формат 24 год увімкнено",
      datetimeIs24False: "Формат 24 год вимкнено",
      csanyIsExactDate: "Як ви бажаете налаштовувати дату",
      csanyIsExactDateTrue: "Точна дата",
      csanyIsExactDateFalse: "Відносна дата",
      csanyDatetimeMinMaxNotExactPlaceholder: '"x" або "-x" днів від сьогодні',
      addNewSectionDatetime: "Дата/час",
      tariffsPromo: "Акційні пропозиції",
      tariffsMonth: "Щомісячно",
      tariffsAnual: "Щорічно",
      leadsOn1Month: "на місяць",
      leadsOn2Month: "на 2 місяці",
      leadsOn3Month: "на 3 місяці",
      leadsOn4Month: "на 4 місяці",
      leadsOn5Month: "на 5 місяців",
      leadsOn6Month: "на 6 місяців",
      leadsOn7Month: "на 7 місяців",
      leadsOn8Month: "на 8 місяців",
      leadsOn9Month: "на 9 місяців",
      leadsOn10Month: "на 10 місяців",
      leadsOn11Month: "на 11 місяців",
      leadsOn12Month: "на рік",
      leadsOn24Month: "на 2 роки",
      leads: "лідів",
      tariffsWordPrice: "Ціна",
      tariffsNoLimits: "Без обмежень",
      tariffsNoLimitsQuizzesAndFunctions: "у квізах та функціоналі",
      billingTariffYear: "рік",
      billingChangePlanSubscribe: "Підписатись",
      paymentAllTariffInactive: "Тариф не активний",
      subscriptionTariffLableOnly: "Доступно лише для підписки",
      notExactBeforeYear: "рік тому",
      notExactBeforeHalfYear: "пів року тому",
      notExactBeforeQuarter: "чверть року тому",
      notExactBeforeMonth: "місяць тому",
      notExactBeforeWeek: "тиждень тому",
      notExactBeforeYesterday: "позавчора",
      notExactYesterday: "вчора",
      notExactToday: "сьогодні",
      notExactTomorrow: "завтра",
      notExactAfterTomorrow: "післязавтра",
      notExactAfterWeek: "за тиждень",
      notExactAfterMonth: "за місяць",
      notExactAfterQuarter: "за чверть року",
      notExactAfterHalfYear: "за пів року",
      notExactAfterYear: "за рік",
      tipDefaultDateHeader: "За замовчуванням",
      tipDefaultDateText: "Якщо обидва перемикачі 'Точна дата' та 'Відносна дата' вимкнені, календар дозволятиме вибирати дати в діапазоні від 1800 до 2200 року за замовчуванням.",
      tipExactDateHeader: "Перемикач точної дати",
      tipExactDateText: "Увімкнення цього перемикача обмежить календар фіксованим діапазоном дат, які вибрані в полях введення нижче.",
      tipRelativeDateHeader: "Перемикач відносної дати",
      tipRelativeDateText: "Увімкнення цього перемикача динамічно встановить обмеження календаря на основі відносних дат, які обчислюються під час завантаження сторінки. Ці обмеження персоналізовані та перераховуються для кожного користувача окремо.",
      langPT: "Португальська",
      placeholderCompanyContactText: 'Наприклад: "Телефон компанії: +380993322211"',
      integrationConnectService: "Підключити",
      leadtype_id: "Тип дії",
      selectVariant: "Оберіть",
      sfirstAddCompanyContacts: "Текст з контактами",
      addNewSectionTextarea: "Великий інпут",
      allstepsVariantView: "Формат зображення",
      tariffsDiscountSave: "Економія",
      cAdminSettingsTitle: "Адмін налаштування",
      cAdminSettingsInstruction: "Впишіть в поле нижче теги для квізу. Теги можуть складатися з декількох слів, кожен тег відділяється комою. Теги 'Послуги' і 'послуги' - це різні теги. Варто використовувати теги які починаються з великої літери. Також, можна виключити квіз зі списку шаблонів.",
      cAdminSettingsIsTemplate: "Чи являється шаблоном",
      cIsTemplateStateAreSaved: "Стан шаблону збережено",


      sfirstAddLogotype: "Додайте логотип",
      logotypeSizeTip: "Логотип буде вставлено в реальному розмірі, але не більше 120 пікселів по більшій зі сторін.",
      cfirstLogotypeUpdated: "Логотип додано",
      cfirstLogotypeDeleted: "Логотип успішно видалено!",


      cOffersPageSettings: "Налаштування",
      offerPageTitlePlaceholder: "Введіть заголовок",
      offerPageDescriptionPlaceholder: "Введіть опис для сторінки",
      offerPageButtonTextPlaceholder: "Введіть текст кнопки (необов'язково)",
      allstepsOffers: "Список офферів",
      offerProposalHeader: "Оффер",
      offerAttachAddImg: "Додати зображення",
      offerAttachAddVideo: "Додати відео",
      offerItemImg: "Зображення",
      offerItemTitle: "Заголовок офферів",
      offerTitlePlaceholder: "Введіть заголовок тут",
      offerItemSubtitle: "Підзаголовок (наприклад ціна)",
      offerSubtitlePlaceholder: "Введіть текст тут",
      offerItemDescription: "Опис оффера",
      offerDescriptionPlaceholder: "Введіть опис тут",
      offerItemButtonText: "Текст кнопки оффера",
      offerBtnTextPlaceholder: "Введіть текст кнопки",
      offerItemButtonLink: "Посилання для кнопки",
      offerBtnLinkPlaceholder: "Посилання починайте з https://",
      csanyOfferLogic: "Логіка відображення",
      offerAddNewOffer: "Додати новий оффер",

      offerLogicSetupTitle: "Налаштування логіки",
      OfferLogicSetupDescription: "Тут ви можете налаштувати при яких відповідях будуть відображатись ті чи інші офферри",
      cstepsOffersPage: "Сторінка результатів",
      addNewSectionResultsByLogic: "Сторінка результатів",
      cformActionAfterContactOffersOr: "Сторінка з офферами / ",
      tolltipOffersAfterContact: "Якщо після проходження квізу, логіка відображення, хоча б одного офферу буде виконана, буде відображено сторінку з офферами, інакше - Сторінку подяки",
      videoCircleTextQuestion: "Як це працює?",
      offerPhotoOptimalRatio: "Для оптимального відображення зображення радимо використовувати пропорції 16:9",
      tolltipThankYouAfterContact: "Після проходження квізу, буде відображено сторінку вдячності",
      cformActionAfterContactOffers: "Сторінка результатів",
      addOfferPageInContact: "Увімкнути сторінку результатів",
      offerPageHeader: "Заголовок для сторінки офферів",
      pumkinOrange: "Помаранчевий",



    },
    EN: {
      madeInUkr: "made in Ukraine",
      upProVersion: "Switch to PRO",
      getLeads: "Get Leads",
      poperBillings: "Billings",
      poperTemplates: "Templates",
      poperPartnership: "Partnership",
      poperProfile: "Profile",
      poperLogout: "Log Out",
      defaultNewQuestion: "New Question",
      defaultNewQuestionDescription: "Here you can insert a short description of the question (up to 80 characters)",
      defaultNewVariant: "New Option",
      defaultBtnText: "Start",
      navBillings: "Billings",
      navPartnership: "Partnership",
      navProfile: "Profile",
      navTariffs: "Tariffs",
      welcomeBack: "Welcome Back!",
      loginHeader: "Sign In",
      loginHeader2Part: "to Continue",
      loginEmailPlaceholder: "E-mail",
      loginPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      loginBtnValue: "Sign In",
      loginWith: "Login with",
      passwordAreForgetPassword: "Forgot your password?",
      passwordResetPasswordHeader: "Password reset",
      passwordResetPassword: "Reset password",
      passwordWeSentReset: "Follow the instructions in the email we send you to reset your password",
      passwordInputNewPassword: "Enter a new password",
      passwordConfirmPassword: "Confirm password",
      passwordChangePassword: "Save password",
      passwordResetedPasswordCompleted: "Your password has been successfully changed!",
      passwordGoToWorkboard: "Go to Workboard",
      loginSignup: "Sign up",
      helloHeader: "Hello!",
      signupHeader: "Sign up",
      signupHeader2Part: "to Continue",
      signupNamePlaceholder: "Oleksander",
      signupPhonePlaceholder: "phone number",
      signupEmailPlaceholder: "E-mail",
      signupPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      signupBtnValue: "Create an account",
      signupWith: "Sign up with",
      signupLogin: "Login to an account",
      signupTariffHeader: "Tarif",
      signupTariffFree: "Free.",
      signupTariffPro: "Pro.",
      signupTariffFreeDescription: "Unlimited number of creation of new quizzes (web pages). Ability to generate up to 300 applications per month for free.",
      signupTariffProPrice: "$20/month",
      signupTariffProDescription: "Unlimited number of creation of new quizzes (web pages). Ability to generate ",
      signupTariffProDescriptionBold: "up to 300 applications per month.",
      userBackToQuiz: "Back to Workboard",
      userBackToSettings: "Back to Settings",
      billingYourTariff: "Your plan",
      billingYourPro: "Pro",
      billingYorFree: "Free",
      billingFreeLeads: "Free leads",
      billingInfinity: "unlimited",
      billingTariffRenewData: "Tariff renew date",
      billingTariffEndData: "End date of the plan",
      billingOtherTariffFree: "Free.",
      billingOtherTariffPro: "Pro.",
      billingTariffFreeDescription: "Unlimited number of creation of new quizzes (web pages). Ability to generate up to 300 applications per month for free.",
      billingYouHavePro: "If you're currently on the Pro version, why take a step back?",
      billingYouHasFreeLeads: "Free leads",
      billingYouHasLeads: "Leads left",
      billingTariffProDescription: "Unlimited number of new quizzes (web pages). Ability to generate ",
      billingTariffProDescriptionBold: "more than 300 applications.",
      billingYourFutureTariff: "Your future plan",
      billingBtnChangeToPro: "switch to ",
      billingBtnRenewPro: "Renew",
      billingBillings: "Billings",
      billingYouHaveNotBillings: "You have no payments yet",
      billingPaymentsMethod: "Payment method",
      billingPaymentCard: "Card",
      billingChangeData: "Change data",
      billingDeletePayMethod: "Delete",
      billingModalDeleteShure: "Are you sure you want to remove this payment method?",
      billingModalDeleteCancel: "Cancel",
      billingModalDeleteOk: "Delete",
      billingModalDeleteSuccess: "You have successfully deleted your card",
      billingModalRegularPaymentDeleteSuccess: "Regular payments are cancelled",
      billingModalRegularPaymentDeleteOk: "ОК",
      billingYourMonthPlan: "Your monthly tarif",
      billingLeads: "leads",
      billingChangePlan: "Change the tarif",
      billingChangePlanDescription: "The price will correspond to the desired number of applications from all quizzes in your account.",
      billingSelectPeriod: "Select period",
      billingChangePlanDescriptionImportant: "Important:",
      billingChangePlanDescriptionImportantMonth: "Leads can be used 1 month after purchase.",
      subscriptionTariffLable: "Subscribe for our automatic subscription.",
      billingChangePlanWarning: "",
      billingTariffMonth: "mon.",
      billingChangePlanPay: "Buy",
      profileName: "Name",
      profilePhone: "Phone number",
      profileEmail: "E-mail",
      profileStatus: "Status",
      profileStatusAgency: "Agency",
      profileStatusPlaceholder: "What described you better?",
      profileStatusFreelancer: "Freelancer",
      profileStatusBuisines: "Business owner",
      profilePassword: "Пароль",
      profileChangePassword: "Change password",
      profileNewPassword: "New password",
      profileSaveNewData: "Update data",
      workboardAddNewQuiz: "Create Project",
      workboardModalGiveQuizName: "Give a name to the quiz",
      workboardModalQuizNamePlaceholder: "New quiz",
      workboardModalNewQuizBtnValue: "add",
      workboardAlertGiveAnyName: "Enter any name",
      workboardAlertNextTimeAddQuiz: "Well, next time, dare)",
      workboardChangeQuizName: "Change the name",
      workboardQuizLink: "link",
      workboardQuizLinkCopied: "The link is copied to the clipboard",
      workboardEditQuiz: "Edit",
      workboardCopyQuiz: "Copy the quiz",
      workboardQuizCopied: "Quiz successfully copied",
      workboardDeleteQuiz: "Delete",
      workboardModalRemoveQuizTrash: "This quiz will be moved to the trash and permanently deleted after 1 month.",
      workboardModalRemoveQuizSure: "Are you sure you want to move this quiz to the trash?",
      workboardModalDeleteQuizCancel: "Cancel",
      workboardModalDeleteQuizDelete: "Delete",
      workboardModalDeleteQuizSuccess: "You have successfully deleted this quiz",
      workboardModalQuizDeleteOk: "ОК",
      workboardStatsTitle: "Statistics",
      workboardModalLeadsAnswersCount: "Answers to questions",
      workboardModalLeadsLeadSerial: "№",
      workboardModalLeadsLeadDate: "Date",
      workboardModalLeadsLeadEmail: "Email",
      workboardModalLeadsLeadName: "Name",
      workboardModalLeadsLeadPhone: "Phone",
      workboardModalLeadsLeadActions: "Actions",
      workboardModalLeadsLeadShowMore: "See more",
      workboardModalLeadsLeadHideMore: "Hide",
      workboardModalLeadsLeadAnswerScipped: "Skipped",
      workboardModalNoLeads: "Пока лидов нет",
      constructorLink: "link",
      constructorLinkCopied: "Copied to clipboard:",
      constructorNavSteps: "Steps",
      constructorNavDesign: "Design",
      constructorNavSettings: "Settings",
      constructorStep: "Step",
      cancel: "Cancel",
      constructorCancel: "Cancel",
      constructorSave: "Save",
      constructorextraNavStart: "S",
      constructorextraNavContact: "C",
      constructorextraNavFinish: "F",
      cstepsSection: "Sections",
      cstepsStartPage: "Start page",
      cstepsContactPage: "Contact form",
      cstepsFinishPage: "Final page",
      cstepsEdit: "Edit",
      cstepsMove: "Move",
      cstepsDeleteStep: "Delete step",
      cstepsAddSection: "Add section",
      cdesignColorTheme: "Color Theme",
      cdesignCustomTheme: "Custom Color Theme",
      cdesignDefaultThemes: "Our Color Themes",
      cdesignThemeStandart: "Default",
      cdesignThemeDark: "Dark",
      cdesignThemeNud: "Nude",
      cdesignThemeGradient: "Gradient",
      cdesignShapeDesign: "Design form",
      cdesignShapeRound: "Rounded",
      cdesignShapeSquare: "Angular",
      csettingsIntegration: "Integration",
      csettingsOweDomain: "Connecting own domain",
      csettingsDocs: "Legal documents",
      cintegrationIntegration: "Integration",
      cintegrationShareLeads: "Share Leads",
      cintegrationTitleEmail: "E-MAIL",
      cintegrationInstruction: "Instruction",
      cintegrationInstructionDetail: "Details",
      cintegrationEmailInstruction: "Trigger an email to your respondent and notify your team. Collaborate with your team.",
      cintegrationPublishAfterEdit: "Save the quiz after editing",
      cintegrationSendNewLeadsTo: "Send messages about new leads to:",
      cintegrationInputEmail: "Input Email",
      cintegrationAddEmail: "Add Email",
      cintegrationEmailSaved: "Email Saved",
      cintegrationEmailDeleted: "Email Deleted",
      cintegrationTitleFB: "FB pixel",
      cintegrationIDPixel: "Pixel ID",
      cintegrationFBInstruction1: "Go to the Facebook Ads advertising cabinet and copy the Facebook Pixel ID in the Event Manager section.",
      cintegrationFBInstruction2: "Don't copy the whole code, just the 15-16 digit ID.",
      cintegrationFBInstruction3: "Copy and paste into the box below.",
      cintegrationFBInstruction: 'The event for which you need to configure the conversion is "Lead". This event will be called as soon as the user submits an application in the quiz.',
      cintegrationFBInstructionTextTitle: "List of all events we send:",
      cintegrationFBInstructionTextTextStart: " – visit to the start page;",
      cintegrationFBInstructionTextQuestionN: " – visit to step N;",
      cintegrationFBInstructionTextOpenContactPage: " – visit to the contact page;",
      cintegrationFBInstructionTextLead: " – successful submission of the contact form;",
      cintegrationFBInstructionTextThankYou: " – visit to the final page.",
      bold_adsquiz_Start: "adsquiz_Start",
      bold_adsquiz_QuestionN: "Question_N",
      bold_adsquiz_OpenContactPage: "Open_Contact-Page",
      bold_adsquiz_Lead: "Lead",
      bold_adsquiz_ThankYou: "adsquiz_ThankYou",
      cintegrationInputIDPixel: "Input Pixel ID",
      cintegrationDataFBSaved: "Pixel FB data saved",
      cintegrationAccesToken: "ACCES TOKEN",
      cintegrationTitleGA: "Google analytics",
      cintegrationGAInstructionDescription: "Learn how people are finding and interacting with your quiz. Get the data you need to analyze campaigns, improve conversions, and more.",
      cintegrationGAInstruction: 'When a quiz application is received in Google Analytics, the "generate_lead" event will be triggered.',
      cintegrationIDFolow: "Tracking ID",
      cintegrationInputID: "Input ID",
      cintegrationDataGASaved: "Google analytics data saved",
      cintegrationTitleGTM: "Google Tag Manager",
      cintegrationGTMInstructionDescription: "Description for Google Tag Manager.",
      cintegrationGTMInstruction: "Copy the Google Tag Manager id and paste it into the field below.",
      cintegrationDataGTMSaved: "Google Tag Manager data saved",
      cintegrationTitleTG: "Telegram",
      cintegrationHowReciveTGLeadBot: "How to get leads in Telegram?",
      cintegrationTGInstructionBot1: "1. Copy the token in the input below.",
      cintegrationTGInstructionBot2: '2. Follow the link to the bot, click the "START" button.',
      cintegrationTGInstructionBot3: "3. Send the copied token as a message to our bot.",
      cintegrationHowReciveTGLeadChat: "How to get applications in Telegram chat?",
      cintegrationTGInstructionChat1: "1. Add our bot to the desired chat and set the bot to administrator status.",
      cintegrationTGInstructionChat2: "2. Copy the token in the field below and send it to the desired chat.",
      cintegrationTGInstructionChat3: "3. Wait for the answer `OK! Your quiz token was saved!”",
      cintegrationTGInstructionChat4: "4. Leads will now be sent to this chat.",
      cintegrationTGInstructionWarning: "Importantly:",
      cintegrationTGInstructionWarningText: "Please note that this should be a group, not a Telegram channel. Leads will not be sent to the Telegram channel.",
      cintegrationTGBotLink: "chatbot link",
      cintegrationTGTitleToken: "TOKEN",
      cintegrationInputTGToken: "Enter Telegram chatbot token",
      cintegrationAddIntegration: "Add integration",
      cintegrationDataTGSaved: "Telegram integration data saved",
      cintegrationTitleTT: "TT pixel",
      cintegrationTTInstruction: "To track events in your Tik-Tok ad account, copy the pixel ID and paste it into the box below. ",
      cintegrationDataTTSaved: "Pixel TT data saved",
      cintegrationTitleWH: "WebHook",
      cintegrationWHInstruction1: "You can notify third-party applications about incoming leads. To do this, specify the URL to which the WebHook will be sent.",
      cintegrationWHInstruction2: "We have prepared",
      cintegrationWHInstruction3: "video instruction",
      cintegrationWHInstruction4: "with a detailed description of this functionality.",
      cintegrationWHInstruction5: "View video.",
      cintegrationWHInstruction6: "In this video, we will look at passing a hook based on sending data to a Google spreadsheet and sending data to a Trello CRM system.",
      cintegrationWHInstruction7: "We recommend using the service for this purpose.",
      cintegrationWHInstruction8: "When registering using this link, for our users, there is a bonus - 10,000 free actions for any integrations.",
      cintegrationWHInstruction9: "Link",
      cintegrationWHInstruction10: "to register with a bonus.",
      cintegrationWHUrl: "The webhook URL's",
      cintegrationInputWHUrl: "Input the webhook URL",
      cintegrationAddWH: "Add a Web-hook",
      cintegrationWebHookDataSaved: "WebHook data saved",


      cintegrationComonWHInstructionShort: "You can notify third-party applications about incoming leads. Specify the webhook URL for data transmission.",
      cintegrationComonWHInstruction1Title: "1. Insert the webhook URL into the input field:",
      cintegrationComonWHInstruction1Description: "Paste the webhook URL provided by your service or application into the input field.",
      cintegrationComonWHInstruction2Title: "2. Save changes:",
      cintegrationComonWHInstruction2Description: 'Click the "Save Changes" button to save the webhook settings.',
      cintegrationComonWHInstruction3Title: "3. Done:",
      cintegrationComonWHInstruction3Description: "Now your webhook is configured to send data to the service you have selected.",
      cintegrationComonWHInstructionAdditional: "Data will be sent to the webhook with each lead received. POST method is used for transmission. An example of the data that will be sent is located ",
      cintegrationComonWHInstructionAdditionalLink: "here",
      cintegrationComonWHInstructionVideo: "Detailed video instruction",
      
      cintegrationApixDriveWHInstructionShort: "You can notify ApixDrive about incoming leads. To do this, specify the URL to which the WebHook will be sent.",
      cintegrationApixDriveWHInstructionShort1: "Upon registration through ",
      cintegrationApixDriveWHInstructionShort2: "this link",
      cintegrationApixDriveWHInstructionShort3: " our users receive a bonus - 10,000 free actions for any integrations.",
      cintegrationApixDriveWHInstruction1Title: "1. Register or open your ApixDrive account:",
      cintegrationApixDriveWHInstruction1Description1: "Link ",
      cintegrationApixDriveWHInstruction1Description2: "for registration with a bonus.",
      cintegrationApixDriveWHInstruction2Title: "2. Get the URL address for the webhook:",
      cintegrationApixDriveWHInstruction2Description1: "Go to the settings of the necessary connection and copy the address for sending the webhook:",
      cintegrationApixDriveWHInstruction2Description2: 'You can create a new connection by clicking the "Create Connection" button and at the system selection step, choose "ADSQuiz".',
      cintegrationApixDriveWHInstruction3Title: "3. Insert the webhook URL:",
      cintegrationApixDriveWHInstruction3Description: "Paste the webhook URL provided by ApixDrive into the input field.",
      cintegrationApixDriveWHInstruction4Title: "4. Save changes:",
      cintegrationApixDriveWHInstruction4Description: 'Click the "Save Changes" button to save the webhook settings.',
      cintegrationApixDriveWHInstruction5Title: "5. Done:",
      cintegrationApixDriveWHInstruction5Description: "Now your webhook is configured to send data to ApixDrive.",
      cintegrationApixDriveWHInstructionAdditional: "Data will be sent to the webhook with each lead received. POST method is used for transmission. An example of the data that will be sent is located ",
      cintegrationApixDriveWHInstructionAdditionalLink: "here",
      cintegrationApixDriveWHInstructionVideo: "Detailed video instruction",
      



      cintegrationConectOweDomain: "Connecting your own domain",
      cintegrationOweDomainInstruction1: "1. To place your quiz on the required domain, log into your hosting account. Go to DNS settings and create an A record with the IP parameter: 185.25.117.21",
      cintegrationOweDomainInstruction2: "2. Input your domain above and save the quiz.",
      cintegrationDomain: "Domain",
      cintegrationInputYourDomain: "Input your domain",
      cintegrationCheckDomenIsCorrect: "Check that the entered domain is correct",
      cintegrationDomainWhichCorrect: "Enter a domain without protocols. For example: some.site.com",
      cintegrationInsertedDefaultDomain: "The domain is set by default",
      cintegrationDomainDataSaved: "Domain data saved",
      cintegrationPrivacyPolicy: "Privacy policy",
      cintegrationPrivacyPolicyByDefault: "By default, we use our standard privacy policy.  We recommend adding your privacy policy that is relevant to your business.",
      cintegrationPrivacyPolicyAdventage: "This will make it clear to your potential customers how you use the received contact information.  This is also required by some advertising platforms.",
      cintegrationPrivacyPolicyUrl: "Link to documents",
      cintegrationPrivacyPolicyInputYourDocsLink: "Insert link to documents",
      cintegrationDocsDataSaved: "Link to your documents saved",
      allstepsUpploadPhoto: "Upload a image",
      allstepsChangePhoto: "Change the image",
      allstepsDeletePhoto: "Delete image",
      allstepsTitle: "Title",
      allstepsDescription: "Description",
      allstepsButtonText: "Button text",
      allstepsOtherSettings: "Other settings",
      allstepsIsSaveBeforeRoute: "Save data before changing route?",
      cfirstFormat: "Format",
      cfirstHeaderStartText: "Your headline should be here. Motivate users to complete the quiz to the end",
      cfirstAddMediafile: "Add media (image)",
      cfirstBonus: "Bonus",
      cfirstAddBonus: "Add bonus",
      cfirstStartPageDataUpdated: "The start page data has been updated",
      csanyAnswerType: "Question type",
      csanyATCard: "card",
      csanyATList: "list",
      csanyATCustom: "free field",
      csanyQuestion: "Question",
      csanyAnswers: "Answers",
      csanyAddAnswer: "Add option",
      csanyTitleForModalConfirm: "This action is irreversible!",
      csanyQuestionForModalConfirm: "Are you sure you want to delete this question?",
      csanyRequiredStep: "Required step",
      csanyAlowMultiple: "Allow multiple answers",
      csanyAutoRedirect: "Auto next question",
      csanyQuestionLogic: "Question logic setup",
      csanyDataStepUpdated: "Data Step Updated",
      cformForm: "Form",
      cformValueName: "Name",
      cformValueSurname: "Surname",
      cformValuePhone: "Phone number",
      cformValueEmail: "E-mail",
      cformDataContactsPageUpdated: "Data Contacts Page Updated",
      cformRequiredInput: "Mandatory",
      cfinishFinishPageTitle: "Finish Page",
      cfinishText: "Text",
      cfinishDescription: "Description",
      cfinishAddImage: "Add an image",
      cfinishImageAdded: "Image Added",
      cfinishURL: "URL",
      cfinishButtonText: "Button Text",
      cfinishInsertNikname: "Insert Nikname",
      cfinishCheckDataIsCorrect: "Check that the entered id is correct",
      cfinishFbWhichCorrect: "Enter a id without oter adress. For example: some.name",
      cfinishIgWhichCorrect: "Enter a id without oter adress. For example: some.name",
      cfinishTgWhichCorrect: "Enter a id without oter adress. For example: some.name",
      cfinishFinishPageDataUpdated: "The finish page data has been updated",
      adminUsersReg: "Users in the system",
      adminNewUsers: "New users per month",
      adminProAccounts: "PRO accounts",
      adminLTV: "LTV",
      adminSharePro: "PRO relative to all",
      adminReciveLeads: "Leads received",
      adminLeadsForOne: "Leads Per user",
      adminMadeQuiz: "Made Quizzes",
      adminQuizForOne: "Quiz Per user",
      adminAvarageUsed: "Average usage months",
      adminProfitMonth: "Profit Month",
      adminProfitAlltime: "Earned for all time",
      adminUsersList: "User base",
      notificationErrorLogin: "Authorization error:",
      notificationLogin: "Login...",
      notificationWrongLoginData: "Incorrect login details",
      notificationNetworkError: "Check your internet connection",
      notificationInputLoginData: "Input login data",
      notificationCheckInputEmail: "Verify that your email address is correct",
      notificationCheckInputPhone: "Check that the phone number is correct",
      notificationInputAllInputs: "Fill in all fields for registration",
      notificationAgreePrivacyPolicy: "Agree to the privacy policy",
      adminEditorForPartnership: "Affiliate program editor",
      developmentPage: "Section is under development",
      signupAgreeIAgreeWith: "I agree with",
      signupAgreePolicy: "the privacy policy",
      signupAgreeAnd: "and",
      signupAgreeOffer: "the public offer",
      signupAgreeService: "of the service",
      workboardModalLeadsLeadReferrer: "Unclear",
      workboardModalLeadsLeadReferrerClarification: "Referrer data blocked by source or undefined",
      workboardModalLeadsLeadEmailCopied: "Email copied to clipboard",
      workboardModalLeadsLeadPhoneCopied: "Phone copied to clipboard",
      workboardModalLeadsHaveNotSended: "You have the not-sended leads",
      workboardModalLeadsLeadReload: "Recive",
      workboardModalLeadsLeadReloadedTitle: "Request recived",
      workboardModalLeadsLeadReloaded: "",
      cfirstStartPagePhotoUpdated: "The start page photo has been updated",
      allstepsPhotoTooBig: "File is too big!",
      allstepsPhotoMustBeLess: "Must be less than",
      cfirstStartPageBonusPhotoUpdated: "The bonus photo has been updated",
      cstepsQuestionsOrderSaved: "Question order saved",
      csanyAnswerOrderSaved: "Answer order saved",
      allstepsPhotoAdaptation: "Photo adaptation for devices",
      allstepsPhotoFit: "Aspect ratio",
      constructorYouHideStep: "You are hidden this step",
      cstepsHiddenStep: "Hide Step",
      cstepsShowStep: "Show Step",
      cfinishFinishPageHiddenUpdated: "Фінішна сторінка прихована.",
      cfinishFinishPageHiddenUpdatedGoContact: "Перейдіть на сторінку Форми контактів",
      cfinishFinishPageHiddenUpdatedSetRedirect: "та налаштуйте редирект після відправки даних",
      cformActionAfterContactRecive: "Follow-up on Contact Information",
      cformActionAfterContactThankyou: "Thank you Page",
      cformActionAfterContactRedirect: "Redirect",
      cformAdditionalSettings: "Additional settings",
      cformRedirectURL: "URL",
      cintegrationOweDomainInstructionImportant: "Important: The address in your hosting account and the field above must match. The time of applying changes to DNS settings and extracting the SSL certificate is up to 3 hours after saving.",
      cintegrationOweDomainInstructionVideo: "Watch video instruction",
      workboardModalLeadsLeadLimit: "Your lead limit has been reached.",
      workboardModalLeadsLeadLimitInstruction1: "To see new leads,",
      workboardModalLeadsLeadLimitInstruction2: "switch to a another tariff",
      workboardModalLeadsLeadLimitInstruction3: "renew the current tariff",
      workboardModalLeadsLeadLimitInstruction4: '(does not apply to "Free") or wait for the tariff to be updated if you have an auto-renewal of the tariff connected',
      billingBonusesTitle: 'Promo code',
      billingBonusesDescription: 'Enter the promo code you received in the next field',
      billingBonusesPlaceholder: 'Promo code',
      billingBonusesBtn: 'Activate',
      billingBonusesActivated: 'Promo code activated!',
      billingBonusesLeadIncreased: 'The number of available leads has been increased.',
      billingBonusesInvalid: 'Wrong promo code',
      billingBonusesNotAvailable: 'Promo code is no longer available',
      billingBonusesAlreadyActivated: 'This coupon is invalid',
      createQuizSPDescription: "There is place for the short description",
      createQuizCPTitle: "How can we contact with you?",
      createQuizCPDescription: "Please fill the form below",
      createQuizCPButton: "Done!",
      createQuizFPTitle: "Thank you!",
      createQuizFPDescription: "Now we looking for a best solution for you!",
      createQuizFPButton: "Show me something!",
      selectInternationalPrefix: "Select international prefix",
      autoInternationalPrefix: "Determine the country automatically",
      dovnloadCsvTitle: "Export data for the selected period",
      dovnloadCsvSelectAllPeriod: "Choose for the entire period",
      dovnloadCsvCancel: "Cancel",
      dovnloadCsvExport: "Export",
      
      
      
      
      
      widgetEmbedVidgetToSite: "Embed quiz to the own website",
      widgetEmbedVidgetSelectEmbedVariant: "Select embed variant",
      widgetEmbedVidgetSitecode: "In the site code",
      widgetEmbedVidgetPopup: "Pop-up",
      widgetEmbedVidgetChat: "Quiz-button",
      widgetEmbedVidgetInProgres: "Under development",
      widgetEmbedVidgetToSiteCode: "Embed quiz to the website",
      widgetSiteCodeInstruction0: "To embed the quiz on your site, add the code from the first field to the `<head>` tag on your site or in Google Tag Manager if it is integrated into your site. If after you have already embeded the quiz on your site you change the domain of the quiz you will need to update the integration code on your site.",
      widgetSiteCodeInstruction1: "In the place where you want to integrate the quiz, insert the code from the second field.",
      widgetSiteCodeInstruction2: "The quiz can be integrated into a container of any size, even into sidebars! The maximum size of the quiz, as well as when taking it on a separate domain, is 900x552 pixels. It can be added to larger containers, in which case the quiz will be centered.",
      widgetSiteCodeInstruction3: "Do not be shy - experiment!",
      widgetSiteCodeCopyToHead: "Copy and paste into the `<head>` of your site",
      widgetSiteCodeCopyToBody: "Paste the code where the quiz should be",
      workboardModalAddQuizHeader: "Create new Quiz",
      workboardModalGiveQuizNameAndMethod: "Come up with a name for your quiz and choose the way you want to create it",
      workboardModalNewQuizFromStart: "Create from blank",
      workboardModalNewQuizFromTemplates: "Ready templates",
      QuizTemplatesSearchInput: "Name of the template and/or categories",
      QuizTemplatesSearchBtn: "Search",
      QuizTemplatesReadyTemplates: "Ready templates",
      QuizTemplateView: "View",
      QuizTemplateSelect: "Choose",
      widgetPopupInstruction0: "To install a quiz on your site, add the code from the code box to the `<head>` tag on your site or in Google Tag Manager if it is integrated into your site. If, after you have already inserted a quiz on your site, you change the quiz domain or open triggers (scroll percentage or timing), you will need to update the integration code on your site.",
      widgetPopupInstruction1: "Just before the closing `</footer>` tag, paste the code from the second field.",
      widgetPopupInstruction2: "For programmers or advanced users",
      widgetPopupInstruction3: "If, for some reason, you are uncomfortable inserting a quiz before the closing `</footer>` tag, you can paste the code from the second field in any convenient place, but keep in mind that the container in which you paste the code from the second field must be on top of all windows and highly desirable, should have the maximum height and width possible for the display.",
      widgetPopupCopyToHead: "Copy and paste into the `<head>` of your site",
      widgetPopupCopyToBody: "Paste the code before the closing `</footer>` tag",
      widgetPopupTimeToOpen: "A period of time",
      secondReduction: "sec",
      widgetPopupTimeClarification: "The popup will appear with a delay of the above time in seconds",
      widgetPopupScrollToOpen: "Site scrolling percentage",
      widgetPopupScrollClarification: "The popup will appear after the scrolling percentage of your site specified above",
      quizzesTemplatesAllTags: "All Templates",
      widgetEmbedVidgetButton: "Button constructor",
      widgetButtonInstruction0: "You can set a quiz on your website that will open when you click on the button.",
      widgetButtonInstruction1: "Add a border, shadow, and text to the button. Also, you can change the color of the button and the text inside.",
      widgetButtonInstruction2: "Copy the script placed in the first code box and paste it before the closing tag `</head>`.",
      widgetButtonInstruction3: "Copy the generated button code from the second code field and paste it anywhere on your site.",
      widgetButtonInstruction4: 'You can skip the code from the second button field and simply add `id="button_open_quiz_popup"` to any `<input type="button">` or `<button></button>` element on the site.',
      widgetRealTimeChange: "You can see the changes in real time on the right side of the screen",
      widgetButtonCornerRound: "Button corner rounding",
      widgetButtonShadow: "Button shadow",
      widgetButtonBgColor: "Button color",
      widgetButtonTextColor: "Text color",
      widgetButtonTextValue: "Button text",
      openPopupQuizText: "Start Quiz",
      widgetButtonInsertBeforeAfterBody: "Insert the quiz code immediately after the opening `<body>` tag or immediately before the closing `</body>` tag",
      widgetSiteCodeCopyButton: "Paste the button code anywhere on the site",
      footerContactUs: "Have questions? Feel free to contact us:",
      enterYourName: "Enter your name",
      enterYourPhone: "Enter your phone number",
      enterYourEmail: "Enter your email",
      enterYourPassword: "Enter your password",
      selectOption: "Select option",
      createNewGroup: "Create new group",
      dontHaveAccount: "Don’t have an account?",
      resetPasswordTip: "If you have any further issues or questions, please don't hesitate to contact our customer support team. We're here to help.",
      getBackAccount: "Get Back to Your Account",
      inMinutes: "in Minutes",
      alreadyHaveAccount: "Already have an account?",
      quizSlotLastEdit: "Last edit",
      workboardQuizzesTitle: "My Quizzes",
      allstepsBack: "Back",
      constructorGetLink: "Link",
      allOr: "or",
      allAnd: "and",
      acceptedFileType: "Accepted formats: ",
      conjunctionAnd: "and",
      acceptedFileSize: "Maximum size: ",
      acceptedFileSizeMeasure: "MB",
      cfirstBonusDisabled: "Disabled",
      cfirstBonusAvailable: "Available",
      allstepsResetInHeader: "Reset",
      placeholderText: "Type here",
      constructorSaveChanges: "Save",
      cformInputAvailable: "Available",
      cformInputDisabled: "Disabled",
      constructorBack: "Back",
      videoInstruction: "Video Instruction",
      csettingsIntegrationFB: "Integration - Facebook Pixel",
      csettingsIntegrationTT: "Integration - TikTok",
      csettingsIntegrationGA: "Integration - Google Analytics",
      csettingsIntegrationGTM: "Integration - Google Tag Manager",
      csettingsIntegrationWH: "Integration - WebHook",
      csettingsIntegrationTG: "Integration - Telegram",
      csettingsIntegrationApixDrive: "Integration - APIxDrive",
      csettingsIntegrationEmail: "Integration - Email",
      csettingsIntegrationAbout: "About",
      csettingsIntegrationFBAbout: "Go to the Facebook Ads advertising cabinet and copy the Facebook Pixel ID in the Event Manager section.Don't copy the whole code, just the ID Copy and paste into the box below.",
      csettingsIntegrationTTAbout: "To track events in your Tik-Tok ad account, copy the pixel ID and paste it into the box below.",
      csettingsIntegrationGAAbout: "Copy the Google Analytics id and paste it into the field below.",
      csettingsIntegrationGTMAbout: "Copy the Google Tag Manager id and paste it into the field below.",
      csettingsIntegrationWHAbout1: "You can notify third-party applications about incoming leads. To do this, specify the URL to which the WebHook will be sent.We recommend using the service for this purpose.",
      csettingsIntegrationWHAboutApix: "Apix-Drive",
      csettingsIntegrationWHAbout2: "When registering using this link, for our users, there is a bonus - 10,000 free actions for any integrations.",
      csettingsIntegrationTGAbout: "Importantly: Please note that this should be a group, not a Telegram channel. Leads will not be sent to the Telegram channel.",
      csettingsIntegrationADAbout: "Something about APIxDrive",
      csettingsIntegrationEmailAbout: "Trigger an email to your respondent and notify your team. Collaborate with your team.",
      cSettingsIntegrationNeedHelp: "Need Help?",
      cSettingsIntegrationRefreshToken: "Refresh Token",
      csettingsSettingASettings: "Advanced settings",
      cSettingsSettingQuizLangTitle: "Quiz Language",
      cSettingsSettingQuizLangDescription: "To ensure you have the best experience, please select your desired quiz language from the options below. System notifications and some other constant texts will be displayed in this language when passing the quiz.",
      selectQuizLanguage: "Select Quiz language",
      cSettingsSettingQuizHideLabelTitle: "Hide label",
      cSettingsSettingQuizHideLabelDescription: 'By enabling this option, you can enjoy an interface free of the "made by adsquiz" label, allowing you and your visitors to focus solely on the questions.',
      cSettingDataSaved: "Additional settings are saved",
      userAccountInfo: "Account info",
      userGeneralInfoHeader: "General info",
      userGeneralInfoDescription1: "Your user profile is where you can manage your account and settings.",
      userGeneralInfoDescription2: "Update your personal information, change your password, and adjust your notification preferences.",
      profileNameFirst: "First Name",
      profileNameLast: "Last Name",
      userSecuritySettingsHeader: "Security settings",
      userSecuritySettingsDescription: "To change your password, simply enter your new password twice to confirm.",
      profileEmailCurent: "Current Email Address",
      profileEmailNew: "New Email Address",
      profileNewPasswordConfirm: "Confirm New Password",
      profileDeleteAccount: "Delete account",
      profileDeleteAccountPopupHeader: "Delete Account?",
      profileDeleteAccountPopupDescription: 'Are you sure you want to delete your account? This action cannot be undone. To confirm deletion, please type the word "DELETE" in the field below.',
      profileDeleteAccountCheckedWord: "DELETE",
      profileDeleteAccountPopupPlaceholder: "Type here",
      notificationSuccess: "Success!",
      notificationSuccessSaveAccount: "Your password has been successfully changed! Use a new password to log in again",
      notificationSuccessDeleteAccount: "Your password has been successfully changed! Use a new password to log in again",
      cardPanUnspecified: "Unspecified",
      userBillingsDate: "Date",
      userBillingsCost: "Cost",
      userBillingsId: "ID",
      userBillingsMethod: "Payment Method",
      paymentSwitchToProHeader: "Switch to Pro",
      paymentSwitchToProDescription: "Find the plan that's right for you! Upgrade or downgrade at any time - we make it easy to get the plan you need.",
      paymentYourCurrentPlan: "Your current plan:",
      paymentYourFuturePlan: "Your new plan:",
      paymentPlanLeadsLimit: "Limit of leads per month:",
      paymentPlanLeadsLimitForTest: "Your quiz test limit:",
      paymentPlanLeadsLeft: "Leads left:",
      paymentPlanPrice: "Price:",
      paymentPlanRenew: "Tarif renew date:",
      addNewSectionHeader: "Add New Section",
      addNewSectionList: "List",
      addNewSectionCustom: "Input field",
      addNewSectionCard: "Cards with photo",
      addNewSectionWithEmoji: "List with emoji",
      addNewSectionTags: "Tags",
      addNewSectionSlider: "Slider",
      templatesBackToQuiz: "Back to Workboard",
      constructorStepsSaved: "All quiz data has been successfully saved",
      constructorDesignSaved: "The quiz design has been successfully saved",
      azureBlueWhite: "Standard",
      azureBlue: "Winter night",
      moderatePurplishRed: "Red velvet",
      violetBlueCrayola: "Winter breeze",
      wetRainforest: "Summer maple",
      lavender: "Space",
      blueLilac: "Dark lavender",
      opalGreen: "Carpathian forest",
      dustyBlue: "Tiffany",
      almondCrayola: "Coral reef",
      periwinkleCrayolaLilac: "Lilac",
      periwinkleCrayolaAqua: "Synevir",
      jazzJam: "Jazz",
      slateGrayBlue: "Mist",
      turquoiseBlueCrayola: "Diia",
      blackPearlescentOpal: "Ash",
      pearlescentGentianBlue: "Sapphire",
      sapphireBlue: "Dark sapphire",
      errorUserDataUpdate: "Error in updated data: ",
      completeUserDataUpdate: "User data updated",
      passwordDoNotCoincidence: "Passwords do not match",
      passwordDoNotCoincidenceAgain: "Try again",
      nonBillingsYet: "You don't have any billing history yet. ",
      youCanChangePlan: "You can change current plan by clicking “Switch to PRO” button",
      rangeMin: "Min",
      rangeMax: "Max",
      rangePositionStart: "Position Start",
      rangePositionEnd: "Position End",
      rangeStep: "Step",
      rangeIsRange: "Range",
      rangeIsRangeTrue: "Available",
      rangeIsRangeFalse: "Disable",
      recivePromotionNewsletter: "Receive email newsletters",
      reciveEmailCheckbox: "Receive Email Checkbox",
      additionalCheckbox: "Additional Checkbox",
      contactPrivacyPolicyCheckbox: "Contact Privacy Policy Checkbox",
      contactAgreeIAgreeWith: "I agree with",
      contactAgreePolicy: "the privacy policy",
      contactAgreeAnd: "and",
      contactAgreeTermsOfUse: "the terms of use",
      contactAgreeAgreementAdditionalWord: "",
      langDE: "German",
      langEN: "English",
      langIT: "Italian",
      langKK: "Kazakh",
      langPL: "Polish",
      langRU: "Russian",
      langSP: "Spanish",
      langUA: "Ukrainian",
      langLT: "Lithuanian",
      langLV: "Latvian",
      langET: "Estonian",
      langRO: "Romanian",
      leadsLeft: "Leads left",
      generateDomainTitle: "Enter the domain name",
      generateDomainDescription: "This is the unique address where your page will be accessible. You can change the address at any time or connect your own one.",
      generateDomainDomainNameLabel: "Domain name",
      cancelAllAction: "Cancel",
      confirmAllAction: "Confirm",
      falseHasValidCharacters: "Invalid characters in the domain. Valid characters are letters, numbers and hyphens.",
      falseHasValidLength: "Domain length should be between 6 and 20 alphanumeric characters.",
      falseDoesNotStartOrEndWithSpecialChar: "Domain should not start or end with a special character.",
      falseDoesNotHaveConsecutiveSpecialChar: "Domain should not contain two or more consecutive special characters.",
      falseDoesNotHaveConsecutiveSameChar: "Domain should not contain three or more consecutive identical characters.",
      falseDoesNotContainForbiddenSubstrings: "Domain should not contain forbidden substrings.",
      generateDomainTaken: "This domain is already taken. Try another one",
      temporarilyUnavailable: "Temporarily unavailable",
      csettingsAdmin: "Settings for admins",
      questionLogicSetupTitle: "Question logic setup",
      questionLogicSetupDescription: "Add conditions to help you tailor the survey to each respondent and collect more accurate data.",
      questionLogicConditionTitle: "Condition",
      questionLogicQuestion: "Question",
      questionLogicSetupQuestionDisableOption: "Select Question",
      questionLogicSetupAnswer: "Answer",
      questionLogicSetupLogicType: "Type",
      questionLogicSetupAnswerDisableOption: "Select type of condition",
      questionLogicSetupRangeBetween: "Between",
      questionLogicSetupRangeLess: "Less",
      questionLogicSetupRangeMore: "More",
      questionLogicSetupRangeEqualTo: "Equal to",
      questionLogicSetupRangeFrom: "From",
      questionLogicSetupRangeTo: "To",
      questionLogicSetupVariantAll: "All",
      questionLogicSetupVariantAny: "Any",
      questionLogicSetupVariantNot: "None",
      questionLogicSetupCustomEqual: "Equal",
      questionLogicSetupCustomContains: "Contains",
      questionLogicSetupCustomNotContains: "Not contains",
      questionLogicSetupRangeValue: "Value",
      questionLogicSetupExpectedAnswer: "Expected answers",
      questionLogicSetupPlaceholder: "Type here",
      questionLogicAllMandatory: "All of the above conditions are mandatory",
      questionLogicAddConditionBtn: "Add Condition",
      questionLogicNotAvailable: "Logic settings are not available for the first question",
      questionLogicSaved: "Step display logic settings are saved",
      billingPaymentInfoTitle: "Tariff Information",
      billingPaymentInfoChangeTarrif: "Change tariff",
      billingPaymentInfoDescription: "When you pay for any paid tariff, you receive a certain number of leads included in the package you have chosen. These leads are provided for a month. If you haven't used your leads within a month from the moment of payment, they will be canceled.",
      billingGeneralName: "Your Tariff",
      billingGeneralCost: "Cost",
      billingMonth: "month",
      billingGeneralExpired: "Valid until",
      billingGeneralLeadsLeft: "Leads left",
      billingGeneralAllTarifExpired: "Tariff expiration date",
      billingGeneralBonus: "Leads with a promo code",
      billingGeneralLeadsFromPastTarifs: "Leads from previous tariffs",
      billingAcordeonPaymentInfoTitle: "Payment information",
      billingAcordeonPaymentInfoFormat: "Format",
      billingAcordeonPaymentInfoSubscription: "Subscription",
      billingAcordeonPaymentInfoPay: "Payment",
      billingAcordeonPaymentInfoPayMethod: "Payment Method",
      billingAcordeonPaymentInfoCancelSubscription: "Cancel Subscription",
      billingAcordeonPromocode: "Promo Code",
      billingAcordeonPromocodeLabel: "Enter promo code",
      billingAcordeonPayHistory: "Transaction History",
      paymentAllTarifInactive: "tariff is inactive",
      subscriptionUnsubscribeModalTitle: "Unsubscribe",
      subscriptionUnsubscribeModalDescription: "Are you sure you want to unsubscribe? Leads accrued for the subscription will be available to you for a month from the last billing date.",
      subscriptionIsNotDeleteTitle: "Subscription Not cancelled",
      subscriptionIsNotDeleteDescription: "Please contact customer support",
      subscriptionCanceledTitle: "Subscription cancelled",
      modalSureUnsavedChangesTitle: "You have unsaved changes",
      modalSureUnsavedChangesDescription: "Before leaving the current page, please decide what to do with the unsaved changes",
      modalSureUnsavedChangesDontSave: "Don't Save",
      modalSureUnsavedChangesSave: "Save",
      telegramTokenCopied: "The quiz token has been copied to the clipboard",
      cSettingsSettingQuizBackButtonTitle: "Back button",
      cSettingsSettingQuizBackButtonDescription: "You can enable or disable the back button. This will provide an opportunity to change the previously given answer.",
      cSettingsCustomThemePrimaryColor: "Color of accents",
      cSettingsCustomThemeSecondaryColor: "Background color",
      cSettingsCustomThemeAdditionalColor: "Text color",
      cSettingsCustomThemeButtonTextColor: "Text in button",
      cSettingsCustomThemeModeDark: "Dark",
      cSettingsCustomThemeModeLight: "Light",
      allstepsPlaceholder: "Placeholder",
      allstepsCustomInput: "Custom input",
      allstepsCustomInputOn: "Available",
      allstepsCustomPlaceholder: "Other",
      trashcanTitle: "Trashcan",
      trashcanImportant: "Important!",
      trashcanDescription: "The quiz will be automatically deleted permanently 30 days after it is moved to the trashcan.",
      trashcanClearAll: "Clear all",
      quizSlotDeletedData: "Deleted at",
      workboardLeads: "Leads",
      workboardDuplicate: "Duplicate",
      leadsTable: "Leads List",
      exportInfo: "Export Leads",
      leadHide: "Hide",
      leadShowMore: "Show More",
      poperTrashcan: "Trashcan",
      restoreQuizSendedTitle: "A recovery task is running",
      restoreSuizSendedImportant: "Important",
      restoreQuizSendedDescription: "Depending on how many leads there were in your quiz, restoring the quiz may take from a few seconds to 2 minutes. After restoration, the quiz will appear on your main workboard.",
      ok: "Okay",
      month: "0 month | {n} month | {n} months",
      discount: "Discount up to",
      saveMoney: "Subscription for a year",
      upProVersionMobile: "To PRO",
      upProVersionDesktop: "Switch to PRO",




      csettingsIntegrationSendPulse: "Integration - Send Pulse",
      csettingsIntegrationMake: "Integration - Make",
      csettingsIntegrationZapier: "Integration - Zapier",
      csettingsIntegrationCRMBitrix: "Integration - CRM Bitrix",
      csettingsIntegrationPipeDrive: "Integration - Pipe Drive",
      csettingsIntegrationRemOnline: "Integration - Rem Online",
      csettingsIntegrationKeyCRM: "Integration - Key CRM",
            
      csettingsIntegrationZapierAbout: "Paste the data sending URL provided by Zapier into the input field.",
      csettingsIntegrationMakeAbout: "Paste the data sending URL provided by Make into the input field.",
      csettingsIntegrationMakeAboutTextStep1: "To set up the integration with",
      csettingsIntegrationMakeAboutTextStep2: "watch the",
      csettingsIntegrationMakeAboutTextStep3: "For setup, select “Webhook” among the integrations on the Make side, then choose “Webhook response”.",
      csettingsIntegrationMakeAboutTextStep4: "Copy the generated link and paste it into the field below in ADS Quiz.",
      csettingsIntegrationMakeAboutTextStep5: "To test, go back to Make, click “Run”, and complete the quiz by submitting a lead.",
      csettingsIntegrationMakeAboutTextStep6: "If the test is successful, save the integration and don’t forget to activate updates.",
      csettingsIntegrationMakeAboutLink: "https://www.make.com/en/register?pc=adschool",
      csettingsIntegrationMakeAboutLinkVideo: "https://www.youtube.com/embed/3ZyagaBQmiI",
      csettingsIntegrationMakeAboutLinkVideoText: "video",
      csettingsIntegrationCRMBitrixAbout: "Paste the data sending URL provided by CRM Bitrix into the input field.",

      csettingsIntegrationSendPulseAboutStart: "You can set up integration with the ",
      csettingsIntegrationSendPulseAboutMiddle: " service. To do this, go to your account settings and open the ",
      csettingsIntegrationSendPulseAboutEnd: " tab. Copy the values of the 'ID' and 'Secret' parameters, then paste them into the corresponding fields in the ADS Quiz service (in the fields below). Next, you need to enter the address book ID. To do this, go to the required address book and copy the value from the URL after id/. Only numbers are needed, without any additional characters. Save the changes. A more detailed guide is available in the ",
      csettingsIntegrationSendPulseAboutLinkSendPulse: "https://sendpulse.com/en/#60c9ccfd55f4b",
      csettingsIntegrationSendPulseAboutLinkAPI: "https://login.sendpulse.com/login/#api",
      csettingsIntegrationSendPulseAboutLinkVideo: "https://www.youtube.com/watch?v=4mnhpbKkePs",
      csettingsIntegrationSendPulseAboutLinkVideoText: "video",

      csettingsIntegrationKeyCRMAbout: "Paste the API key and PipelineID into the respective input fields.",
      csettingsIntegrationPipeDriveAbout: "Paste the token and company domain into the respective input fields.",
      csettingsIntegrationRemOnlineAbout: "Paste the Rem Online API key into the respective input field.",
      csettingsIntegrationActiveIntegration: "Active Integration",
      cintegrationIntegrationData: "Integration Data",
      cintegrationIntegrationUrl: "Integration URL",
      cintegrationWriteUrl: "Enter URL",
      cintegrationDataFBError: "Incorrect pixel identifier",
      cintegrationDataWebHookError: "Invalid webhook address",
      cintegrationZapierDataSaved: "Zapier integration data saved",
      cintegrationDataZapierError: "Invalid integration address",
      cintegrationAPIxDriveDataSaved: "APIxDrive integration data saved",
      cintegrationDataAPIxDriveError: "The string is not an APIxDrive integration address",
      cintegrationMakeDataSaved: "Make integration data saved",
      cintegrationDataMakeError: "Invalid integration address",
      cintegrationSendPulseDataSaved: "SendPulse integration data saved",
      cintegrationDataSendPulseError: "Invalid or empty integration data",
      cintegrationPipeDriveDataSaved: "PipeDrive integration data saved",
      cintegrationDataPipeDriveError: "Invalid or empty integration data",
      cintegrationRemOnlineDataSaved: "RemOnline integration data saved",
      cintegrationDataRemOnlineError: "Invalid or empty integration data",
      cintegrationKeyCRMDataSaved: "KeyCRM integration data saved",
      cintegrationDataKeyCRMError: "Invalid or empty integration data",
      cintegrationInputEmailUrl: "Enter Email",
      cintegrationEmailDataSaved: "Email saved",
      cintegrationDataEmailError: "The entered string is not an Email",
      cintegrationCRMBitrixDataSaved: "Email saved",
      cintegrationDataCRMBitrixError: "The entered string is not an Email",
      cintegrationTelegramBotDataSaved: "Telegram integration settings saved",
      cintegrationDataTelegramBotError: "Save error",
      cintegrationGTMDataSaved: "Google Tag Manager ID saved",
      cintegrationDataGTMError: "Invalid ID",
      cintegrationDataGTMErrorDescription: "The ID should have the format GTM-XXXXXX",
      cintegrationGoogleAnalyticsDataSaved: "Google Analytics ID saved",
      cintegrationDataGoogleAnalyticsError: "Incorrect ID",
      cintegrationDataGoogleAnalyticsErrorDescription: 'The ID should have the format "UA-XXXXXXX-X" or "G-XXXXXXXXXX-XX"',
      cintegrationTikTokDataSaved: "Tik-Tok pixel saved",
      cintegrationDataTikTokError: "The entered string is not a Tik-Tok pixel",
      cintegrationIntegrationToken: "Integration Token",
      cintegrationWriteToken: "Enter integration token",
      cintegrationDataYourAPIKey: "Enter your API key",
      viewQuizInMobile: "View Quiz",
      notificationCSRFTokenMismatch: "CSRF Token Mismatch",
      notificationReloadPage: "Please reload the page and try again",
      csettingsIntegrationKeyRemOnline: "Paste the token and channel ID in the appropriate fields",
      cintegrationDataYourIntegrationToken: "API key",
      cintegrationDataLeadTypeId: "Channel ID",
      send_utm: "Send UTM tags",
      send_quiz: "Send quiz data",
      send_answers: "Send answers",
      csettingsIntegrationKeyCrm: "KeyCRM integration",
      cintegrationSendPulseClientId: "Client ID",
      cintegrationSendPulseClientSecret: "Secret",
      cintegrationSendPulseAddressBookId: "Address book ID",
      cintegrationSendPulseAction: "Action with contact",
      sendpulseActionAddContact: "Add contact",
      sendpulseActionDeleteContact: "Delete contact",
      cintegrationPipeDriveToken: "API token",
      cintegrationPipeDriveDomain: "Your unique subdomain",
      cintegrationKeyCrmApiKey: "API key",
      cintegrationKeyCrmPipelineId: "Pipeline ID",
      cintegrationMenuGoogleTagManager:"Google Tag Manager",
      cintegrationMenuGoogleAnalytics:"Google Analytics",
      cintegrationMenuFacebookPixel:"Facebook pixel",
      cintegrationMenuTikTokPixel:"TikTok Pixel",
      cintegrationMenuWebHook:"Webhook",
      cintegrationMenuZapier:"Zapier",
      cintegrationMenuAPIxDrive:"APIx Drive",
      cintegrationMenuMake:"Make",
      cintegrationMenuSendPulse:"Send Pulse",
      cintegrationMenuEmail:"Email",
      cintegrationMenuTelegramBot:"Telegram-bot",
      cintegrationMenuPipeDrive:"PipeDrive",
      cintegrationMenuRemOnline:"Rem Online",
      cintegrationMenuKeyCRM:"Key CRM",
      cintegrationMenuCRMBitrix:"CRM Bitrix",
      trashcanRestoreQuiz: "Restore Quiz",
      cformValuePromotionAccepted: "I agree to the email newsletter",


      constructorAddIntegration: "Add a new integration",
      cintegrationKeyCrmSourceId: "Source ID (optional)",
      cintegrationKeyCrmManagerId: "Manager ID (optional)",
      cSettingsSettingQuizSpamFilterTitle: "Spam Protection",
      cSettingsSettingQuizSpamFilterDescription: "This feature is disabled by default. If you encounter spam leads, enable protection. After that, a single user will not be able to submit more than the specified number of leads for this quiz.",
      cSettingsSettingQuizSpamFilterLimitPlaceholder: "Number of leads",
      langNO: "Norwegian",
      loadMoreLeads: "See more leads",
      userAdditionalSettingsHeader: "Additionat settings",
      userAdditionalSettingsDescription1: "Here you can configure new advanced profile settings.",
      userAdditionalSettingsPagination: "Pagination on the workboard",

      benefitType: "Benefit Type",
      benefitLabelPlaceholder: "Bonus text",
      benefitDescriptionPlaceholderDiscount: "Promo code or instruction",
      benefitDescriptionPlaceholderVideo: "Link to video",
      benefitDescriptionPlaceholderTextfile: "Link to text file",
      benefitDescriptionPlaceholderGift: "Bonus description",
      benefitDescriptionPlaceholderPDF: "Link to PDF",
      benefitDescriptionPlaceholderLink: "Link",
      benefitDescriptionPlaceholderFile: "Link to file",
      benefitLabelDiscount: "Discount",
      benefitLabelVideo: "Video",
      benefitLabelTextfile: "Textfile",
      benefitLabelGift: "Gift",
      benefitLabelPDF: "PDF file",
      benefitLabelLink: "Link",
      benefitLabelFile: "File",
      addBenefit: "Add benefit",
      saveBenefit: "Save bonus",
      cfinishSocialNetworks: "Social networks",

      saveSocialNet: "Save Social Network",
      networkLabelFacebook: "Facebook",
      networkLabelInstagram: "Instagram",
      networkLabelTelegram: "Telegram",
      networkLabelTiktok: "Tik-Tok",
      networkLabelYoutube: "Youtube",
      networkLabelLinkedin: "LinkedIn",
      networkLabelWhatsapp: "WhatsApp",
      socialNetDescriptionPlaceholderFacebook: "profile_nikname",
      socialNetDescriptionPlaceholderInstagram: "profile_nikname",
      socialNetDescriptionPlaceholderTelegram: "profile/chanel_nikname",
      socialNetDescriptionPlaceholderTiktok: "profile_nikname",
      socialNetDescriptionPlaceholderYoutube: "chanel_name",
      socialNetDescriptionPlaceholderLinkedin: '"in/name" or "company/name"',
      socialNetDescriptionPlaceholderWhatsapp: "+15555551234",

      addNewSectionEmptyPage: "Simple Text Page",
      csanyMakeImpasse: "Impasse",
      impaseIsImpaseTrue: "ON",
      impaseIsImpaseFalse: "OFF",
      defaultNewEmptyQuestionDescription: "You can paste and format long text here (we do not recommend pasting more than 3000 characters).",
      cSettingsSettingQuizTimezoneTitle: "Quiz Timezone",
      cSettingsSettingQuizTimezoneDescription: "Enter the timezone in which the time for lead submission will be recorded. This is especially useful when you are in one timezone, and the client is in another. In this case, you can set the client's timezone, and the leads you receive will show the time according to the client's timezone.",
      cSettingsSettingQuizTimezonePlaceholder: "Timezone",
      userAdditionalSettingsTimezone: "User's time zone",
      userAdditionalSettingsTimezonePlaceholder: "Timezone",
      langHE: "Hebrew",
      langCS: "Czech",
      cintegrationCAPIFBInstruction: "To set up integration with Facebook Conversion API, you need to enter the pixel ID and access token. These details can be obtained in the Facebook Business Manager settings. After entering the required information, save the integration.",
      cintegrationCAPIFBInstructionTextTitle: "Event Names for FB Conversions:",
      cintegrationCAPIFBInstructionTextStart: " – visit to the start page;",
      cintegrationCAPIFBInstructionTextQuestionN: " – visit to step N (step number is dynamically inserted);",
      cintegrationCAPIFBInstructionTextOpenContactPage: " – visit to the contact page;",
      cintegrationCAPIFBInstructionTextLead: " – successful submission of the contact form;",
      cintegrationCAPIFBInstructionTextThankYou: " – visit to the final page.",
      cintegrationCAPIFBInstructionTextOptimizationAdvice: "We recommend running ads optimized for the event called",
      cintegrationCAPIFBInstructionTextLeadTriggerCondition: "This event is triggered",
      bold_capi_adsquiz_Start: "capi_adsquiz_Start",
      bold_capi_adsquiz_QuestionN: "capi_adsquiz_Question-N",
      bold_capi_adsquiz_OpenContactPage: "capi_adsquiz_OpenContactPage",
      bold_capi_adsquiz_Lead: "capi_adsquiz_Lead",
      bold_capi_adsquiz_ThankYou: "capi_adsquiz_ThankYou",
      bold_capi_adsquiz_OptimizationAdvice: "“capi_adsquiz_Lead”.",
      bold_capi_adsquiz_LeadTriggerCondition: "only when a user successfully submits a lead form.",
      csettingsIntegrationFbCapiAbout: "Integration with Facebook Conversion API allows you to send user interaction data directly to Facebook to improve the effectiveness of advertising campaigns.",
      cintegrationInputCapiAccessToken: "Enter access token",
      cintegrationInputCapiCustomparameter: "Enter custom event parameters",
      cintegrationDataFbCapiSaved: "Facebook Conversion API integration successfully saved",
      cintegrationComonRenovateHubInstructionTitle: "Instructions for setting up integration with RenovateHub",
      cintegrationComonRenovateHubInstructionDescription: "To set up integration with RenovateHub, provide the webhook URL where the data will be sent. Make sure the URL is correct and accessible for receiving data.",
      cintegrationComonRenovateHubInstructionShort: "Integration with RenovateHub allows you to send data to CRM through a webhook for process automation.",
      cintegrationRenovateHubUrl: "Enter RenovateHub URL",
      cintegrationInputRenovateHubUrl: "Specify the webhook URL for RenovateHub",
      cintegrationRenovateHubDataSaved: "RenovateHub integration successfully saved",
      cintegrationDataRenovateHubError: "Error saving integration with RenovateHub. Please check the entered data for accuracy.",
      cintegrationMenuFacebookConvAPI: "Facebook Conversions API",
      cintegrationMenuRenovateHub: "RenovateHub",
      csettingsIntegrationRenovateHub: "RenovateHub integration",
      csettingsIntegrationCAPIFB: "Facebook Conversions API integration",
      paymentPlanEnd: "End date of the plan",
      paymentPlanLeadsLimitForYear: "Limit of leads per year:",
      paymentPlanLeadsLimitForPeriod: "Limit of leads per period:",
      EditTags: "Edit tags",
      quizzesTemplatesNoTags: "No Tags",
      Monday: "Monday",
      Tuesday: "Tuesday",
      Wednesday: "Wednesday",
      Thursday: "Thursday",
      Friday: "Friday",
      Saturday: "Saturday",
      Sunday: "Sunday",
      datetimeDisplayMode: "Picker type",
      datetimeDisplayModeSelect: "Select type",
      datetimeDisplayModeDate: "Date",
      datetimeDisplayModeTime: "Time",
      datetimeDisplayModeDatetime: "Date & Time",
      datetimeMinDate: "Min. date",
      datetimeMinDateCustom: "Select min. date",
      datetimeMaxDate: "Max. date",
      datetimeMaxDateCustom: "Select max. date",
      datetimeProhibitDaysOfWeek: "Restrict days",
      datetimeIsRange: "Enable range",
      datetimeIsRangeTrue: "Range enabled",
      datetimeIsRangeFalse: "Range disabled",
      datetimeMinTime: "Min. time",
      datetimeMaxTime: "Max. time",
      datetimeIs24: "24-hour format",
      datetimeIs24True: "24-hour enabled",
      datetimeIs24False: "24-hour disabled",
      csanyIsExactDate: "How would you like to set the date",
      csanyIsExactDateTrue: "Exact Date",
      csanyIsExactDateFalse: "Relative Date",
      csanyDatetimeMinMaxNotExactPlaceholder: '"x" or "-x" days from today',
      addNewSectionDatetime: "Date/time",
      tariffsPromo: "Promotional offers",
      tariffsMonth: "Monthly",
      tariffsAnual: "Annually",
      leadsOn1Month: "per month",
      leadsOn2Month: "for 2 months",
      leadsOn3Month: "for 3 months",
      leadsOn4Month: "for 4 months",
      leadsOn5Month: "for 5 months",
      leadsOn6Month: "for 6 months",
      leadsOn7Month: "for 7 months",
      leadsOn8Month: "for 8 months",
      leadsOn9Month: "for 9 months",
      leadsOn10Month: "for 10 months",
      leadsOn11Month: "for 11 months",
      leadsOn12Month: "per year",
      leadsOn24Month: "for 2 years",
      leads: "leads",
      tariffsWordPrice: "Price",
      tariffsNoLimits: "No restrictions",
      tariffsNoLimitsQuizzesAndFunctions: "on quizzes and functionality",
      billingTariffYear: "year",
      billingChangePlanSubscribe: "Subscribe",
      paymentAllTariffInactive: "Tariff is not active",
      subscriptionTariffLableOnly: "Available by subscription only",
      notExactBeforeYear: "before Year",
      notExactBeforeHalfYear: "before Half year",
      notExactBeforeQuarter: "before Quarter",
      notExactBeforeMonth: "before Month",
      notExactBeforeWeek: "before Week",
      notExactBeforeYesterday: "before Yesterday",
      notExactYesterday: "Yesterday",
      notExactToday: "Today",
      notExactTomorrow: "Tomorrow",
      notExactAfterTomorrow: "after Tomorrow",
      notExactAfterWeek: "after Week",
      notExactAfterMonth: "after Month",
      notExactAfterQuarter: "after Quarter",
      notExactAfterHalfYear: "after Half year",
      notExactAfterYear: "after Year",
      tipDefaultDateHeader: "Default",
      tipDefaultDateText: "If both the 'Exact Date' and 'Relative Date' toggles are turned off, the calendar will allow selecting dates from the year 1800 to 2200 by default.",
      tipExactDateHeader: "Exact Date Toggle",
      tipExactDateText: "Enabling this toggle will limit the calendar to a fixed date range defined by the exact date(s) selected in the input fields below.",
      tipRelativeDateHeader: "Relative Date Toggle",
      tipRelativeDateText: "Enabling this toggle will dynamically set the calendar limits based on relative dates calculated at the moment of page load. These limits are personalized and recalculated for each user individually.",
      langPT: "Portuguese",
      placeholderCompanyContactText: 'For example: "Company phone: +180993322211"',
      integrationConnectService: "Connect",
      leadtype_id: "Action type",
      selectVariant: "Select",
      sfirstAddCompanyContacts: "Text with contacts",
      addNewSectionTextarea: "Long input",
      allstepsVariantView: "Image format",
      tariffsDiscountSave: "Save",
      cAdminSettingsTitle: "Admin settings",
      cAdminSettingsInstruction: "Enter the tags for the quiz in the field below. Tags can consist of multiple words, each tag is separated by a comma. Tags 'Services' and 'services' are different tags. It is recommended to use tags that start with a capital letter. You can also exclude the quiz from the list of templates.",
      cAdminSettingsIsTemplate: "Is it a template",
      cIsTemplateStateAreSaved: "The template status has been saved",
      sfirstAddLogotype: "Add a logo",
      logotypeSizeTip: "The logo will be inserted at its actual size but no larger than 120 pixels on the longer side.",
      cfirstLogotypeUpdated: "The logo has been added",
      cfirstLogotypeDeleted: "The logo has been successfully deleted!",

      cOffersPageSettings: "Settings",
      offerPageTitlePlaceholder: "Enter title",
      offerPageDescriptionPlaceholder: "Enter page description",
      offerPageButtonTextPlaceholder: "Enter button text (optional)",
      allstepsOffers: "List of offers",
      offerProposalHeader: "Offer",
      offerAttachAddImg: "Add image",
      offerAttachAddVideo: "Add video",
      offerItemImg: "Image",
      offerItemTitle: "Offer title",
      offerTitlePlaceholder: "Enter title here",
      offerItemSubtitle: "Subtitle (e.g., price)",
      offerSubtitlePlaceholder: "Enter text here",
      offerItemDescription: "Offer description",
      offerDescriptionPlaceholder: "Enter description here",
      offerItemButtonText: "Offer button text",
      offerBtnTextPlaceholder: "Enter button text",
      offerItemButtonLink: "Button link",
      offerBtnLinkPlaceholder: "Start link with https://",
      csanyOfferLogic: "Display logic",
      offerAddNewOffer: "Add new offer",
      offerLogicSetupTitle: "Logic settings",
      OfferLogicSetupDescription: "Here you can set up which answers will display specific offers",
      cstepsOffersPage: "Results page",
      addNewSectionResultsByLogic: "Offers",
      cformActionAfterContactOffersOr: "Offers page / ",
      tolltipOffersAfterContact: "If, after completing the quiz, the logic for displaying at least one offer is met, the offers page will be shown; otherwise, the Thank You page will be displayed.",
      videoCircleTextQuestion: "How does it work?",
      offerPhotoOptimalRatio: "For optimal image display, we recommend using a 16:9 ratio.",
      tolltipThankYouAfterContact: "After completing the quiz, a thank-you page will be displayed.",
      cformActionAfterContactOffers: "Results page",
      addOfferPageInContact: "Enable results page",
      offerPageHeader: "Header for the offers page",
      pumkinOrange: "Orange",



    },
    RU: {
      madeInUkr: "made in Ukraine",
      upProVersion: "Перейти на PRO",
      getLeads: "Купить Лиды",
      poperBillings: "Платежи",
      poperTemplates: "Шаблоны",
      poperPartnership: "Партнерская программа",
      poperProfile: "Профиль",
      poperLogout: "Выход",
      defaultNewQuestion: "Новый вопрос",
      defaultNewQuestionDescription: "Сюда можно вставить краткое описание вопроса (до 80 символов)",
      defaultNewVariant: "Новый вариант",
      defaultBtnText: "Начать",
      navBillings: "Платежи",
      navPartnership: "Партнерская программа",
      navProfile: "Профиль",
      navTariffs: "Тарифы",
      welcomeBack: "С возвращением!",
      loginHeader: "Войти",
      loginHeader2Part: "чтобы продолжить",
      loginEmailPlaceholder: "E-mail",
      loginPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      loginBtnValue: "Войти",
      loginWith: "Войти с помощью",
      loginSignup: "Регистрация",
      passwordAreForgetPassword: "Забыли пароль?",
      passwordResetPasswordHeader: "Восстановление пароля",
      passwordResetPassword: "Восстановить пароль",
      passwordWeSentReset: "Мы отправили вам ссылку на восстановление пароля по электронной почте.",
      passwordInputNewPassword: "Введите новый пароль",
      passwordConfirmPassword: "Подтвердите пароль",
      passwordChangePassword: "Изменить пароль",
      passwordResetedPasswordCompleted: "Ваш пароль успешно изменен!",
      passwordGoToWorkboard: "Перейти в акаунт",
      helloHeader: "Здравствуйте!",
      signupHeader: "Зарегистрироваться",
      signupHeader2Part: "чтобы продолжить",
      signupNamePlaceholder: "Александр",
      signupPhonePlaceholder: "номер телефона",
      signupEmailPlaceholder: "E-mail",
      signupPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      signupBtnValue: "Создать аккаунт",
      signupWith: "Регистрация с помощью",
      signupLogin: "Войти в мой аккаунт",
      signupTariffHeader: "Тарифы",
      signupTariffFree: "Free.",
      signupTariffPro: "Pro.",
      signupTariffFreeDescription: "Безлимитное количество новых квизов (веб страниц). Возможность генерировать до 300 заявок в месяц бесплатно.",
      signupTariffProPrice: "$20/месяц",
      signupTariffProDescription: "Безлимитное количество новых квизов (веб страниц). Возможность генерировать",
      signupTariffProDescriptionBold: "более 300 заявок в месяц бесплатно.",
      userBackToQuiz: "Вернуться на воркборд",
      userBackToSettings: "Вернуться к настройкам",
      billingYourTariff: "Ваш тарифный план",
      billingYourPro: "Pro",
      billingYorFree: "Free",
      billingFreeLeads: "Бесплатных заявок",
      billingInfinity: "безлимит",
      billingTariffRenewData: "Дата обновлення тарифа",
      billingTariffEndData: "Дата окончания тарифа",
      billingOtherTariffFree: "Free.",
      billingOtherTariffPro: "Pro.",
      billingTariffFreeDescription: "Безлимитное количество новых квизов (веб страниц). Возможность генерировать до 300 заявок в месяц бесплатно.",
      billingYouHavePro: "Теперь вы на Pro версии. Зачем делать шаг назад?",
      billingYouHasFreeLeads: "Бесплатных заявок",
      billingYouHasLeads: "Осталось заявок",
      billingTariffProDescription: "Безлимитное количество новых квизов (веб страниц). Возможность генерировать ",
      billingTariffProDescriptionBold: "более 300 заявок.",
      billingYourFutureTariff: "Ваш будущий тариф",
      billingBtnChangeToPro: "Перейти",
      billingBtnRenewPro: "Обновить",
      billingBillings: "Оплаты",
      billingYouHaveNotBillings: "У вас пока нет оплат",
      billingPaymentsMethod: "Способ оплаты",
      billingPaymentCard: "Карта",
      billingChangeData: "Изменить данные",
      billingDeletePayMethod: "Удалить",
      billingModalDeleteShure: "Вы действительно хотите удалить этот способ оплаты?",
      billingModalDeleteCancel: "Отменить",
      billingModalDeleteOk: "Удалить",
      billingModalDeleteSuccess: "Вы успешно удалили свою карту",
      billingModalRegularPaymentDeleteSuccess: "Регулярные платежи отменены",
      billingModalRegularPaymentDeleteOk: "ОК",
      billingYourMonthPlan: "Ваш ежемесячный план",
      billingLeads: "заявок",
      billingChangePlan: "Изменить план",
      billingChangePlanDescription: "Прайс будет соответствующим к желаемому количеству заявок со всех квизов в вашом аккаунте.",
      billingSelectPeriod: "Выбрать период",
      billingChangePlanDescriptionImportant: "Важно:",
      billingChangePlanDescriptionImportantMonth: "Лиды можно использовать 1 месяц после покупки.",
      subscriptionTariffLable: "Подпишитесь на нашу автоматическую подписку.",
      billingChangePlanWarning: "",
      billingTariffMonth: "мес.",
      billingChangePlanPay: "Купить",
      profileName: "Имя",
      profilePhone: "Номер телефона",
      profileEmail: "E-mail",
      profileStatus: "Статус",
      profileStatusAgency: "Агенство",
      profileStatusPlaceholder: "Что описывает вас лучше всего?",
      profileStatusFreelancer: "Фрилансер",
      profileStatusBuisines: "Владелец бизнеса",
      profilePassword: "Password",
      profileChangePassword: "Изменить пароль",
      profileNewPassword: "Новый пароль",
      profileSaveNewData: "Обновить данные",
      workboardAddNewQuiz: "Создать проект",
      workboardModalGiveQuizName: "Дайте название квиза",
      workboardModalQuizNamePlaceholder: "Новый квиз",
      workboardModalNewQuizBtnValue: "добавить",
      workboardAlertGiveAnyName: "Введите хотя бы какое-нибудь название",
      workboardAlertNextTimeAddQuiz: "Что ж, в следующий раз решитесь)",
      workboardChangeQuizName: "Сменить название",
      workboardQuizLink: "ссылка",
      workboardQuizLinkCopied: "Ссылка скопирована в буфер",
      workboardEditQuiz: "Редактировать",
      workboardCopyQuiz: "Скопировать квиз",
      workboardQuizCopied: "Квиз успешно скопирован",
      workboardDeleteQuiz: "Удалить",
      workboardModalRemoveQuizTrash: "Этот квиз будет перемещён в корзину и удалён окончательно через 1 месяц.",
      workboardModalRemoveQuizSure: "Вы уверены, что хотите переместить этот квиз в корзину?",
      workboardModalDeleteQuizCancel: "Отменить",
      workboardModalDeleteQuizDelete: "Удалить",
      workboardModalDeleteQuizSuccess: "Квиз успешно удалён",
      workboardModalQuizDeleteOk: "ОК",
      workboardStatsTitle: "Статистика",
      workboardModalLeadsAnswersCount: "Ответы на квиз",
      workboardModalLeadsLeadSerial: "№",
      workboardModalLeadsLeadDate: "Дата",
      workboardModalLeadsLeadEmail: "Email",
      workboardModalLeadsLeadName: "Имя",
      workboardModalLeadsLeadPhone: "Телефон",
      workboardModalLeadsLeadActions: "Действия",
      workboardModalLeadsLeadShowMore: "Показать больше",
      workboardModalLeadsLeadHideMore: "Скрыть",
      workboardModalLeadsLeadAnswerScipped: "Пропущено",
      workboardModalNoLeads: "Пока заявок нет",
      constructorLink: "ссылка",
      constructorLinkCopied: "Скопировано в буфер обмена:",
      constructorNavSteps: "Шаги",
      constructorNavDesign: "Дизайн",
      constructorNavSettings: "Настройка",
      constructorStep: "Шаг",
      cancel: "Отменить",
      constructorCancel: "Отменить",
      constructorSave: "Сохранить",
      constructorextraNavStart: "С",
      constructorextraNavContact: "К",
      constructorextraNavFinish: "Ф",
      cstepsSection: "Секции",
      cstepsStartPage: "Стартовая страница",
      cstepsContactPage: "Форма контактов",
      cstepsFinishPage: "Финишная страница",
      cstepsEdit: "Редактировать",
      cstepsMove: "Переместить",
      cstepsDeleteStep: "Удалить шаг",
      cstepsAddSection: "Добавить секцию",
      cdesignColorTheme: "Цветовая схема",
      cdesignCustomTheme: "Пользовательская цветовая схема",
      cdesignDefaultThemes: "Готовые цветовые схемы",
      cdesignThemeStandart: "Стандарт",
      cdesignThemeDark: "Дарк",
      cdesignThemeNud: "Нюд",
      cdesignThemeGradient: "Градиент",
      cdesignShapeDesign: "Форма дизайна",
      cdesignShapeRound: "Закругленная",
      cdesignShapeSquare: "Угловая",
      csettingsIntegration: "Интеграция",
      csettingsOweDomain: "Подключение личного домена",
      csettingsDocs: "Юридические документы",
      cintegrationIntegration: "Интеграция",
      cintegrationShareLeads: "Передача заявок",
      cintegrationTitleEmail: "E-MAIL",
      cintegrationInstruction: "Инструкция",
      cintegrationInstructionDetail: "Детали",
      cintegrationEmailInstruction: "Вставьте в поле снизу нужную электронную почту, на которую необходимо отправлять информацию. Каждая заявка с этого квизу будет дублироваться на указанную вами почту.",
      cintegrationPublishAfterEdit: "Сохраните квиз после редактирования",
      cintegrationSendNewLeadsTo: "Отправлять сообщения о новых заявках на:",
      cintegrationInputEmail: "Введите адрес электронной почты",
      cintegrationAddEmail: "Добавить email",
      cintegrationEmailSaved: "Данные Email интеграции сохранены",
      cintegrationEmailDeleted: "Данные Email интеграции удалены",
      cintegrationTitleFB: "FB pixel",
      cintegrationIDPixel: "Pixel ID",
      cintegrationFBInstruction1: "Зайдите в рекламный кабинете Facebook Ads и в разделе Event Manager скопируйте ID Facebook Pixel. ",
      cintegrationFBInstruction2: "Не копируйте весь код, только идентификатор из 15-16 цифр. ",
      cintegrationFBInstruction3: "Скопируйте и вставьте в поле ниже.",
      cintegrationFBInstruction: 'Событие, на которое нужно настраивать конверсию это "Lead". Это событие будет вызвано как только пользователь отправит заявку в квизе.',
      cintegrationFBInstructionTextTitle: "Название событий для FB Conversions:",
      cintegrationFBInstructionTextTextStart: " – посещение стартовой страницы;",
      cintegrationFBInstructionTextQuestionN: " – посещение шага N",
      cintegrationFBInstructionTextOpenContactPage: " – посещение страницы контактов;",
      cintegrationFBInstructionTextLead: " – успешная отправка формы с контактными данными;",
      cintegrationFBInstructionTextThankYou: " – посещение финальной страницы.",
      bold_adsquiz_Start: "adsquiz_Start",
      bold_adsquiz_QuestionN: "Question_N",
      bold_adsquiz_OpenContactPage: "Open_Contact-Page",
      bold_adsquiz_Lead: "Lead",
      bold_adsquiz_ThankYou: "adsquiz_ThankYou",
      cintegrationInputIDPixel: "Введите ID пикселя",
      cintegrationDataFBSaved: "Данные FB пикселя сохранены",
      cintegrationAccesToken: "ACCES TOKEN",
      cintegrationTitleGA: "Google analytics",
      cintegrationGAInstructionDescription: "Узнайте, как люди находят ваш квиз и взаимодействуют с ним.  ",
      cintegrationGAInstruction: 'При получении заявки в квизе, в Google Analitics будет вызвано событие "generate_lead".',
      cintegrationIDFolow: "ID отслеживания",
      cintegrationInputID: "Ввести ID",
      cintegrationDataGASaved: "Данные Google Tag Manager сохранены",
      cintegrationTitleGTM: "Google Tag Manager",
      cintegrationGTMInstructionDescription: "Узнайте, как люди находят ваш квиз и взаимодействуют с ним.  ",
      cintegrationGTMInstruction: "Скопируйте идентификатор Google Tag Manager и вставьте его в поле ниже.",
      cintegrationDataGTMSaved: "Данные Google Tag Manager сохранены",
      cintegrationTitleTG: "Telegram",
      cintegrationHowReciveTGLeadBot: "Как получить заявки в Телеграм?",
      cintegrationTGInstructionBot1: "1. Скопируйте token находящийся в поле ниже.",
      cintegrationTGInstructionBot2: "2. Перейдите по ссылке в бот, нажмите кнопку «СТАРТ».",
      cintegrationTGInstructionBot3: "3. Скопированный token отправьте сообщением в наш бот.",
      cintegrationHowReciveTGLeadChat: "Как получить заявки в чат-телеграм?",
      cintegrationTGInstructionChat1: "1. Разместите наш бот в нужный чат и выставьте боту статус администратора.",
      cintegrationTGInstructionChat2: "2. Скопируйте token в поле ниже и отправьте его в нужный чат.",
      cintegrationTGInstructionChat3: "3. Дождитесь ответа `OK! Your quiz token was saved!”",
      cintegrationTGInstructionChat4: "4. Тепер заявки будут приходить в этот чат.",
      cintegrationTGInstructionWarning: "Важно:",
      cintegrationTGInstructionWarningText: "Обратите внимание, что это должна быть именно группа, а не Telegram канал. В Telegram канал заявки поступать не будут.",
      cintegrationTGBotLink: "ссылка на чатбот",
      cintegrationTGTitleToken: "TOKEN",
      cintegrationInputTGToken: "Введите телеграмм токен чат",
      cintegrationAddIntegration: "Добавить интеграцию",
      cintegrationDataTGSaved: "Данные Telegram интеграции сохранены",
      cintegrationTitleTT: "TT pixel",
      cintegrationTTInstruction: "Для отслеживания событий в рекламном кабинете Tik-Tok Ads, скопируйте идентификатор пикселя и вставьте в поле ниже. ",
      cintegrationDataTTSaved: "Данные TT пикселя сохранены",
      cintegrationTitleWH: "WebHook",
      cintegrationWHInstruction1: "Вы можете уведомлять посторонние приложения о поступивших заявках. Для этого укажите URL, на который будет отправлен WebHook.",
      cintegrationWHInstruction2: "Мы подготовили",
      cintegrationWHInstruction3: "видео инструкцию",
      cintegrationWHInstruction4: "с подробным описанием данного функционала.",
      cintegrationWHInstruction5: "Просмотреть видео.",
      cintegrationWHInstruction6: "В этом видео мы рассмотрим передачу хука на основе отправки данных в Google таблицу и отправку данных в CRM-систему Trello.",
      cintegrationWHInstruction7: "Мы рекомендуем использовать для этих целей сервис",
      cintegrationWHInstruction8: "При регистрации по данной ссылки, для наших пользователей, действуют бонус - 10 000 бесплатных действия для любых интеграций.",
      cintegrationWHInstruction9: "Ссылка",
      cintegrationWHInstruction10: "для регистрации с бонусом.",
      cintegrationWHUrl: "Адреса вебхука",
      cintegrationInputWHUrl: "Введите адрес вебхука",
      cintegrationAddWH: "Добавить Web-hook",
      cintegrationWebHookDataSaved: "WebHook данные сохранены",


      cintegrationComonWHInstructionShort: "Вы можете уведомлять сторонние приложения о поступающих лидах. Укажите URL-адрес вебхука для отправки данных.",
      cintegrationComonWHInstruction1Title: "1. Вставьте URL-адрес вебхука в поле для ввода:",
      cintegrationComonWHInstruction1Description: "Вставьте URL-адрес вебхука, предоставленный вашим сервисом или приложением, в поле для ввода.",
      cintegrationComonWHInstruction2Title: "2. Сохраните изменения:",
      cintegrationComonWHInstruction2Description: 'Нажмите кнопку "Сохранить изменения" для сохранения настроек вебхука.',
      cintegrationComonWHInstruction3Title: "3. Готово:",
      cintegrationComonWHInstruction3Description: "Теперь ваш вебхук настроен для отправки данных на выбранный вами сервис.",
      cintegrationComonWHInstructionAdditional: "Данные будут отправлятся на вебхук при каждом получении лида. Метод отправки POST. Пример данных которые будут отправлены находится ",
      cintegrationComonWHInstructionAdditionalLink: "тут",
      cintegrationComonWHInstructionVideo: "Подробная видео-инструкция",

      cintegrationApixDriveWHInstructionShort: "Вы можете уведомлять ApixDrive о поступающих лидах. Для этого укажите URL-адрес, на который будет отправлен WebHook.",
      cintegrationApixDriveWHInstructionShort1: "При регистрации по ",
      cintegrationApixDriveWHInstructionShort2: "этой ссылке",
      cintegrationApixDriveWHInstructionShort3: " наши пользователи получают бонус - 10 000 бесплатных действий для любых интеграций.",
      cintegrationApixDriveWHInstruction1Title: "Зарегистрируйтесь или откройте ваш акаунт ApixDrive:",
      cintegrationApixDriveWHInstruction1Description1: "Ссылка ",
      cintegrationApixDriveWHInstruction1Description2: "для регистрации с бонусом.",
      cintegrationApixDriveWHInstruction2Title: "Получите URL адрес для вебхука:",
      cintegrationApixDriveWHInstruction2Description1: "Зайдите в настройки необходимой связи и скопируйте адрес для отправки вебхука:",
      cintegrationApixDriveWHInstruction2Description2: 'Вы можете создать новую связь нажав кнопку "Создать связь" и на шаге выбора системы выберите "ADSQuiz".',
      cintegrationApixDriveWHInstruction3Title: "Вставьте URL-адрес вебхука:",
      cintegrationApixDriveWHInstruction3Description: "Вставьте URL-адрес вебхука, предоставленный ApixDrive, в поле для ввода.",
      cintegrationApixDriveWHInstruction4Title: "Сохраните изменения:",
      cintegrationApixDriveWHInstruction4Description: 'Нажмите кнопку "Сохранить изменения" для сохранения настроек вебхука.',
      cintegrationApixDriveWHInstruction5Title: "Готово:",
      cintegrationApixDriveWHInstruction5Description: "Теперь ваш вебхук настроен для отправки данных на ApixDrive.",
      cintegrationApixDriveWHInstructionAdditional: "Данные будут отправлятся на вебхук при каждом получении лида. Метод отправки POST. Пример данных которые будут отправлены находится ",
      cintegrationApixDriveWHInstructionAdditionalLink: "тут",
      cintegrationApixDriveWHInstructionVideo: "Подробная видео-инструкция",



      cintegrationConectOweDomain: "Подключение личного домена",
      cintegrationOweDomainInstruction1: "1. Чтобы разместить ваш квиз на необходимом домене, зайдите в аккаунт вашего хостинга. Выделите DNS-настройки и создайте A-запись с параметром IP: 185.25.117.21",
      cintegrationOweDomainInstruction2: "2. Укажите домен в поле выше и сохраните квиз.",
      cintegrationDomain: "Домен",
      cintegrationInputYourDomain: "Введите ваш домен",
      cintegrationCheckDomenIsCorrect: "Проверьте правильность введенного домена",
      cintegrationDomainWhichCorrect: "Введите домен без протоколов. Например: some.site.com",
      cintegrationInsertedDefaultDomain: "Домен установлен по умолчанию",
      cintegrationDomainDataSaved: "Данные домена сохранены",
      cintegrationPrivacyPolicy: "Политика конфиденциальности",
      cintegrationPrivacyPolicyByDefault: "По умолчанию мы используем нашу стандартную политику конфиденциальности. Мы рекомендует добавить вашу политику конфиденциальности, которая будет актуальна для вашего бизнеса. ",
      cintegrationPrivacyPolicyAdventage: "Это даст понять вашим потенциальным клиентам как вы используете полученные контактные данные. Также этого требуют некоторые рекламные площадки.",
      cintegrationPrivacyPolicyUrl: "Ссылка на документы",
      cintegrationPrivacyPolicyInputYourDocsLink: "Вставте ссылку на документы",
      cintegrationDocsDataSaved: "Ссылка на ваши документы сохранена",
      allstepsUpploadPhoto: "Загрузить фото",
      allstepsChangePhoto: "Изменить фото",
      allstepsDeletePhoto: "Удалить фото",
      allstepsTitle: "Заголовок",
      allstepsDescription: "Описание",
      allstepsButtonText: "Текст кнопки",
      allstepsOtherSettings: "Другие настройки",
      allstepsIsSaveBeforeRoute: "Сохранить данные перед переходом?",
      cfirstFormat: "Формат",
      cfirstHeaderStartText: "Ваш заголовок должен быть здесь. Мотивируйте пользователей пройти тест до конца",
      cfirstAddMediafile: "Добавить медиафайл (фото)",
      cfirstBonus: "Бонус",
      cfirstAddBonus: "Добавить бонус",
      cfirstStartPageDataUpdated: "Данные стартовой страницы обновлены",
      csanyAnswerType: "Тип вопроса",
      csanyATCard: "карта",
      csanyATList: "список",
      csanyATCustom: "свободное поле",
      csanyQuestion: "Вопрос",
      csanyAnswers: "Ответы",
      csanyAddAnswer: "Добавить",
      csanyTitleForModalConfirm: "Это действие необратимо!",
      csanyQuestionForModalConfirm: "Вы уверены, что хотите удалить этот вопрос?",
      csanyRequiredStep: "Обязательный шаг",
      csanyAlowMultiple: "Разрешить несколько ответов",
      csanyAutoRedirect: "Автопереход далее",
      csanyQuestionLogic: "Настройки логики вопросов",
      csanyDataStepUpdated: "Данные страницы с вопросом обновлены",
      cformForm: "Форма",
      cformValueName: "Имя",
      cformValueSurname: "Фамилия",
      cformValuePhone: "Номер телефона",
      cformValueEmail: "E-mail",
      cformDataContactsPageUpdated: "Данные контактной страницы обновлены",
      cformRequiredInput: "Обязательный",
      cfinishFinishPageTitle: "Финишная страница",
      cfinishText: "Текст",
      cfinishDescription: "Описание",
      cfinishAddImage: "Добавить изображение",
      cfinishImageAdded: "Изображение добавлено",
      cfinishURL: "URL",
      cfinishButtonText: "Текст кнопки",
      cfinishInsertNikname: "Вставьте здесь ваш никнейм",
      cfinishCheckDataIsCorrect: "Проверьте правильность введенного идентификатора",
      cfinishFbWhichCorrect: "Введите идентификатор (никнейм) без остального адреса. Нпример: some.name",
      cfinishIgWhichCorrect: "Введите идентификатор (никнейм) без остального адреса. Нпример: some.name",
      cfinishTgWhichCorrect: "Введите идентификатор (никнейм) без остального адреса. Нпример: some.name",
      cfinishFinishPageDataUpdated: "Данные  финишной страницы обновлены",
      adminUsersReg: "Пользователей в системе",
      adminNewUsers: "Новых пользователей за месяц",
      adminProAccounts: "PRO accounts",
      adminLTV: "LTV",
      adminSharePro: "PRO сравнительно со всеми",
      adminReciveLeads: "Получено заявок",
      adminLeadsForOne: "На одного пользователя",
      adminMadeQuiz: "Сделано квизов",
      adminQuizForOne: "На одного пользователя",
      adminAvarageUsed: "Среднее количество месяцев использования",
      adminProfitMonth: "Заработан за месяц",
      adminProfitAlltime: "Заработано за все время",
      adminUsersList: "База пользователей",
      notificationErrorLogin: "Ошибка авторизации:",
      notificationLogin: "Вход...",
      notificationWrongLoginData: "Неправильные данные входа",
      notificationNetworkError: "Проверьте подключение к Интернету",
      notificationInputLoginData: "Введите данные для входа",
      notificationCheckInputEmail: "Проверьте правильность электронной почты",
      notificationCheckInputPhone: "Проверьте правильность номера телефона",
      notificationInputAllInputs: "Заполните все поля для регистрации",
      notificationAgreePrivacyPolicy: "Согласитесь с политикой конфиденциальности",
      adminEditorForPartnership: "Редактор партнерской программы",
      developmentPage: "Раздел находится в разработке",
      signupAgreeIAgreeWith: "Я согласен с",
      signupAgreePolicy: "политикой конфиденциальности",
      signupAgreeAnd: "и",
      signupAgreeOffer: "публичной офертой",
      signupAgreeService: "сервиса",
      workboardModalLeadsLeadReferrer: "Не определено",
      workboardModalLeadsLeadReferrerClarification: "Данные об источнике перехода заблокированы или не определены",
      workboardModalLeadsLeadEmailCopied: "Email скопирован в буфер",
      workboardModalLeadsLeadPhoneCopied: "Номер телефона скопирован в буфер",
      workboardModalLeadsHaveNotSended: "У вас есть неполученные лиды",
      workboardModalLeadsLeadReload: "Получить",
      workboardModalLeadsLeadReloadedTitle: "Запрос получен",
      workboardModalLeadsLeadReloaded: "If you have available leads in your tarif - the requested leads will be loaded at the top of the list and sent to your integrations",
      cfirstStartPagePhotoUpdated: "Фото стартовой страницы обновлено",
      allstepsPhotoTooBig: "Файл слишком большой",
      allstepsPhotoMustBeLess: "Фото должно быть меньше",
      cfirstStartPageBonusPhotoUpdated: "Фото бонуса обновлено",
      cstepsQuestionsOrderSaved: "Порядок вопросов сохранён",
      csanyAnswerOrderSaved: "Порядок ответов сохранён",
      allstepsPhotoAdaptation: "Адаптация фото под устройства",
      allstepsPhotoFit: "Соотношение сторон",
      constructorYouHideStep: "Вы спрятали этот шаг",
      cstepsHiddenStep: "Спрятать шаг",
      cstepsShowStep: "Отобразить шаг",
      cfinishFinishPageHiddenUpdated: "Фінішна сторінка прихована.",
      cfinishFinishPageHiddenUpdatedGoContact: "Перейдіть на сторінку Форми контактів",
      cfinishFinishPageHiddenUpdatedSetRedirect: "та налаштуйте редирект після відправки даних",
      cformActionAfterContactRecive: "Действия после отправки формы",
      cformActionAfterContactThankyou: "Страница благодарности",
      cformActionAfterContactRedirect: "Переадресация",
      cformAdditionalSettings: "Дополнительные настройки",
      cformRedirectURL: "URL",
      cintegrationOweDomainInstructionImportant: "Важно: Адрес в вашем хостинг аккаунте и поле ниже, должны совпадать. Время применения изменений DNS-настроек и выписки SSL сертификата - до 3-х часов после сохранения.",
      cintegrationOweDomainInstructionVideo: "Посмотреть видео инструкцию",
      workboardModalLeadsLeadLimit: "Лимит ваших лидов исчерпан.",
      workboardModalLeadsLeadLimitInstruction1: "Чтобы увидеть новые лиды,",
      workboardModalLeadsLeadLimitInstruction2: "перейдите на другой тариф",
      workboardModalLeadsLeadLimitInstruction3: "обновите текущий тариф",
      workboardModalLeadsLeadLimitInstruction4: '(не касается "Free") или дождитесь обновления тарифа, если у вас подключено автопродление тарифа',
      billingBonusesTitle: 'Промокод',
      billingBonusesDescription: 'Введите полученный промокод в поле рядом',
      billingBonusesPlaceholder: 'Промокод',
      billingBonusesBtn: 'Активировать',
      billingBonusesActivated: 'Промокод активирован!',
      billingBonusesLeadIncreased: 'Количество доступных лидов увеличено.',
      billingBonusesInvalid: 'Неправильный промокод',
      billingBonusesNotAvailable: 'Промокод больше недоступен',
      billingBonusesAlreadyActivated: 'Данный купон недействителен',
      createQuizSPDescription: "Это место для краткого описания",
      createQuizCPTitle: "Как мы можем связаться с вами?",
      createQuizCPDescription: "Пожалуйста, заполните форму ниже",
      createQuizCPButton: "Готово!",
      createQuizFPTitle: "Спасибо!",
      createQuizFPDescription: "Теперь мы подберём лучшее решение для вас!",
      createQuizFPButton: "Покажите мне что-нибудь!",
      selectInternationalPrefix: "Выбрать международный префикс",
      autoInternationalPrefix: "Определять страну автоматически",
      dovnloadCsvTitle: "Экспортировать данные за вибраный период",
      dovnloadCsvSelectAllPeriod: "Выбрать за весь период",
      dovnloadCsvCancel: "Отменить",
      dovnloadCsvExport: "Экспортировать",
      
      
      
      
      widgetEmbedVidgetToSite: "Установка квиза на сайте",
      widgetEmbedVidgetSelectEmbedVariant: "Выберите вариант интеграции",
      widgetEmbedVidgetSitecode: "В коде сайта",
      widgetEmbedVidgetPopup: "Поп-ап",
      widgetEmbedVidgetChat: "Квиз-кнопка",
      widgetEmbedVidgetInProgres: "В разработке",
      widgetEmbedVidgetToSiteCode: "Установка квиза в код сайта",
      widgetSiteCodeInstruction0: "Чтобы установить квиз на вашем сайте, добавьте код с первого поля в тег `<head>` на вашем сайте или в Google Tag Manager, если он интегрирован в ваш сайт. Если после того как вы уже вставите квиз на сайт вы измените домен у квиза Вам нужно будет обновить код интеграции на вашем сайте.",
      widgetSiteCodeInstruction1: "В место куда вы хотите интегрировать квиз, вставьте код со второго поля.",
      widgetSiteCodeInstruction2: "Квиз можно интегрировать в контейнер любого размера, даже в сайдбары! Максимальный размер квиза, также как и при прямом входе на домен - 900х552 пикселя. Его можно добавлять в контейнеры и большего размера, в таком случае квиз будет отцентрирован.",
      widgetSiteCodeInstruction3: "Не стесняйтесь – экспериментируйте!",
      widgetSiteCodeCopyToHead: "Скопируйте и вставьте в `<head>` вашего сайта",
      widgetSiteCodeCopyToBody: "Вставьте код в место, где должен быть квиз",
      workboardModalAddQuizHeader: "Создание нового Quiz",
      workboardModalGiveQuizNameAndMethod: "Придумайте название вашего квиза и виберите желаемый способ создания",
      workboardModalNewQuizFromStart: "Создать с нуля",
      workboardModalNewQuizFromTemplates: "Готовые шаблоны",
      QuizTemplatesSearchInput: "Название шаблона и/или категорий",
      QuizTemplatesSearchBtn: "Поиск",
      QuizTemplatesReadyTemplates: "Готовые шаблоны",
      QuizTemplateView: "Посмотреть",
      QuizTemplateSelect: "Выбрать",
      widgetPopupInstruction0: "Чтобы установить квиз на вашем сайте, добавьте код из поля с кодом в тег `<head>` на вашем сайте или в Google Tag Manager, если он интегрирован в ваш сайт. Если после того как вы уже вставите квиз на сайт вы измените домен у квиза или тригеры открытия (процент скрола или тайминг), вам нужно будет обновить код интеграции на вашем сайте.",
      widgetPopupInstruction1: "Сразу перед закрывающим тегом </footer> вставьте код из второго поля.",
      widgetPopupInstruction2: "Для программистов или продвинутых пользователей",
      widgetPopupInstruction3: "Если вам, по каким-либо причинам, неудобно вставлять квиз перед закрывающим тегом `</footer>` вы можете вставить код из второго поля в любое удобное место, но учитывайте, что контейнер в который вы вставляете код из второго поля, должен быть поверх всех окон и крайне желательно, должен иметь высоту и ширину максимально возможную для дисплея.",
      widgetPopupCopyToHead: "Скопируйте и вставьте в `<head>` вашего сайта",
      widgetPopupCopyToBody: "Скопируйте и вставьте код перед закрывающим тегом `</footer>`",
      widgetPopupTimeToOpen: "Промежуток времени",
      secondReduction: "сек",
      widgetPopupTimeClarification: "Попап появится с задержкой указанного выше времени в секундах",
      widgetPopupScrollToOpen: "Процент пролистывания сайта",
      widgetPopupScrollClarification: "Попап появится после указанного выше процента пролистывания вашего сайта",
      quizzesTemplatesAllTags: "Все шаблоны",
      widgetEmbedVidgetButton: "Конструктор кнопки",
      widgetButtonInstruction0: "Ви можете установить на вашем сайте квиз который будет открываться при клике по кнопке.",
      widgetButtonInstruction1: "Додайте заокруглення, тінь, та текст кнопки. Також, ви можете змінити колір кнопки та тексту всередині.",
      widgetButtonInstruction2: "Скопируйте скрипт, расположенный в первом поле с кодом, и вставьте его перед закрывающим тегом `</head>`.",
      widgetButtonInstruction3: "Скопируйте сгенерированный код кнопки со второго поля с кодом и вставьте в любое место вашего сайта.",
      widgetButtonInstruction4: 'Вы можете не использовать код со второго поля с кнопкой, а просто добавить `id="button_open_quiz_popup"` к любому элементу `<input type="button">` или элементу `<button></button>` на сайте.',
      widgetRealTimeChange: "Вы можете видеть изменения в реальном времени в правой части экрана",
      widgetButtonCornerRound: "Скругления кнопки",
      widgetButtonShadow: "Тень кнопки",
      widgetButtonBgColor: "Цвет кнопки",
      widgetButtonTextColor: "Цвет текста",
      widgetButtonTextValue: "Текст кнопки",
      openPopupQuizText: "Начать квиз",
      widgetButtonInsertBeforeAfterBody: "Вставьте код квиза сразу после открывающего тега `<body>` или перед сразу закрывающим тегом `</body>`",
      widgetSiteCodeCopyButton: "Вставьте код кнопки в любое место на сайте",
      footerContactUs: "Возникли вопросы? Вы можете связаться с нами:",
      enterYourName: "Введите ваше имя",
      enterYourPhone: "Введите номер телефона",
      enterYourEmail: "Введите ваш email",
      enterYourPassword: "Введите ваш пароль",
      selectOption: "Выберите вариант",
      createNewGroup: "Создать новую групу",
      dontHaveAccount: "У вас нету акаунта?",
      resetPasswordTip: "Если у вас есть какие-либо дополнительные проблемы или вопросы, пожалуйста, не стесняйтесь обращаться в нашу службу поддержки клиентов. Мы здесь, чтобы помочь.",
      getBackAccount: "Вернитесь к своей учетной записи",
      inMinutes: "за минуту",
      alreadyHaveAccount: "Уже есть акаунт?",
      quizSlotLastEdit: "Последнее изменение",
      workboardQuizzesTitle: "Мои квизы",
      allstepsBack: "Назад",
      constructorGetLink: "Ссылка",
      allOr: "или",
      allAnd: "и",
      acceptedFileType: "Допустимые форматы: ",
      conjunctionAnd: "и",
      acceptedFileSize: "Максимальный размер: ",
      acceptedFileSizeMeasure: "МБ",
      cfirstBonusDisabled: "Отключено",
      cfirstBonusAvailable: "Доступно",
      allstepsResetInHeader: "Сбросить",
      placeholderText: "Введите здесь",
      constructorSaveChanges: "Сохранить",
      cformInputAvailable: "Доступно",
      cformInputDisabled: "Отключено",
      constructorBack: "Назад",
      videoInstruction: "Видеоинструкция",
      csettingsIntegrationFB: "Интеграция - Facebook Pixel",
      csettingsIntegrationTT: "Интеграция - TikTok",
      csettingsIntegrationGA: "Интеграция - Google Analytics",
      csettingsIntegrationGTM: "Интеграция - Google Tag Manager",
      csettingsIntegrationWH: "Интеграция - WebHook",
      csettingsIntegrationTG: "Интеграция - Telegram",
      csettingsIntegrationApixDrive: "Интеграция - APIxDrive",
      csettingsIntegrationEmail: "Интеграция - Эл. почта",
      csettingsIntegrationAbout: "Описание",
      csettingsIntegrationFBAbout: "Перейдите в рекламный кабинет Facebook Ads и скопируйте идентификатор пикселя Facebook в разделе Менеджер событий. Не копируйте весь код, только идентификатор. Скопируйте и вставьте его в поле ниже.",
      csettingsIntegrationTTAbout: "Для отслеживания событий в вашей учетной записи Tik-Tok скопируйте идентификатор пикселя и вставьте его в поле ниже.",
      csettingsIntegrationGAAbout: "Скопируйте идентификатор Google Analytics и вставьте его в поле ниже.",
      csettingsIntegrationGTMAbout: "Скопируйте идентификатор Google Tag Manager и вставьте его в поле ниже.",
      csettingsIntegrationWHAbout1: "Вы можете уведомлять сторонние приложения о поступающих лидах. Для этого укажите URL-адрес, на который будет отправлен WebHook. Мы рекомендуем использовать эту службу для этой цели.",
      csettingsIntegrationWHAboutApix: "Apix-Drive",
      csettingsIntegrationWHAbout2: "При регистрации по этой ссылке наши пользователи получают бонус - 10 000 бесплатных действий для любых интеграций.",
      csettingsIntegrationTGAbout: "Важно: обратите внимание, что это должна быть группа, а не канал Telegram. Лиды не будут отправляться на канал Telegram.",
      csettingsIntegrationADAbout: "Что-то о APIxDrive",
      csettingsIntegrationEmailAbout: "Инициируйте отправку электронной почты вашему респонденту и уведомьте свою команду. Сотрудничайте с вашей командой.",
      cSettingsIntegrationNeedHelp: "Нужна помощь?",
      cSettingsIntegrationRefreshToken: "Обновить токен",
      csettingsSettingASettings: "Расширенные настройки",
      cSettingsSettingQuizLangTitle: "Язык квиза",
      cSettingsSettingQuizLangDescription: "Чтобы обеспечить наилучший опыт, пожалуйста, выберите нужный язык квиза из вариантов ниже. На этом языке отображаются системные оповещения и некоторые другие неизменные тексты при прохождении квиза.",
      selectQuizLanguage: "Выберите язык квиза",
      cSettingsSettingQuizHideLabelTitle: "Спрятать метку",
      cSettingsSettingQuizHideLabelDescription: 'Включив этот параметр, вы сможете наслаждаться свободным от метки "made by adsquiz" интерфейсом, который позволит вам и вашим посетителям сосредоточиться исключительно на вопросах.',
      cSettingDataSaved: "Дополнительные настройки сохранены",
      userAccountInfo: "Информация об учетной записи",
      userGeneralInfoHeader: "Общая информация",
      userGeneralInfoDescription1: "Ваш профиль пользователя - это место, где вы можете управлять своей учетной записью и настройками.",
      userGeneralInfoDescription2: "Обновите свои личные данные, смените пароль и настройте предпочтения по уведомлениям.",
      profileNameFirst: "Имя",
      profileNameLast: "Фамилия",
      userSecuritySettingsHeader: "Настройки безопасности",
      userSecuritySettingsDescription: "Чтобы изменить пароль, просто введите ваш новый пароль дважды для подтверждения.",
      profileEmailCurent: "Текущий адрес эл. почты",
      profileEmailNew: "Новый адрес эл. почты",
      profileNewPasswordConfirm: "Подтвердите новый пароль",
      profileDeleteAccount: "Удалить учетную запись",
      profileDeleteAccountPopupHeader: "Удалить учетную запись?",
      profileDeleteAccountPopupDescription: 'Вы уверены, что хотите удалить свою учетную запись? Это действие нельзя отменить. Для подтверждения удаления введите слово "УДАЛИТЬ" в поле ниже.',
      profileDeleteAccountCheckedWord: "УДАЛИТЬ",
      profileDeleteAccountPopupPlaceholder: "Введите здесь",
      notificationSuccess: "Успех!",
      notificationSuccessSaveAccount: "Ваш пароль успешно изменен! Используйте новый пароль для входа",
      notificationSuccessDeleteAccount: "Ваш пароль успешно изменен! Используйте новый пароль для входа",
      cardPanUnspecified: "Не указано",
      userBillingsDate: "Дата",
      userBillingsCost: "Стоимость",
      userBillingsId: "ID",
      userBillingsMethod: "Способ оплаты",
      paymentSwitchToProHeader: "Переключиться на Pro",
      paymentSwitchToProDescription: "Найдите подходящий для вас план! Повышайте или понижайте уровень в любое время - мы упрощаем получение нужного плана.",
      paymentYourCurrentPlan: "Ваш текущий план:",
      paymentYourFuturePlan: "Ваш новый план:",
      paymentPlanLeadsLimit: "Лимит лидов на месяц:",
      paymentPlanLeadsLimitForTest: "Ваш лимит для теста квизов:",
      paymentPlanLeadsLeft: "Осталось лидов:",
      paymentPlanPrice: "Цена:",
      paymentPlanRenew: "Дата продления тарифа:",
      addNewSectionHeader: "Добавить новый раздел",
      addNewSectionList: "Список",
      addNewSectionCustom: "Поле ввода",
      addNewSectionCard: "Карточки",
      addNewSectionWithEmoji: "Список с эмодзи",
      addNewSectionTags: "Теги",
      addNewSectionSlider: "Ползунок",
      templatesBackToQuiz: "Вернуться к воркборду",
      constructorStepsSaved: "Все данные квиза успешно сохранены",
      constructorDesignSaved: "Дизайн квиза успешно сохранен",
      azureBlueWhite: "Стандартный",
      azureBlue: "Зимняя ночь",
      moderatePurplishRed: "Красный бархат",
      violetBlueCrayola: "Зимний бриз",
      wetRainforest: "Летний клен",
      lavender: "Космос",
      blueLilac: "Темная лаванда",
      opalGreen: "Карпатский лес",
      dustyBlue: "Тиффани",
      almondCrayola: "Кораловий риф",
      periwinkleCrayolaLilac: "Сирень",
      periwinkleCrayolaAqua: "Синевир",
      jazzJam: "Джаз",
      slateGrayBlue: "Мгла",
      turquoiseBlueCrayola: "Дия",
      blackPearlescentOpal: "Зола",
      pearlescentGentianBlue: "Сапфир",
      sapphireBlue: "Темный сапфир",
      errorUserDataUpdate: "Ошибка в обновленных данных: ",
      completeUserDataUpdate: "Данные пользователя обновлены",
      passwordDoNotCoincidence: "Пароли не совпадают",
      passwordDoNotCoincidenceAgain: "Спробуйте ще раз",
      nonBillingsYet: "У вас пока нет истории платежей",
      youCanChangePlan: "Вы можете изменить текущий план, нажав кнопку «Переключиться на PRO»",
      rangeMin: "Мин",
      rangeMax: "Макс",
      rangePositionStart: "Начальное положение",
      rangePositionEnd: "Конечное положение",
      rangeStep: "Шаг",
      rangeIsRange: "Диапазон",
      rangeIsRangeTrue: "Включено",
      rangeIsRangeFalse: "Выключено",
      recivePromotionNewsletter: "Получать имейл рассылки",
      reciveEmailCheckbox: "Чекбокс получения электронной почты",
      additionalCheckbox: "Дополнительный чекбокс",
      contactPrivacyPolicyCheckbox: "Чекбокс политики конфиденциальности",
      contactAgreeIAgreeWith: "Я согласен с",
      contactAgreePolicy: "политикой конфиденциальности",
      contactAgreeAnd: "и",
      contactAgreeTermsOfUse: "правилами использования",
      contactAgreeAgreementAdditionalWord: "",
      langEN: "Английский",
      langDE: "Немецкий",
      langSP: "Испанский",
      langPL: "Польский",
      langIT: "Итальянский",
      langKK: "Казахский",
      langRU: "Русский",
      langUA: "Украинский",
      langLT: "Литовский",
      langLV: "Латвийский",
      langET: "Эстонский",
      langRO: "Румынский",
      leadsLeft: "Осталось лидов",
      generateDomainTitle: "Введите доменное имя",
      generateDomainDescription: "Это уникальный адрес, по которому будет доступна ваша страница. Вы можете изменить адрес в любой момент или подключить свой.",
      generateDomainDomainNameLabel: "Доменное имя",
      cancelAllAction: "Отменить",
      confirmAllAction: "Подтвердить",
      falseHasValidCharacters: "Недопустимые символы в домене. Допустимыми символами являются буквы латинского алфавита, цифры и дефисы.",
      falseHasValidLength: "Длина домена должна быть от 6 до 20 буквенно-цифровых символов.",
      falseDoesNotStartOrEndWithSpecialChar: "Домен не должен начинаться или заканчиваться специальным символом.",
      falseDoesNotHaveConsecutiveSpecialChar: "Домен не должен содержать два или более подряд специальных символа.",
      falseDoesNotHaveConsecutiveSameChar: "Домен не должен содержать три или более подряд одинаковых символа.",
      falseDoesNotContainForbiddenSubstrings: "Домен не должен содержать запрещенные подстроки.",
      generateDomainTaken: "Этот домен уже занят. Попробуйте другой",
      temporarilyUnavailable: "Временно недоступно",
      csettingsAdmin: "Настройки для админов",
      questionLogicSetupTitle: "Настройка логики вопроса",
      questionLogicSetupDescription: "Добавьте условия, чтобы настроить опрос для каждого респондента и собирать более точные данные.",
      questionLogicConditionTitle: "Условие",
      questionLogicQuestion: "Вопрос",
      questionLogicSetupQuestionDisableOption: "Выберите вопрос",
      questionLogicSetupAnswer: "Ответ",
      questionLogicSetupLogicType: "Тип",
      questionLogicSetupAnswerDisableOption: "Выберите тип условия",
      questionLogicSetupRangeBetween: "Между",
      questionLogicSetupRangeLess: "Меньше",
      questionLogicSetupRangeMore: "Больше",
      questionLogicSetupRangeEqualTo: "Равно",
      questionLogicSetupRangeFrom: "От",
      questionLogicSetupRangeTo: "До",
      questionLogicSetupVariantAll: "Все из выбранных",
      questionLogicSetupVariantAny: "Один из",
      questionLogicSetupVariantNot: "Ни одного из",
      questionLogicSetupCustomEqual: "Равно",
      questionLogicSetupCustomContains: "Содержит",
      questionLogicSetupCustomNotContains: "Не содержит",
      questionLogicSetupRangeValue: "Значение",
      questionLogicSetupExpectedAnswer: "Ожидаемые ответы",
      questionLogicSetupPlaceholder: "Введите здесь",
      questionLogicAllMandatory: "Все указанные условия обязательны",
      questionLogicAddConditionBtn: "Добавить условие",
      questionLogicNotAvailable: "Настройки логики недоступны для первого вопроса",
      questionLogicSaved: "Настройки логики отображения шага сохранены",
      billingPaymentInfoTitle: "Информация о тарифе",
      billingPaymentInfoChangeTarrif: "Сменить тариф",
      billingPaymentInfoDescription: "При оплате любого платного тарифа вы получаете определенное количество лидов, включенных в выбранный вами пакет. Эти лиды предоставляются на месяц. Если вы не использовали свои лиды в течение месяца с момента оплаты, они будут аннулированы.",
      billingGeneralName: "Ваш тариф",
      billingGeneralCost: "Стоимость",
      billingMonth: "месяц",
      billingGeneralExpired: "Действителен до",
      billingGeneralLeadsLeft: "Лидов осталось",
      billingGeneralAllTarifExpired: "Срок действия тарифов истек",
      billingGeneralBonus: "Лиды по промокоду",
      billingGeneralLeadsFromPastTarifs: "Лиды с предыдущих тарифов",
      billingAcordeonPaymentInfoTitle: "Платежная информация",
      billingAcordeonPaymentInfoFormat: "Формат",
      billingAcordeonPaymentInfoSubscription: "Подписка",
      billingAcordeonPaymentInfoPay: "Оплата",
      billingAcordeonPaymentInfoPayMethod: "Способ оплаты",
      billingAcordeonPaymentInfoCancelSubscription: "Отменить подписку",
      billingAcordeonPromocode: "Промокод",
      billingAcordeonPromocodeLabel: "Введите промокод",
      billingAcordeonPayHistory: "История транзакций",
      paymentAllTarifInactive: "тариф неактивен",
      subscriptionUnsubscribeModalTitle: "Отписаться",
      subscriptionUnsubscribeModalDescription: "Вы уверены, что хотите отписаться? Начисленные лиды по подписке будут доступны вам в течение месяца с момента последнего списания средств по подписке.",
      subscriptionIsNotDeleteTitle: "Подписка не удалена",
      subscriptionIsNotDeleteDescription: "Пожалуйста, обратитесь в службу поддержки",
      subscriptionCanceledTitle: "Подписка аннулирована",
      modalSureUnsavedChangesTitle: "У вас есть несохраненные изменения",
      modalSureUnsavedChangesDescription: "Прежде чем покинуть текущую страницу, пожалуйста, решите, что делать с несохраненными изменениями",
      modalSureUnsavedChangesDontSave: "Не сохранять",
      modalSureUnsavedChangesSave: "Сохранить",
      telegramTokenCopied: "Токен квиза скопирован в буфер обмена",
      cSettingsSettingQuizBackButtonTitle: "Кнопка назад",
      cSettingsSettingQuizBackButtonDescription: "Вы можете включить или выключить кнопку возврата на предыдущий шаг. Это позволит изменять данный ранее ответ.",
      cSettingsCustomThemePrimaryColor: "Цвет акцентов",
      cSettingsCustomThemeSecondaryColor: "Фоновый цвет",
      cSettingsCustomThemeAdditionalColor: "Цвет текста",
      cSettingsCustomThemeButtonTextColor: "Текст в кнопках",
      cSettingsCustomThemeModeDark: "Тёмная",
      cSettingsCustomThemeModeLight: "Светлая",
      allstepsPlaceholder: "Плейсхолдер",
      allstepsCustomInput: "Свой вариант",
      allstepsCustomInputOn: "Включить",
      allstepsCustomPlaceholder: "Введите свой вариант",
      trashcanTitle: "Корзина",
      trashcanImportant: "Важно!",
      trashcanDescription: "Квиз будет автоматически удалена без возможности восстановления через 30 дней после того, как он был перемещён в корзину.",
      trashcanClearAll: "Очистить всё",
      quizSlotDeletedData: "Удалено",
      workboardLeads: "Лиды",
      workboardDuplicate: "Дублировать",
      leadsTable: "Список лидов",
      exportInfo: "Экспорт лидов",
      leadHide: "Скрыть",
      leadShowMore: "Подробнее",
      poperTrashcan: "Корзина",
      restoreQuizSendedTitle: "Запущена задача восстановления",
      restoreSuizSendedImportant: "Важно",
      restoreQuizSendedDescription: "В зависимости от того, как много лидов было в вашем квизе, восстановление квиза, может занять от нескольких секунд до 2-х минут. После восстановления квиз появится на вашем основном воркборде.",
      ok: "Хорошо",
      month: "0 месяцев | {n} месяц | {n} месяца | {n} месяцев",
      discount: "Скидка до",
      saveMoney: "Подписка на год",
      upProVersionMobile: "На PRO",
      upProVersionDesktop: "Перейти на PRO",




      csettingsIntegrationSendPulse: "Интеграция - Send Pulse",
      csettingsIntegrationMake: "Интеграция - Make",
      csettingsIntegrationZapier: "Интеграция - Zapier",
      csettingsIntegrationCRMBitrix: "Интеграция - CRM Bitrix",
      csettingsIntegrationPipeDrive: "Интеграция - Pipe Drive",
      csettingsIntegrationRemOnline: "Интеграция - Rem Online",
      csettingsIntegrationKeyCRM: "Интеграция - Key CRM",
            
      csettingsIntegrationZapierAbout: "Вставьте URL-адрес для отправки данных, предоставленный Zapier, в поле для ввода.",
      csettingsIntegrationMakeAbout: "Вставьте URL-адрес для отправки данных, предоставленный Make, в поле для ввода.",
      csettingsIntegrationMakeAboutTextStep1: "Для настройки интеграции с",
      csettingsIntegrationMakeAboutTextStep2: "посмотрите",
      csettingsIntegrationMakeAboutTextStep3: "Для настройки выберете среди интеграций на стороне make - “Webhook”, далее “Webhook response”.",
      csettingsIntegrationMakeAboutTextStep4: "Скопируйте полученную ссылку и вставьте в поле ниже в ADS Quiz.",
      csettingsIntegrationMakeAboutTextStep5: "Для тестирования, вернитесь в Make, нажмете кнопку “Run” и пройдите квиз отправив одну заявку.",
      csettingsIntegrationMakeAboutTextStep6: "В случае успешного тестирования сохраните интеграцию и не забудьте активировать обновления.",
      csettingsIntegrationMakeAboutLink: "https://www.make.com/en/register?pc=adschool",
      csettingsIntegrationMakeAboutLinkVideo: "https://www.youtube.com/embed/3ZyagaBQmiI",
      csettingsIntegrationMakeAboutLinkVideoText: "видео",
      csettingsIntegrationCRMBitrixAbout: "Вставьте URL-адрес для отправки данных, предоставленный CRM Bitrix, в поле для ввода.",

      csettingsIntegrationSendPulseAboutStart: "Вы можете настроить интеграцию с сервисом ",
      csettingsIntegrationSendPulseAboutMiddle: ". Для этого вам нужно перейти в настройки вашего аккаунта, и далее перейти на вкладку ",
      csettingsIntegrationSendPulseAboutEnd: ". Скопируйте значение параметра 'ID' и 'Secret' и вставьте их в соответствующие поля в сервисе ADS Quiz (в поля ниже). Далее необходимо вставить id адресной книги. Для этого перейдите в нужную адресную книгу и скопируйте в адресе страницы (в URL поле адреса сайта) значение после id/. Необходимы только цифры без дополнительных символов. Сохраните изменения. Более детальная инструкция в ",
      csettingsIntegrationSendPulseAboutLinkSendPulse: "https://sendpulse.com/ru#60c9ccfd55f4b",
      csettingsIntegrationSendPulseAboutLinkAPI: "https://login.sendpulse.com/login/#api",
      csettingsIntegrationSendPulseAboutLinkVideo: "https://www.youtube.com/watch?v=4mnhpbKkePs",
      csettingsIntegrationSendPulseAboutLinkVideoText: "видео",

      csettingsIntegrationKeyCRMAbout: "Вставьте API ключ и PipelineID, в соответствующие поля для ввода.",
      csettingsIntegrationPipeDriveAbout: "Вставьте токен и домен компании, в соответствующие поля для ввода.",
      csettingsIntegrationRemOnlineAbout: "Вставьте API ключ Rem Online в соответствующее поле для ввода.",
      csettingsIntegrationActiveIntegration: "Активная интеграция",
      cintegrationIntegrationData: "Данные интеграции",
      cintegrationIntegrationUrl: "URL интеграции",
      cintegrationWriteUrl: "Введите URL",
      cintegrationDataFBError: "Неверный идентификатор пикселя",
      cintegrationDataWebHookError: "Неверный адрес вебхука",
      cintegrationZapierDataSaved: "Данные интеграции с Zapier сохранены",
      cintegrationDataZapierError: "Неверный адрес интеграции",
      cintegrationAPIxDriveDataSaved: "Данные интеграции с APIxDrive сохранены",
      cintegrationDataAPIxDriveError: "Строка не является адресом интеграции APIxDrive ",
      cintegrationMakeDataSaved: "Данные интеграции с Make сохранены",
      cintegrationDataMakeError: "Неверный адрес интеграции",
      cintegrationSendPulseDataSaved: "Данные интеграции с SendPulse сохранены",
      cintegrationDataSendPulseError: "Невозможные или пустые данные интеграции",
      cintegrationPipeDriveDataSaved: "Данные интеграции с PipeDrive сохранены",
      cintegrationDataPipeDriveError: "Невозможные или пустые данные интеграции",
      cintegrationRemOnlineDataSaved: "Данные интеграции с RemOnline сохранены",
      cintegrationDataRemOnlineError: "Невозможные или пустые данные интеграции",
      cintegrationKeyCRMDataSaved: "Данные интеграции с KeyCRM сохранены",
      cintegrationDataKeyCRMError: "Невозможные или пустые данные интеграции",
      cintegrationInputEmailUrl: "Введите Email",
      cintegrationEmailDataSaved: "Email сохранен",
      cintegrationDataEmailError: "Введенная строка не является Email",
      cintegrationCRMBitrixDataSaved: "Email сохранен",
      cintegrationDataCRMBitrixError: "Введенная строка не является Email",
      cintegrationTelegramBotDataSaved: "Настройки Telegram интеграции сохранены",
      cintegrationDataTelegramBotError: "Ошибка сохранения",
      cintegrationGTMDataSaved: "Идентификатор Google Tag Manager сохранен",
      cintegrationDataGTMError: "Неверный идентификатор",
      cintegrationDataGTMErrorDescription: "Идентификатор должен иметь вид GTM-XXXXXX",
      cintegrationGoogleAnalyticsDataSaved: "Идентификатор Google Analytics сохранен",
      cintegrationDataGoogleAnalyticsError: "Неверный идентификатор",
      cintegrationDataGoogleAnalyticsErrorDescription: 'Идентификатор должен иметь вид "UA-XXXXXXX-X" или "G-XXXXXXXXXX-XX"',
      cintegrationTikTokDataSaved: "Tik-Tok пиксель сохранен",
      cintegrationDataTikTokError: "Введенная строка не является Tik-Tok пикселем",
      cintegrationIntegrationToken: "Токен интеграции",
      cintegrationWriteToken: "Введите токен интеграции",
      cintegrationDataYourAPIKey: "Введите ваш API ключ",
      viewQuizInMobile: "Просмотреть квиз",
      notificationCSRFTokenMismatch: "Несоответствие CSRF токена",
      notificationReloadPage: "Перезагрузите страницу и попробуйте снова",
      csettingsIntegrationKeyRemOnline: "Вставьте токен и идентификатор канала в соответствующие поля",
      cintegrationDataYourIntegrationToken: "API ключ",
      cintegrationDataLeadTypeId: "Идентификатор канала",
      send_utm: "Отправлять UTM метки",
      send_quiz: "Отправлять данные квиза",
      send_answers: "Отправлять ответы",
      csettingsIntegrationKeyCrm: "Интеграция KeyCRM",
      cintegrationSendPulseClientId: "ID клиента",
      cintegrationSendPulseClientSecret: "Secret",
      cintegrationSendPulseAddressBookId: "ID адресной книги",
      cintegrationSendPulseAction: "Действия с контактом",
      sendpulseActionAddContact: "Добавить контакт",
      sendpulseActionDeleteContact: "Удалить контакт",
      cintegrationPipeDriveToken: "API токен",
      cintegrationPipeDriveDomain: "Ваш уникальный субдомен",
      cintegrationKeyCrmApiKey: "API ключ",
      cintegrationKeyCrmPipelineId: "Идентификатор воронки",
      cintegrationMenuGoogleTagManager:"Google Tag Manager",
      cintegrationMenuGoogleAnalytics:"Google Analytics",
      cintegrationMenuFacebookPixel:"Facebook pixel",
      cintegrationMenuTikTokPixel:"TikTok Pixel",
      cintegrationMenuWebHook:"Webhook",
      cintegrationMenuZapier:"Zapier",
      cintegrationMenuAPIxDrive:"APIx Drive",
      cintegrationMenuMake:"Make",
      cintegrationMenuSendPulse:"Send Pulse",
      cintegrationMenuEmail:"Email",
      cintegrationMenuTelegramBot:"Telegram-бот",
      cintegrationMenuPipeDrive:"PipeDrive",
      cintegrationMenuRemOnline:"Rem Online",
      cintegrationMenuKeyCRM:"Key CRM",
      cintegrationMenuCRMBitrix:"CRM Bitrix",
      trashcanRestoreQuiz: "Восстановить квиз",
      cformValuePromotionAccepted: "Соглашаюсь на Email рассылку",


      constructorAddIntegration: "Добавить новую интеграцию",
      cintegrationKeyCrmSourceId: "Идентификатор источника (необязательно)",
      cintegrationKeyCrmManagerId: "Идентификатор менеджера (необязательно)",
      cSettingsSettingQuizSpamFilterTitle: "Защита от спама",
      cSettingsSettingQuizSpamFilterDescription: "Функция по умолчанию отключена. Если вы столкнулись с проблемой спамовых лидов, включите защиту. После этого один пользователь не сможет отправить больше указанного количества лидов на этот квиз.",
      cSettingsSettingQuizSpamFilterLimitPlaceholder: "Количество лидов",
      langNO: "Норвежский",
      loadMoreLeads: "Загрузить больше лидов",
      userAdditionalSettingsHeader: "Дополнительные настройки",
      userAdditionalSettingsDescription1: "Здесь можно настроить новые дополнительные параметры профиля.",
      userAdditionalSettingsPagination: "Пагинация на воркборде",

      benefitType: "Тип бонуса",
      benefitLabelPlaceholder: "Текст бонуса",
      benefitDescriptionPlaceholderDiscount: "Промокод или инструкция",
      benefitDescriptionPlaceholderVideo: "Ссылка на видео",
      benefitDescriptionPlaceholderTextfile: "Ссылка на текстовый файл",
      benefitDescriptionPlaceholderGift: "Описание бонуса",
      benefitDescriptionPlaceholderPDF: "Ссылка на PDF",
      benefitDescriptionPlaceholderLink: "Ссылка",
      benefitDescriptionPlaceholderFile: "Ссылка на файл",
      benefitLabelDiscount: "Скидка",
      benefitLabelVideo: "Видео",
      benefitLabelTextfile: "Текстовый файл",
      benefitLabelGift: "Подарок",
      benefitLabelPDF: "PDF файл",
      benefitLabelLink: "Ссылка",
      benefitLabelFile: "Файл",
      addBenefit: "Добавить бонус",
      saveBenefit: "Сохранить бонус",
      cfinishSocialNetworks: "Социальные сети",

      saveSocialNet: "Сохранить социальную сеть",
      networkLabelFacebook: "Facebook",
      networkLabelInstagram: "Instagram",
      networkLabelTelegram: "Telegram",
      networkLabelTiktok: "Tik-Tok",
      networkLabelYoutube: "Youtube",
      networkLabelLinkedin: "LinkedIn",
      networkLabelWhatsapp: "WhatsApp",
      socialNetDescriptionPlaceholderFacebook: "имя_профиля",
      socialNetDescriptionPlaceholderInstagram: "имя_профиля",
      socialNetDescriptionPlaceholderTelegram: "имя_профиля/канала",
      socialNetDescriptionPlaceholderTiktok: "имя_профиля",
      socialNetDescriptionPlaceholderYoutube: "имя_канала",
      socialNetDescriptionPlaceholderLinkedin: '"in/name" или "company/name"',
      socialNetDescriptionPlaceholderWhatsapp: "+15555551234",

      addNewSectionEmptyPage: "Текстовая страница",
      csanyMakeImpasse: "Сделать тупиком",
      impaseIsImpaseTrue: "Включено",
      impaseIsImpaseFalse: "Выключено",
      defaultNewEmptyQuestionDescription: "Сюда можно вставить и отформатировать длинный текст (не рекомендуем вставлять более 3000 символов).",
      cSettingsSettingQuizTimezoneTitle: "Часовой пояс квиза",
      cSettingsSettingQuizTimezoneDescription: "Введите часовой пояс, по которому будет указан время заполнения лидов. Это особенно удобно, если вы находитесь в одном часовом поясе, а клиент — в другом. В таком случае вы можете указать часовой пояс клиента, и в лидах будет указан время по его часовому поясу.",
      cSettingsSettingQuizTimezonePlaceholder: "Часовой пояс",
      userAdditionalSettingsTimezone: "Часовой пояс пользователя",
      userAdditionalSettingsTimezonePlaceholder: "Часовой пояс",
      langHE: "Иврит",
      langCS: "Чешский",

      cintegrationCAPIFBInstruction: "Чтобы настроить интеграцию с Facebook Conversion API, вам необходимо ввести идентификатор пикселя и маркер доступа. Эти данные можно получить в настройках Facebook Business Manager. После того как вы ввели необходимые данные, сохраните интеграцию.",
      cintegrationCAPIFBInstructionTextTitle: "Название событий для FB Conversions:",
      cintegrationCAPIFBInstructionTextStart: " – посещение стартовой страницы;",
      cintegrationCAPIFBInstructionTextQuestionN: " – посещение шага N (номер шага подставляется динамически);",
      cintegrationCAPIFBInstructionTextOpenContactPage: " – посещение страницы контактов;",
      cintegrationCAPIFBInstructionTextLead: " – успешная отправка формы с контактными данными;",
      cintegrationCAPIFBInstructionTextThankYou: " – посещение финальной страницы.",
      cintegrationCAPIFBInstructionTextOptimizationAdvice: "Мы рекомендуем запускать рекламу с оптимизацией на событие",
      cintegrationCAPIFBInstructionTextLeadTriggerCondition: "Это событие отправляется",
      bold_capi_adsquiz_Start: "capi_adsquiz_Start",
      bold_capi_adsquiz_QuestionN: "capi_adsquiz_Question-N",
      bold_capi_adsquiz_OpenContactPage: "capi_adsquiz_OpenContactPage",
      bold_capi_adsquiz_Lead: "capi_adsquiz_Lead",
      bold_capi_adsquiz_ThankYou: "capi_adsquiz_ThankYou",
      bold_capi_adsquiz_OptimizationAdvice: "“capi_adsquiz_Lead”.",
      bold_capi_adsquiz_LeadTriggerCondition: "только при успешном получении заявки от пользователя.",
      csettingsIntegrationFbCapiAbout: "Интеграция с Facebook Conversion API позволяет передавать данные о взаимодействиях пользователей напрямую в Facebook для улучшения эффективности рекламных кампаний.",
      cintegrationInputCapiAccessToken: "Введите маркер доступа",
      cintegrationInputCapiCustomparameter: "Введите кастомные параметры событий",
      cintegrationDataFbCapiSaved: "Интеграция с Facebook Conversion API успешно сохранена",
      cintegrationComonRenovateHubInstructionTitle: "Инструкция по настройке интеграции с RenovateHub",
      cintegrationComonRenovateHubInstructionDescription: "Для настройки интеграции с RenovateHub укажите URL вебхука, на который будут отправляться данные. Убедитесь, что URL правильный и доступен для приёма данных.",
      cintegrationComonRenovateHubInstructionShort: "Интеграция с RenovateHub позволяет передавать данные в СРМ через вебхук для автоматизации процессов.",
      cintegrationRenovateHubUrl: "Введите URL RenovateHub",
      cintegrationInputRenovateHubUrl: "Укажите URL вебхука для RenovateHub",
      cintegrationRenovateHubDataSaved: "Интеграция с RenovateHub успешно сохранена",
      cintegrationDataRenovateHubError: "Ошибка при сохранении интеграции с RenovateHub. Проверьте правильность введённых данных.",
      cintegrationMenuFacebookConvAPI: "Facebook Conversions API",
      cintegrationMenuRenovateHub: "RenovateHub",
      csettingsIntegrationRenovateHub: "Интеграция с RenovateHub",
      csettingsIntegrationCAPIFB: "Интеграция с Facebook Conversions API",
      paymentPlanEnd: "Дата окончания тарифа",
      paymentPlanLeadsLimitForYear: "Лимит лидов на год:",
      paymentPlanLeadsLimitForPeriod: "Лимит лидов на период:",
      EditTags: "Изменить теги",
      quizzesTemplatesNoTags: "Без тегов",


      Monday: "Понедельник",
      Tuesday: "Вторник",
      Wednesday: "Среда",
      Thursday: "Четверг",
      Friday: "Пятница",
      Saturday: "Суббота",
      Sunday: "Воскресенье",
      datetimeDisplayMode: "Тип выбора",
      datetimeDisplayModeSelect: "Выберите тип",
      datetimeDisplayModeDate: "Дата",
      datetimeDisplayModeTime: "Время",
      datetimeDisplayModeDatetime: "Дата и время",
      datetimeMinDate: "Минимальная дата",
      datetimeMinDateCustom: "Укажите минимальную дату",
      datetimeMaxDate: "Максимальная дата",
      datetimeMaxDateCustom: "Укажите максимальную дату",
      datetimeProhibitDaysOfWeek: "Запретить дни",
      datetimeIsRange: "Диапазон",
      datetimeIsRangeTrue: "Диапазон включен",
      datetimeIsRangeFalse: "Диапазон выключен",
      datetimeMinTime: "Минимальное время",
      datetimeMaxTime: "Максимальное время",
      datetimeIs24: "24-часовой формат",
      datetimeIs24True: "Формат 24 ч включен",
      datetimeIs24False: "Формат 24 ч выключен",
      csanyIsExactDate: "Как вы хотите настроить дату",
      csanyIsExactDateTrue: "Точная дата",
      csanyIsExactDateFalse: "Относительная дата",
      csanyDatetimeMinMaxNotExactPlaceholder: '"x" или "-x" дней от сегодня',
      addNewSectionDatetime: "Дата/время",
      tariffsPromo: "Акционные предложения",
      tariffsMonth: "Ежемесячно",
      tariffsAnual: "Ежегодно",
      leadsOn1Month: "в месяц",
      leadsOn2Month: "на 2 месяца",
      leadsOn3Month: "на 3 месяца",
      leadsOn4Month: "на 4 месяца",
      leadsOn5Month: "на 5 месяцев",
      leadsOn6Month: "на 6 месяцев",
      leadsOn7Month: "на 7 месяцев",
      leadsOn8Month: "на 8 месяцев",
      leadsOn9Month: "на 9 месяцев",
      leadsOn10Month: "на 10 месяцев",
      leadsOn11Month: "на 11 месяцев",
      leadsOn12Month: "на год",
      leadsOn24Month: "на 2 года",
      leads: "лидов",
      tariffsWordPrice: "Цена",
      tariffsNoLimits: "Без ограничений",
      tariffsNoLimitsQuizzesAndFunctions: "в квизах и функционале",
      billingTariffYear: "год",
      billingChangePlanSubscribe: "Подписаться",
      paymentAllTariffInactive: "Тариф не активен",
      subscriptionTariffLableOnly: "Доступно только для подписки",
      notExactBeforeYear: "год назад",
      notExactBeforeHalfYear: "полгода назад",
      notExactBeforeQuarter: "квартал назад",
      notExactBeforeMonth: "месяц назад",
      notExactBeforeWeek: "неделю назад",
      notExactBeforeYesterday: "позавчера",
      notExactYesterday: "вчера",
      notExactToday: "сегодня",
      notExactTomorrow: "завтра",
      notExactAfterTomorrow: "послезавтра",
      notExactAfterWeek: "через неделю",
      notExactAfterMonth: "через месяц",
      notExactAfterQuarter: "через квартал",
      notExactAfterHalfYear: "через полгода",
      notExactAfterYear: "через год",
      tipDefaultDateHeader: "По умолчанию",
      tipDefaultDateText: "Если оба переключателя 'Точная дата' и 'Относительная дата' отключены, календарь позволит выбирать даты в диапазоне от 1800 до 2200 года по умолчанию.",
      tipExactDateHeader: "Переключатель точной даты",
      tipExactDateText: "Включение этого переключателя ограничит календарь фиксированным диапазоном дат, которые выбраны в полях ввода ниже.",
      tipRelativeDateHeader: "Переключатель относительной даты",
      tipRelativeDateText: "Включение этого переключателя динамически установит ограничения календаря на основе относительных дат, которые рассчитываются во время загрузки страницы. Эти ограничения персонализированы и пересчитываются для каждого пользователя отдельно.",
      langPT: "Португальский",
      placeholderCompanyContactText: 'Например: "Телефон компании: +380993322211"',
      integrationConnectService: "Подключить",
      leadtype_id: "Тип действия",
      selectVariant: "Выберите",
      sfirstAddCompanyContacts: "Текст с контактами",
      addNewSectionTextarea: "Большое поле ввода",
      allstepsVariantView: "Формат изображения",
      tariffsDiscountSave: "Экономия",
      cAdminSettingsTitle: "Админ настройки",
      cAdminSettingsInstruction: "Введите теги для квиза в поле ниже. Теги могут состоять из нескольких слов, каждый тег отделяется запятой. Теги 'Услуги' и 'услуги' — это разные теги. Рекомендуется использовать теги, начинающиеся с заглавной буквы. Также можно исключить квиз из списка шаблонов.",
      cAdminSettingsIsTemplate: "Является ли шаблоном",
      cIsTemplateStateAreSaved: "Статус шаблона сохранён",
      sfirstAddLogotype: "Добавьте логотип",
      logotypeSizeTip: "Логотип будет вставлен в реальном размере, но не больше 120 пикселей по большей из сторон.",
      cfirstLogotypeUpdated: "Логотип добавлен",
      cfirstLogotypeDeleted: "Логотип успешно удалён!",

      cOffersPageSettings: "Настройки",
      offerPageTitlePlaceholder: "Введите заголовок",
      offerPageDescriptionPlaceholder: "Введите описание страницы",
      offerPageButtonTextPlaceholder: "Введите текст кнопки (необязательно)",
      allstepsOffers: "Список офферов",
      offerProposalHeader: "Оффер",
      offerAttachAddImg: "Добавить изображение",
      offerAttachAddVideo: "Добавить видео",
      offerItemImg: "Изображение",
      offerItemTitle: "Заголовок оффера",
      offerTitlePlaceholder: "Введите заголовок здесь",
      offerItemSubtitle: "Подзаголовок (например, цена)",
      offerSubtitlePlaceholder: "Введите текст здесь",
      offerItemDescription: "Описание оффера",
      offerDescriptionPlaceholder: "Введите описание здесь",
      offerItemButtonText: "Текст кнопки оффера",
      offerBtnTextPlaceholder: "Введите текст кнопки",
      offerItemButtonLink: "Ссылка для кнопки",
      offerBtnLinkPlaceholder: "Ссылка должна начинаться с https://",
      csanyOfferLogic: "Логика отображения",
      offerAddNewOffer: "Добавить новый оффер",
      offerLogicSetupTitle: "Настройки логики",
      OfferLogicSetupDescription: "Здесь вы можете настроить, при каких ответах будут отображаться те или иные офферы",
      cstepsOffersPage: "Страница результатов",
      addNewSectionResultsByLogic: "Офферы",
      cformActionAfterContactOffersOr: "Страница с офферами / ",
      tolltipOffersAfterContact: "Если после прохождения квиза будет выполнена логика отображения хотя бы одного оффера, отобразится страница с офферами, иначе – страница благодарности.",
      videoCircleTextQuestion: "Как это работает?",
      offerPhotoOptimalRatio: "Для оптимального отображения изображения рекомендуем использовать соотношение 16:9.",
      tolltipThankYouAfterContact: "После прохождения квиза будет отображена страница благодарности.",
      cformActionAfterContactOffers: "Страница результатов",
      addOfferPageInContact: "Включить страницу результатов",
      offerPageHeader: "Заголовок для страницы офферов",
      pumkinOrange: "Оранжевый",


    },
    DE: {
      madeInUkr: "In Ukraine hergestellt",
      upProVersion: "Wechsel zu Pro",
      getLeads: "Kaufen Sie Leads",
      poperBillings: "Zahlung",
      poperTemplates: "Vorlagen",
      poperPartnership: "Partnerschaft",
      poperProfile: "Profil",
      poperLogout: "Abmelden",
      defaultNewQuestion: "Neue Frage",
      defaultNewQuestionDescription: "Hier können Sie eine kurze Beschreibung der Frage einfügen (bis zu 80 Zeichen)",
      defaultNewVariant: "Neue Option",
      defaultBtnText: "Start",
      navBillings: "Zahlung",
      navPartnership: "Partnerschaft",
      navProfile: "Profil",
      navTariffs: "Taryfy",
      welcomeBack: "Willkommen zurück!",
      loginHeader: "Anmeldung zu meinem Koto",
      loginHeader2Part: "to Continue",
      loginEmailPlaceholder: "E-mail",
      loginPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      loginBtnValue: "Anmelden",
      loginWith: "Anmelden mit",
      passwordAreForgetPassword: "Haben Sie Ihr Passwort vergessen?",
      passwordResetPasswordHeader: "Passwort zurücksetzen",
      passwordResetPassword: "Zurücksetzen Passwort",
      passwordWeSentReset: "Wir haben Ihnen eine E-Mail zum Zurücksetzen Ihres Passwortes geschickt.",
      passwordInputNewPassword: "Neues Passwort eingeben",
      passwordConfirmPassword: "Passwort bestätigen",
      passwordChangePassword: "Passwort speichern",
      passwordResetedPasswordCompleted: "Ihr Passwort wurde erfolgreich geändert!",
      passwordGoToWorkboard: "Zum Workboard gehen",
      loginSignup: "Registrieren Sie sich",
      helloHeader: "Hallo!",
      signupHeader: "Registrieren",
      signupHeader2Part: "weitermachen",
      signupNamePlaceholder: "Oleksander",
      signupPhonePlaceholder: "Telefonnummer",
      signupEmailPlaceholder: "E-mail",
      signupPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      signupBtnValue: "Ein Konto erstellen",
      signupWith: "Anmelden mit",
      signupLogin: "Anmeldung zum Konto",
      signupTariffHeader: "Tarif",
      signupTariffFree: "Kostenslos.",
      signupTariffPro: "Pro.",
      signupTariffFreeDescription: "Unbegrenzte Anzahl der Erstellung von neuen Quizzen (Webseiten). Die Möglichkeit kostenlos bis zu 300 Anwendungen pro Monat zu erstellen.",
      signupTariffProPrice: "$20/Monat",
      signupTariffProDescription: "Unbegrenzte Anzahl der Erstellung von neuen Quizzen (Webseiten). Die Möglichkeit zum Erstellen",
      signupTariffProDescriptionBold: "von bis zu 300 Anwendungen pro Monat. ",
      userBackToQuiz: "Zurück zur Arbeitstafel",
      userBackToSettings: "Zurück zu den Einstellungen",
      billingYourTariff: "Ihr Plan",
      billingYourPro: "Pro",
      billingYorFree: "Kostenlos",
      billingFreeLeads: "Kostenlose Leads",
      billingInfinity: "unbegrenzt",
      billingTariffRenewData: "Datum der Verlängerung des Tarifs",
      billingTariffEndData: "Enddatum des Plans",
      billingOtherTariffFree: "Kostenlos.",
      billingOtherTariffPro: "Pro.",
      billingTariffFreeDescription: "Unbegrenzte Anzahl der Erstellung von neuen Quizzen (Webseiten). Die Möglichkeit kostenlos bis zu 300 Anwendungen pro Monat zu erstellen.",
      billingYouHavePro: "Wenn Sie derzeit die Pro-Version verwenden, warum sollten Sie einen Schritt zurück gehen?",
      billingYouHasFreeLeads: "Kostenlose Leads",
      billingYouHasLeads: "Verbleibende Leads",
      billingTariffProDescription: "Unbegrenzte Anzahl der Erstellung von neuen Quizzen (Webseiten). Die Möglichkeit zum Erstellen",
      billingTariffProDescriptionBold: "von mehr als 300 Anwendungen",
      billingYourFutureTariff: "Ihr zukünftiger Plan",
      billingBtnChangeToPro: "Wechsel zu Pro",
      billingBtnRenewPro: "Verlängern",
      billingBillings: "Zahlung",
      billingYouHaveNotBillings: "Sie haben noch keine Zahlung",
      billingPaymentsMethod: "Zahlungsweise",
      billingPaymentCard: "Karte",
      billingChangeData: "Daten ändern",
      billingDeletePayMethod: "Löschen",
      billingModalDeleteShure: "Sind Sie sicher, dass Sie diese Zahlungsmethode entfernen möchten?",
      billingModalDeleteCancel: "Abbrechen",
      billingModalDeleteOk: "Löschen",
      billingModalDeleteSuccess: "Wir haben Ihre Karte erfolgreich gelöscht",
      billingModalRegularPaymentDeleteSuccess: "Regelmäßige Zahlungen werden eingestellt",
      billingModalRegularPaymentDeleteOk: "Ok",
      billingYourMonthPlan: "Dein monatlicher Tarif",
      billingLeads: "Leads",
      billingChangePlan: "Ändere den Tarif",
      billingChangePlanDescription: "Der Preis richtet sich nach der gewünschten Anzahl von Bewerbern aus alle Quizzen Ihres Kontos",
      billingSelectPeriod: "Zeitraum auswählen",
      billingChangePlanDescriptionImportant: "Wichtig:",
      billingChangePlanDescriptionImportantMonth: "Die Leads können 1 Monat nach dem Kauf verwendet werden. ",
      subscriptionTariffLable: "Abonnieren Sie unser automatisches Abonnement.",
      billingChangePlanWarning: "",
      billingTariffMonth: "mon.",
      billingChangePlanPay: "Kupić",
      profileName: "Name",
      profilePhone: "Telefonnummer",
      profileEmail: "E-Mails",
      profileStatus: "Status",
      profileStatusAgency: "Agentur",
      profileStatusPlaceholder: "Was beschreibt Sie besser?",
      profileStatusFreelancer: "Freiberufler",
      profileStatusBuisines: "Geschäftsinhaber",
      profilePassword: "Passwort",
      profileChangePassword: "Passwort ändern",
      profileNewPassword: "Neues Passwort",
      profileSaveNewData: "Daten aktualisieren",
      workboardAddNewQuiz: "Projekt erstellen",
      workboardModalGiveQuizName: "Geben Sie dem Quiz einen Namen",
      workboardModalQuizNamePlaceholder: "Neues Quiz",
      workboardModalNewQuizBtnValue: "Hinzufügen",
      workboardAlertGiveAnyName: "Tragen Sie einen beliebigen Namen ein",
      workboardAlertNextTimeAddQuiz: "Na ja, nächstes Mal sollten Sie sich trauen.",
      workboardChangeQuizName: "Ändern Sie den Namen ",
      workboardQuizLink: "Link",
      workboardQuizLinkCopied: "Der Link wird in die Zwischenablage kopiert",
      workboardEditQuiz: "Bearbeiten",
      workboardCopyQuiz: "Quiz kopieren",
      workboardQuizCopied: "Quiz erfolgreich kopiert",
      workboardDeleteQuiz: "Löschen",
      workboardModalRemoveQuizTrash: "Dieses Quiz wird in den Papierkorb verschoben und nach 1 Monat dauerhaft gelöscht.",
      workboardModalRemoveQuizSure: "Sind Sie sicher, dass Sie dieses Quiz in den Papierkorb verschieben möchten?",
      workboardModalDeleteQuizCancel: "Abbrechen",
      workboardModalDeleteQuizDelete: "Löschen",
      workboardModalDeleteQuizSuccess: "Sie haben das Quiz erfolgreich gelöscht",
      workboardModalQuizDeleteOk: "Ok",
      workboardStatsTitle: "Statistik",
      workboardModalLeadsAnswersCount: "Antworten auf die Frage",
      workboardModalLeadsLeadSerial: "№",
      workboardModalLeadsLeadDate: "Datum",
      workboardModalLeadsLeadEmail: "Email",
      workboardModalLeadsLeadName: "Namen",
      workboardModalLeadsLeadPhone: "Telefonnummer",
      workboardModalLeadsLeadActions: "Aktionen",
      workboardModalLeadsLeadShowMore: "Mehr sehen",
      workboardModalLeadsLeadHideMore: "Ausblenden",
      workboardModalLeadsLeadAnswerScipped: "Übersprungen",
      workboardModalNoLeads: "Bsiher keine Hinweise",
      constructorLink: "Link",
      constructorLinkCopied: "In die Zwischenablage kopiert: ",
      constructorNavSteps: "Schritte",
      constructorNavDesign: "Entwurf",
      constructorNavSettings: "Einstellungen",
      constructorStep: "Schritte",
      cancel: "Abbrechen",
      constructorCancel: "Abbrechen",
      constructorSave: "Sichern",
      constructorextraNavStart: "S",
      constructorextraNavContact: "C",
      constructorextraNavFinish: "F",
      cstepsSection: "Abschnitte",
      cstepsStartPage: "Startseite",
      cstepsContactPage: "Kontaktformular",
      cstepsFinishPage: "Letzte Seite",
      cstepsEdit: "Bearbeiten",
      cstepsMove: "Verschieben",
      cstepsDeleteStep: "Schritt löschen",
      cstepsAddSection: "Abschnitt hinzufügen",
      cdesignColorTheme: "Farb Design",
      cdesignCustomTheme: "Kundenfarbthema",
      cdesignDefaultThemes: "Unsere Farbthemen",
      cdesignThemeStandart: "Standard",
      cdesignThemeDark: "Schwarz",
      cdesignThemeNud: "Hautfarbe",
      cdesignThemeGradient: "Farbverlauf",
      cdesignShapeDesign: "Design Vorlage",
      cdesignShapeRound: "Gerundet",
      cdesignShapeSquare: "Eckig",
      csettingsIntegration: "Integration",
      csettingsOweDomain: "Eigene Domain verknüpfen",
      csettingsDocs: "Rechtliche Dokumente",
      cintegrationIntegration: "Integration",
      cintegrationShareLeads: "Leads teilen",
      cintegrationTitleEmail: "E-Mail",
      cintegrationInstruction: "Anleitung",
      cintegrationInstructionDetail: "Einzelheiten",
      cintegrationEmailInstruction: "Schicken Sie eine E-Mail an Ihren Befrgaten aus und banachrichtigenb Sie Ihr Team. Arbeiten Sie mit Ihrem Team zusammen.",
      cintegrationPublishAfterEdit: "Speichern Sie das Quiz nach der Bearbeitung",
      cintegrationSendNewLeadsTo: "Sende Sie eine Nachricht über neue Leads an: ",
      cintegrationInputEmail: "Eingabe E-Mail",
      cintegrationAddEmail: "E-Mail hinzufügen",
      cintegrationEmailSaved: "E-Mail sichern",
      cintegrationEmailDeleted: "E-Mail löschen",
      cintegrationTitleFB: "FB pixel ",
      cintegrationIDPixel: "Pixel ID",
      cintegrationFBInstruction1: "Gehen Sie zum Facebook Ads-Bereich und kopieren Sie die Facebook Pixel-ID in den Event Manager-Bereich.",
      cintegrationFBInstruction2: "Kopieren Sie nicht den gesamten Code, sondern nur die 15-16-stellige ID.",
      cintegrationFBInstruction3: "Kopieren und in das unten stehende Feld einfügen. ",
      cintegrationFBInstruction: 'Das Ereignis, für das Sie die Konvertierung konfigurieren müssen, ist "Lead". Diese Veranstaltung wird aufgerufen, sobald der Benutzer eine Bewerbung im Quiz abgibt.',
      cintegrationFBInstructionTextTitle: "Liste aller gesendeten Ereignisse:",
      cintegrationFBInstructionTextTextStart: " – Besuch der Startseite;",
      cintegrationFBInstructionTextQuestionN: " – Besuch von Schritt N;",
      cintegrationFBInstructionTextOpenContactPage: " – Besuch der Kontaktseite;",
      cintegrationFBInstructionTextLead: " – erfolgreiche Übermittlung des Kontaktformulars;",
      cintegrationFBInstructionTextThankYou: " – Besuch der Abschlussseite.",
      bold_adsquiz_Start: "adsquiz_Start",
      bold_adsquiz_QuestionN: "Question_N",
      bold_adsquiz_OpenContactPage: "Open_Contact-Page",
      bold_adsquiz_Lead: "Lead",
      bold_adsquiz_ThankYou: "adsquiz_ThankYou",
      cintegrationInputIDPixel: "Eingabe Pixel-ID",
      cintegrationDataFBSaved: "Pixel FB-Daten gespeichert",
      cintegrationAccesToken: "Zugangstoken",
      cintegrationTitleGA: "Google Analytics",
      cintegrationGAInstructionDescription: "Erfahren Sie, wie Nutzer Ihr Quiz finden und damit interagieren. Erhalten Sie hierzu die Daten, die Sie benötigen, um Ihre Kampagnen zu analysieren, Konversionen zu verbessern und vieles mehr.",
      cintegrationGAInstruction: 'Wenn eine Quiz-Bewerbung in Google Analytics eingeht, wird das Ereignis "generate_lead" ausgelöst.',
      cintegrationIDFolow: "Tracking-ID",
      cintegrationInputID: "Eingabe-ID",
      cintegrationDataGASaved: "Gespeicherte Google-Analytics-Daten",
      cintegrationTitleGTM: "Google Tag Manager",
      cintegrationGTMInstructionDescription: "Beschreibung für Google Tag Manager.",
      cintegrationGTMInstruction: "Kopieren Sie die Google Tag Manager-ID und fügen Sie diese in das unten stehende Feld ein.",
      cintegrationDataGTMSaved: "Google Tag Manager Daten gespeichert",
      cintegrationTitleTG: "Telegram",
      cintegrationHowReciveTGLeadBot: "Wie bekommt man Leads in Telegram?",
      cintegrationTGInstructionBot1: "1. Kopieren Sie das Token in das unten stehende Eingabefeld.",
      cintegrationTGInstructionBot2: '2. Folgen Sie dem Link zum Bot, klicken Sie auf den "Start" Button.',
      cintegrationTGInstructionBot3: "3. Senden Sie das kopierte Token als Nachricht an unseren Bot.",
      cintegrationHowReciveTGLeadChat: "Wie bekommen Sie Bewerbungen im Telegram-Chat?",
      cintegrationTGInstructionChat1: "1. Fügen Sie unseren Bot zum gewünschten Chat hinzu und setzen Sie den Bot auf den Administratorstatus.",
      cintegrationTGInstructionChat2: "2. Kopieren Sie den Token in das unten stehende Feld und senden Sie ihn an den gewünschten Chat.",
      cintegrationTGInstructionChat3: '3. Warten Sie auf die Antwort "OK! Ihr Quiz-Token wurde gespeichert!"',
      cintegrationTGInstructionChat4: "4. Die Leads werden nun an diesen Chat gesendet.",
      cintegrationTGInstructionWarning: "Wichtig:",
      cintegrationTGInstructionWarningText: "Bitte beachten Sie, dass es sich um eine Gruppe und nicht um einen Telegram-Kanal handeln sollte. Leads werden nicht an den Telegram-Kanal gesendet.",
      cintegrationTGBotLink: "Chatbot Link",
      cintegrationTGTitleToken: "Token",
      cintegrationInputTGToken: "Telegram Chatbot Token eingeben",
      cintegrationAddIntegration: "Integration hinzufügen",
      cintegrationDataTGSaved: "Telegram-Integrationsdaten gespeichert",
      cintegrationTitleTT: "TT Pixel",
      cintegrationTTInstruction: "Um Ereignisse in Ihrem Tik-Tok-Konto zu verfolgen, kopieren Sie die Pixel-ID und fügen Sie diese in das unten sthendde Feld ein.",
      cintegrationDataTTSaved: "Pixel TT-Daten gespeichert",
      cintegrationTitleWH: "WebHook",
      cintegrationWHInstruction1: "Sie können Anwendungen von Drittanbeitern über eingehende Leads benachrichtigen. Geben Sie dazu die URL an, an die der WebHooker gesendet werden soll. ",
      cintegrationWHInstruction2: "Wir haben vorbereitet",
      cintegrationWHInstruction3: "Video Anleitung",
      cintegrationWHInstruction4: "mit einer ausführlichen Beschreibung dieser Funktionalität. ",
      cintegrationWHInstruction5: "Siehe Video.",
      cintegrationWHInstruction6: "In diesem Video sehen wir die Übergabe eines Hooks, der afu dem Senden von Daten an ein Google Spreadsheet und dem Senden von Daten an ein Trello CRM-System basiert.",
      cintegrationWHInstruction7: "Wir empfehlen, den Dienst zu diesem Zweck zu nutzen.",
      cintegrationWHInstruction8: "Wenn Sie sich über diesen Link registrieren, gibt es für Sie als Nutzer einen Bonus von 10.000 kostenlosen Aktionen für alle Integrationen. ",
      cintegrationWHInstruction9: "Link",
      cintegrationWHInstruction10: "um sich für den Bonus zu registrieren. ",
      cintegrationWHUrl: "Die Webhook-URLs",
      cintegrationInputWHUrl: "Eingabe der Webhook-URL",
      cintegrationAddWH: "Hinzufügen eines Web-Hook",
      cintegrationWebHookDataSaved: "Web-Hook Daten gesichert",


      cintegrationComonWHInstructionShort: "Sie können Drittanbieteranwendungen über eingehende Leads informieren. Geben Sie die Webhook-URL für die Datenübermittlung an.",
      cintegrationComonWHInstruction1Title: "1. Fügen Sie die Webhook-URL in das Eingabefeld ein:",
      cintegrationComonWHInstruction1Description: "Fügen Sie die Webhook-URL, die Ihr Service oder Ihre Anwendung bereitstellt, in das Eingabefeld ein.",
      cintegrationComonWHInstruction2Title: "2. Änderungen speichern:",
      cintegrationComonWHInstruction2Description: 'Klicken Sie auf die Schaltfläche "Änderungen speichern", um die Einstellungen des Webhooks zu speichern.',
      cintegrationComonWHInstruction3Title: "3. Fertig:",
      cintegrationComonWHInstruction3Description: "Ihr Webhook ist jetzt so konfiguriert, dass Daten an den von Ihnen ausgewählten Service gesendet werden.",
      cintegrationComonWHInstructionAdditional: "Daten werden bei jedem empfangenen Lead an den Webhook gesendet. Die Übertragung erfolgt über die POST-Methode. Ein Beispiel für die gesendeten Daten finden Sie ",
      cintegrationComonWHInstructionAdditionalLink: "hier",
      cintegrationComonWHInstructionVideo: "Ausführliche Videoanleitung",
      
      cintegrationApixDriveWHInstructionShort: "Sie können ApixDrive über eingehende Leads informieren. Geben Sie dazu die URL an, an die der WebHook gesendet wird.",
      cintegrationApixDriveWHInstructionShort1: "Bei der Anmeldung über ",
      cintegrationApixDriveWHInstructionShort2: "diesen Link",
      cintegrationApixDriveWHInstructionShort3: " erhalten unsere Benutzer einen Bonus von 10.000 kostenlosen Aktionen für beliebige Integrationen.",
      cintegrationApixDriveWHInstruction1Title: "1. Registrieren Sie sich oder öffnen Sie Ihr ApixDrive-Konto:",
      cintegrationApixDriveWHInstruction1Description1: "Link ",
      cintegrationApixDriveWHInstruction1Description2: "für die Anmeldung mit Bonus.",
      cintegrationApixDriveWHInstruction2Title: "2. Holen Sie sich die URL-Adresse für den Webhook:",
      cintegrationApixDriveWHInstruction2Description1: "Gehen Sie zu den Einstellungen der erforderlichen Verbindung und kopieren Sie die Adresse für den Versand des Webhooks:",
      cintegrationApixDriveWHInstruction2Description2: 'Sie können eine neue Verbindung erstellen, indem Sie auf die Schaltfläche "Verbindung erstellen" klicken und im Schritt zur Auswahl des Systems "ADSQuiz" auswählen.',
      cintegrationApixDriveWHInstruction3Title: "3. Fügen Sie die URL des Webhooks ein:",
      cintegrationApixDriveWHInstruction3Description: "Fügen Sie die vom ApixDrive bereitgestellte URL des Webhooks in das Eingabefeld ein.",
      cintegrationApixDriveWHInstruction4Title: "4. Speichern Sie die Änderungen:",
      cintegrationApixDriveWHInstruction4Description: 'Klicken Sie auf die Schaltfläche "Änderungen speichern", um die Einstellungen des Webhooks zu speichern.',
      cintegrationApixDriveWHInstruction5Title: "5. Fertig:",
      cintegrationApixDriveWHInstruction5Description: "Ihr Webhook ist jetzt so konfiguriert, dass Daten an ApixDrive gesendet werden.",
      cintegrationApixDriveWHInstructionAdditional: "Daten werden bei jedem empfangenen Lead an den Webhook gesendet. Die Übertragung erfolgt über die POST-Methode. Ein Beispiel für die gesendeten Daten finden Sie ",
      cintegrationApixDriveWHInstructionAdditionalLink: "hier",
      cintegrationApixDriveWHInstructionVideo: "Ausführliche Videoanleitung",
      



      cintegrationConectOweDomain: "Veerknüpfung mit Ihrer eigenen Domain",
      cintegrationOweDomainInstruction1: "1. Um Ihr Quiz auf der gewünschten Domain zu platzieren, loggen Sie sich in Ihr Hostiung-Konto ein. Gehen Sie zu den DNS-Einstellung und erstellen Sie einen A-Eintrag mit dem IP-Parameter: 185.25.117.21",
      cintegrationOweDomainInstruction2: "2. Geben Sie die Domain in das Feld oben ein und speichern Sie das Quiz.",
      cintegrationDomain: "Domain",
      cintegrationInputYourDomain: "Eingabe Ihrer Domain",
      cintegrationCheckDomenIsCorrect: "Prüfen Sie, ob die eingegebene Domain korrekt ist",
      cintegrationDomainWhichCorrect: "Geben Sie eine Domain ohne Protokolle ein. Zum Beispiel: some.site.com",
      cintegrationInsertedDefaultDomain: "Die Domain ist standardmäßig eingestellt",
      cintegrationDomainDataSaved: "Domaindaten gesichert",
      cintegrationPrivacyPolicy: "Datenschutzbestimmungen",
      cintegrationPrivacyPolicyByDefault: "Standardmäßig verwenden wir unsere Standard-Datenschutzrichtlinie. Wir empfehlen Ihnen, Ihre eigene Datenschutzrichtlinie hinzuzufügen, die für Ihr Unternehmen relevant ist.",
      cintegrationPrivacyPolicyAdventage: "Dadruch wird Ihren potenziellen Kunden deutlich, wie Sie die erhaltenen Kontaktinformationen verwenden. Dies wird auch von einigen Werbeplattformen verlangt.",
      cintegrationPrivacyPolicyUrl: "Link zu Dokumenten",
      cintegrationPrivacyPolicyInputYourDocsLink: "Link zu Doumenten einfügen ",
      cintegrationDocsDataSaved: "Link zu Ihren Dokumenten speichern",
      allstepsUpploadPhoto: "Laden Sie ein Bild hoch",
      allstepsChangePhoto: "Ändern Sie das Bild",
      allstepsDeletePhoto: "Löschen Sie das Bild",
      allstepsTitle: "Titel",
      allstepsDescription: "Beschreibung",
      allstepsButtonText: "Button Text",
      allstepsOtherSettings: "Andere Einstellungen",
      allstepsIsSaveBeforeRoute: "Daten vor dem Ändern der Route speichern?",
      cfirstFormat: "Format",
      cfirstHeaderStartText: "Hier sollte Ihre Überschrift stehen. Motivieren Sie die Nutzer, das Quiz bis zum Ende auszufüllen ",
      cfirstAddMediafile: "Medium (Bild) hinzufügen",
      cfirstBonus: "Bonus",
      cfirstAddBonus: "Bonus hinzufügen",
      cfirstStartPageDataUpdated: "Die Daten auf Ihrer Startseite wurden aktualisiert",
      csanyAnswerType: "Fragen-Typ",
      csanyATCard: "Karte",
      csanyATList: "Liste",
      csanyATCustom: "Freies Feld",
      csanyQuestion: "Frage ",
      csanyAnswers: "Antworten",
      csanyAddAnswer: "Antworten hinzufügen",
      csanyTitleForModalConfirm: "Diese Aktion ist unwiderruflich!",
      csanyQuestionForModalConfirm: "Sind Sie sicher, dass Sie diese Frage löschen möchten?",
      csanyRequiredStep: "Erforderlicher Schritt",
      csanyAlowMultiple: "Mehrfachantworten erlaubt",
      csanyAutoRedirect: "Automatische nächste Frage",
      csanyQuestionLogic: "Einrichtung der Fragelogik",
      csanyDataStepUpdated: "Datenschritt aktualisieren",
      cformForm: "Formular",
      cformValueName: "Name",
      cformValueSurname: "Nachname",
      cformValuePhone: "Telefonnummer",
      cformValueEmail: "E-Mail",
      cformDataContactsPageUpdated: "Datenkontakte-Seite aktualisiert",
      cformRequiredInput: "Pflichtfeld",
      cfinishFinishPageTitle: "Letzte Seite",
      cfinishText: "Text",
      cfinishDescription: "Beschreibung",
      cfinishAddImage: "Fügen Sie ein Bild hinzu",
      cfinishImageAdded: "Bild hinzugefügt",
      cfinishURL: "URL",
      cfinishButtonText: "Button Text",
      cfinishInsertNikname: "Spitznamen einfügen",
      cfinishCheckDataIsCorrect: "Prüfen Sie, ob die eingegebene ID korrekt ist",
      cfinishFbWhichCorrect: "Geben Sie eine ID ohne weitere Adresse ein. Zum Beispiel: some.name",
      cfinishIgWhichCorrect: "Geben Sie eine ID ohne weitere Adresse ein. Zum Beispiel: some.name",
      cfinishTgWhichCorrect: "Geben Sie eine ID ohne weitere Adresse ein. Zum Beispiel: some.name",
      cfinishFinishPageDataUpdated: "Die Daten der letzten Seite wurden aktualisiert",
      adminUsersReg: "Nutzer im System",
      adminNewUsers: "Neuer Nutzer pro Monat",
      adminProAccounts: "Pro Account",
      adminLTV: "LTV",
      adminSharePro: "Pro im Vergleich zu allen anderen",
      adminReciveLeads: "Erhaltende Leads",
      adminLeadsForOne: "Leads pro Nutzer",
      adminMadeQuiz: "Gemachte Quizze",
      adminQuizForOne: "Quiz pro Nutzer",
      adminAvarageUsed: "Durchschnittliche Nutzer pro Monat",
      adminProfitMonth: "Gewinn im Monat",
      adminProfitAlltime: "Verdienst über die gesamte Zeit",
      adminUsersList: "Nutzerbasis",
      notificationErrorLogin: "Autorisierungsfehler:",
      notificationLogin: "Login…",
      notificationWrongLoginData: "Falsche Anmeldedaten",
      notificationNetworkError: "Überprüfen Sie Ihre Internetverbindung",
      notificationInputLoginData: "Anmeldedaten eingeben",
      notificationCheckInputEmail: "Überprüfen Sie , ob Ihre E-Mail-Adresse korrekt ist",
      notificationCheckInputPhone: "Prüfen Sie, ob Ihre Telefonnummer korrekt ist",
      notificationInputAllInputs: "Füllen Sie alle Felder für die Registrierung aus",
      notificationAgreePrivacyPolicy: "Akzeptieren Sie die Datenschutzbestimmungen",
      adminEditorForPartnership: "Editor für Partnerprogramme",
      developmentPage: "Abschnitt befindet sich in der Entwicklung",
      signupAgreeIAgreeWith: "Ich stimme zu",
      signupAgreePolicy: "die Datenschutzbestimmung",
      signupAgreeAnd: "und",
      signupAgreeOffer: "das öffentliche Angebot",
      signupAgreeService: "des Dienstes",
      workboardModalLeadsLeadReferrer: "Unklar",
      workboardModalLeadsLeadReferrerClarification: "Verweis-Daten durch Quelle blockiert oder undefiniert",
      workboardModalLeadsLeadEmailCopied: "E-Mail in die Zwischenablage kopiert",
      workboardModalLeadsLeadPhoneCopied: "Telefonnummer in die Zwischenablage kopiert",
      workboardModalLeadsHaveNotSended: "Sie haben die nicht gesendeten Leads",
      workboardModalLeadsLeadReload: "Empfangen Sie",
      workboardModalLeadsLeadReloadedTitle: "Anfrage erhalten",
      workboardModalLeadsLeadReloaded: "",
      cfirstStartPagePhotoUpdated: "Das Foto auf der Startseite wurde aktualisiert",
      allstepsPhotoTooBig: "Datei ist zu groß!",
      allstepsPhotoMustBeLess: "Muss kleiner sein als",
      cfirstStartPageBonusPhotoUpdated: "Das Bonusfoto wurde aktualisiert",
      cstepsQuestionsOrderSaved: "Reihenfolge der Frage gespeichert",
      csanyAnswerOrderSaved: "Reihenfolgen der Antworten gespeichert",
      allstepsPhotoAdaptation: "Fotoanpassung für Geräte",
      allstepsPhotoFit: "Seitenverhältnis",
      constructorYouHideStep: "Dieser Schritt wurde übersprungen",
      cstepsHiddenStep: "Schritt überspringen",
      cstepsShowStep: "Schritt zeigen",
      cfinishFinishPageHiddenUpdated: "Die Zielseite ist ausgeblendet.",
      cfinishFinishPageHiddenUpdatedGoContact: "Gehen Sie zur Seite Kontakte",
      cfinishFinishPageHiddenUpdatedSetRedirect: "und eine Umleitung nach dem Senden der Daten einrichten",
      cformActionAfterContactRecive: "Aktion nach Erhalt von Kontakten",
      cformActionAfterContactThankyou: "Dankeschön Seite",
      cformActionAfterContactRedirect: "Umleitung",
      cformAdditionalSettings: "Zusätzliche Einstellungen",
      cformRedirectURL: "URL",
      cintegrationOweDomainInstructionImportant: "Wichtig: Die Adresse in Ihrem Hosting-Konto und das obige Feld müssen übereinstimmen. Die Anwendung der Änderung an den DNS-Einstellungen und das Extrahieren des SSL-Zertifikats kann  bis zu 3 Stunden nach dem Speichern in Anspruch nehmen.",
      cintegrationOweDomainInstructionVideo: "Sehen Sie die Video-Anleitung",
      workboardModalLeadsLeadLimit: "Das Limit für die Anzahl der Anfragen wurden erreicht. ",
      workboardModalLeadsLeadLimitInstruction1: "Um neue Leads zu sehen, ",
      workboardModalLeadsLeadLimitInstruction2: "wechseln Sie zu eienm anderen Tarif",
      workboardModalLeadsLeadLimitInstruction3: "den derzeitigen Tarif verlängern",
      workboardModalLeadsLeadLimitInstruction4: "(gilt nicht für „Kostenlos“) oder warten Sie auf die Aktualisierung des Tarifs, wenn Sie eine automatische Verlängerung des Tarifs angeschlossen haben",
      billingBonusesTitle: "Promo-Code",
      billingBonusesDescription: "Geben Sie den Promo-Code, den Sie erhalten haben, in das Feld daneben ein",
      billingBonusesPlaceholder: 'Promocode',
      billingBonusesBtn: "Aktivieren",
      billingBonusesActivated: "Promo-Code aktiviert",
      billingBonusesLeadIncreased: "Die Zahl der verfügbaren Leads wurde erhöht.",
      billingBonusesInvalid: "Flascher Promo-Code",
      billingBonusesNotAvailable: "Promo-Code ist abgelaufen",
      billingBonusesAlreadyActivated: "Dieser Gutschein ist ungültig",
      createQuizSPDescription: "Hier ist Platz für die Kurzbeschreibung",
      createQuizCPTitle: "Wie können wir mit Ihnen Konatkt aufnehmen?",
      createQuizCPDescription: "Bitte füllen Sie das unten stehende Formular aus",
      createQuizCPButton: "Fertig!",
      createQuizFPTitle: "Vielen Dank!",
      createQuizFPDescription: "Jetzt suchen wir nach der besten Lösung für Sie!",
      createQuizFPButton: "Zeigen Sie mir etwas!",
      selectInternationalPrefix: "Internationale Vorwahl wählen",
      autoInternationalPrefix: "Bestimmen Sie das Land automatisch",
      dovnloadCsvTitle: "Daten für den ausgewählten Zeitraum exportieren",
      dovnloadCsvSelectAllPeriod: "Wählen Sie für den gesamten Zeitraum ",
      dovnloadCsvCancel: "Abbrechen",
      dovnloadCsvExport: "Exportieren",
      
      
      
      
      widgetEmbedVidgetToSite: "Quiz auf der eigenen Webseite einbetten",
      widgetEmbedVidgetSelectEmbedVariant: "Einbettungsvariante auswählen",
      widgetEmbedVidgetSitecode: "Im Code der Website",
      widgetEmbedVidgetPopup: "Pop-Up",
      widgetEmbedVidgetChat: "Quiz-Taste",
      widgetEmbedVidgetInProgres: "In Entwicklung",
      widgetEmbedVidgetToSiteCode: "Quiz auf der Webseite einbetten",
      widgetSiteCodeInstruction0: "Um das Quiz auf Ihrer Website einzubinden, fügen Sie den Code aus dem ersten Feld ind en `<head>`-Tag auf Ihrer Webseite oder in Google Tag Manager ein, wenn auf Ihrer Webseite integriert ist. Wenn Sie das Quiz bereits auf ihrer Webseite eingebettet haben und die Domain des Quizzes ändern, müssen Sie den Integrationscode auf Ihrer Webseite aktualisieren.",
      widgetSiteCodeInstruction1: "Fügen Sie an der Stelle, an der Sie das Quiz integrieren möchten, den Code aus dem zweiten Feld ein. ",
      widgetSiteCodeInstruction2: "Das Quiz kann in einen Container beliebiger Größe integriert werden, sogar in Seitenleisten. Die maximale Größe des Quizzes, auch wenn es auf einer separaten Domain stattfindet, beträgt 900x552 Pixel. Es kann in größere Container eingefügt weerden, in diesem Fall wird das Quiz zentriert.",
      widgetSiteCodeInstruction3: "Seien Sie nicht schüchtern - Experimentieren Sie!",
      widgetSiteCodeCopyToHead: "Kopieren und in den `<head>` Ihrer Webseite einfügen",
      widgetSiteCodeCopyToBody: "Fügen Sie den Code dort ein, wo das Quiz stehen soll",
      workboardModalAddQuizHeader: "Neuen Quiz erstellen",
      workboardModalGiveQuizNameAndMethod: "Überlegen Sie sich einen Namen für Ihr Quiz und wählen Sie, wie Sie es erstellen möchten",
      workboardModalNewQuizFromStart: "Leeres Quiz erstellen",
      workboardModalNewQuizFromTemplates: "Fertige Vorlagen",
      QuizTemplatesSearchInput: "Name der Vorlage und/oder Kategorien",
      QuizTemplatesSearchBtn: "Suche",
      QuizTemplatesReadyTemplates: "Fertige Vorlagen",
      QuizTemplateView: "Anzeigen",
      QuizTemplateSelect: "Auswählen",
      widgetPopupInstruction0: "Um ein Quiz auf Ihrer Website zu installieren, fügen Sie den Code aus dem Codefeld zum Tag `<head>` auf Ihrer Website ein oder in den Google Tag Manager, wenn er in Ihre Website integriert ist. Wenn Sie nach dem Einfügen eines Quiz auf Ihrer Website die Quiz-Domain ändern oder Trigger öffnen (Scroll-Prozentsatz oder Timing), müssen Sie den Integrationscode auf Ihrer Website aktualisieren.",
      widgetPopupInstruction1: "Fügen Sie den Code aus dem zweiten Feld direkt vor dem Abschluss-Tag `</footer>` ein.",
      widgetPopupInstruction2: "Für Programmierer oder fortgeschrittene Benutzer",
      widgetPopupInstruction3: "Falls Sie aus irgendeinem Grund nicht den Code vor dem Abschluss-Tag `</footer>` einfügen möchten, können Sie den Code aus dem zweiten Feld an einer beliebigen passenden Stelle einfügen. Beachten Sie jedoch, dass der Container, in den Sie den Code aus dem zweiten Feld einfügen, sich über allen Fenstern befinden und möglichst die maximale Höhe und Breite für die Anzeige haben sollte.",
      widgetPopupCopyToHead: "Kopieren und in das `<head>` Ihrer Website einfügen",
      widgetPopupCopyToBody: "Fügen Sie den Code vor dem Abschluss-Tag `</footer>` ein",
      widgetPopupTimeToOpen: "Zeitspanne",
      secondReduction: "Sek",
      widgetPopupTimeClarification: "Das Popup erscheint mit einer Verzögerung von oben angegebener Zeit in Sekunden",
      widgetPopupScrollToOpen: "Scroll-Prozentsatz der Website",
      widgetPopupScrollClarification: "Das Popup erscheint nach dem angegebenen Scroll-Prozentsatz Ihrer Website",
      quizzesTemplatesAllTags: "Alle Vorlagen",
      widgetEmbedVidgetButton: "Schaltflächenkonstruktor",
      widgetButtonInstruction0: "Sie können auf Ihrer Website ein Quiz einrichten, das geöffnet wird, wenn Sie auf die Schaltfläche klicken.",
      widgetButtonInstruction1: "Fügen Sie der Schaltfläche einen Rahmen, Schatten und Text hinzu. Außerdem können Sie die Farbe der Schaltfläche und den darin enthaltenen Text ändern.",
      widgetButtonInstruction2: "Kopieren Sie das im ersten Codefeld platzierte Skript und fügen Sie es vor dem schließenden Tag `</head>` ein.",
      widgetButtonInstruction3: "Kopieren Sie den generierten Schaltflächencode aus dem zweiten Codefeld und fügen Sie ihn an einer beliebigen Stelle auf Ihrer Website ein.",
      widgetButtonInstruction4: 'Sie können den Code aus dem zweiten Schaltflächenfeld überspringen und einfach `id="button_open_quiz_popup"` zu jedem `<input type="button">`- oder `<button></button>`-Element auf der Website hinzufügen.',
      widgetRealTimeChange: "Sie können die Änderungen in Echtzeit auf der rechten Seite des Bildschirms sehen",
      widgetButtonCornerRound: "Abrundung der Knopfecken",
      widgetButtonShadow: "Knopfschatten",
      widgetButtonBgColor: "Knopffarbe",
      widgetButtonTextColor: "Textfarbe",
      widgetButtonTextValue: "Schaltflächentext",
      openPopupQuizText: "Start",
      widgetButtonInsertBeforeAfterBody: "Fügen Sie den Quizcode unmittelbar nach dem öffnenden Tag `<body>` oder unmittelbar vor dem schließenden Tag `</body>` ein",
      widgetSiteCodeCopyButton: "Fügen Sie den Schaltflächencode an einer beliebigen Stelle auf der Website ein",
      footerContactUs: "Habe Fragen? Fühlen Sie sich frei uns zu kontaktieren:",
      enterYourName: "Gib deinen Namen ein",
      enterYourPhone: "Gib deine Handynummer ein",
      enterYourEmail: "Geben sie ihre E-Mail Adresse ein",
      enterYourPassword: "Geben Sie Ihr Passwort ein",
      selectOption: "Wähle eine Option",
      createNewGroup: "Neue Gruppe erstellen",
      dontHaveAccount: "Sie haben noch kein Konto?",
      resetPasswordTip: "Wenn Sie weitere Probleme oder Fragen haben, wenden Sie sich bitte an unser Kundensupport-Team. Wir sind hier um zu helfen.",
      getBackAccount: "Gehen Sie zurück zu Ihrem Konto",
      inMinutes: "in Minuten",
      alreadyHaveAccount: "Sie haben bereits ein Konto?",
      quizSlotLastEdit: "Letzte Bearbeitung",
      workboardQuizzesTitle: "Meine Quizze",
      allstepsBack: "Zurück",
      constructorGetLink: "Link",
      allOr: "oder",
      allAnd: "und",
      acceptedFileType: "Akzeptierte Formate: ",
      conjunctionAnd: "und",
      acceptedFileSize: "Maximale Größe: ",
      acceptedFileSizeMeasure: "MB",
      cfirstBonusDisabled: "Deaktiviert",
      cfirstBonusAvailable: "Verfügbar",
      allstepsResetInHeader: "Zurücksetzen",
      placeholderText: "Hier eingeben",
      constructorSaveChanges: "Speichern",
      cformInputAvailable: "Verfügbar",
      cformInputDisabled: "Deaktiviert",
      constructorBack: "Zurück",
      videoInstruction: "Videoanleitung",
      csettingsIntegrationFB: "Integration - Facebook Pixel",
      csettingsIntegrationTT: "Integration - TikTok",
      csettingsIntegrationGA: "Integration - Google Analytics",
      csettingsIntegrationGTM: "Integration - Google Tag Manager",
      csettingsIntegrationWH: "Integration - WebHook",
      csettingsIntegrationTG: "Integration - Telegram",
      csettingsIntegrationApixDrive: "Integration - APIxDrive",
      csettingsIntegrationEmail: "Integration - E-Mail",
      csettingsIntegrationAbout: "Über",
      csettingsIntegrationFBAbout: "Gehen Sie zum Werbekonto von Facebook Ads und kopieren Sie die Facebook Pixel ID im Bereich Event Manager. Kopieren Sie nur die ID und fügen Sie sie unten ein.",
      csettingsIntegrationTTAbout: "Um Ereignisse in Ihrem Tik-Tok-Werbeaccount zu verfolgen, kopieren Sie die Pixel-ID und fügen Sie sie unten ein.",
      csettingsIntegrationGAAbout: "Kopieren Sie die Google Analytics-ID und fügen Sie sie in das Feld unten ein.",
      csettingsIntegrationGTMAbout: "Kopieren Sie die Google Tag Manager-ID und fügen Sie sie in das Feld unten ein.",
      csettingsIntegrationWHAbout1: "Sie können Drittanbieteranwendungen über eingehende Leads informieren. Geben Sie dazu die URL an, an die der WebHook gesendet werden soll. Wir empfehlen, diesen Dienst zu diesem Zweck zu verwenden.",
      csettingsIntegrationWHAboutApix: "Apix-Drive",
      csettingsIntegrationWHAbout2: "Bei der Registrierung über diesen Link erhalten unsere Benutzer einen Bonus von 10.000 kostenlosen Aktionen für beliebige Integrationen.",
      csettingsIntegrationTGAbout: "Wichtig: Beachten Sie, dass es sich hierbei um eine Gruppe handeln sollte, nicht um einen Telegram-Kanal. Leads werden nicht an den Telegram-Kanal gesendet.",
      csettingsIntegrationADAbout: "Etwas über APIxDrive",
      csettingsIntegrationEmailAbout: "Lösen Sie eine E-Mail an Ihren Befragten aus und informieren Sie Ihr Team. Arbeiten Sie mit Ihrem Team zusammen.",
      cSettingsIntegrationNeedHelp: "Hilfe benötigt?",
      cSettingsIntegrationRefreshToken: "Token aktualisieren",
      csettingsSettingASettings: "Erweiterte Einstellungen",
      cSettingsSettingQuizLangTitle: "Quizsprache",
      cSettingsSettingQuizLangDescription: "Um sicherzustellen, dass Sie das beste Erlebnis haben, wählen Sie bitte die gewünschte Quizsprache aus den folgenden Optionen aus. Systembenachrichtigungen und einige andere ständige Texte werden beim Bestehen des Quiz in dieser Sprache angezeigt.",
      selectQuizLanguage: "Wählen Sie die Quizsprache aus",
      cSettingsSettingQuizHideLabelTitle: "Etikett ausblenden",
      cSettingsSettingQuizHideLabelDescription: 'Wenn Sie diese Option aktivieren, können Sie eine Benutzeroberfläche nutzen, die nicht mit dem Label „made by adsquiz“ versehen ist, sodass Sie und Ihre Besucher sich ausschließlich auf die Fragen konzentrieren können.',
      cSettingDataSaved: "Zusätzliche Einstellungen werden gespeichert",
      userAccountInfo: "Konto-Info",
      userGeneralInfoHeader: "Allgemeine Informationen",
      userGeneralInfoDescription1: "Ihr Benutzerprofil ist der Ort, an dem Sie Ihr Konto und Ihre Einstellungen verwalten können.",
      userGeneralInfoDescription2: "Aktualisieren Sie Ihre persönlichen Informationen, ändern Sie Ihr Passwort und passen Sie Ihre Benachrichtigungseinstellungen an.",
      profileNameFirst: "Vorname",
      profileNameLast: "Nachname",
      userSecuritySettingsHeader: "Sicherheitseinstellungen",
      userSecuritySettingsDescription: "Um Ihr Passwort zu ändern, geben Sie einfach Ihr neues Passwort zweimal ein, um es zu bestätigen.",
      profileEmailCurent: "Aktuelle E-Mail-Adresse",
      profileEmailNew: "Neue E-Mail-Adresse",
      profileNewPasswordConfirm: "Neues Passwort bestätigen",
      profileDeleteAccount: "Konto löschen",
      profileDeleteAccountPopupHeader: "Konto löschen?",
      profileDeleteAccountPopupDescription: 'Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden. Um die Löschung zu bestätigen, geben Sie das Wort "LÖSCHEN" in das Feld unten ein.',
      profileDeleteAccountCheckedWord: "LÖSCHEN",
      profileDeleteAccountPopupPlaceholder: "Hier eingeben",
      notificationSuccess: "Erfolg!",
      notificationSuccessSaveAccount: "Ihr Passwort wurde erfolgreich geändert! Verwenden Sie ein neues Passwort, um sich erneut anzumelden",
      notificationSuccessDeleteAccount: "Ihr Passwort wurde erfolgreich geändert! Verwenden Sie ein neues Passwort, um sich erneut anzumelden",
      cardPanUnspecified: "Nicht angegeben",
      userBillingsDate: "Datum",
      userBillingsCost: "Kosten",
      userBillingsId: "ID",
      userBillingsMethod: "Zahlungsmethode",
      paymentSwitchToProHeader: "Zu Pro wechseln",
      paymentSwitchToProDescription: "Finden Sie den Plan, der zu Ihnen passt! Steigen Sie jederzeit auf oder ab - wir machen es Ihnen leicht, den gewünschten Plan zu erhalten.",
      paymentYourCurrentPlan: "Ihr aktueller Plan:",
      paymentYourFuturePlan: "Ihr neuer Plan:",
      paymentPlanLeadsLimit: "Begrenzung der Leads pro Monat:",
      paymentPlanLeadsLimitForTest: "Ihr Quiz-Testlimit:",
      paymentPlanLeadsLeft: "Verbleibende Leads:",
      paymentPlanPrice: "Preis:",
      paymentPlanRenew: "Tarifverlängerungsdatum:",
      addNewSectionHeader: "Neuen Abschnitt hinzufügen",
      addNewSectionList: "Liste",
      addNewSectionCustom: "Eingabefeld",
      addNewSectionCard: "Karten mit Fotos",
      addNewSectionWithEmoji: "Liste mit Emoji",
      addNewSectionTags: "Tags",
      addNewSectionSlider: "Schieberegler",
      templatesBackToQuiz: "Zurück zur Arbeitsfläche",
      constructorStepsSaved: "Alle Quizdaten wurden erfolgreich gespeichert",
      constructorDesignSaved: "Das Quizdesign wurde erfolgreich gespeichert",
      azureBlueWhite: "Standard",
      azureBlue: "Winternacht",
      moderatePurplishRed: "Roter Samt",
      violetBlueCrayola: "Winterbrise",
      wetRainforest: "Sommerahorn",
      lavender: "Raum",
      blueLilac: "Dunkler Lavendel",
      opalGreen: "Karpatenwald",
      dustyBlue: "Tiffany",
      almondCrayola: "Korallenriff",
      periwinkleCrayolaLilac: "Lila",
      periwinkleCrayolaAqua: "Synevir",
      jazzJam: "Jazz",
      slateGrayBlue: "Nebel",
      turquoiseBlueCrayola: "Diia",
      blackPearlescentOpal: "Asche",
      pearlescentGentianBlue: "Saphir",
      sapphireBlue: "Dunkler Saphir",
      errorUserDataUpdate: "Fehler in den aktualisierten Daten: ",
      completeUserDataUpdate: "Benutzerdaten aktualisiert",
      passwordDoNotCoincidence: "Passwörter stimmen nicht überein",
      passwordDoNotCoincidenceAgain: "Versuchen Sie es erneut",
      nonBillingsYet: "Sie haben noch keinen Abrechnungsverlauf",
      youCanChangePlan: "Sie können den aktuellen Plan ändern, indem Sie auf die Schaltfläche „Wechsel zu Pro“ klicken",
      rangeMin: "Min",
      rangeMax: "Max",
      rangePositionStart: "Positionsanfang",
      rangePositionEnd: "Positionsende",
      rangeStep: "Schritt",
      rangeIsRange: "Bereich",
      rangeIsRangeTrue: "Aktiviert",
      rangeIsRangeFalse: "Deaktiviert",
      recivePromotionNewsletter: "Erhalten Sie E-Mail-Newsletter",
      reciveEmailCheckbox: "E-Mail-Checkbox empfangen",
      additionalCheckbox: "Zusätzliche Checkbox",
      contactPrivacyPolicyCheckbox: "Kontakt Datenschutzrichtlinie Checkbox",
      contactAgreeIAgreeWith: "Ich stimme der",
      contactAgreePolicy: "Datenschutzrichtlinie",
      contactAgreeAnd: "und",
      contactAgreeTermsOfUse: "den Nutzungsbedingungen",
      contactAgreeAgreementAdditionalWord: "zu.",
      langEN: "Englisch",
      langDE: "Deutsch",
      langSP: "Spanisch",
      langPL: "Polnisch",
      langIT: "Italienisch",
      langKK: "Kasachisch",
      langRU: "Russisch",
      langUA: "Ukrainisch",
      langLT: "Litauisch",
      langLV: "Lettisch",
      langET: "Estnisch",
      langRO: "Rumänisch",
      leadsLeft: "Bleibt übrig",
      generateDomainTitle: "Geben Sie den Domänennamen ein",
      generateDomainDescription: "Dies ist die eindeutige Adresse, unter der auf Ihre Seite zugegriffen werden kann. Sie können die Adresse jederzeit ändern oder eine eigene anschließen.",
      generateDomainDomainNameLabel: "Domainname",
      cancelAllAction: "Stornieren",
      confirmAllAction: "Bestätigen",
      falseHasValidCharacters: "Ungültige Zeichen im Domänennamen. Erlaubte Zeichen sind Buchstaben, Zahlen und Bindestriche.",
      falseHasValidLength: "Die Länge des Domänennamen sollte zwischen 6 und 20 alphanumerischen Zeichen liegen.",
      falseDoesNotStartOrEndWithSpecialChar: "Der Domänennamen darf nicht mit einem Sonderzeichen beginnen oder enden.",
      falseDoesNotHaveConsecutiveSpecialChar: "Der Domänennamen darf keine zwei oder mehr aufeinanderfolgenden Sonderzeichen enthalten.",
      falseDoesNotHaveConsecutiveSameChar: "Der Domänennamen darf nicht drei oder mehr aufeinanderfolgende identische Zeichen enthalten.",
      falseDoesNotContainForbiddenSubstrings: "Der Domänennamen darf keine verbotenen Teilzeichenfolgen enthalten.",
      generateDomainTaken: "Dieses Haus ist noch nicht fertig. Versuchen Sie es",
      temporarilyUnavailable: "Vorübergehend nicht erreichbar",
      csettingsAdmin: "Einstellungen für Administratoren",
      questionLogicSetupTitle: "Einstellung der Fragelogik",
      questionLogicSetupDescription: "Fügen Sie Bedingungen hinzu, um die Umfrage für jeden Befragten anzupassen und genauere Daten zu sammeln.",
      questionLogicConditionTitle: "Bedingung",
      questionLogicQuestion: "Frage",
      questionLogicSetupQuestionDisableOption: "Frage auswählen",
      questionLogicSetupAnswer: "Antwort",
      questionLogicSetupLogicType: "Typ",
      questionLogicSetupAnswerDisableOption: "Bedingungstyp auswählen",
      questionLogicSetupRangeBetween: "Zwischen",
      questionLogicSetupRangeLess: "Weniger",
      questionLogicSetupRangeMore: "Mehr",
      questionLogicSetupRangeEqualTo: "Gleich",
      questionLogicSetupRangeFrom: "Von",
      questionLogicSetupRangeTo: "Bis",
      questionLogicSetupVariantAll: "Alle sind ausgewählt",
      questionLogicSetupVariantAny: "Обрано один з",
      questionLogicSetupVariantNot: "Einer der Auserwählten",
      questionLogicSetupCustomEqual: "Ist gleich",
      questionLogicSetupCustomContains: "Enthält",
      questionLogicSetupCustomNotContains: "Beinhaltet nicht",
      questionLogicSetupRangeValue: "Wert",
      questionLogicSetupExpectedAnswer: "Erwartete Antworten",
      questionLogicSetupPlaceholder: "Hier eingeben",
      questionLogicAllMandatory: "Alle oben genannten Bedingungen sind obligatorisch",
      questionLogicAddConditionBtn: "Bedingung hinzufügen",
      questionLogicNotAvailable: "Die für den ersten Schritt erforderlichen Logik-Logiken",
      questionLogicSaved: "Die Einstellungen der Schrittanzeigelogik werden gespeichert",
      billingPaymentInfoTitle: "Tarifinformation",
      billingPaymentInfoChangeTarrif: "Tarif ändern",
      billingPaymentInfoDescription: "Wenn Sie einen kostenpflichtigen Tarif bezahlen, erhalten Sie eine bestimmte Anzahl von Leads, die im von Ihnen gewählten Paket enthalten sind. Diese Leads werden für einen Monat bereitgestellt. Wenn Sie Ihre Leads innerhalb eines Monats ab dem Zeitpunkt der Zahlung nicht genutzt haben, werden sie storniert.",
      billingGeneralName: "Ihr Tarif",
      billingGeneralCost: "Kosten",
      billingMonth: "Monat",
      billingGeneralExpired: "Gültig bis",
      billingGeneralLeadsLeft: "Übrige Leads",
      billingGeneralAllTarifExpired: "Ablaufdatum des Tarifs",
      billingGeneralBonus: "Leads mit einem Promo-Code",
      billingGeneralLeadsFromPastTarifs: "Leads aus früheren Tarifen",
      billingAcordeonPaymentInfoTitle: "Zahlungsinformationen",
      billingAcordeonPaymentInfoFormat: "Format",
      billingAcordeonPaymentInfoSubscription: "Abonnement",
      billingAcordeonPaymentInfoPay: "Zahlung",
      billingAcordeonPaymentInfoPayMethod: "Zahlungsmethode",
      billingAcordeonPaymentInfoCancelSubscription: "Abonnement kündigen",
      billingAcordeonPromocode: "Promo-Code",
      billingAcordeonPromocodeLabel: "Geben Sie den Aktionscode ein",
      billingAcordeonPayHistory: "Transaktionsverlauf",
      paymentAllTarifInactive: "Tarif ist inaktiv",
      subscriptionUnsubscribeModalTitle: "Abbestellen",
      subscriptionUnsubscribeModalDescription: "Sind Sie sicher, dass Sie sich abmelden möchten? Die für das Abonnement berechneten Leads stehen Ihnen einen Monat ab dem letzten Abbuchungstag zur Verfügung.",
      subscriptionIsNotDeleteTitle: "Abonnement nicht gelöscht",
      subscriptionIsNotDeleteDescription: "Bitte kontaktieren Sie den Kundensupport",
      subscriptionCanceledTitle: "Abonnement gekündigt",
      modalSureUnsavedChangesTitle: "Sie haben ungespeicherte Änderungen",
      modalSureUnsavedChangesDescription: "Bevor Sie die aktuelle Seite verlassen, entscheiden Sie bitte, was mit den ungespeicherten Änderungen geschehen soll",
      modalSureUnsavedChangesDontSave: "Nicht speichern",
      modalSureUnsavedChangesSave: "Speichern",
      telegramTokenCopied: "Der Quiz-Token wurde in die Zwischenablage kopiert",
      cSettingsSettingQuizBackButtonTitle: "Zurück-Button",
      cSettingsSettingQuizBackButtonDescription: "Sie können die Zurück-Schaltfläche aktivieren oder deaktivieren. Dies bietet die Möglichkeit, die zuvor gegebene Antwort zu ändern.",
      cSettingsCustomThemePrimaryColor: "Farbe der Akzente",
      cSettingsCustomThemeSecondaryColor: "Hintergrundfarbe",
      cSettingsCustomThemeAdditionalColor: "Textfarbe",
      cSettingsCustomThemeButtonTextColor: "Text in Schaltflächen",
      cSettingsCustomThemeModeDark: "Dunkel",
      cSettingsCustomThemeModeLight: "Licht",
      allstepsPlaceholder: "Platzhalter",
      allstepsCustomInput: "Ihre Option",
      allstepsCustomInputOn: "Anmachen",
      allstepsCustomPlaceholder: "Eigene Option ",
      trashcanTitle: "Korb",
      trashcanImportant: "Wichtig!",
      trashcanDescription: "Das Quiz wird 30 Tage nach dem Verschieben in den Papierkorb automatisch endgültig gelöscht.",
      trashcanClearAll: "Alles löschen",
      quizSlotDeletedData: "Gelöscht um",
      workboardLeads: "Führt",
      workboardDuplicate: "Duplikat",
      leadsTable: "Leads-Liste",
      exportInfo: "Leads exportieren",
      leadHide: "Ausblenden",
      leadShowMore: "Mehr",
      poperTrashcan: "Korb",
      restoreQuizSendedTitle: "Eine Wiederherstellungsaufgabe wird ausgeführt",
      restoreSuizSendedImportant: "Wichtig",
      restoreQuizSendedDescription: "Je nachdem, wie viele Leads es in Ihrem Quiz gab, kann die Wiederherstellung des Quiz einige Sekunden bis 2 Minuten dauern. Nach der Wiederherstellung wird das Quiz auf Ihrem Hauptarbeitsbrett angezeigt.",
      ok: "Okay",
      month: "0 Monat | {n} Monat | {n} Monate",
      discount: "Rabatt bis zu",
      saveMoney: "Abonnement für ein Jahr",
      upProVersionMobile: "Zu Pro",
      upProVersionDesktop: "Wechsel zu Pro",




      csettingsIntegrationSendPulse: "Integration - Send Pulse",
      csettingsIntegrationMake: "Integration - Make",
      csettingsIntegrationZapier: "Integration - Zapier",
      csettingsIntegrationCRMBitrix: "Integration - CRM Bitrix",
      csettingsIntegrationPipeDrive: "Integration - Pipe Drive",
      csettingsIntegrationRemOnline: "Integration - Rem Online",
      csettingsIntegrationKeyCRM: "Integration - Key CRM",
            
      csettingsIntegrationZapierAbout: "Geben Sie die URL ein, um die Daten, die von Zapier bereitgestellt werden, in das Eingabefeld zu senden.",
      csettingsIntegrationMakeAbout: "Geben Sie die URL ein, um die Daten, die von Make bereitgestellt werden, in das Eingabefeld zu senden.",
      csettingsIntegrationMakeAboutTextStep1: "Um die Integration mit",
      csettingsIntegrationMakeAboutTextStep2: "einzurichten, sehen Sie sich das",
      csettingsIntegrationMakeAboutTextStep3: "Wählen Sie zur Einrichtung in Make die Integration “Webhook”, dann “Webhook response”.",
      csettingsIntegrationMakeAboutTextStep4: "Kopieren Sie den generierten Link und fügen Sie ihn in das Feld unten in ADS Quiz ein.",
      csettingsIntegrationMakeAboutTextStep5: "Zum Testen kehren Sie zu Make zurück, klicken auf “Run” und durchlaufen das Quiz, indem Sie eine Anfrage absenden.",
      csettingsIntegrationMakeAboutTextStep6: "Wenn der Test erfolgreich war, speichern Sie die Integration und vergessen Sie nicht, die Aktualisierungen zu aktivieren.",
      csettingsIntegrationMakeAboutLink: "https://www.make.com/en/register?pc=adschool",
      csettingsIntegrationMakeAboutLinkVideo: "https://www.youtube.com/embed/3ZyagaBQmiI",
      csettingsIntegrationMakeAboutLinkVideoText: "Video",
      csettingsIntegrationCRMBitrixAbout: "Geben Sie die URL ein, um die Daten, die von CRM Bitrix bereitgestellt werden, in das Eingabefeld zu senden.",

      csettingsIntegrationSendPulseAboutStart: "Sie können die Integration mit dem Dienst ",
      csettingsIntegrationSendPulseAboutMiddle: " einrichten. Dazu gehen Sie zu den Kontoeinstellungen und öffnen die Registerkarte ",
      csettingsIntegrationSendPulseAboutEnd: ". Kopieren Sie die Werte der Parameter 'ID' und 'Secret' und fügen Sie sie in die entsprechenden Felder im Dienst ADS Quiz ein (in die Felder unten). Als Nächstes müssen Sie die Adressbuch-ID eingeben. Gehen Sie dazu zum gewünschten Adressbuch und kopieren Sie aus der URL den Wert nach id/. Es werden nur Zahlen benötigt, ohne zusätzliche Zeichen. Speichern Sie die Änderungen. Eine detailliertere Anleitung finden Sie im ",
      csettingsIntegrationSendPulseAboutLinkSendPulse: "https://sendpulse.com/de#60c9ccfd55f4b",
      csettingsIntegrationSendPulseAboutLinkAPI: "https://login.sendpulse.com/login/#api",
      csettingsIntegrationSendPulseAboutLinkVideo: "https://www.youtube.com/watch?v=4mnhpbKkePs",
      csettingsIntegrationSendPulseAboutLinkVideoText: "Video",

      csettingsIntegrationKeyCRMAbout: "Geben Sie den API-Schlüssel und die Pipeline-ID in die entsprechenden Eingabefelder ein.",
      csettingsIntegrationPipeDriveAbout: "Geben Sie den Token und die Domain des Unternehmens in die entsprechenden Eingabefelder ein.",
      csettingsIntegrationRemOnlineAbout: "Geben Sie den Rem Online API-Schlüssel in das entsprechende Eingabefeld ein.",
      csettingsIntegrationActiveIntegration: "Aktive Integration",
      cintegrationIntegrationData: "Integrationsdaten",
      cintegrationIntegrationUrl: "URL der Integration",
      cintegrationWriteUrl: "URL eingeben",
      cintegrationDataFBError: "Ungültige Pixel-ID",
      cintegrationDataWebHookError: "Ungültige Webhook-Adresse",
      cintegrationZapierDataSaved: "Integrationsdaten mit Zapier gespeichert",
      cintegrationDataZapierError: "Ungültige Integrationsadresse",
      cintegrationAPIxDriveDataSaved: "Integrationsdaten mit APIxDrive gespeichert",
      cintegrationDataAPIxDriveError: "Der String ist keine APIxDrive-Integrationsadresse",
      cintegrationMakeDataSaved: "Integrationsdaten mit Make gespeichert",
      cintegrationDataMakeError: "Ungültige Integrationsadresse",
      cintegrationSendPulseDataSaved: "Integrationsdaten mit SendPulse gespeichert",
      cintegrationDataSendPulseError: "Integrationsdaten nicht möglich oder leer",
      cintegrationPipeDriveDataSaved: "Integrationsdaten mit PipeDrive gespeichert",
      cintegrationDataPipeDriveError: "Integrationsdaten nicht möglich oder leer",
      cintegrationRemOnlineDataSaved: "Integrationsdaten mit RemOnline gespeichert",
      cintegrationDataRemOnlineError: "Integrationsdaten nicht möglich oder leer",
      cintegrationKeyCRMDataSaved: "Integrationsdaten mit KeyCRM gespeichert",
      cintegrationDataKeyCRMError: "Integrationsdaten nicht möglich oder leer",
      cintegrationInputEmailUrl: "E-Mail eingeben",
      cintegrationEmailDataSaved: "E-Mail gespeichert",
      cintegrationDataEmailError: "Der eingegebene String ist keine E-Mail-Adresse",
      cintegrationCRMBitrixDataSaved: "E-Mail gespeichert",
      cintegrationDataCRMBitrixError: "Der eingegebene String ist keine E-Mail-Adresse",
      cintegrationTelegramBotDataSaved: "Telegram-Integrationseinstellungen gespeichert",
      cintegrationDataTelegramBotError: "Fehler beim Speichern",
      cintegrationGTMDataSaved: "Google Tag Manager-ID gespeichert",
      cintegrationDataGTMError: "Ungültige ID",
      cintegrationDataGTMErrorDescription: "Die ID sollte im Format GTM-XXXXXX sein",
      cintegrationGoogleAnalyticsDataSaved: "Google Analytics-ID gespeichert",
      cintegrationDataGoogleAnalyticsError: "Ungültige ID",
      cintegrationDataGoogleAnalyticsErrorDescription: 'Die ID sollte im Format "UA-XXXXXXX-X" oder "G-XXXXXXXXXX-XX" sein',
      cintegrationTikTokDataSaved: "TikTok-Pixel gespeichert",
      cintegrationDataTikTokError: "Der eingegebene String ist kein TikTok-Pixel",
      cintegrationIntegrationToken: "Integrations-Token",
      cintegrationWriteToken: "Token eingeben",
      cintegrationDataYourAPIKey: "Geben Sie Ihren API-Schlüssel ein",
      viewQuizInMobile: "Quiz ansehen",
      notificationCSRFTokenMismatch: "CSRF-Token stimmt nicht überein",
      notificationReloadPage: "Bitte laden Sie die Seite neu und versuchen Sie es erneut",
      csettingsIntegrationKeyRemOnline: "Geben Sie den Token und die Kanal-ID in die entsprechenden Felder ein",
      cintegrationDataYourIntegrationToken: "API-Schlüssel",
      cintegrationDataLeadTypeId: "Kanal-ID",
      send_utm: "UTM-Tags senden",
      send_quiz: "Quiz-Daten senden",
      send_answers: "Fragen und Antworten senden",
      csettingsIntegrationKeyCrm: "KeyCRM-Integration",
      cintegrationSendPulseClientId: "Kunden-ID",
      cintegrationSendPulseClientSecret: "Geheimnis",
      cintegrationSendPulseAddressBookId: "Adressbuch-ID",
      cintegrationSendPulseAction: "Aktion mit Kontakt",
      sendpulseActionAddContact: "Kontakt hinzufügen",
      sendpulseActionDeleteContact: "Kontakt löschen",
      cintegrationPipeDriveToken: "API-Token",
      cintegrationPipeDriveDomain: "Ihre einzigartige Subdomain",
      cintegrationKeyCrmApiKey: "API-Schlüssel",
      cintegrationKeyCrmPipelineId: "Trichter-ID",
      cintegrationMenuGoogleTagManager:"Google Tag Manager",
      cintegrationMenuGoogleAnalytics:"Google Analytics",
      cintegrationMenuFacebookPixel:"Facebook pixel",
      cintegrationMenuTikTokPixel:"TikTok Pixel",
      cintegrationMenuWebHook:"Webhook",
      cintegrationMenuZapier:"Zapier",
      cintegrationMenuAPIxDrive:"APIx Drive",
      cintegrationMenuMake:"Make",
      cintegrationMenuSendPulse:"Send Pulse",
      cintegrationMenuEmail:"Email",
      cintegrationMenuTelegramBot:"Telegram-bot",
      cintegrationMenuPipeDrive:"PipeDrive",
      cintegrationMenuRemOnline:"Rem Online",
      cintegrationMenuKeyCRM:"Key CRM",
      cintegrationMenuCRMBitrix:"CRM Bitrix",
      trashcanRestoreQuiz: "Quiz wiederherstellen",
      cformValuePromotionAccepted: "Ich stimme dem E-Mail-Newsletter zu",


      constructorAddIntegration: "Integration hinzufügen",
      cintegrationKeyCrmSourceId: "Quellen-ID (optional)",
      cintegrationKeyCrmManagerId: "Manager-ID (optional)",
      cSettingsSettingQuizSpamFilterTitle: "Spamschutz",
      cSettingsSettingQuizSpamFilterDescription: "Die Funktion ist standardmäßig deaktiviert. Wenn Sie auf das Problem von Spam-Leads stoßen, aktivieren Sie den Schutz. Danach kann ein Benutzer nicht mehr als die angegebene Anzahl von Leads für dieses Quiz einreichen.",
      cSettingsSettingQuizSpamFilterLimitPlaceholder: "Anzahl der Leads",
      langNO: "Norwegisch",
      loadMoreLeads: "Mehr Leads anzeigen",
      userAdditionalSettingsHeader: "Erweiterte Profileinstellungen",
      userAdditionalSettingsDescription1: "Hier können Sie neue erweiterte Profileinstellungen konfigurieren.",
      userAdditionalSettingsPagination: "Paginierung auf der Arbeitstafel",

      benefitType: "Bonustyp",
      benefitLabelPlaceholder: "Bonustext",
      benefitDescriptionPlaceholderDiscount: "Gutscheincode oder Anweisung",
      benefitDescriptionPlaceholderVideo: "Link zum Video",
      benefitDescriptionPlaceholderTextfile: "Link zur Textdatei",
      benefitDescriptionPlaceholderGift: "Bonusbeschreibung",
      benefitDescriptionPlaceholderPDF: "Link zum PDF",
      benefitDescriptionPlaceholderLink: "Link",
      benefitDescriptionPlaceholderFile: "Link zur Datei",
      benefitLabelDiscount: "Rabatt",
      benefitLabelVideo: "Video",
      benefitLabelTextfile: "Textdatei",
      benefitLabelGift: "Geschenk",
      benefitLabelPDF: "PDF-Datei",
      benefitLabelLink: "Link",
      benefitLabelFile: "Datei",
      addBenefit: "Bonus hinzufügen",
      saveBenefit: "Bonus speichern",
      cfinishSocialNetworks: "Soziale Netzwerke",

      saveSocialNet: "Soziales Netzwerk speichern",
      networkLabelFacebook: "Facebook",
      networkLabelInstagram: "Instagram",
      networkLabelTelegram: "Telegram",
      networkLabelTiktok: "Tik-Tok",
      networkLabelYoutube: "Youtube",
      networkLabelLinkedin: "LinkedIn",
      networkLabelWhatsapp: "WhatsApp",
      socialNetDescriptionPlaceholderFacebook: "profil_nikname",
      socialNetDescriptionPlaceholderInstagram: "profil_nikname",
      socialNetDescriptionPlaceholderTelegram: "profil/kanal_nikname",
      socialNetDescriptionPlaceholderTiktok: "profil_nikname",
      socialNetDescriptionPlaceholderYoutube: "kanal_name",
      socialNetDescriptionPlaceholderLinkedin: '"in/name" oder "company/name"',
      socialNetDescriptionPlaceholderWhatsapp: "+15555551234",

      addNewSectionEmptyPage: "Textseite",
      csanyMakeImpasse: "Sackgasse",
      impaseIsImpaseTrue: "Ermöglicht",
      impaseIsImpaseFalse: "Deaktiviert",
      defaultNewEmptyQuestionDescription: "Sie können hier langen Text einfügen und formatieren (wir empfehlen, nicht mehr als 3000 Zeichen einzufügen).",
      cSettingsSettingQuizTimezoneTitle: "Quiz-Zeitzone",
      cSettingsSettingQuizTimezoneDescription: "Geben Sie die Zeitzone ein, in der die Zeit für die Lead-Eingabe angegeben wird. Dies ist besonders nützlich, wenn Sie sich in einer Zeitzone befinden und der Kunde in einer anderen. In diesem Fall können Sie die Zeitzone des Kunden einstellen, und die Leads, die Sie erhalten, zeigen die Zeit entsprechend der Zeitzone des Kunden an.",
      cSettingsSettingQuizTimezonePlaceholder: "Zeitzone",
      userAdditionalSettingsTimezone: "Zeitzone des Benutzers",
      userAdditionalSettingsTimezonePlaceholder: "Zeitzone",
      langHE: "Hebräisch",
      langCS: "Tschechisch",
      cintegrationCAPIFBInstruction: "Um die Integration mit Facebook Conversion API einzurichten, müssen Sie die Pixel-ID und den Zugriffstoken eingeben. Diese Informationen finden Sie in den Einstellungen des Facebook Business Managers. Nachdem Sie die erforderlichen Daten eingegeben haben, speichern Sie die Integration.",
      cintegrationCAPIFBInstructionTextTitle: "Ereignisnamen für FB Conversions:",
      cintegrationCAPIFBInstructionTextStart: " – Besuch der Startseite;",
      cintegrationCAPIFBInstructionTextQuestionN: " – Besuch von Schritt N (die Schrittzahl wird dynamisch eingefügt);",
      cintegrationCAPIFBInstructionTextOpenContactPage: " – Besuch der Kontaktseite;",
      cintegrationCAPIFBInstructionTextLead: " – erfolgreiche Übermittlung des Kontaktformulars;",
      cintegrationCAPIFBInstructionTextThankYou: " – Besuch der Abschlussseite.",
      cintegrationCAPIFBInstructionTextOptimizationAdvice: "Wir empfehlen, Anzeigen mit der Optimierung für das Ereignis zu schalten:",
      cintegrationCAPIFBInstructionTextLeadTriggerCondition: "Dieses Ereignis wird",
      bold_capi_adsquiz_Start: "capi_adsquiz_Start",
      bold_capi_adsquiz_QuestionN: "capi_adsquiz_Question-N",
      bold_capi_adsquiz_OpenContactPage: "capi_adsquiz_OpenContactPage",
      bold_capi_adsquiz_Lead: "capi_adsquiz_Lead",
      bold_capi_adsquiz_ThankYou: "capi_adsquiz_ThankYou",
      bold_capi_adsquiz_OptimizationAdvice: "“capi_adsquiz_Lead”.",
      bold_capi_adsquiz_LeadTriggerCondition: " nur ausgelöst, wenn eine Anfrage erfolgreich eingereicht wurde.",
      csettingsIntegrationFbCapiAbout: "Die Integration mit Facebook Conversion API ermöglicht es Ihnen, Nutzerdaten direkt an Facebook zu senden, um die Wirksamkeit von Werbekampagnen zu verbessern.",
      cintegrationInputCapiAccessToken: "Zugriffstoken eingeben",
      cintegrationInputCapiCustomparameter: "Benutzerdefinierte Ereignisparameter eingeben",
      cintegrationDataFbCapiSaved: "Facebook Conversion API-Integration erfolgreich gespeichert",
      cintegrationComonRenovateHubInstructionTitle: "Anleitung zur Einrichtung der Integration mit RenovateHub",
      cintegrationComonRenovateHubInstructionDescription: "Um die Integration mit RenovateHub einzurichten, geben Sie die Webhook-URL an, an die die Daten gesendet werden. Stellen Sie sicher, dass die URL korrekt ist und Daten empfangen kann.",
      cintegrationComonRenovateHubInstructionShort: "Die Integration mit RenovateHub ermöglicht es Ihnen, Daten zur Prozessautomatisierung über einen Webhook an das CRM zu senden.",
      cintegrationRenovateHubUrl: "RenovateHub-URL eingeben",
      cintegrationInputRenovateHubUrl: "Geben Sie die Webhook-URL für RenovateHub an",
      cintegrationRenovateHubDataSaved: "RenovateHub-Integration erfolgreich gespeichert",
      cintegrationDataRenovateHubError: "Fehler beim Speichern der RenovateHub-Integration. Bitte überprüfen Sie die eingegebenen Daten.",
      cintegrationMenuFacebookConvAPI: "Facebook Conversions API",
      cintegrationMenuRenovateHub: "RenovateHub",
      csettingsIntegrationRenovateHub: "RenovateHub-Integration",
      csettingsIntegrationCAPIFB: "Facebook Conversions API - Integration",
      paymentPlanEnd: "Enddatum des Plans",
      paymentPlanLeadsLimitForYear: "Begrenzung der Leads pro Jahr:",
      paymentPlanLeadsLimitForPeriod: "Begrenzung der Leads pro Zeitraum:",
      EditTags: "Tags bearbeiten",
      quizzesTemplatesNoTags: "Keine Tags",
    
      Monday: "Montag",
      Tuesday: "Dienstag",
      Wednesday: "Mittwoch",
      Thursday: "Donnerstag",
      Friday: "Freitag",
      Saturday: "Samstag",
      Sunday: "Sonntag",
      datetimeDisplayMode: "Typ auswählen",
      datetimeDisplayModeSelect: "Typ auswählen",
      datetimeDisplayModeDate: "Datum",
      datetimeDisplayModeTime: "Uhrzeit",
      datetimeDisplayModeDatetime: "Datum & Uhrzeit",
      datetimeMinDate: "Min. Datum",
      datetimeMinDateCustom: "Min. Datum wählen",
      datetimeMaxDate: "Max. Datum",
      datetimeMaxDateCustom: "Max. Datum wählen",
      datetimeProhibitDaysOfWeek: "Tage sperren",
      datetimeIsRange: "Bereich aktivieren",
      datetimeIsRangeTrue: "Bereich aktiviert",
      datetimeIsRangeFalse: "Bereich deaktiviert",
      datetimeMinTime: "Min. Uhrzeit",
      datetimeMaxTime: "Max. Uhrzeit",
      datetimeIs24: "24-Stunden-Format",
      datetimeIs24True: "24-Stunden aktiviert",
      datetimeIs24False: "24-Stunden deaktiviert",
      csanyIsExactDate: "Wie möchten Sie das Datum einstellen",
      csanyIsExactDateTrue: "Genaues Datum",
      csanyIsExactDateFalse: "Relatives Datum",
      csanyDatetimeMinMaxNotExactPlaceholder: '"x" oder "-x" Tage ab heute',
      addNewSectionDatetime: "Datum/Uhrzeit",
      tariffsPromo: "Werbeangebote",
      tariffsMonth: "Monatlich",
      tariffsAnual: "Jährlich",
      leadsOn1Month: "pro Monat",
      leadsOn2Month: "für 2 Monate",
      leadsOn3Month: "für 3 Monate",
      leadsOn4Month: "für 4 Monate",
      leadsOn5Month: "für 5 Monate",
      leadsOn6Month: "für 6 Monate",
      leadsOn7Month: "für 7 Monate",
      leadsOn8Month: "für 8 Monate",
      leadsOn9Month: "für 9 Monate",
      leadsOn10Month: "für 10 Monate",
      leadsOn11Month: "für 11 Monate",
      leadsOn12Month: "pro Jahr",
      leadsOn24Month: "für 2 Jahre",
      leads: "Leitungen",
      tariffsWordPrice: "Preis",
      tariffsNoLimits: "Keine Einschränkungen",
      tariffsNoLimitsQuizzesAndFunctions: "bei Tests und Funktionalität",
      billingTariffYear: "Jahr",
      billingChangePlanSubscribe: "Abonnieren",
      paymentAllTariffInactive: "Der Tarif ist nicht aktiv",
      subscriptionTariffLableOnly: "Nur im Abonnement verfügbar",
      notExactBeforeYear: "vor einem Jahr",
      notExactBeforeHalfYear: "vor einem halben Jahr",
      notExactBeforeQuarter: "vor einem Quartal",
      notExactBeforeMonth: "vor einem Monat",
      notExactBeforeWeek: "vor einer Woche",
      notExactBeforeYesterday: "vorgestern",
      notExactYesterday: "gestern",
      notExactToday: "heute",
      notExactTomorrow: "morgen",
      notExactAfterTomorrow: "übermorgen",
      notExactAfterWeek: "in einer Woche",
      notExactAfterMonth: "in einem Monat",
      notExactAfterQuarter: "in einem Quartal",
      notExactAfterHalfYear: "in einem halben Jahr",
      notExactAfterYear: "in einem Jahr",
      tipDefaultDateHeader: "Standard",
      tipDefaultDateText: "Wenn sowohl der Schalter 'Genaues Datum' als auch 'Relatives Datum' ausgeschaltet sind, erlaubt der Kalender standardmäßig die Auswahl von Daten im Bereich von Jahr 1800 bis 2200.",
      tipExactDateHeader: "Schalter für genaues Datum",
      tipExactDateText: "Wenn dieser Schalter aktiviert ist, wird der Kalender auf einen festen Datumsbereich beschränkt, der in den unten stehenden Eingabefeldern ausgewählt wird.",
      tipRelativeDateHeader: "Schalter für relatives Datum",
      tipRelativeDateText: "Wenn dieser Schalter aktiviert ist, werden die Kalendergrenzen dynamisch basierend auf relativen Daten festgelegt, die zum Zeitpunkt des Seitenladevorgangs berechnet werden. Diese Grenzen sind personalisiert und werden für jeden Benutzer individuell neu berechnet.",
      langPT: "Portugiesisch",
      placeholderCompanyContactText: 'Zum Beispiel: "Firmentelefon: +490993322211"',
      integrationConnectService: "Verbinden",
      leadtype_id: "Aktionstyp",
      selectVariant: "Wählen",
      sfirstAddCompanyContacts: "Text mit Kontakten",
      addNewSectionTextarea: "Großes Eingabefeld",
      allstepsVariantView: "Bildformat",
      tariffsDiscountSave: "Spare",
      cAdminSettingsTitle: "Administrative Einstellungen",
      cAdminSettingsInstruction: "Geben Sie die Tags für das Quiz in das untenstehende Feld ein. Tags können aus mehreren Wörtern bestehen, jedes Tag wird durch ein Komma getrennt. Tags 'Dienstleistungen' und 'dienstleistungen' sind unterschiedliche Tags. Es wird empfohlen, Tags zu verwenden, die mit einem Großbuchstaben beginnen. Sie können das Quiz auch aus der Liste der Vorlagen ausschließen.",
      cAdminSettingsIsTemplate: "Ist es eine Vorlage",
      cIsTemplateStateAreSaved: "Der Vorlagenstatus wurde gespeichert",
      sfirstAddLogotype: "Fügen Sie ein Logo hinzu",
      logotypeSizeTip: "Das Logo wird in seiner tatsächlichen Größe eingefügt, jedoch nicht größer als 120 Pixel auf der längeren Seite.",
      cfirstLogotypeUpdated: "Das Logo wurde hinzugefügt",
      cfirstLogotypeDeleted: "Das Logo wurde erfolgreich gelöscht!",


      cOffersPageSettings: "Einstellungen",
      offerPageTitlePlaceholder: "Titel eingeben",
      offerPageDescriptionPlaceholder: "Seitenbeschreibung eingeben",
      offerPageButtonTextPlaceholder: "Button-Text eingeben (optional)",
      allstepsOffers: "Liste der Angebote",
      offerProposalHeader: "Angebot",
      offerAttachAddImg: "Bild hinzufügen",
      offerAttachAddVideo: "Video hinzufügen",
      offerItemImg: "Bild",
      offerItemTitle: "Angebotstitel",
      offerTitlePlaceholder: "Titel hier eingeben",
      offerItemSubtitle: "Untertitel (z. B. Preis)",
      offerSubtitlePlaceholder: "Text hier eingeben",
      offerItemDescription: "Angebotsbeschreibung",
      offerDescriptionPlaceholder: "Beschreibung hier eingeben",
      offerItemButtonText: "Button-Text des Angebots",
      offerBtnTextPlaceholder: "Button-Text eingeben",
      offerItemButtonLink: "Button-Link",
      offerBtnLinkPlaceholder: "Link muss mit https:// beginnen",
      csanyOfferLogic: "Anzeigelogik",
      offerAddNewOffer: "Neues Angebot hinzufügen",
      offerLogicSetupTitle: "Logikeinstellungen",
      OfferLogicSetupDescription: "Hier können Sie einstellen, welche Antworten welche Angebote anzeigen",
      cstepsOffersPage: "Ergebnisseite",
      addNewSectionResultsByLogic: "Angebote",
      cformActionAfterContactOffersOr: "Angebotsseite / ",
      tolltipOffersAfterContact: "Wenn nach dem Abschluss des Quiz die Logik zur Anzeige von mindestens einem Angebot erfüllt ist, wird die Angebotsseite angezeigt, andernfalls die Dankeseite.",
      videoCircleTextQuestion: "Wie funktioniert das?",
      offerPhotoOptimalRatio: "Für eine optimale Bildanzeige empfehlen wir ein Seitenverhältnis von 16:9.",
      tolltipThankYouAfterContact: "Nach Abschluss des Quiz wird eine Dankeseite angezeigt.",
      cformActionAfterContactOffers: "Ergebnisseite",
      addOfferPageInContact: "Ergebnisseite aktivieren",
      offerPageHeader: "Überschrift für die Angebotsseite",
      pumkinOrange: "Orange",



    },
    PL: {
      acceptedFileSize:"Maksymalny rozmiar:",
      acceptedFileSizeMeasure:"MB",
      acceptedFileType:"Akceptowane formaty:",
      additionalCheckbox:"Dodatkowy Checkbox",
      addNewSectionCard:"Karty ze zdjęciami",
      addNewSectionCustom:"Pole wprowadzania",
      addNewSectionHeader:"Dodaj nową sekcję",
      addNewSectionList:"Lista",
      addNewSectionSlider:"Suwak",
      addNewSectionTags:"Tagi",
      addNewSectionWithEmoji:"Lista z emoji",
      adminAvarageUsed:"Średnia ilość miesięcy użytkowania",
      adminEditorForPartnership:"Redaktor programu partnerskiego",
      adminLeadsForOne:"Na jednego użytkownika",
      adminLTV:"LTV",
      adminMadeQuiz:"Stworzono quizów",
      adminNewUsers:"Nowi użytkownicy za miesiąc",
      adminProAccounts:"Konta PRO",
      adminProfitAlltime:"Stworzono za cały czas",
      adminProfitMonth:"Stworzono za miesiąc",
      adminQuizForOne:"Na jednego użytkownika",
      adminReciveLeads:"Otrzymano aplikacji",
      adminSharePro:"PRO w stosunku do wszystkich",
      adminUsersList:"Baza użytkowników",
      adminUsersReg:"Użytkownicy w systemie",
      allAnd:"і",
      allOr:"lub",
      allstepsBack:"Wstecz",
      allstepsButtonText:"Tekst przycisku",
      allstepsChangePhoto:"Zmień zdjęcie",
      allstepsCustomInput:"Własna wersja",
      allstepsCustomInputOn:"Włącz",
      allstepsCustomPlaceholder:"Własna wersja",
      allstepsDeletePhoto:"Usuń zdjęcie",
      allstepsDescription:"Opis.",
      allstepsIsSaveBeforeRoute:"Zapisać dane przed przełączeniem?",
      allstepsOtherSettings:"Więcej ustawień",
      allstepsPhotoAdaptation:"Dostosowywanie zdjęć do urządzeń",
      allstepsPhotoFit:"Proporcje",
      allstepsPhotoMustBeLess:"Zdjęcie powinno być mniejsze",
      allstepsPhotoTooBig:"Plik jest za duży!",
      allstepsPlaceholder:"Placeholder",
      allstepsResetInHeader:"Reset",
      allstepsTitle:"Tytuł",
      allstepsUpploadPhoto:"Pobierz zdjęcie",
      almondCrayola:"Rafa koralowa",
      alreadyHaveAccount:"Masz już konto?",
      autoInternationalPrefix:"Wykryj kraj automatycznie",
      azureBlue:"Zimowa noc",
      azureBlueWhite:"Standard",
      billingAcordeonPayHistory:"Historia transakcji",
      billingAcordeonPaymentInfoCancelSubscription:"Anuluj subskrypcję",
      billingAcordeonPaymentInfoFormat:"Format",
      billingAcordeonPaymentInfoPay:"Płatność",
      billingAcordeonPaymentInfoPayMethod:"Metoda płatności",
      billingAcordeonPaymentInfoSubscription:"Subskrypcja",
      billingAcordeonPaymentInfoTitle:"Informacje o płatności",
      billingAcordeonPromocode:"Kod promocyjny",
      billingAcordeonPromocodeLabel:"Wprowadź kod promocyjny",
      billingBillings:"Płatności",
      billingBonusesActivated:"Kod promocyjny został aktywowany!",
      billingBonusesAlreadyActivated:"Ten kupon jest nieważny",
      billingBonusesBtn:"Aktywuj",
      billingBonusesDescription:"Wprowadź otrzymany kod promocyjny w polu obok.",
      billingBonusesInvalid:"Nieprawidłowy kod promocyjny",
      billingBonusesLeadIncreased:"Ilość dostępnych leadów została zwiększona.",
      billingBonusesNotAvailable:"Ten kod promocyjny został już wykorzystany",
      billingBonusesPlaceholder:"Kod promocyjny",
      billingBonusesTitle:"Kod promocyjny",
      billingBtnChangeToPro:"Przejdź do",
      billingBtnRenewPro:"Aktualizacja",
      billingChangeData:"Zmień dane",
      billingChangePlan:"Zmień plan",
      billingChangePlanDescription:"Cena zostanie dostosowana do żądanej ilości leadów ze wszystkich quizów na koncie.",
      billingChangePlanDescriptionImportant:"Ważne:",
      billingChangePlanDescriptionImportantMonth:"Leady mogą być używane przez 1 miesiąc po zakupie",
      billingChangePlanDescriptionImportantMonthAfterBuy:"po zakupie.",
      billingChangePlanPay:"Kaufen",
      billingChangePlanWarning:"Zakupione leady będą",
      billingDeletePayMethod:"Usuń",
      billingFreeLeads:"Brak bezpłatnych leadów",
      billingGeneralAllTarifExpired:"Taryfy wygasły",
      billingGeneralBonus:"Leady według kodu promocyjnego",
      billingGeneralCost:"Koszt",
      billingGeneralExpired:"Ważne do",
      billingGeneralLeadsFromPastTarifs:"Saldo potencjalnych klientów z poprzednich taryf",
      billingGeneralLeadsLeft:"Zostało się  leadów",
      billingGeneralName:"Twoja taryfa",
      billingInfinity:"nieograniczony",
      billingLeads:"leady",
      billingModalDeleteCancel:"Anuluj",
      billingModalDeleteOk:"Usuń",
      billingModalDeleteShure:"Czy naprawdę chcesz usunąć tę metodę płatności?",
      billingModalDeleteSuccess:"Karta została pomyślnie usunięta",
      billingModalRegularPaymentDeleteOk:"OK",
      billingModalRegularPaymentDeleteSuccess:"Regularne płatności zostały anulowane",
      billingMonth:"miesiąc",
      billingOtherTariffFree:"Free.",
      billingOtherTariffPro:"Pro.",
      billingPaymentCard:"Karta",
      billingPaymentInfoChangeTarrif:"Zmiana taryfy",
      billingPaymentInfoDescription:"Płacąc za dowolną płatną taryfę, otrzymujesz określoną liczbę leadów zawartych w wybranym pakiecie. Leady  te są dostępne przez miesiąc. Jeśli nie wykorzystasz swojej liczby leadów w ciągu miesiąca od dokonania płatności, takie dane zostaną anulowane.",
      billingPaymentInfoTitle:"Informacje o taryfie",
      billingPaymentsMethod:"Metoda płatności",
      billingSelectPeriod:"Wybierz okres",
      billingTariffEndData:"Data wygaśnięcia taryfy",
      billingTariffFreeDescription:"Nieograniczona liczba nowych quizów (stron internetowych). Możliwość wygenerowania do 300 leadów",
      billingTariffMonth:"miesiąc",
      billingTariffProDescription:"Nieograniczone tworzenie nowych quizów (stron internetowych). Możliwość wygenerowania",
      billingTariffProDescriptionBold:"ponad 300 leadów",
      billingTariffRenewData:"Data aktualizacji taryfy",
      billingYorFree:"Bezpłatne",
      billingYouHasFreeLeads:"Zostało bezpłatnych leadów",
      billingYouHasLeads:"Zostało leadów",
      billingYouHaveNotBillings:"Nie masz jeszcze żadnych płatności",
      billingYouHavePro:"Obecnie korzystasz z wersji Pro. Dlaczego robisz krok w tył?",
      billingYourFutureTariff:"Twój przyszły plan",
      billingYourMonthPlan:"Twój plan miesięczny",
      billingYourPro:"Pro",
      billingYourTariff:"Twój obecny plan",
      blackPearlescentOpal:"Popiół",
      blueLilac:"Ciemna lawenda",
      cancel:"Anuluj",
      cancelAllAction:"Anuluj",
      cardPanUnspecified:"Nie określono",
      cdesignColorTheme:"Schemat kolorów",
      cdesignCustomTheme:"Stwórz własny schemat kolorów",
      cdesignDefaultThemes:"Gotowe schematy kolorów",
      cdesignShapeDesign:"Kształt designu",
      cdesignShapeRound:"Zaokrąglony",
      cdesignShapeSquare:"Kątowy",
      cdesignThemeDark:"Ciemny",
      cdesignThemeGradient:"Gradient",
      cdesignThemeNud:"Nude",
      cdesignThemeStandart:"Standardowy",
      cfinishAddImage:"Dodaj obrazek",
      cfinishButtonText:"Tekst przycisku",
      cfinishCheckDataIsCorrect:"Sprawdź poprawność wprowadzonego identyfikatora",
      cfinishDescription:"Opis.",
      cfinishFbWhichCorrect:"Wprowadź identyfikator (pseudonim) bez adresu. Na przykład: some.name",
      cfinishFinishPageDataUpdated:"Dane strony finałowej zostały zaktualizowane",
      cfinishFinishPageHiddenUpdated:"Strona finałowa jest ukryta.",
      cfinishFinishPageHiddenUpdatedGoContact:"Przejdź do strony Formularze kontaktowe",
      cfinishFinishPageHiddenUpdatedSetRedirect:"i skonfiguruj przekierowanie po przesłaniu danych",
      cfinishFinishPageTitle:"Strona finałowa ",
      cfinishIgWhichCorrect:"Wprowadź identyfikator (pseudonim) bez adresu. Na przykład: some.name",
      cfinishImageAdded:"Obrazek dodany",
      cfinishInsertNikname:"Wstaw tutaj swój pseudonim",
      cfinishText:"Tekst.",
      cfinishTgWhichCorrect:"Wprowadź identyfikator (pseudonim) bez adresu. Na przykład: some.name",
      cfinishURL:"URL",
      cfirstAddBonus:"Dodaj bonus",
      cfirstAddMediafile:"Dodaj plik multimedialny (zdjęcie)",
      cfirstBonus:"Bonus",
      cfirstBonusAvailable:"Dostępny",
      cfirstBonusDisabled:"Niedostępny",
      cfirstFormat:"Format",
      cfirstHeaderStartText:"Twój nagłówek powinien znaleźć się tutaj. Zmotywuj użytkowników do ukończenia quizu ",
      cfirstStartPageBonusPhotoUpdated:"Zaktualizowano zdjęcie bonusowe",
      cfirstStartPageDataUpdated:"Zaktualizowano dane strony startowej",
      cfirstStartPagePhotoUpdated:"Zaktualizowano zdjęcie strony głównej",
      cformActionAfterContactRecive:"Działania po otrzymaniu kontaktów",
      cformActionAfterContactRedirect:"Przekierowanie",
      cformActionAfterContactThankyou:"Strona z podziękowaniem",
      cformAdditionalSettings:"Dodatkowe ustawienia",
      cformDataContactsPageUpdated:"Zaktualizowano dane strony kontaktowej",
      cformForm:"Formularz",
      cformInputAvailable:"Dostępny",
      cformInputDisabled:"Wyłączony",
      cformRedirectURL:"URL",
      cformRequiredInput:"Wymagane",
      cformValueEmail:"E-mail",
      cformValueName:"Nazwa",
      cformValuePhone:"Numer telefonu",
      cformValueSurname:"Nazwisko",
      cintegrationAccesToken:"ACCES TOKEN",
      cintegrationAddEmail:"Dodaj e-mail",
      cintegrationAddIntegration:"Dodaj integrację",
      cintegrationAddWH:"Dodaj Web-hook",
      cintegrationApixDriveWHInstruction1Description1:"Link",
      cintegrationApixDriveWHInstruction1Description2:"dla rejestracji z bonusem.",
      cintegrationApixDriveWHInstruction1Title:"1. Zarejestruj się lub otwórz konto ApixDrive:",
      cintegrationApixDriveWHInstruction2Description1:"Przejdź do ustawień wymaganego połączenia i skopiuj adres, aby wysłać webhook:",
      cintegrationApixDriveWHInstruction2Description2:"Możesz utworzyć nowe połączenie, klikając przycisk „Utwórz połączenie” i wybierz „ADSQuiz” w kroku wyboru systemu.",
      cintegrationApixDriveWHInstruction2Title:"2. Uzyskaj adres URL elementu webhook:",
      cintegrationApixDriveWHInstruction3Description:"Wklej adres URL elementu webhook dostarczonego przez ApixDrive do pola wprowadzania.",
      cintegrationApixDriveWHInstruction3Title:"3. Wklej adres URL elementu webhook:",
      cintegrationApixDriveWHInstruction4Description:'Kliknij przycisk "Zapisz zmiany", aby zapisać ustawienia elementu webhook.',
      cintegrationApixDriveWHInstruction4Title:"4. Zapisz zmiany:",
      cintegrationApixDriveWHInstruction5Description:"Twój webhook jest teraz skonfigurowany do wysyłania danych do ApixDrive.",
      cintegrationApixDriveWHInstruction5Title:"5. Zakończ:",
      cintegrationApixDriveWHInstructionAdditional:"Dane będą wysyłane do webhooka za każdym razem, gdy otrzymasz lead. Metoda wysyłania - POST. Przykład danych, które zostaną wysłane to",
      cintegrationApixDriveWHInstructionAdditionalLink:"tutaj",
      cintegrationApixDriveWHInstructionShort:"Możesz powiadamiać ApixDrive o przychodzących leadach. Aby to zrobić, określ adres URL, na który zostanie wysłany webhook.",
      cintegrationApixDriveWHInstructionShort1:"Podczas rejestracji przez",
      cintegrationApixDriveWHInstructionShort2:"ten link",
      cintegrationApixDriveWHInstructionShort3:"nasi użytkownicy otrzymują bonus - 10 000 darmowych działań dla każdej integracji.",
      cintegrationApixDriveWHInstructionVideo:"Szczegółowa instrukcja wideo",
      cintegrationCheckDomenIsCorrect:"Sprawdź, czy wprowadzona domena jest poprawna",
      cintegrationComonWHInstruction1Description:"Wklej adres URL webhooka dostarczonego przez twoją usługę lub aplikację do pola wprowadzania.",
      cintegrationComonWHInstruction1Title:"1. Wklej adres URL elementu webhook w polu wprowadzania:",
      cintegrationComonWHInstruction2Description:"Kliknij przycisk Zapisz zmiany, aby zapisać ustawienia webhook.",
      cintegrationComonWHInstruction2Title:"2. Zapisz zmiany:",
      cintegrationComonWHInstruction3Description:"Twój webhook jest teraz skonfigurowany do wysyłania danych do wybranej usługi.",
      cintegrationComonWHInstruction3Title:"3. Zakończ:",
      cintegrationComonWHInstructionAdditional:"Dane będą wysyłane do webhooka za każdym razem, gdy otrzymasz lead. Metodą wysyłania jest POST. Przykład danych, które zostaną wysłane, można znaleźć",
      cintegrationComonWHInstructionAdditionalLink:"tutaj",
      cintegrationComonWHInstructionShort:"Możesz powiadamiać inne aplikacje o przychodzących leadach. Wpisz adres URL elementu webhook, do którego mają być wysyłane dane.",
      cintegrationComonWHInstructionVideo:"Szczegółowa instrukcja wideo",
      cintegrationConectOweDomain:"Podłączenie własnej domeny",
      cintegrationDataFBSaved:"Dane piksela FB zostały  zostały zapisane ",
      cintegrationDataGASaved:"Dane Google Analytics zostały  zapisane ",
      cintegrationDataGTMSaved:"Dane Google Tag Manager zostały  zapisane ",
      cintegrationDataTGSaved:"Dane integracji Telegram zostały  zapisane ",
      cintegrationDataTTSaved:"Dane TT piksel zostały zapisane",
      cintegrationDocsDataSaved:"Linki do Twoich dokumentów zostały zapisane",
      cintegrationDomain:"Domena",
      cintegrationDomainDataSaved:"Dane domeny zostały zapisane",
      cintegrationDomainWhichCorrect:"Wprowadź domenę bez protokołów. np: some.site.com",
      cintegrationEmailDeleted:"Usunięto dane integracji poczty e-mail",
      cintegrationEmailInstruction:"Wprowadź wymagany adres e-mail, na który chcesz wysłać informacje w polu poniżej. Każde lead z tego quizu zostanie zduplikowany na podany adres e-mail.",
      cintegrationEmailSaved:"Dane e-mail integracji zostały zapisane",
      cintegrationFBInstruction:"Zdarzeniem, dla którego należy skonfigurować konwersję jest „Lead”. To zdarzenie zostanie uruchomione, gdy tylko użytkownik pomyślnie wyśli dane do quizu.  ",
      cintegrationFBInstruction1:"Przejdź do swojego konta Facebook Ads i skopiuj identyfikator piksela Facebooka w sekcji Event Manager.",
      cintegrationFBInstruction2:"Nie kopiuj całego kodu, tylko 15-16 cyfrowy identyfikator.",
      cintegrationFBInstruction3:"Skopiuj go i wklej w polu poniżej.",
      cintegrationGAInstruction:"Po otrzymaniu danych w quizie, zdarzenie „generate_lead” zostanie uruchomione w Google Analytics.",
      cintegrationFBInstructionTextTitle: "Lista wszystkich wysyłanych zdarzeń:",
      cintegrationFBInstructionTextTextStart: " – odwiedziny strony startowej;",
      cintegrationFBInstructionTextQuestionN: " – odwiedziny kroku N;",
      cintegrationFBInstructionTextOpenContactPage: " – odwiedziny strony kontaktowej;",
      cintegrationFBInstructionTextLead: " – pomyślne wysłanie formularza kontaktowego;",
      cintegrationFBInstructionTextThankYou: " – odwiedziny strony końcowej.",
      bold_adsquiz_Start: "adsquiz_Start",
      bold_adsquiz_QuestionN: "Question_N",
      bold_adsquiz_OpenContactPage: "Open_Contact-Page",
      bold_adsquiz_Lead: "Lead",
      bold_adsquiz_ThankYou: "adsquiz_ThankYou",
      cintegrationGAInstructionDescription:"Dowiedz się, jak ludzie znajdują i wchodzą w interakcję z Twoim quizem. Uzyskaj dane potrzebne do analizy kampanii, poprawy konwersji i nie tylko.",
      cintegrationGTMInstruction:"Skopiuj identyfikator Google Tag Manager i wklej go w poniższe pole.",
      cintegrationGTMInstructionDescription:"Opis dla Google Tag Manager (nieużywany)",
      cintegrationHowReciveTGLeadBot:"Jak mogę otrzymywać leady (dane) do bota Telegram?",
      cintegrationHowReciveTGLeadChat:"Jak mogę otrzymywać leady (dane) w czat Telegram?",
      cintegrationIDFolow:"ID śledzenia",
      cintegrationIDPixel:"Pixel ID",
      cintegrationInputEmail:"Wprowadź swój adres e-mail",
      cintegrationInputID:"Wprowadź ID pixela",
      cintegrationInputIDPixel:"Wprowadź ID piksela",
      cintegrationInputTGToken:"Wprowadź token telegramu dla chatbota",
      cintegrationInputWHUrl:"Wprowadź adres webhook",
      cintegrationInputYourDomain:"Wprowadź swoją domenę",
      cintegrationInsertedDefaultDomain:"Domena jest ustawiona domyślnie",
      cintegrationInstruction:"Instrukcje",
      cintegrationInstructionDetail:"Szczegóły",
      cintegrationIntegration:"Integracje",
      cintegrationOweDomainInstruction1:"1. Aby umieścić quiz w wymaganej domenie, zaloguj się na swoje konto hostingowe. Przejdź do ustawień DNS i utwórz A-rekord z parametrem IP: 185.25.117.21",
      cintegrationOweDomainInstruction2:"2. W powyższym polu określ domenę i zapisz quiz.",
      cintegrationOweDomainInstructionImportant:"Ważne: Adres na koncie hostingowym i w polu poniżej musi się zgadzać. Czas na zastosowanie zmian w ustawieniach DNS i wydanie certyfikatu SSL wynosi do 3 godzin od zapisania.",
      cintegrationOweDomainInstructionVideo:"Obejrzyj instrukcję wideo",
      cintegrationPrivacyPolicy:"Polityka prywatności.",
      cintegrationPrivacyPolicyAdventage:"Wyjaśni to potencjalnym klientom, w jaki sposób wykorzystujesz otrzymane dane kontaktowe. Jest to również wymagane przez niektóre platformy reklamowe.",
      cintegrationPrivacyPolicyByDefault:"Domyślnie używamy naszej standardowej polityki prywatności. Zalecamy dodanie własnej polityki prywatności, która jest odpowiednia dla Twojej firmy.",
      cintegrationPrivacyPolicyInputYourDocsLink:"Wstaw link do Twoich dokumentów",
      cintegrationPrivacyPolicyUrl:"Link do Twoich dokumentów",
      cintegrationPublishAfterEdit:"Zapisz quiz po edycji",
      cintegrationSendNewLeadsTo:"Wysyłaj powiadomienia o nowych leadach do:",
      cintegrationShareLeads:"Przeniesienie leadów",
      cintegrationTGBotLink:"link do chatbota",
      cintegrationTGInstructionBot1:"1. Skopiuj token w polu poniżej.",
      cintegrationTGInstructionBot2:"2. Podążaj za linkiem do bota, kliknij przycisk „START”.",
      cintegrationTGInstructionBot3:"3. Wyślij skopiowany token do naszego bota za pośrednictwem wiadomości.",
      cintegrationTGInstructionChat1:"1. Dodaj naszego bota do wybranego czatu i ustaw bota na status administratora.",
      cintegrationTGInstructionChat2:"2. Skopiuj token w polu poniżej i wyślij go do wybranego czatu.",
      cintegrationTGInstructionChat3:"3. Poczekaj na odpowiedź OK! Your quiz token was saved!`",
      cintegrationTGInstructionChat4:"4. Teraz dane będą wysyłane do tego czatu.",
      cintegrationTGInstructionWarning:"Ważne:",
      cintegrationTGInstructionWarningText:"Należy pamiętać, że powinna to być grupa, a nie kanał Telegram. Dane nie będą wysywać się do kanalu Telegram.",
      cintegrationTGTitleToken:"TOKEN",
      cintegrationTitleEmail:"E-MAIL",
      cintegrationTitleFB:"Piksel FB",
      cintegrationTitleGA:"Google Analytics",
      cintegrationTitleGTM:"Google Tag Manager",
      cintegrationTitleTG:"Telegram",
      cintegrationTitleTT:"Piksel TT",
      cintegrationTitleWH:"WebHook",
      cintegrationTTInstruction:"Aby śledzić zdarzenia na koncie Tik-Tok Ads, skopiuj identyfikator piksela i wklej go w poniższe pole.",
      cintegrationWHDataSaved:"WebHook dane zostały zapisane",
      cintegrationWHInstruction1:"Możesz powiadamiać inne aplikacje o przychodzących danych. Aby to zrobić, wpropwadź adres URL, na który zostanie wysłany WebHook.",
      cintegrationWHInstruction10:"dla rejestracji z bonusem.",
      cintegrationWHInstruction2:"Przygotowaliśmy",
      cintegrationWHInstruction3:"wideo instrukcja ",
      cintegrationWHInstruction4:"ze szczegółowym opisem tej funkcjonalności.",
      cintegrationWHInstruction5:"Obejrzyj wideo.",
      cintegrationWHInstruction6:"W tym filmie omówimy wysyłanie huka w oparciu o wysyłanie danych do arkusza Google i wysyłanie danych do systemu Trello CRM.",
      cintegrationWHInstruction7:"Zalecamy korzystanie dla tego serwisui",
      cintegrationWHInstruction8:"Rejestrując się za pomocą tego linku, nasi użytkownicy otrzymują bonus - 10 000 darmowych leadów dla dowolnej integracji.",
      cintegrationWHInstruction9:"Link",
      cintegrationWHUrl:"Adres webhooka",
      completeUserDataUpdate:"Dane użytkownika zostały zaktualizowane",
      confirmAllAction:"Potwierdź",
      conjunctionAnd:"i",
      constructorBack:"Wstecz",
      constructorCancel:"Anuluj",
      constructorDesignSaved:"Design quizu został pomyślnie zapisany",
      constructorextraNavContact:"C",
      constructorextraNavFinish:"F",
      constructorextraNavStart:"S",
      constructorGetLink:"Link",
      constructorLink:"link",
      constructorLinkCopied:"Skopiowany do schowka:",
      constructorNavDesign:"Design",
      constructorNavSettings:"Ustawienia",
      constructorNavSteps:"Kroki",
      constructorSave:"Zapisz",
      constructorSaveChanges:"Zapisz",
      constructorStep:"krok",
      constructorStepsSaved:"Wszystkie dane quizu zostały pomyślnie zapisane",
      constructorYouHideStep:"Ten krok został ukryty",
      contactAgreeAgreementAdditionalWord:"",
      contactAgreeAnd:"i",
      contactAgreeIAgreeWith:"Zgadzam się z",
      contactAgreePolicy:"polityką prywatności",
      contactAgreeTermsOfUse:"warunki użytkowania",
      contactPrivacyPolicyCheckbox:"Pole wyboru umowy o ochronie prywatności",
      createNewGroup:"Utwórz nową grupę",
      createQuizCPButton:"Gotowe!",
      createQuizCPDescription:"Wypełnij poniższy formularz",
      createQuizCPTitle:"Jak możemy się z Tobą skontaktować?",
      createQuizFPButton:"Pokaż mi coś!",
      createQuizFPDescription:"Teraz znajdziemy najlepsze rozwiązanie dla Ciebie!",
      createQuizFPTitle:"Dziękujemy!",
      createQuizSPDescription:"To jest miejsce na krótki opis",
      csanyAddAnswer:"dodać",
      csanyTitleForModalConfirm: "Ta akcja jest nieodwracalna!",
      csanyQuestionForModalConfirm: "Czy na pewno chcesz usunąć to pytanie?",
      csanyAlowMultiple:"Zezwalaj na wiele odpowiedzi",
      csanyAnswerOrderSaved:"Kolejność odpowiedzi jest zapisywana",
      csanyAnswers:"Odpowiedzi",
      csanyAnswerType:"Typ pytania",
      csanyATCard:"karta",
      csanyATCustom:"wolne pole",
      csanyATList:"lista",
      csanyAutoRedirect:"Automatycznie przejdź dalej",
      csanyDataStepUpdated:"Dane strony z pytaniami zostały zaktualizowane",
      csanyQuestion:"Pytania",
      csanyQuestionLogic:"Konfigurowanie logiki pytań",
      csanyRequiredStep:"Wymagany krok",
      cSettingDataSaved:"Ustawienia dodatkowe zostały zapisane",
      csettingsAdmin:"Ustawienia dla administratorów",
      cSettingsCustomThemeAdditionalColor:"Kolor tekstu",
      cSettingsCustomThemeButtonTextColor:"Tekst w przyciskach",
      cSettingsCustomThemeModeDark:"Ciemny",
      cSettingsCustomThemeModeLight:"Jasny",
      cSettingsCustomThemePrimaryColor:"Kolor akcentu",
      cSettingsCustomThemeSecondaryColor:"Kolor tła",
      csettingsDocs:"Dokumenty prawne",
      csettingsIntegration:"Integracje",
      csettingsIntegrationAbout:"Szczegóły",
      csettingsIntegrationADAbout:"Coś o APIxDrive",
      csettingsIntegrationApixDrive:"Integracja - APIxDrive",
      csettingsIntegrationEmail:"Integracja - E-mail",
      csettingsIntegrationEmailAbout:"Aktywuj wiadomość e-mail dla respondenta i poinformuj o tym swój zespół. Współpracuj ze swoim zespołem.",
      csettingsIntegrationFB:"Integracja - Piksel Facebooka",
      csettingsIntegrationFBAbout:"Przejdź do swojego konta Facebook Ads i skopiuj identyfikator Facebook Pixel w sekcji Event Manager. Skopiuj tylko identyfikator, a nie cały kod, i wklej go w polu poniżej.",
      csettingsIntegrationGA:"Integracja - Google Analytics",
      csettingsIntegrationGAAbout:"Skopiuj identyfikator Google Analytics i wklej go w polu poniżej.",
      csettingsIntegrationGTM:"Integracja - Google Tag Manager",
      csettingsIntegrationGTMAbout:"Skopiuj identyfikator  Google Tag Manager i wklej go w polu poniżej.",
      cSettingsIntegrationNeedHelp:"Potrzebujesz pomocy?",
      cSettingsIntegrationRefreshToken:"Zaktualizuj swój token",
      csettingsIntegrationTG:"Integracja - Telegram",
      csettingsIntegrationTGAbout:"Ważne: Należy pamiętać, że powinna to być grupa, a nie kanał w Telegramie. Leady nie będą wysyłane na kanał Telegram.",
      csettingsIntegrationTT:"Integracja - TikTok",
      csettingsIntegrationTTAbout:"Aby śledzić wydarzenia na koncie reklamowym TikTok, skopiuj identyfikator piksela i wklej go w poniższe pole.",
      csettingsIntegrationWH:"Integracja - WebHook",
      csettingsIntegrationWHAbout1:"Możesz powiadamiać inne aplikacje o przychodzących leadach. Aby to zrobić, wprowadź adres URL, na który zostanie wysłany webhook. Zalecamy korzystanie z tej usługi.",
      csettingsIntegrationWHAbout2:"Rejestrując się za pomocą tego linku, nasi użytkownicy otrzymują bonus - 10 000 darmowych akcji dla każdej integracji.",
      csettingsIntegrationWHAboutApix:"Apix-Drive",
      csettingsOweDomain:"Podłącz własną domenę",
      csettingsSettingASettings:"Dodatkowe ustawienia",
      cSettingsSettingQuizBackButtonDescription:"Możesz włączyć lub wyłączyć przycisk powrotu do poprzedniego kroku. Umożliwi to zmianę udzielonej wcześniej odpowiedzi.",
      cSettingsSettingQuizBackButtonTitle:"Przycisk Wstecz",
      cSettingsSettingQuizHideLabelDescription:"Włączając tę opcję, będziesz mógł cieszyć się interfejsem „made by adsquiz”, pozwalając Tobie i Twoim odwiedzającym skupić się wyłącznie na pytaniach.",
      cSettingsSettingQuizHideLabelTitle:"Ukryj tag",
      cSettingsSettingQuizLangDescription:"Aby zapewnić jak najlepsze wrażenia, wybierz preferowany język quizu z poniższych opcji. Język ten będzie używany do wyświetlania powiadomień systemowych i niektórych innych niezmienionych tekstów podczas quizu.",
      cSettingsSettingQuizLangTitle:"Język quizu",
      cstepsAddSection:"Dodaj sekcję",
      cstepsContactPage:"Formularz kontaktowy",
      cstepsDeleteStep:"Usuń krok",
      cstepsEdit:"Edytuj",
      cstepsFinishPage:"Strona finałowa",
      cstepsHiddenStep:"Ukryj krok",
      cstepsMove:"Przesuń",
      cstepsQuestionsOrderSaved:"Kolejność pytań została zapisana",
      cstepsSection:"Sekcje",
      cstepsShowStep:"Pokaż krok",
      cstepsStartPage:"Strona początkowa",
      defaultBtnText:"Rozpocznij",
      defaultNewQuestion:"Nowe pytanie",
      defaultNewQuestionDescription:"Tutaj można wstawić krótki opis pytania (do 80 znaków).",
      defaultNewVariant:"Nowa opcja",
      developmentPage:"Sekcja jest w trakcie opracowywania",
      discount:"Zniżka do",
      dontHaveAccount:"Nie masz konta?",
      dovnloadCsvCancel:"Anuluj",
      dovnloadCsvExport:"Eksportuj do",
      dovnloadCsvSelectAllPeriod:"Wybierz dla całego okresu",
      dovnloadCsvTitle:"Eksportuj dane dla wybranego okresu",
      dustyBlue:"Tiffany",
      enterYourEmail:"Wprowadź swój adres e-mail",
      enterYourName:"Wprowadź swoje imię i nazwisko",
      enterYourPassword:"Wprowadź hasło",
      enterYourPhone:"Wprowadź swój numer telefonu",
      errorUserDataUpdate:"Błąd podczas aktualizacji danych: ",
      exportInfo:"Eksportowanie leadów",
      falseDoesNotContainForbiddenSubstrings:"Domena nie może zawierać niedozwolonych podciągów.",
      falseDoesNotHaveConsecutiveSameChar:"Domena nie może zawierać trzech lub więcej następujących po sobie identycznych znaków.",
      falseDoesNotHaveConsecutiveSpecialChar:"Domena nie może zawierać dwóch lub więcej następujących po sobie znaków specjalnych.",
      falseDoesNotStartOrEndWithSpecialChar:"Domena nie może zaczynać się ani kończyć znakiem specjalnym.",
      falseHasValidCharacters:"Nieprawidłowe znaki w domenie. Prawidłowe znaki to litery alfabetu łacińskiego, cyfry i myślniki.",
      falseHasValidLength:"Długość domeny musi wynosić od 6 do 20 znaków alfanumerycznych.",
      footerContactUs:"Masz jakieś pytania? Skontaktuj się z nami:",
      generateDomainDescription:"Jest to unikalny adres, pod którym Twoja strona będzie dostępna. Możesz zmienić adres lub dodać własny w dowolnym momencie.",
      generateDomainDomainNameLabel:"Nazwa domeny",
      generateDomainTaken:"Ta domena jest już zajęta. Wypróbuj inną",
      generateDomainTitle:"Wprowadź nazwę domeny",
      getBackAccount:"Powrót do konta",
      getLeads:"Kup leady",
      helloHeader:"Cześć",
      inMinutes:"za minutę",
      jazzJam:"Jazz",
      langDE:"Niemiecki",
      langEN:"angielski",
      langIT:"włoski",
      langKK:"Kazachski",
      langPL:"polski",
      langRU:"rosyjski",
      langSP:"hiszpański",
      langUA:"ukraiński",
      lavender:"Kosmos",
      leadHide:"Ukryć",
      leadShowMore:"Więcej",
      leadsLeft:"Pozostały leady",
      leadsTable:"Lista leadów",
      loginBtnValue:"Zaloguj się",
      loginEmailPlaceholder:"E-mail",
      loginHeader:"Zaloguj się",
      loginHeader2Part:"aby kontynuować",
      loginPasswordlPlaceholder:"○ ○ ○ ○ ○ ○ ○ ○ ○",
      loginSignup:"Zarejestruj konto",
      loginWith:"Zaloguj się za pomocą",
      madeInUkr:"wyprodukowano na Ukrainie",
      modalSureUnsavedChangesDescription:"Przed opuszczeniem bieżącej strony zdecyduj, co zrobić z niezapisanymi zmianami",
      modalSureUnsavedChangesDontSave:"Nie zapisuj",
      modalSureUnsavedChangesSave:"Zapisz",
      modalSureUnsavedChangesTitle:"Masz niezapisane zmiany",
      moderatePurplishRed:"Czerwony aksamit",
      month:"0 miesięcy |{n} miesiąc |{n} miesiąc |{n} miesiąc",
      navBillings:"Płatności",
      navPartnership:"Program partnerski",
      navProfile:"Profil",
      navTariffs: "Tarife",
      nonBillingsYet:"Nie masz jeszcze historii płatności.",
      notificationAgreePrivacyPolicy:"Prosimy o zaakceptowanie polityki prywatności",
      notificationCheckInputEmail:"Sprawdź poprawność adresu e-mail",
      notificationCheckInputPhone:"Sprawdź, czy Twój numer telefonu jest poprawny",
      notificationErrorLogin:"Wystąpił błąd autoryzacji:",
      notificationInputAllInputs:"Wypełnij wszystkie pola, aby się zarejestrować",
      notificationInputLoginData:"Wprowadź dane logowania",
      notificationLogin:"Zaloguj się...",
      notificationNetworkError:"Sprawdź połączenie internetowe",
      notificationSuccess:"sukces!",
      notificationSuccessDeleteAccount:"Twoje konto zostało pomyślnie usunięte! Użyj nowego hasła, aby się zalogować",
      notificationSuccessSaveAccount:"Twoje hasło zostało pomyślnie zmienione! Użyj nowego hasła, aby się zalogować",
      notificationWrongLoginData:"Nieprawidłowe dane logowania",
      ok:"Dobrze",
      opalGreen:"Karpacki las",
      openPopupQuizText:"Rozpocznij zadanie",
      passwordAreForgetPassword:"Zapomniałeś hasła?",
      passwordChangePassword:"Zmień hasło",
      passwordConfirmPassword:"Potwierdź hasło",
      passwordDoNotCoincidence:"Hasła nie pasują",
      passwordDoNotCoincidenceAgain:"Spróbuj ponownie",
      passwordGoToWorkboard:"Zaloguj się do swojego konta",
      passwordInputNewPassword:"Wprowadź nowe hasło",
      passwordResetedPasswordCompleted:"Twoje hasło zostało pomyślnie zmienione!",
      passwordResetPassword:"Odzyskiwanie hasła",
      passwordResetPasswordHeader:"Odzyskiwanie hasła",
      passwordWeSentReset:"Link do resetowania hasła został wysłany pocztą e-mail.",
      paymentAllTarifInactive:"taryfa jest nieaktywna",
      paymentPlanRenew:"Data odnowienia taryfy:",
      paymentPlanLeadsLeft:"Pozostało leadów:",
      paymentPlanLeadsLimit:"Limit leadów na miesiąc:",
      paymentPlanLeadsLimitForTest:"Twój limit leadów testowych:",
      paymentPlanPrice:"Cena:",
      paymentSwitchToProDescription:"Znajdź plan odpowiedni dla siebie! Uaktualnij lub obniż plan w dowolnym momencie - ułatwiamy wybór odpowiedniego planu dla Ciebie.",
      paymentSwitchToProHeader:"Aktualizacja do Pro",
      paymentYourCurrentPlan:"Twój obecny plan:",
      paymentYourFuturePlan:"Twój nowy plan:",
      pearlescentGentianBlue:"Sapphire",
      periwinkleCrayolaAqua:"Cynevir",
      periwinkleCrayolaLilac:"Liliowy",
      placeholderText:"Wprowadź tutaj",
      poperBillings:"Płatności",
      poperLogout:"Wylogować się",
      poperPartnership:"Program partnerski",
      poperProfile:"Profil",
      poperTemplates:"Szablony",
      poperTrashcan:"Koszyk",
      profileChangePassword:"Zmiana hasła",
      profileDeleteAccount:"Usuń konto",
      profileDeleteAccountCheckedWord:"USUŃ",
      profileDeleteAccountPopupDescription:'Czy na pewno chcesz usunąć swoje konto? Tej czynności nie można anulować. Aby potwierdzić usunięcie, wpisz słowo "USUŃ" w polu poniżej.',
      profileDeleteAccountPopupHeader:"Chcesz usunąć swoje konto?",
      profileDeleteAccountPopupPlaceholder:"Wpisz tutaj",
      profileEmail:"E-mail",
      profileEmailCurent:"Aktualny adres e-mail",
      profileEmailNew:"Nowy adres e-mail",
      profileName:"Imię",
      profileNameFirst:"Imię",
      profileNameLast:"Nazwisko",
      profileNewPassword:"Nowe hasło",
      profileNewPasswordConfirm:"Potwierdź nowe hasło",
      profilePassword:"Hasło",
      profilePhone:"Numer telefonu",
      profileSaveNewData:"Zaktualizuj dane",
      profileStatus:"Status",
      profileStatusAgency:"Agencja",
      profileStatusBuisines:"Właściciel firmy",
      profileStatusFreelancer:"Freelancer",
      profileStatusPlaceholder:"Wybierz to, co najlepiej Cię opisuje",
      questionLogicAddConditionBtn:"Dodaj warunek",
      questionLogicAllMandatory:"Wszystkie poniższe warunki są wymagane",
      questionLogicConditionTitle:"Warunek.",
      questionLogicNotAvailable:"Ustawienia logiki nie są dostępne dla pierwszego pytania",
      questionLogicQuestion:"Pytanie.",
      questionLogicSaved:"Ustawienia logiki wyświetlania kroków są zapisane",
      questionLogicSetupAnswer:"Odpowiedź.",
      questionLogicSetupAnswerDisableOption:"Wybierz typ warunku",
      questionLogicSetupCustomContains:"Zawiera",
      questionLogicSetupCustomEqual:"Jest równy",
      questionLogicSetupCustomNotContains:"Nie zawiera",
      questionLogicSetupDescription:"Dodaj warunki, aby dostosować ankietę do każdego respondenta i zebrać dokładniejsze dane.",
      questionLogicSetupExpectedAnswer:"Oczekiwane odpowiedzi",
      questionLogicSetupLogicType:"Typ.",
      questionLogicSetupPlaceholder:"Wpisz tutaj",
      questionLogicSetupQuestionDisableOption:"Wybierz pytanie",
      questionLogicSetupRangeBetween:"Pomiędzy",
      questionLogicSetupRangeEqualTo:"Równy",
      questionLogicSetupRangeFrom:"Od",
      questionLogicSetupRangeLess:"Mniej niż",
      questionLogicSetupRangeMore:"Więcej niż",
      questionLogicSetupRangeTo:"Do",
      questionLogicSetupRangeValue:"Wartość",
      questionLogicSetupTitle:"Konfigurowanie logiki pytań",
      questionLogicSetupVariantAll:"Wszystkie",
      questionLogicSetupVariantAny:"Wybrana jest jedna z poniższych wartości",
      questionLogicSetupVariantNot:"Żadne z wybranych",
      quizSlotDeletedData:"Usunięto",
      quizSlotLastEdit:"Ostatnia zmiana",
      QuizTemplateSelect:"Wybierz",
      QuizTemplatesReadyTemplates:"Gotowe szablony",
      QuizTemplatesSearchBtn:"Wyszukiwanie",
      QuizTemplatesSearchInput:"Nazwa szablonu i/lub kategorii",
      QuizTemplateView:"Podgląd",
      quizzesTemplatesAllTags:"Wszystkie szablony",
      rangeIsRange:"Zakres",
      rangeIsRangeFalse:"Wyłączone",
      rangeIsRangeTrue:"Włączone",
      rangeMax:"Maks.",
      rangeMin:"Min",
      rangePositionEnd:"Pozycja końcowa",
      rangePositionStart:"Pozycja początkowa",
      rangeStep:"Krok",
      reciveEmailCheckbox:"Pole wyboru odbierania wiadomości e-mail",
      recivePromotionNewsletter:"Otrzymywanie wiadomości e-mail z newsletterem",
      resetPasswordTip:"W przypadku dalszych wątpliwości lub pytań prosimy o kontakt z naszym zespołem obsługi klienta. Jesteśmy tutaj, aby pomóc.",
      restoreQuizSendedDescription:"W zależności od liczby leadów w quizie, przywrócenie quizu może potrwać od kilku sekund do 2 minut. Po przywróceniu lead pojawi się na Workboardzie.",
      restoreQuizSendedTitle:"Zadanie odzyskiwania jest w toku",
      restoreSuizSendedImportant:"Ważne",
      sapphireBlue:"Ciemny szafir",
      saveMoney:"Abonament na rok",
      secondReduction:"sek",
      selectInternationalPrefix:"Wybierz format międzynarodowy",
      selectOption:"Wybierz opcję",
      selectQuizLanguage:"Wybierz język quizu",
      signupAgreeAnd:"i",
      signupAgreeIAgreeWith:"Zgadzam się z",
      signupAgreeOffer:"oferta publiczna",
      signupAgreePolicy:"polityka prywatności",
      signupAgreeService:"serwisu",
      signupBtnValue:"Utwórz konto",
      signupEmailPlaceholder:"E-mail",
      signupHeader:"Zarejestruj konto",
      signupHeader2Part:"aby kontynuować",
      signupLogin:"Zaloguj się do mojego konta",
      signupNamePlaceholder:"Alexander",
      signupPasswordlPlaceholder:"○ ○ ○ ○ ○ ○ ○ ○ ○",
      signupPhonePlaceholder:"numer telefonu",
      signupTariffFree:"Bezpłatnie.",
      signupTariffFreeDescription:"Nieograniczone tworzenie nowych quizów (stron internetowych). Możliwość wygenerowania do 300 leadów miesięcznie za darmo.",
      signupTariffHeader:"Taryfy",
      signupTariffPro:"Pro.",
      signupTariffProDescription:"Nieograniczone tworzenie nowych quizów (stron internetowych). Możliwość wygenerowania ",
      signupTariffProDescriptionBold:"ponad 300 leadów.",
      signupTariffProPrice:"$20 / miesiąc",
      signupWith:"Zarejestruj się w",
      slateGrayBlue:"Mgla",
      subscriptionCanceledTitle:"Subskrypcja została anulowana",
      subscriptionIsNotDeleteDescription:"Prosimy o kontakt z obsługą klienta",
      subscriptionIsNotDeleteTitle:"Subskrypcja nie została anulowana",
      subscriptionTariffLable:"Zapisz się na naszą automatyczną subskrypcję.",
      subscriptionUnsubscribeModalDescription:"Czy na pewno chcesz zrezygnować z subskrypcji? Leady zgromadzone w ramach subskrypcji będzie można wykorzystać w ciągu miesiąca od daty ostatniego rozliczenia.",
      subscriptionUnsubscribeModalTitle:"Rezygnacja z subskrypcji",
      telegramTokenCopied:"Token quizu został skopiowany do schowka",
      templatesBackToQuiz:"Powrót do Workboard",
      temporarilyUnavailable:"Tymczasowo niedostępne",
      trashcanClearAll:"Wyczyść wszystko",
      trashcanDescription:"Quiz zostanie automatycznie usunięty na stałe w ciągu 30 dni po przeniesieniu go do koszyka.",
      trashcanImportant:"Ważne!",
      trashcanTitle:"Koszyk",
      turquoiseBlueCrayola:"Działanie",
      upProVersion:"Przełącz na PRO",
      upProVersionDesktop:"Przełącz na PRO",
      upProVersionMobile:"Do PRO",
      userAccountInfo:"Informacje o koncie",
      userBackToQuiz:"Powrót do Workboard",
      userBackToSettings:"Powrót do ustawień",
      userBillingsCost:"Koszt",
      userBillingsDate:"Data",
      userBillingsId:"ID",
      userBillingsMethod:"Metoda płatności",
      userGeneralInfoDescription1:"Profil użytkownika umożliwia zarządzanie kontem i ustawieniami.",
      userGeneralInfoDescription2:"Zaktualizuj swoje dane osobowe, zmień hasło i dostosuj informacje o powiadomieniach.",
      userGeneralInfoHeader:"Informacje ogólne",
      userSecuritySettingsDescription:"Aby zmienić hasło, wystarczy dwukrotnie wprowadzić nowe hasło w celu potwierdzenia.",
      userSecuritySettingsHeader:"Ustawienia zabezpieczeń",
      videoInstruction:"Wideo instrukcja ",
      violetBlueCrayola:"Zimowa bryza",
      welcomeBack:"Witamy ponownie!",
      wetRainforest:"Letni klon",
      widgetButtonBgColor:"Kolor przycisku",
      widgetButtonCornerRound:"Zaokrąglenie przycisku",
      widgetButtonInsertBeforeAfterBody:"Wstaw kod quizu tuż po tagu otwierającym `<body>` lub tuż przed tagiem zamykającym `</body>`.",
      widgetButtonInstruction0:"Możesz umieścić quiz na swojej stronie internetowej, który zostanie otwarty po kliknięciu przycisku.",
      widgetButtonInstruction1:"Dodaj zaokrąglenie, cień i tekst przycisku. Możesz także zmienić kolor przycisku i tekstu wewnątrz.",
      widgetButtonInstruction2:"Skopiuj skrypt znajdujący się w pierwszym polu kodu i wklej go przed tagiem zamykającym `</head>`.",
      widgetButtonInstruction3:"Skopiuj wygenerowany kod przycisku z drugiego pola kodu i wklej go w dowolnym miejscu na swojej stronie internetowej.",
      widgetButtonInstruction4:'Możesz pominąć kod z drugiego pola przycisku i po prostu dodać `id="button_open_quiz_popup"` do dowolnego elementu `<input type="button">` lub `<button></button>` na stronie internetowej.',
      widgetButtonShadow:"Cień przycisku",
      widgetButtonTextColor:"Kolor tekstu",
      widgetButtonTextValue:"Tekst przycisku",
      widgetEmbedVidgetButton:"Konstruktor przycisku",
      widgetEmbedVidgetChat:"Przycisk quizu",
      widgetEmbedVidgetInProgres:"W opracowaniu",
      widgetEmbedVidgetPopup:"Wyskakujące okienko",
      widgetEmbedVidgetSelectEmbedVariant:"Wybierz opcję integracji",
      widgetEmbedVidgetSitecode:"W kodzie strony",
      widgetEmbedVidgetToSite:"Umieszczenie quizu na stronie internetowej",
      widgetEmbedVidgetToSiteCode:"Umieszczenie quizu na stronie internetowej",
      widgetPopupCopyToBody:"Skopiuj i wklej kod przed tagiem zamykającym `</footer>`.",
      widgetPopupCopyToHead:"Skopiuj i wklej do `<head>` swojej strony internetowej.",
      widgetPopupInstruction0:"Aby umieścić quiz na swojej stronie internetowej, dodaj kod z pola kodu do tagu `<head>` na swojej stronie internetowej lub do Google Tag Manager, jeśli jest on zintegrowany z Twoją stroną internetową. Jeśli zmienisz domenę quizu lub open triggers (procent przewijania lub timing) po umieszczeniu quizu na swojej stronie internetowej, będziesz musiał zaktualizować kod integracji na swojej stronie internetowej.",
      widgetPopupInstruction1:"Tuż przed zamknięciem tagu `</footer>' wklej kod z drugiego pola.",
      widgetPopupInstruction2:"Dla programistów lub zaawansowanych użytkowników",
      widgetPopupInstruction3:"Jeśli z jakiegoś powodu nie czujesz się komfortowo z umieszczaniem quizu przed zamykającym tagiem `</footer>`, możesz wstawić kod z drugiego pola w dowolnym dogodnym miejscu, ale pamiętaj, że kontener, w którym wstawiasz kod z drugiego pola, powinien znajdować się na górze wszystkich okien i, najlepiej, powinien mieć maksymalną możliwą wysokość i szerokość wyświetlania.",
      widgetPopupScrollClarification:"Wyskakujące okienko pojawi się po określonym powyżej procencie przewijania strony internetowej",
      widgetPopupScrollToOpen:"Procent przewijania strony internetowej",
      widgetPopupTimeClarification:"Wyskakujące okienko pojawi się po upływie powyższego zaznaczonego czasu w sekundach",
      widgetPopupTimeToOpen:"Przedział czasu",
      widgetRealTimeChange:"Możesz zobaczyć zmiany w czasie rzeczywistym po prawej stronie ekranu.",
      widgetSiteCodeCopyButton:"Wklej kod przycisku w dowolnym miejscu na swojej stronie internetowej ",
      widgetSiteCodeCopyToBody:"Wklej kod w miejscu, w którym chcesz umieścić przycisk",
      widgetSiteCodeCopyToHead:"Skopiuj i wklej do `<head>` swojej strony internetowej.",
      widgetSiteCodeInstruction0:"Aby umieścić quiz na swojej stronie internetowej, dodaj kod z pola kodu do tagu `<head>` na swojej stronie internetowej lub do Google Tag Manager, jeśli jest on zintegrowany z Twoją stroną internetową. Jeśli zmienisz domenę quizu na swojej stronie internetowej, będziesz musiał zaktualizować kod integracji na swojej stronie internetowej.",
      widgetSiteCodeInstruction1:"Wklej kod z drugiego pola w miejscu, w którym chcesz zintegrować quiz.",
      widgetSiteCodeInstruction2:"Quiz można zintegrować z kontenerem o dowolnym rozmiarze, nawet z sidebarem! Maksymalny rozmiar quizu, jak również w przypadku uruchomienia w oddzielnej domenie, wynosi 900x552 pikseli. Można go dodać do kontenerów o większym rozmiarze, w tym przypadku quiz zostanie wyśrodkowany.",
      widgetSiteCodeInstruction3:"Nie wahaj się eksperymentować!",
      workboardAddNewQuiz:"Stwórz projekt",
      workboardAlertGiveAnyName:"Wprowadź przynajmniej nazwę",
      workboardAlertNextTimeAddQuiz:"Cóż, następnym razem odważ się:)",
      workboardChangeQuizName:"Zmień nazwę",
      workboardCopyQuiz:"Skopiować cytat",
      workboardDeleteQuiz:"Usuń",
      workboardDuplicate:"Duplikować",
      workboardEditQuiz:"Edytuj",
      workboardLeads:"Leady",
      workboardModalAddQuizHeader:"Stwórz nowy quiz",
      workboardModalDeleteQuizCancel:"Anuluj",
      workboardModalDeleteQuizDelete:"Usuń",
      workboardModalRemoveQuizTrash: "Ten quiz zostanie przeniesiony do kosza i trwale usunięty po 1 miesiącu.",
      workboardModalRemoveQuizSure: "Czy na pewno chcesz przenieść ten quiz do kosza?",
      workboardModalDeleteQuizSure:"Czy na pewno chcesz usunąć ten quiz?",
      workboardModalGiveQuizName:"Nadaj quizu nazwę",
      workboardModalGiveQuizNameAndMethod:"Wymyśl nazwę dla swojego quizu i wybierz preferowaną metodę tworzenia.",
      workboardModalLeadsAnswersCount:"Odpowiedzi na zapytania",
      workboardModalLeadsHaveNotSended:"Masz nieodebrane leady",
      workboardModalLeadsLeadActions:"Działania",
      workboardModalLeadsLeadAnswerScipped:"Nieodebrane",
      workboardModalLeadsLeadDate:"Data",
      workboardModalLeadsLeadEmail:"E-mail",
      workboardModalLeadsLeadEmailCopied:"Email skopiowany do bufora",
      workboardModalLeadsLeadHideMore:"Ukryć",
      workboardModalLeadsLeadLimit:"Osiągnięto limit leadów",
      workboardModalLeadsLeadLimitInstruction1:"Aby zobaczyć leadów,",
      workboardModalLeadsLeadLimitInstruction2:"przełącz się na inny plan",
      workboardModalLeadsLeadLimitInstruction3:"uaktualnić bieżący plan",
      workboardModalLeadsLeadLimitInstruction4:'(nie dotyczy "Test") lub poczekaj na aktualizację planu, jeśli masz włączone automatyczne odnawianie planu.',
      workboardModalLeadsLeadName:"Nazwa",
      workboardModalLeadsLeadPhone:"Numer telefonu",
      workboardModalLeadsLeadPhoneCopied:"Numer telefonu jest kopiowany do bufora",
      workboardModalLeadsLeadReferrer:"Niezdefiniowany",
      workboardModalLeadsLeadReferrerClarification:"Dane źródła przełączenia są zablokowane lub niezdefiniowane",
      workboardModalLeadsLeadReload:"Odbierz",
      workboardModalLeadsLeadReloaded:"Jeśli pozostały jakieś leady, nieodebrane leady zostaną załadowane na górze listy. Zostaną one również wysłane do dodanych integracji",
      workboardModalLeadsLeadReloadedTitle:"Wniosek został przyjęty",
      workboardModalLeadsLeadSerial:"№",
      workboardModalLeadsLeadShowMore:"Więcej",
      workboardModalNewQuizBtnValue:"dodać",
      workboardModalNewQuizFromStart:"Stwórz od 0",
      workboardModalNewQuizFromTemplates:"Gotowe szablony",
      workboardModalNoLeads:"Nie ma jeszcze żadnych leadów",
      workboardModalQuizDeleteOk:"OK",
      workboardModalQuizNamePlaceholder:"Nowy quiz",
      workboardQuizCopied:"Quiz został pomyślnie skopiowany",
      workboardQuizLink:"link",
      workboardQuizLinkCopied:"Link został skopiowany do bufora",
      workboardQuizzesTitle:"Moje quizy",
      workboardStatsTitle:"Statystyki",
      youCanChangePlan:'Możesz zmienić swój obecny plan, klikając przycisk "Przełącz na PRO"',




      csettingsIntegrationSendPulse: "Integracja - Send Pulse",
      csettingsIntegrationMake: "Integracja - Make",
      csettingsIntegrationZapier: "Integracja - Zapier",
      csettingsIntegrationCRMBitrix: "Integracja - CRM Bitrix",
      csettingsIntegrationPipeDrive: "Integracja - Pipe Drive",
      csettingsIntegrationRemOnline: "Integracja - Rem Online",
      csettingsIntegrationKeyCRM: "Integracja - Key CRM",
      csettingsIntegrationZapierAbout: "Wklej adres URL wysyłania danych dostarczony przez Zapier do pola wejściowego.",
      csettingsIntegrationMakeAbout: "Wklej adres URL wysyłania danych dostarczony przez Make do pola wejściowego.",
      csettingsIntegrationMakeAboutTextStep1: "Aby skonfigurować integrację z",
      csettingsIntegrationMakeAboutTextStep2: ", obejrzyj",
      csettingsIntegrationMakeAboutTextStep3: "Aby ją skonfigurować, wybierz “Webhook” spośród integracji w Make, a następnie “Webhook response”.",
      csettingsIntegrationMakeAboutTextStep4: "Skopiuj wygenerowany link i wklej go w polu poniżej w ADS Quiz.",
      csettingsIntegrationMakeAboutTextStep5: "Aby przetestować, wróć do Make, kliknij “Run” i wypełnij quiz, wysyłając zgłoszenie.",
      csettingsIntegrationMakeAboutTextStep6: "Jeśli test zakończy się pomyślnie, zapisz integrację i nie zapomnij aktywować aktualizacji.",
      csettingsIntegrationMakeAboutLink: "https://www.make.com/en/register?pc=adschool",
      csettingsIntegrationMakeAboutLinkVideo: "https://www.youtube.com/embed/3ZyagaBQmiI",
      csettingsIntegrationMakeAboutLinkVideoText: "wideo",
      csettingsIntegrationCRMBitrixAbout: "Wklej adres URL wysyłania danych dostarczony przez CRM Bitrix do pola wejściowego.",

      csettingsIntegrationSendPulseAboutStart: "Możesz skonfigurować integrację z usługą ",
      csettingsIntegrationSendPulseAboutMiddle: ". Aby to zrobić, przejdź do ustawień swojego konta i otwórz zakładkę ",
      csettingsIntegrationSendPulseAboutEnd: ". Skopiuj wartości parametrów 'ID' i 'Secret', a następnie wklej je w odpowiednie pola w usłudze ADS Quiz (w pola poniżej). Następnie musisz wkleić ID książki adresowej. Aby to zrobić, przejdź do odpowiedniej książki adresowej i skopiuj z adresu URL strony wartość po id/. Potrzebne są tylko cyfry, bez dodatkowych znaków. Zapisz zmiany. Bardziej szczegółowa instrukcja znajduje się w ",
      csettingsIntegrationSendPulseAboutLinkSendPulse: "https://sendpulse.com/en/#60c9ccfd55f4b",
      csettingsIntegrationSendPulseAboutLinkAPI: "https://login.sendpulse.com/login/#api",
      csettingsIntegrationSendPulseAboutLinkVideo: "https://www.youtube.com/watch?v=4mnhpbKkePs",
      csettingsIntegrationSendPulseAboutLinkVideoText: "wideo",

      csettingsIntegrationKeyCRMAbout: "Wklej klucz API i identyfikator potoku do odpowiednich pól wejściowych.",
      csettingsIntegrationPipeDriveAbout: "Wklej token i domenę firmy do odpowiednich pól wejściowych.",
      csettingsIntegrationRemOnlineAbout: "Wklej klucz API Rem Online do odpowiedniego pola wejściowego.",
      csettingsIntegrationActiveIntegration: "Aktywna integracja",
      cintegrationIntegrationData: "Dane integracji",
      cintegrationIntegrationUrl: "URL integracji",
      cintegrationWriteUrl: "Wprowadź URL",
      cintegrationDataFBError: "Nieprawidłowy identyfikator piksela",
      cintegrationDataWebHookError: "Nieprawidłowy adres webhooka",
      cintegrationZapierDataSaved: "Dane integracji Zapier zapisane",
      cintegrationDataZapierError: "Nieprawidłowy adres integracji",
      cintegrationAPIxDriveDataSaved: "Dane integracji APIxDrive zapisane",
      cintegrationDataAPIxDriveError: "Ciąg nie jest adresem integracji APIxDrive",
      cintegrationMakeDataSaved: "Dane integracji Make zapisane",
      cintegrationDataMakeError: "Nieprawidłowy adres integracji",
      cintegrationSendPulseDataSaved: "Dane integracji SendPulse zapisane",
      cintegrationDataSendPulseError: "Nieprawidłowe lub puste dane integracji",
      cintegrationPipeDriveDataSaved: "Dane integracji PipeDrive zapisane",
      cintegrationDataPipeDriveError: "Nieprawidłowe lub puste dane integracji",
      cintegrationRemOnlineDataSaved: "Dane integracji RemOnline zapisane",
      cintegrationDataRemOnlineError: "Nieprawidłowe lub puste dane integracji",
      cintegrationKeyCRMDataSaved: "Dane integracji KeyCRM zapisane",
      cintegrationDataKeyCRMError: "Nieprawidłowe lub puste dane integracji",
      cintegrationInputEmailUrl: "Wprowadź e-mail",
      cintegrationEmailDataSaved: "E-mail zapisany",
      cintegrationDataEmailError: "Wprowadzony ciąg nie jest adresem e-mail",
      cintegrationCRMBitrixDataSaved: "E-mail zapisany",
      cintegrationDataCRMBitrixError: "Wprowadzony ciąg nie jest adresem e-mail",
      cintegrationTelegramBotDataSaved: "Ustawienia integracji z Telegram zapisane",
      cintegrationDataTelegramBotError: "Błąd zapisu",
      cintegrationGTMDataSaved: "Zapisany identyfikator Google Tag Manager",
      cintegrationDataGTMError: "Nieprawidłowy identyfikator",
      cintegrationDataGTMErrorDescription: "Identyfikator powinien mieć format GTM-XXXXXX",
      cintegrationGoogleAnalyticsDataSaved: "Zapisany identyfikator Google Analytics",
      cintegrationDataGoogleAnalyticsError: "Nieprawidłowy identyfikator",
      cintegrationDataGoogleAnalyticsErrorDescription: 'Identyfikator powinien mieć format "UA-XXXXXXX-X" lub "G-XXXXXXXXXX-XX"',
      cintegrationTikTokDataSaved: "Piksel Tik-Tok zapisany",
      cintegrationDataTikTokError: "Wprowadzony ciąg nie jest pikselem Tik-Tok",
      cintegrationIntegrationToken: "Token integracji",
      cintegrationWriteToken: "Wprowadź token integracji",
      cintegrationDataYourAPIKey: "Wprowadź swój klucz API",
      cintegrationDataLeadTypeId: "Wprowadź identyfikator kanału",
      send_utm: "Wyślij znaczniki UTM",
      send_quiz: "Wyślij dane quizu",
      send_answers: "Wyślij odpowiedzi",
      csettingsIntegrationKeyCrm: "Integracja KeyCRM",
      cintegrationSendPulseClientId: "Identyfikator klienta",
      cintegrationSendPulseClientSecret: "Sekret",
      cintegrationSendPulseAddressBookId: "Identyfikator książki adresowej",
      cintegrationSendPulseAction: "Działanie z kontaktem",
      sendpulseActionAddContact: "Dodaj kontakt",
      sendpulseActionDeleteContact: "Usuń kontakt",
      cintegrationPipeDriveToken: "Token API",
      cintegrationPipeDriveDomain: "Twój unikalny subdomena",
      cintegrationKeyCrmApiKey: "Klucz API",
      cintegrationKeyCrmPipelineId: "Identyfikator potoku",
      cintegrationMenuGoogleTagManager:"Menadżer tagów Google",
      cintegrationMenuGoogleAnalytics:"Google Analytics",
      cintegrationMenuFacebookPixel:"Piksel Facebooka",
      cintegrationMenuTikTokPixel:"Piksel TikTok",
      cintegrationMenuWebHook:"Webhook",
      cintegrationMenuZapier:"Zapier",
      cintegrationMenuAPIxDrive:"APIx Drive",
      cintegrationMenuMake:"Make",
      cintegrationMenuSendPulse:"Send Pulse",
      cintegrationMenuEmail:"E-mail",
      cintegrationMenuTelegramBot:"Bot Telegram",
      cintegrationMenuPipeDrive:"PipeDrive",
      cintegrationMenuRemOnline:"Rem Online",
      cintegrationMenuKeyCRM:"Key CRM",
      cintegrationMenuCRMBitrix:"CRM Bitrix",
      trashcanRestoreQuiz: "Przywróć quiz",
      cformValuePromotionAccepted: "Zgadzam się na newsletter",


      constructorAddIntegration: "Dodaj nową integrację",
      cintegrationKeyCrmSourceId: "Identyfikator źródła (opcjonalnie)",
      cintegrationKeyCrmManagerId: "Identyfikator menedżera (opcjonalnie)",
      cSettingsSettingQuizSpamFilterTitle: "Ochrona przed spamem",
      cSettingsSettingQuizSpamFilterDescription: "Funkcja jest domyślnie wyłączona. Jeśli napotkasz problem spamowych leadów, włącz ochronę. Po tym jeden użytkownik nie będzie mógł przesłać więcej niż określona liczba leadów dla tego quizu.",
      cSettingsSettingQuizSpamFilterLimitPlaceholder: "Liczba leadów",
      langNO: "Norweski",
      loadMoreLeads: "Zobacz więcej leadów",
      userAdditionalSettingsHeader: "Zaawansowane ustawienia",
      userAdditionalSettingsDescription1: "Tutaj możesz skonfigurować nowe zaawansowane ustawienia profilu.",
      userAdditionalSettingsPagination: "Paginacja na pulpicie roboczym",

      benefitType: "Typ bonusu",
      benefitLabelPlaceholder: "Tekst bonusu",
      benefitDescriptionPlaceholderDiscount: "Kod promocyjny lub instrukcja",
      benefitDescriptionPlaceholderVideo: "Link do wideo",
      benefitDescriptionPlaceholderTextfile: "Link do pliku tekstowego",
      benefitDescriptionPlaceholderGift: "Opis bonusu",
      benefitDescriptionPlaceholderPDF: "Link do PDF",
      benefitDescriptionPlaceholderLink: "Link",
      benefitDescriptionPlaceholderFile: "Link do pliku",
      benefitLabelDiscount: "Zniżka",
      benefitLabelVideo: "Wideo",
      benefitLabelTextfile: "Plik tekstowy",
      benefitLabelGift: "Prezent",
      benefitLabelPDF: "Plik PDF",
      benefitLabelLink: "Link",
      benefitLabelFile: "Plik",
      addBenefit: "Dodaj bonus",
      saveBenefit: "Zapisz bonus",
      cfinishSocialNetworks: "Media społecznościowe",
      
      saveSocialNet: "Zapisz sieć społecznościową",
      networkLabelFacebook: "Facebook",
      networkLabelInstagram: "Instagram",
      networkLabelTelegram: "Telegram",
      networkLabelTiktok: "Tik-Tok",
      networkLabelYoutube: "Youtube",
      networkLabelLinkedin: "LinkedIn",
      networkLabelWhatsapp: "WhatsApp",
      socialNetDescriptionPlaceholderFacebook: "nazwa_profilu",
      socialNetDescriptionPlaceholderInstagram: "nazwa_profilu",
      socialNetDescriptionPlaceholderTelegram: "nazwa_profilu/kanalu",
      socialNetDescriptionPlaceholderTiktok: "nazwa_profilu",
      socialNetDescriptionPlaceholderYoutube: "nazwa_kanalu",
      socialNetDescriptionPlaceholderLinkedin: '"in/name" lub "company/name"',
      socialNetDescriptionPlaceholderWhatsapp: "+15555551234",

      addNewSectionEmptyPage: "Strona tekstowa",
      csanyMakeImpasse: "Zrób ślepy zaułek",
      impaseIsImpaseTrue: "Włączony",
      impaseIsImpaseFalse: "Wyłączony",
      defaultNewEmptyQuestionDescription: "Tutaj możesz wklejać i formatować długi tekst (nie zalecamy wklejania tekstu dłuższego niż 3000 znaków).",
      cSettingsSettingQuizTimezoneTitle: "Strefa czasowa quizu",
      cSettingsSettingQuizTimezoneDescription: "Wprowadź strefę czasową, według której będzie rejestrowany czas wypełniania leadów. Jest to szczególnie przydatne, gdy Ty znajdujesz się w jednej strefie czasowej, a klient w innej. W takim przypadku możesz ustawić strefę czasową klienta, a w leadach, które otrzymasz, czas będzie podawany zgodnie z jego strefą czasową.",
      cSettingsSettingQuizTimezonePlaceholder: "Strefa czasowa",
      userAdditionalSettingsTimezone: "Strefa czasowa użytkownika",
      userAdditionalSettingsTimezonePlaceholder: "Strefa czasowa",
      langHE: "Hebrajski",
      langCS: "Czeski",
      cintegrationCAPIFBInstruction: "Aby skonfigurować integrację z Facebook Conversion API, należy wprowadzić identyfikator piksela i token dostępu. Te dane można uzyskać w ustawieniach Facebook Business Manager. Po wprowadzeniu wymaganych informacji zapisz integrację.",
      cintegrationCAPIFBInstructionTextTitle: "Nazwy zdarzeń dla FB Conversions:",
      cintegrationCAPIFBInstructionTextStart: " – odwiedziny strony startowej;",
      cintegrationCAPIFBInstructionTextQuestionN: " – odwiedziny kroku N (numer kroku jest wstawiany dynamicznie);",
      cintegrationCAPIFBInstructionTextOpenContactPage: " – odwiedziny strony kontaktowej;",
      cintegrationCAPIFBInstructionTextLead: " – pomyślne wysłanie formularza kontaktowego;",
      cintegrationCAPIFBInstructionTextThankYou: " – odwiedziny strony końcowej.",
      cintegrationCAPIFBInstructionTextOptimizationAdvice: "Zalecamy uruchamianie reklam zoptymalizowanych pod zdarzenie",
      cintegrationCAPIFBInstructionTextLeadTriggerCondition: "To zdarzenie jest wysyłane",
      bold_capi_adsquiz_Start: "capi_adsquiz_Start",
      bold_capi_adsquiz_QuestionN: "capi_adsquiz_Question-N",
      bold_capi_adsquiz_OpenContactPage: "capi_adsquiz_OpenContactPage",
      bold_capi_adsquiz_Lead: "capi_adsquiz_Lead",
      bold_capi_adsquiz_ThankYou: "capi_adsquiz_ThankYou",
      bold_capi_adsquiz_OptimizationAdvice: "“capi_adsquiz_Lead”.",
      bold_capi_adsquiz_LeadTriggerCondition: " tylko wtedy, gdy użytkownik pomyślnie prześle zgłoszenie.",
      csettingsIntegrationFbCapiAbout: "Integracja z Facebook Conversion API umożliwia przesyłanie danych o interakcjach użytkowników bezpośrednio do Facebooka w celu zwiększenia skuteczności kampanii reklamowych.",
      cintegrationInputCapiAccessToken: "Wprowadź token dostępu",
      cintegrationInputCapiCustomparameter: "Wprowadź niestandardowe parametry zdarzeń",
      cintegrationDataFbCapiSaved: "Integracja z Facebook Conversion API została pomyślnie zapisana",
      cintegrationComonRenovateHubInstructionTitle: "Instrukcja konfiguracji integracji z RenovateHub",
      cintegrationComonRenovateHubInstructionDescription: "Aby skonfigurować integrację z RenovateHub, podaj URL webhooka, na który będą przesyłane dane. Upewnij się, że URL jest poprawny i dostępny do odbierania danych.",
      cintegrationComonRenovateHubInstructionShort: "Integracja z RenovateHub pozwala na przesyłanie danych do CRM przez webhook w celu automatyzacji procesów.",
      cintegrationRenovateHubUrl: "Wprowadź URL RenovateHub",
      cintegrationInputRenovateHubUrl: "Podaj URL webhooka dla RenovateHub",
      cintegrationRenovateHubDataSaved: "Integracja z RenovateHub została pomyślnie zapisana",
      cintegrationDataRenovateHubError: "Błąd podczas zapisywania integracji z RenovateHub. Sprawdź poprawność wprowadzonych danych.",
      cintegrationMenuFacebookConvAPI: "Facebook Conversions API",
      cintegrationMenuRenovateHub: "RenovateHub",
      csettingsIntegrationRenovateHub: "Integracja RenovateHub",
      csettingsIntegrationCAPIFB: "Integracja Facebook Conversions API",
      paymentPlanEnd: "Data wygaśnięcia taryfy",
      paymentPlanLeadsLimitForYear:"Limit leadów na rok:",
      paymentPlanLeadsLimitForPeriod:"Limit leadów na okres:",
      EditTags: "Edytuj tagi",
      quizzesTemplatesNoTags: "Brak tagów",

      Monday: "Poniedziałek",
      Tuesday: "Wtorek",
      Wednesday: "Środa",
      Thursday: "Czwartek",
      Friday: "Piątek",
      Saturday: "Sobota",
      Sunday: "Niedziela",
      datetimeDisplayMode: "Typ wybierania",
      datetimeDisplayModeSelect: "Wybierz typ",
      datetimeDisplayModeDate: "Data",
      datetimeDisplayModeTime: "Godzina",
      datetimeDisplayModeDatetime: "Data i godzina",
      datetimeMinDate: "Min. data",
      datetimeMinDateCustom: "Wybierz min. datę",
      datetimeMaxDate: "Maks. data",
      datetimeMaxDateCustom: "Wybierz maks. datę",
      datetimeProhibitDaysOfWeek: "Zablokuj dni",
      datetimeIsRange: "Włącz zakres",
      datetimeIsRangeTrue: "Zakres włączony",
      datetimeIsRangeFalse: "Zakres wyłączony",
      datetimeMinTime: "Min. godzina",
      datetimeMaxTime: "Maks. godzina",
      datetimeIs24: "Format 24 godz.",
      datetimeIs24True: "24 godz. włączone",
      datetimeIs24False: "24 godz. wyłączone",
      csanyIsExactDate: "Jak chcesz ustawić datę",
      csanyIsExactDateTrue: "Dokładna data",
      csanyIsExactDateFalse: "Data względna",
      csanyDatetimeMinMaxNotExactPlaceholder: '"x" lub "-x" dni od dzisiaj',
      addNewSectionDatetime: "Data/godzina",
      tariffsPromo: "Oferty promocyjne",
      tariffsMonth: "Miesięczny",
      tariffsAnual: "Rocznie",
      leadsOn1Month: "na miesiąc",
      leadsOn2Month: "na 2 miesiące",
      leadsOn3Month: "na 3 miesiące",
      leadsOn4Month: "na 4 miesiące",
      leadsOn5Month: "na 5 miesięcy",
      leadsOn6Month: "na 6 miesięcy",
      leadsOn7Month: "na 7 miesięcy",
      leadsOn8Month: "na 8 miesięcy",
      leadsOn9Month: "na 9 miesięcy",
      leadsOn10Month: "na 10 miesięcy",
      leadsOn11Month: "na 11 miesięcy",
      leadsOn12Month: "na rok",
      leadsOn24Month: "na 2 lata",
      leads: "leadów",
      tariffsWordPrice: "Cena",
      tariffsNoLimits: "Brak ograniczeń",
      tariffsNoLimitsQuizzesAndFunctions: "dotyczących quizów i funkcjonalności",
      billingTariffYear: "rok",
      billingChangePlanSubscribe: "Zapisać się",
      paymentAllTariffInactive: "Taryfa nie jest aktywna",
      subscriptionTariffLableOnly: "Dostępne wyłącznie w ramach subskrypcji",
      notExactBeforeYear: "rok temu",
      notExactBeforeHalfYear: "pół roku temu",
      notExactBeforeQuarter: "kwartał temu",
      notExactBeforeMonth: "miesiąc temu",
      notExactBeforeWeek: "tydzień temu",
      notExactBeforeYesterday: "przedwczoraj",
      notExactYesterday: "wczoraj",
      notExactToday: "dzisiaj",
      notExactTomorrow: "jutro",
      notExactAfterTomorrow: "pojutrze",
      notExactAfterWeek: "za tydzień",
      notExactAfterMonth: "za miesiąc",
      notExactAfterQuarter: "za kwartał",
      notExactAfterHalfYear: "za pół roku",
      notExactAfterYear: "za rok",
      tipDefaultDateHeader: "Domyślnie",
      tipDefaultDateText: "Jeśli oba przełączniki 'Dokładna data' i 'Data względna' są wyłączone, kalendarz pozwoli na wybór dat w zakresie od roku 1800 do 2200 domyślnie.",
      tipExactDateHeader: "Przełącznik dokładnej daty",
      tipExactDateText: "Włączenie tego przełącznika ograniczy kalendarz do stałego zakresu dat zdefiniowanego przez daty wybrane w poniższych polach wejściowych.",
      tipRelativeDateHeader: "Przełącznik daty względnej",
      tipRelativeDateText: "Włączenie tego przełącznika dynamicznie ustawi ograniczenia kalendarza na podstawie dat względnych obliczanych w momencie ładowania strony. Te ograniczenia są personalizowane i obliczane na nowo dla każdego użytkownika indywidualnie.",
      langPT: "Portugalski",
      placeholderCompanyContactText: 'Na przykład: "Telefon: +480993322211"',
      integrationConnectService: "Połącz",
      leadtype_id: "Typ akcji",
      selectVariant: "Wybierz",
      sfirstAddCompanyContacts: "Tekst z kontaktami",
      addNewSectionTextarea: "Duże pole tekstowe",
      allstepsVariantView: "Format obrazu",
      tariffsDiscountSave: "Zaoszczędź",
      cAdminSettingsTitle: "Ustawienia administracyjne",
      cAdminSettingsInstruction: "Wpisz w polu poniżej tagi dla quizu. Tagi mogą składać się z kilku słów, każdy tag oddzielany jest przecinkiem. Tagi 'Usługi' i 'usługi' to różne tagi. Warto używać tagów rozpoczynających się wielką literą. Można również wykluczyć quiz z listy szablonów.",
      cAdminSettingsIsTemplate: "Czy to jest szablon",
      cIsTemplateStateAreSaved: "Stan szablonu został zapisany",
      sfirstAddLogotype: "Dodaj logo",
      logotypeSizeTip: "Logo zostanie wstawione w rzeczywistym rozmiarze, ale nie większym niż 120 pikseli po dłuższym boku.",
      cfirstLogotypeUpdated: "Logo zostało dodane",
      cfirstLogotypeDeleted: "Logo zostało pomyślnie usunięte!",


      cOffersPageSettings: "Ustawienia",
      offerPageTitlePlaceholder: "Wprowadź tytuł",
      offerPageDescriptionPlaceholder: "Wprowadź opis strony",
      offerPageButtonTextPlaceholder: "Wprowadź tekst przycisku (opcjonalnie)",
      allstepsOffers: "Lista ofert",
      offerProposalHeader: "Oferta",
      offerAttachAddImg: "Dodaj obraz",
      offerAttachAddVideo: "Dodaj wideo",
      offerItemImg: "Obraz",
      offerItemTitle: "Tytuł oferty",
      offerTitlePlaceholder: "Wprowadź tytuł tutaj",
      offerItemSubtitle: "Podtytuł (np. cena)",
      offerSubtitlePlaceholder: "Wprowadź tekst tutaj",
      offerItemDescription: "Opis oferty",
      offerDescriptionPlaceholder: "Wprowadź opis tutaj",
      offerItemButtonText: "Tekst przycisku oferty",
      offerBtnTextPlaceholder: "Wprowadź tekst przycisku",
      offerItemButtonLink: "Link do przycisku",
      offerBtnLinkPlaceholder: "Link powinien zaczynać się od https://",
      csanyOfferLogic: "Logika wyświetlania",
      offerAddNewOffer: "Dodaj nową ofertę",
      offerLogicSetupTitle: "Ustawienia logiki",
      OfferLogicSetupDescription: "Tutaj możesz ustawić, które odpowiedzi wyświetlają określone oferty",
      cstepsOffersPage: "Strona wyników",
      addNewSectionResultsByLogic: "Oferty",
      cformActionAfterContactOffersOr: "Strona z ofertami / ",
      tolltipOffersAfterContact: "Jeśli po ukończeniu quizu zostanie spełniona logika wyświetlania przynajmniej jednej oferty, zostanie wyświetlona strona z ofertami, w przeciwnym razie - strona podziękowania.",
      videoCircleTextQuestion: "Jak to działa?",
      offerPhotoOptimalRatio: "Aby uzyskać optymalne wyświetlanie obrazu, zalecamy stosowanie proporcji 16:9.",
      tolltipThankYouAfterContact: "Po zakończeniu quizu zostanie wyświetlona strona podziękowania.",
      cformActionAfterContactOffers: "Strona wyników",
      addOfferPageInContact: "Włącz stronę wyników",
      offerPageHeader: "Nagłówek dla strony ofert",
      pumkinOrange: "Pomarańczowy",


    },
    ES: {
      madeInUkr: "Hecho en Ucrania",
      upProVersion: "Cambiar a PRO",
      getLeads: "Obtener clientes potenciales",
      poperBillings: "Facturación",
      poperTemplates: "Plantillas",
      poperPartnership: "Colaboración",
      poperProfile: "Perfil",
      poperLogout: "Cerrar sesión",
      defaultNewQuestion: "Nueva pregunta",
      defaultNewQuestionDescription: "Aquí puede insertar una breve descripción de la pregunta (hasta 80 caracteres)",
      defaultNewVariant: "Nueva opción",
      defaultBtnText: "Comenzar",
      navBillings: "Facturación",
      navPartnership: "Colaboración",
      navProfile: "Perfil",
      welcomeBack: "¡Bienvenido de nuevo!",
      loginHeader: "Iniciar sesión",
      loginHeader2Part: "para continuar",
      loginEmailPlaceholder: "Correo electrónico",
      loginPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      loginBtnValue: "Iniciar sesión",
      loginWith: "Iniciar sesión con",
      passwordAreForgetPassword: "¿Olvidaste tu contraseña?",
      passwordResetPasswordHeader: "Restablecimiento de contraseña",
      passwordResetPassword: "Restablecer contraseña",
      passwordWeSentReset: "Siga las instrucciones en el correo que le enviamos para restablecer su contraseña",
      passwordInputNewPassword: "Ingrese una nueva contraseña",
      passwordConfirmPassword: "Confirmar contraseña",
      passwordChangePassword: "Guardar contraseña",
      passwordResetedPasswordCompleted: "¡Su contraseña se ha cambiado con éxito!",
      passwordGoToWorkboard: "Ir al tablero de trabajo",
      loginSignup: "Registrarse",
      helloHeader: "¡Hola!",
      signupHeader: "Registrarse",
      signupHeader2Part: "para continuar",
      signupNamePlaceholder: "Oleksander",
      signupPhonePlaceholder: "número de teléfono",
      signupEmailPlaceholder: "Correo electrónico",
      signupPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      signupBtnValue: "Crear una cuenta",
      signupWith: "Registrarse con",
      signupLogin: "Iniciar sesión en una cuenta",
      signupTariffHeader: "Tarifa",
      signupTariffFree: "Gratis.",
      signupTariffPro: "Pro.",
      signupTariffFreeDescription: "Número ilimitado de creación de nuevos cuestionarios (páginas web). Capacidad de generar hasta 300 aplicaciones por mes de forma gratuita.",
      signupTariffProPrice: "$20/mes",
      signupTariffProDescription: "Número ilimitado de creación de nuevos cuestionarios (páginas web). Capacidad de generar ",
      signupTariffProDescriptionBold: "hasta 300 aplicaciones por mes.",
      userBackToQuiz: "Volver al tablero de trabajo",
      userBackToSettings: "Volver a Configuración",
      billingYourTariff: "Su plan",
      billingYourPro: "Pro",
      billingYorFree: "Gratis",
      billingFreeLeads: "Clientes potenciales gratuitos",
      billingInfinity: "ilimitado",
      billingTariffRenewData: "Fecha de renovación de tarifa",
      billingTariffEndData: "Fecha de finalización del plan",
      billingOtherTariffFree: "Gratis.",
      billingOtherTariffPro: "Pro.",
      billingTariffFreeDescription: "Número ilimitado de creación de nuevos cuestionarios (páginas web). Capacidad de generar hasta 300 aplicaciones por mes de forma gratuita.",
      billingYouHavePro: "Si actualmente estás en la versión Pro, ¿por qué dar un paso atrás?",
      billingYouHasFreeLeads: "Clientes potenciales gratuitos",
      billingYouHasLeads: "Leads restantes",
      billingTariffProDescription: "Número ilimitado de nuevos cuestionarios (páginas web). Capacidad de generar ",
      billingTariffProDescriptionBold: "más de 300 aplicaciones.",
      billingYourFutureTariff: "Su plan futuro",
      billingBtnChangeToPro: "cambiar a ",
      billingBtnRenewPro: "Renovar",
      billingBillings: "Facturación",
      billingYouHaveNotBillings: "Aún no tienes pagos",
      billingPaymentsMethod: "Método de pago",
      billingPaymentCard: "Tarjeta",
      billingChangeData: "Cambiar datos",
      billingDeletePayMethod: "Eliminar",
      billingModalDeleteShure: "¿Está seguro de que desea eliminar este método de pago?",
      billingModalDeleteCancel: "Cancelar",
      billingModalDeleteOk: "Eliminar",
      billingModalDeleteSuccess: "Ha eliminado su tarjeta con éxito",
      billingModalRegularPaymentDeleteSuccess: "Los pagos regulares han sido cancelados",
      billingModalRegularPaymentDeleteOk: "Aceptar",
      billingYourMonthPlan: "Su tarifa mensual",
      billingLeads: "clientes potenciales",
      billingChangePlan: "Cambiar la tarifa",
      billingChangePlanDescription: "El precio corresponderá al número deseado de aplicaciones de todos los cuestionarios en su cuenta.",
      billingSelectPeriod: "Seleccionar período",
      billingChangePlanDescriptionImportant: "Importante:",
      billingChangePlanDescriptionImportantMonth: "Los clientes potenciales se pueden usar 1 mes después de la compra.",
      subscriptionTarifLable: "Suscríbase a nuestra suscripción mensual automática. Puede cambiar su plan en cualquier momento.",
      billingChangePlanWarning: "",
      billingTariffMonth: "mes.",
      billingChangePlanPay: "Cambiar el plan",
      profileName: "Nombre",
      profilePhone: "Número de teléfono",
      profileEmail: "Correo electrónico",
      profileStatus: "Estado",
      profileStatusAgency: "Agencia",
      profileStatusPlaceholder: "¿Qué te describe mejor?",
      profileStatusFreelancer: "Freelancer",
      profileStatusBuisines: "Propietario de negocio",
      profilePassword: "Contraseña",
      profileChangePassword: "Cambiar contraseña",
      profileNewPassword: "Nueva contraseña",
      profileSaveNewData: "Actualizar datos",
      workboardAddNewQuiz: "Crear proyecto",
      workboardModalGiveQuizName: "Dar un nombre al cuestionario",
      workboardModalQuizNamePlaceholder: "Nuevo cuestionario",
      workboardModalNewQuizBtnValue: "agregar",
      workboardAlertGiveAnyName: "Ingrese cualquier nombre",
      workboardAlertNextTimeAddQuiz: "Bueno, la próxima vez, atrévete)",
      workboardChangeQuizName: "Cambiar el nombre",
      workboardQuizLink: "enlace",
      workboardQuizLinkCopied: "El enlace se copió al portapapeles",
      workboardEditQuiz: "Editar",
      workboardCopyQuiz: "Copiar el cuestionario",
      workboardQuizCopied: "Cuestionario copiado con éxito",
      workboardDeleteQuiz: "Eliminar",
      workboardModalRemoveQuizTrash: "Este cuestionario se moverá a la papelera y se eliminará permanentemente después de 1 mes.",
      workboardModalRemoveQuizSure: "¿Estás seguro de que quieres mover este cuestionario a la papelera?",
      workboardModalDeleteQuizCancel: "Cancelar",
      workboardModalDeleteQuizDelete: "Eliminar",
      workboardModalDeleteQuizSuccess: "Ha eliminado este cuestionario con éxito",
      workboardModalQuizDeleteOk: "Aceptar",
      workboardStatsTitle: "Estadísticas",
      workboardModalLeadsAnswersCount: "Respuestas a las preguntas",
      workboardModalLeadsLeadSerial: "№",
      workboardModalLeadsLeadDate: "Fecha",
      workboardModalLeadsLeadEmail: "Correo electrónico",
      workboardModalLeadsLeadName: "Nombre",
      workboardModalLeadsLeadPhone: "Teléfono",
      workboardModalLeadsLeadActions: "Acciones",
      workboardModalLeadsLeadShowMore: "Ver más",
      workboardModalLeadsLeadHideMore: "Ocultar",
      workboardModalLeadsLeadAnswerScipped: "Omitido",
      workboardModalNoLeads: "Aún no hay clientes potenciales",
      constructorLink: "enlace",
      constructorLinkCopied: "Copiado al portapapeles:",
      constructorNavSteps: "Pasos",
      constructorNavDesign: "Diseño",
      constructorNavSettings: "Configuración",
      constructorStep: "Paso",
      cancel: "Cancelar",
      constructorCancel: "Cancelar",
      constructorSave: "Guardar",
      constructorextraNavStart: "S",
      constructorextraNavContact: "C",
      constructorextraNavFinish: "F",
      cstepsSection: "Secciones",
      cstepsStartPage: "Página de inicio",
      cstepsContactPage: "Formulario de contacto",
      cstepsFinishPage: "Página final",
      cstepsEdit: "Editar",
      cstepsMove: "Mover",
      cstepsDeleteStep: "Eliminar paso",
      cstepsAddSection: "Agregar sección",
      cdesignColorTheme: "Tema de color",
      cdesignCustomTheme: "Tema de color personalizado",
      cdesignDefaultThemes: "Nuestros temas de color",
      cdesignThemeStandart: "Predeterminado",
      cdesignThemeDark: "Oscuro",
      cdesignThemeNud: "Nude",
      cdesignThemeGradient: "Gradiente",
      cdesignShapeDesign: "Diseño de formulario",
      cdesignShapeRound: "Redondeado",
      cdesignShapeSquare: "Angular",
      csettingsIntegration: "Integración",
      csettingsOweDomain: "Conexión de dominio propio",
      csettingsDocs: "Documentos legales",
      cintegrationIntegration: "Integración",
      cintegrationShareLeads: "Compartir clientes potenciales",
      cintegrationTitleEmail: "CORREO ELECTRÓNICO",
      cintegrationInstruction: "Instrucción",
      cintegrationInstructionDetail: "Detalles",
      cintegrationEmailInstruction: "Envía un correo electrónico a tu encuestado y notifica a tu equipo. Colabora con tu equipo.",
      cintegrationPublishAfterEdit: "Guarde el cuestionario después de editar",
      cintegrationSendNewLeadsTo: "Enviar mensajes sobre nuevos clientes potenciales a:",
      cintegrationInputEmail: "Ingrese correo electrónico",
      cintegrationAddEmail: "Agregar correo electrónico",
      cintegrationEmailSaved: "Correo electrónico guardado",
      cintegrationEmailDeleted: "Correo electrónico eliminado",
      cintegrationTitleFB: "Píxel de FB",
      cintegrationIDPixel: "ID de píxel",
      cintegrationFBInstruction1: "Vaya al administrador de anuncios de Facebook y copie el ID del píxel en la sección del Administrador de Eventos.",
      cintegrationFBInstruction2: "No copie todo el código, solo el ID de 15-16 dígitos.",
      cintegrationFBInstruction3: "Copie y pegue en la casilla de abajo.",
      cintegrationFBInstruction: "El evento para el cual necesita configurar la conversión es 'Lead'. Este evento se activará tan pronto como el usuario envíe una solicitud en el cuestionario.",
      cintegrationFBInstructionTextTitle: "Lista de todos los eventos que enviamos:",
      cintegrationFBInstructionTextTextStart: " – visita a la página de inicio;",
      cintegrationFBInstructionTextQuestionN: " – visita al paso N;",
      cintegrationFBInstructionTextOpenContactPage: " – visita a la página de contacto;",
      cintegrationFBInstructionTextLead: " – envío exitoso del formulario de contacto;",
      cintegrationFBInstructionTextThankYou: " – visita a la página final.",
      bold_adsquiz_Start: "adsquiz_Start",
      bold_adsquiz_QuestionN: "Question_N",
      bold_adsquiz_OpenContactPage: "Open_Contact-Page",
      bold_adsquiz_Lead: "Lead",
      bold_adsquiz_ThankYou: "adsquiz_ThankYou",
      cintegrationInputIDPixel: "Ingrese el ID de píxel",
      cintegrationDataFBSaved: "Datos del píxel de FB guardados",
      cintegrationAccesToken: "TOKEN DE ACCESO",
      cintegrationTitleGA: "Google analytics",
      cintegrationGAInstructionDescription: "Aprenda cómo la gente encuentra e interactúa con su cuestionario. Obtenga los datos que necesita para analizar campañas, mejorar conversiones y más.",
      cintegrationGAInstruction: "Cuando se recibe una aplicación de cuestionario en Google Analytics, se activará el evento 'generate_lead'.",
      cintegrationIDFolow: "ID de seguimiento",
      cintegrationInputID: "Ingrese ID",
      cintegrationDataGASaved: "Datos de Google Analytics guardados",
      cintegrationTitleGTM: "Administrador de etiquetas de Google",
      cintegrationGTMInstructionDescription: "Descripción para el Administrador de etiquetas de Google.",
      cintegrationGTMInstruction: "Copie el ID del Administrador de etiquetas de Google y péguelo en el campo a continuación.",
      cintegrationDataGTMSaved: "Datos del Administrador de etiquetas de Google guardados",
      cintegrationTitleTG: "Telegram",
      cintegrationHowReciveTGLeadBot: "¿Cómo obtener clientes potenciales en Telegram?",
      cintegrationTGInstructionBot1: "1. Copie el token en la entrada de abajo.",
      cintegrationTGInstructionBot2: '2. Siga el enlace al bot y haga clic en el botón "START".',
      cintegrationTGInstructionBot3: "3. Envíe el token copiado como mensaje a nuestro bot.",
      cintegrationHowReciveTGLeadChat: "¿Cómo obtener aplicaciones en el chat de Telegram?",
      cintegrationTGInstructionChat1: "1. Agregue nuestro bot al chat deseado y configúrelo como administrador.",
      cintegrationTGInstructionChat2: "2. Copie el token en el campo de abajo y envíelo al chat deseado.",
      cintegrationTGInstructionChat3: "3. Espere la respuesta `OK! Su token de cuestionario fue guardado!”",
      cintegrationTGInstructionChat4: "4. Los clientes potenciales ahora se enviarán a este chat.",
      cintegrationTGInstructionWarning: "Importante:",
      cintegrationTGInstructionWarningText: "Tenga en cuenta que esto debe ser un grupo, no un canal de Telegram. Los clientes potenciales no se enviarán al canal de Telegram.",
      cintegrationTGBotLink: "enlace del chatbot",
      cintegrationTGTitleToken: "TOKEN",
      cintegrationInputTGToken: "Ingrese el token del chatbot de Telegram",
      cintegrationAddIntegration: "Agregar integración",
      cintegrationDataTGSaved: "Datos de integración de Telegram guardados",
      cintegrationTitleTT: "Píxel de TT",
      cintegrationTTInstruction: "Para rastrear eventos en su cuenta de anuncios de Tik-Tok, copie el ID del píxel y péguelo en el cuadro de abajo.",
      cintegrationDataTTSaved: "Datos del píxel de TT guardados",
      cintegrationTitleWH: "WebHook",
      cintegrationWHInstruction1: "Puede notificar a aplicaciones de terceros sobre los clientes potenciales entrantes. Para hacer esto, especifique la URL a la que se enviará el WebHook.",
      cintegrationWHInstruction2: "Hemos preparado",
      cintegrationWHInstruction3: "instrucción en video",
      cintegrationWHInstruction4: "con una descripción detallada de esta funcionalidad.",
      cintegrationWHInstruction5: "Ver video.",
      cintegrationWHInstruction6: "En este video, veremos cómo pasar un hook basado en enviar datos a una hoja de cálculo de Google y enviar datos a un sistema CRM de Trello.",
      cintegrationWHInstruction7: "Recomendamos utilizar el servicio para este propósito.",
      cintegrationWHInstruction8: "Al registrarse utilizando este enlace, nuestros usuarios obtienen un bono de 10,000 acciones gratuitas para cualquier integración.",
      cintegrationWHInstruction9: "Enlace",
      cintegrationWHInstruction10: "para registrarse con un bono.",
      cintegrationWHUrl: "Las URL del WebHook",
      cintegrationInputWHUrl: "Ingrese la URL del WebHook",
      cintegrationAddWH: "Agregar un WebHook",
      cintegrationWebHookDataSaved: "Datos del WebHook guardados",
      cintegrationComonWHInstructionShort: "Puede notificar a aplicaciones de terceros sobre los clientes potenciales entrantes. Especifique la URL del WebHook para la transmisión de datos.",
      cintegrationComonWHInstruction1Title: "1. Inserte la URL del WebHook en el campo de entrada:",
      cintegrationComonWHInstruction1Description: "Pegue la URL del WebHook proporcionada por su servicio o aplicación en el campo de entrada.",
      cintegrationComonWHInstruction2Title: "2. Guardar cambios:",
      cintegrationComonWHInstruction2Description: 'Haga clic en el botón "Guardar cambios" para guardar la configuración del WebHook.',
      cintegrationComonWHInstruction3Title: "3. Hecho:",
      cintegrationComonWHInstruction3Description: "Ahora su WebHook está configurado para enviar datos al servicio que ha seleccionado.",
      cintegrationComonWHInstructionAdditional: "Los datos se enviarán al WebHook con cada cliente potencial recibido. El método POST se utiliza para la transmisión. Un ejemplo de los datos que se enviarán se encuentra ",
      cintegrationComonWHInstructionAdditionalLink: "aquí",
      cintegrationComonWHInstructionVideo: "Instrucción en video detallada",
      cintegrationApixDriveWHInstructionShort: "Puede notificar a ApixDrive sobre los clientes potenciales entrantes. Para hacer esto, especifique la URL a la que se enviará el WebHook.",
      cintegrationApixDriveWHInstructionShort1: "Al registrarse a través de ",
      cintegrationApixDriveWHInstructionShort2: "este enlace",
      cintegrationApixDriveWHInstructionShort3: " nuestros usuarios reciben un bono de 10,000 acciones gratuitas para cualquier integración.",
      cintegrationApixDriveWHInstruction1Title: "1. Regístrese o abra su cuenta de ApixDrive:",
      cintegrationApixDriveWHInstruction1Description1: "Enlace ",
      cintegrationApixDriveWHInstruction1Description2: "para registrarse con un bono.",
      cintegrationApixDriveWHInstruction2Title: "2. Obtenga la dirección URL para el WebHook:",
      cintegrationApixDriveWHInstruction2Description1: "Vaya a la configuración de la conexión necesaria y copie la dirección para enviar el WebHook:",
      cintegrationApixDriveWHInstruction2Description2: 'Puede crear una nueva conexión haciendo clic en el botón "Crear conexión" y en el paso de selección de sistema, elija "ADSQuiz".',
      cintegrationApixDriveWHInstruction3Title: "3. Inserte la URL del WebHook:",
      cintegrationApixDriveWHInstruction3Description: "Pegue la URL del WebHook proporcionada por ApixDrive en el campo de entrada.",
      cintegrationApixDriveWHInstruction4Title: "4. Guardar cambios:",
      cintegrationApixDriveWHInstruction4Description: 'Haga clic en el botón "Guardar cambios" para guardar la configuración del WebHook.',
      cintegrationApixDriveWHInstruction5Title: "5. Hecho:",
      cintegrationApixDriveWHInstruction5Description: "Ahora su WebHook está configurado para enviar datos a ApixDrive.",
      cintegrationApixDriveWHInstructionAdditional: "Los datos se enviarán al WebHook con cada cliente potencial recibido. El método POST se utiliza para la transmisión. Un ejemplo de los datos que se enviarán se encuentra ",
      cintegrationApixDriveWHInstructionAdditionalLink: "aquí",
      cintegrationApixDriveWHInstructionVideo: "Instrucción en video detallada",
      cintegrationConectOweDomain: "Conectando su propio dominio",
      cintegrationOweDomainInstruction1: "1. Para colocar su cuestionario en el dominio requerido, inicie sesión en su cuenta de hosting. Vaya a la configuración de DNS y cree un registro A con el parámetro de IP: 185.25.117.21",
      cintegrationOweDomainInstruction2: "2. Ingrese su dominio arriba y guarde el cuestionario.",
      cintegrationDomain: "Dominio",
      cintegrationInputYourDomain: "Ingrese su dominio",
      cintegrationCheckDomenIsCorrect: "Verifique que el dominio ingresado sea correcto",
      cintegrationDomainWhichCorrect: "Ingrese un dominio sin protocolos. Por ejemplo: some.site.com",
      cintegrationInsertedDefaultDomain: "El dominio se establece por defecto",
      cintegrationDomainDataSaved: "Datos del dominio guardados",
      cintegrationPrivacyPolicy: "Política de privacidad",
      cintegrationPrivacyPolicyByDefault: "Por defecto, utilizamos nuestra política de privacidad estándar. Recomendamos agregar su política de privacidad que sea relevante para su negocio.",
      cintegrationPrivacyPolicyAdventage: "Esto dejará claro a sus clientes potenciales cómo utiliza la información de contacto recibida. Esto también es requerido por algunas plataformas de publicidad.",
      cintegrationPrivacyPolicyUrl: "Enlace a los documentos",
      cintegrationPrivacyPolicyInputYourDocsLink: "Insertar enlace a los documentos",
      cintegrationDocsDataSaved: "Enlace a sus documentos guardado",
      allstepsUpploadPhoto: "Subir una imagen",
      allstepsChangePhoto: "Cambiar la imagen",
      allstepsDeletePhoto: "Eliminar imagen",
      allstepsTitle: "Título",
      allstepsDescription: "Descripción",
      allstepsButtonText: "Texto del botón",
      allstepsOtherSettings: "Otras configuraciones",
      allstepsIsSaveBeforeRoute: "¿Guardar datos antes de cambiar la ruta?",
      cfirstFormat: "Formato",
      cfirstHeaderStartText: "Su encabezado debe estar aquí. Motive a los usuarios a completar el cuestionario hasta el final",
      cfirstAddMediafile: "Agregar medios (imagen)",
      cfirstBonus: "Bono",
      cfirstAddBonus: "Agregar bono",
      cfirstStartPageDataUpdated: "Los datos de la página de inicio han sido actualizados",
      csanyAnswerType: "Tipo de pregunta",
      csanyATCard: "tarjeta",
      csanyATList: "lista",
      csanyATCustom: "campo libre",
      csanyQuestion: "Pregunta",
      csanyAnswers: "Respuestas",
      csanyAddAnswer: "Agregar opción",
      csanyTitleForModalConfirm: "¡Esta acción es irreversible!",
      csanyQuestionForModalConfirm: "¿Estás seguro de que quieres eliminar esta pregunta?",
      csanyRequiredStep: "Paso requerido",
      csanyAlowMultiple: "Permitir múltiples respuestas",
      csanyAutoRedirect: "Pregunta siguiente automática",
      csanyQuestionLogic: "Configuración de lógica de la pregunta",
      csanyDataStepUpdated: "Paso de datos actualizado",
      cformForm: "Formulario",
      cformValueName: "Nombre",
      cformValueSurname: "Apellido",
      cformValuePhone: "Número de teléfono",
      cformValueEmail: "Correo electrónico",
      cformDataContactsPageUpdated: "Página de contactos de datos actualizada",
      cformRequiredInput: "Obligatorio",
      cfinishFinishPageTitle: "Página de finalización",
      cfinishText: "Texto",
      cfinishDescription: "Descripción",
      cfinishAddImage: "Agregar una imagen",
      cfinishImageAdded: "Imagen agregada",
      cfinishURL: "URL",
      cfinishButtonText: "Texto del botón",
      cfinishInsertNikname: "Insertar apodo",
      cfinishCheckDataIsCorrect: "Verifique que el ID ingresado sea correcto",
      cfinishFbWhichCorrect: "Ingrese un ID sin otra dirección. Por ejemplo: some.name",
      cfinishIgWhichCorrect: "Ingrese un ID sin otra dirección. Por ejemplo: some.name",
      cfinishTgWhichCorrect: "Ingrese un ID sin otra dirección. Por ejemplo: some.name",
      cfinishFinishPageDataUpdated: "Los datos de la página de finalización se han actualizado",
      adminUsersReg: "Usuarios en el sistema",
      adminNewUsers: "Nuevos usuarios por mes",
      adminProAccounts: "Cuentas PRO",
      adminLTV: "LTV",
      adminSharePro: "PRO relativo a todos",
      adminReciveLeads: "Leads recibidos",
      adminLeadsForOne: "Leads por usuario",
      adminMadeQuiz: "Cuestionarios creados",
      adminQuizForOne: "Cuestionarios por usuario",
      adminAvarageUsed: "Meses promedio de uso",
      adminProfitMonth: "Mes de beneficios",
      adminProfitAlltime: "Ganancias de todos los tiempos",
      adminUsersList: "Base de usuarios",
      notificationErrorLogin: "Error de autorización:",
      notificationLogin: "Iniciar sesión...",
      notificationWrongLoginData: "Detalles de inicio de sesión incorrectos",
      notificationNetworkError: "Verifique su conexión a Internet",
      notificationInputLoginData: "Ingresar datos de inicio de sesión",
      notificationCheckInputEmail: "Verifique que su dirección de correo electrónico sea correcta",
      notificationCheckInputPhone: "Verifique que el número de teléfono sea correcto",
      notificationInputAllInputs: "Complete todos los campos para registrarse",
      notificationAgreePrivacyPolicy: "Aceptar la política de privacidad",
      adminEditorForPartnership: "Editor de programa de afiliados",
      developmentPage: "La sección está en desarrollo",
      signupAgreeIAgreeWith: "Estoy de acuerdo con",
      signupAgreePolicy: "la política de privacidad",
      signupAgreeAnd: "y",
      signupAgreeOffer: "la oferta pública",
      signupAgreeService: "del servicio",
      workboardModalLeadsLeadReferrer: "Poco claro",
      workboardModalLeadsLeadReferrerClarification: "Datos del referente bloqueados por la fuente o no definidos",
      workboardModalLeadsLeadEmailCopied: "Correo electrónico copiado al portapapeles",
      workboardModalLeadsLeadPhoneCopied: "Teléfono copiado al portapapeles",
      workboardModalLeadsHaveNotSended: "Tienes los leads no enviados",
      workboardModalLeadsLeadReload: "Recibir",
      workboardModalLeadsLeadReloadedTitle: "Solicitud recibida",
      workboardModalLeadsLeadReloaded: "",
      cfirstStartPagePhotoUpdated: "La foto de la página de inicio se ha actualizado",
      allstepsPhotoTooBig: "¡El archivo es demasiado grande!",
      allstepsPhotoMustBeLess: "Debe ser menor que",
      cfirstStartPageBonusPhotoUpdated: "La foto del bono ha sido actualizada",
      cstepsQuestionsOrderSaved: "Orden de las preguntas guardado",
      csanyAnswerOrderSaved: "Orden de las respuestas guardado",
      allstepsPhotoAdaptation: "Adaptación de la foto para dispositivos",
      allstepsPhotoFit: "Relación de aspecto",
      constructorYouHideStep: "Este paso está oculto",
      cstepsHiddenStep: "Ocultar paso",
      cstepsShowStep: "Mostrar paso",
      cfinishFinishPageHiddenUpdated: "La página final está oculta.",
      cfinishFinishPageHiddenUpdatedGoContact: "Vaya a la página del formulario de contacto",
      cfinishFinishPageHiddenUpdatedSetRedirect: "y configure el redireccionamiento después de enviar los datos",
      cformActionAfterContactRecive: "Seguimiento de la información de contacto",
      cformActionAfterContactThankyou: "Página de agradecimiento",
      cformActionAfterContactRedirect: "Redirigir",
      cformAdditionalSettings: "Configuraciones adicionales",
      cformRedirectURL: "URL",
      cintegrationOweDomainInstructionImportant: "Importante: O endereço na sua conta de hospedagem e o campo acima devem coincidir. O tempo de aplicação das mudanças nas configurações de DNS e extração do certificado SSL é de até 3 horas após salvar.",
      cintegrationOweDomainInstructionVideo: "Ver instrucción en video",
      workboardModalLeadsLeadLimit: "Se ha alcanzado el límite de leads.",
      workboardModalLeadsLeadLimitInstruction1: "Para ver nuevos leads,",
      workboardModalLeadsLeadLimitInstruction2: "cambiar a otra tarifa",
      workboardModalLeadsLeadLimitInstruction3: "renovar la tarifa actual",
      workboardModalLeadsLeadLimitInstruction4: '(no se aplica a "Gratis") o espere a que se actualice la tarifa si tiene renovación automática de la tarifa activada',
      billingBonusesTitle: 'Código promocional',
      billingBonusesDescription: 'Ingrese el código promocional que recibió en el siguiente campo',
      billingBonusesPlaceholder: 'Código promocional',
      billingBonusesBtn: 'Activar',
      billingBonusesActivated: '¡Código promocional activado!',
      billingBonusesLeadIncreased: 'El número de leads disponibles ha aumentado.',
      billingBonusesInvalid: 'Código promocional incorrecto',
      billingBonusesNotAvailable: 'El código promocional ya no está disponible',
      billingBonusesAlreadyActivated: 'Este cupón no es válido',
      createQuizSPDescription: "Hay espacio para la descripción breve",
      createQuizCPTitle: "¿Cómo podemos contactarlo?",
      createQuizCPDescription: "Por favor, complete el formulario a continuación",
      createQuizCPButton: "¡Hecho!",
      createQuizFPTitle: "¡Gracias!",
      createQuizFPDescription: "¡Ahora estamos buscando la mejor solución para usted!",
      createQuizFPButton: "¡Muéstrame algo!",
      selectInternationalPrefix: "Seleccione prefijo internacional",
      autoInternationalPrefix: "Determinar el país automáticamente",
      dovnloadCsvTitle: "Exportar datos para el período seleccionado",
      dovnloadCsvSelectAllPeriod: "Elegir para todo el período",
      dovnloadCsvCancel: "Cancelar",
      dovnloadCsvExport: "Exportar",
      widgetEmbedVidgetToSite: "Incrustar cuestionario en su propio sitio web",
      widgetEmbedVidgetSelectEmbedVariant: "Seleccione variante de incrustación",
      widgetEmbedVidgetSitecode: "En el código del sitio",
      widgetEmbedVidgetPopup: "Pop-up",
      widgetEmbedVidgetChat: "Botón de cuestionario",
      widgetEmbedVidgetInProgres: "En desarrollo",
      widgetEmbedVidgetToSiteCode: "Incrustar cuestionario en el sitio web",
      widgetSiteCodeInstruction0: "Para incrustar el cuestionario en su sitio, agregue el código del primer campo en la etiqueta <head> de su sitio o en Google Tag Manager si está integrado en su sitio. Si después de haber incrustado el cuestionario en su sitio cambia el dominio del cuestionario, necesitará actualizar el código de integración en su sitio.",
      widgetSiteCodeInstruction1: "En el lugar donde desea integrar el cuestionario, inserte el código del segundo campo.",
      widgetSiteCodeInstruction2: "El cuestionario se puede integrar en un contenedor de cualquier tamaño, ¡incluso en barras laterales! El tamaño máximo del cuestionario, al igual que en un dominio separado, es de 900x552 píxeles. Se puede agregar a contenedores más grandes, en cuyo caso el cuestionario se centrará.",
      widgetSiteCodeInstruction3: "¡No sea tímido - experimente!",
      widgetSiteCodeCopyToHead: "Copie y pegue en el <head> de su sitio",
      widgetSiteCodeCopyToBody: "Pegue el código donde debería estar el cuestionario",
      workboardModalAddQuizHeader: "Crear nuevo cuestionario",
      workboardModalGiveQuizNameAndMethod: "Piense en un nombre para su cuestionario y elija cómo desea crearlo",
      workboardModalNewQuizFromStart: "Crear desde cero",
      workboardModalNewQuizFromTemplates: "Plantillas listas",
      QuizTemplatesSearchInput: "Nombre de la plantilla y/o categorías",
      QuizTemplatesSearchBtn: "Buscar",
      QuizTemplatesReadyTemplates: "Plantillas listas",
      QuizTemplateView: "Ver",
      QuizTemplateSelect: "Elegir",
      widgetPopupInstruction0: "Para instalar un cuestionario en su sitio, agregue el código de la caja de códigos en la etiqueta <head> de su sitio o en Google Tag Manager si está integrado en su sitio. Si, después de haber insertado un cuestionario en su sitio, cambia el dominio del cuestionario o los disparadores (porcentaje de desplazamiento o tiempo), deberá actualizar el código de integración en su sitio.",
      widgetPopupInstruction1: "Justo antes de la etiqueta de cierre </footer>, pegue el código del segundo campo.",
      widgetPopupInstruction2: "Para programadores o usuarios avanzados",
      widgetPopupInstruction3: "Si, por alguna razón, se siente incómodo insertando un cuestionario antes de la etiqueta de cierre </footer>, puede pegar el código del segundo campo en cualquier lugar conveniente, pero tenga en cuenta que el contenedor en el que pega el código del segundo campo debe estar encima de todas las ventanas y, de preferencia, debe tener la altura y anchura máximas posibles para la visualización.",
      widgetPopupCopyToHead: "Copie y pegue en el <head> de su sitio",
      widgetPopupCopyToBody: "Pegue el código antes de la etiqueta de cierre </footer>",
      widgetPopupTimeToOpen: "Un período de tiempo",
      secondReduction: "seg",
      widgetPopupTimeClarification: "El popup aparecerá con un retraso del tiempo mencionado en segundos",
      widgetPopupScrollToOpen: "Porcentaje de desplazamiento del sitio",
      widgetPopupScrollClarification: "El popup aparecerá después del porcentaje de desplazamiento de su sitio especificado arriba",
      quizzesTemplatesAllTags: "Todas las plantillas",
      widgetEmbedVidgetButton: "Constructor de botones",
      widgetButtonInstruction0: "Puede configurar un cuestionario en su sitio web que se abrirá cuando haga clic en el botón.",
      widgetButtonInstruction1: "Agregue un borde, sombra y texto al botón. También puede cambiar el color del botón y el texto dentro.",
      widgetButtonInstruction2: "Copie el script colocado en la primera caja de código y péguelo antes de la etiqueta de cierre </head>.",
      widgetButtonInstruction3: "Copie el código de botón generado en el segundo campo de código y péguelo en cualquier lugar de su sitio.",
      widgetButtonInstruction4: 'Puede omitir el código del segundo campo de botón y simplemente agregar id="button_open_quiz_popup" a cualquier elemento <input type="button"> o <button></button> en el sitio.',
      widgetRealTimeChange: "Puede ver los cambios en tiempo real en el lado derecho de la pantalla",
      widgetButtonCornerRound: "Redondeo de esquinas del botón",
      widgetButtonShadow: "Sombra del botón",
      widgetButtonBgColor: "Color del botón",
      widgetButtonTextColor: "Color del texto",
      widgetButtonTextValue: "Texto del botón",
      openPopupQuizText: "Iniciar cuestionario",
      widgetButtonInsertBeforeAfterBody: "Inserte el código del cuestionario inmediatamente después de la etiqueta de apertura <body> o inmediatamente antes de la etiqueta de cierre </body>",
      widgetSiteCodeCopyButton: "Pegue el código del botón en cualquier lugar del sitio",
      footerContactUs: "¿Tiene preguntas? No dude en contactarnos:",
      enterYourName: "Ingrese su nombre",
      enterYourPhone: "Ingrese su número de teléfono",
      enterYourEmail: "Ingrese su correo electrónico",
      enterYourPassword: "Ingrese su contraseña",
      selectOption: "Seleccione opción",
      createNewGroup: "Crear nuevo grupo",
      dontHaveAccount: "¿No tiene una cuenta?",
      resetPasswordTip: "Si tiene algún problema o pregunta adicional, no dude en ponerse en contacto con nuestro equipo de soporte al cliente. Estamos aquí para ayudar.",
      getBackAccount: "Volver a su cuenta",
      inMinutes: "en minutos",
      alreadyHaveAccount: "¿Ya tiene una cuenta?",
      quizSlotLastEdit: "Última edición",
      workboardQuizzesTitle: "Mis cuestionarios",
      allstepsBack: "Atrás",
      constructorGetLink: "Compartir",
      allOr: "o",
      allAnd: "y",
      acceptedFileType: "Formatos aceptados: ",
      conjunctionAnd: "y",
      acceptedFileSize: "Tamaño máximo: ",
      acceptedFileSizeMeasure: "MB",
      cfirstBonusDisabled: "Deshabilitado",
      cfirstBonusAvailable: "Disponible",
      allstepsResetInHeader: "Restablecer",
      placeholderText: "Escriba aquí",
      constructorSaveChanges: "Guardar",
      cformInputAvailable: "Disponible",
      cformInputDisabled: "Deshabilitado",
      constructorBack: "Atrás",
      videoInstruction: "Instrucción en video",
      csettingsIntegrationFB: "Integración - Facebook Pixel",
      csettingsIntegrationTT: "Integración - TikTok",
      csettingsIntegrationGA: "Integración - Google Analytics",
      csettingsIntegrationGTM: "Integración - Google Tag Manager",
      csettingsIntegrationWH: "Integración - WebHook",
      csettingsIntegrationTG: "Integración - Telegram",
      csettingsIntegrationApixDrive: "Integración - APIxDrive",
      csettingsIntegrationEmail: "Integración - Correo Electrónico",
      csettingsIntegrationAbout: "Acerca de",
      csettingsIntegrationFBAbout: "Vaya al gabinete de publicidad de Facebook Ads y copie el ID de Facebook Pixel en la sección de Event Manager. No copie el código completo, solo el ID. Copie y pegue en la caja a continuación.",
      csettingsIntegrationTTAbout: "Para rastrear eventos en su cuenta publicitaria de Tik-Tok, copie el ID del píxel y péguelo en la caja a continuación.",
      csettingsIntegrationGAAbout: "Copie el ID de Google Analytics y péguelo en el campo a continuación.",
      csettingsIntegrationGTMAbout: "Copie el ID de Google Tag Manager y péguelo en el campo a continuación.",
      csettingsIntegrationWHAbout1: "Puede notificar a aplicaciones de terceros sobre los leads entrantes. Para hacerlo, especifique la URL a la que se enviará el WebHook. Recomendamos usar el servicio para este propósito.",
      csettingsIntegrationWHAboutApix: "Apix-Drive",
      csettingsIntegrationWHAbout2: "Al registrarse utilizando este enlace, nuestros usuarios reciben un bono - 10,000 acciones gratuitas para cualquier integración.",
      csettingsIntegrationTGAbout: "Importante: Tenga en cuenta que esto debe ser un grupo, no un canal de Telegram. Los leads no se enviarán al canal de Telegram.",
      csettingsIntegrationADAbout: "Algo sobre APIxDrive",
      csettingsIntegrationEmailAbout: "Envía un correo electrónico a su respondiente y notifica a su equipo. Colabore con su equipo.",
      cSettingsIntegrationNeedHelp: "¿Necesita ayuda?",
      cSettingsIntegrationRefreshToken: "Actualizar token",
      csettingsSettingASettings: "Configuración avanzada",
      cSettingsSettingQuizLangTitle: "Idioma del cuestionario",
      cSettingsSettingQuizLangDescription: "Para asegurarle la mejor experiencia, seleccione su idioma deseado para el cuestionario de las opciones a continuación. Las notificaciones del sistema y otros textos constantes se mostrarán en este idioma al completar el cuestionario.",
      selectQuizLanguage: "Seleccionar idioma del cuestionario",
      cSettingsSettingQuizHideLabelTitle: "Ocultar etiqueta",
      cSettingsSettingQuizHideLabelDescription: 'Al habilitar esta opción, puede disfrutar de una interfaz sin la etiqueta "hecho por adsquiz", lo que le permite a usted y a sus visitantes centrarse únicamente en las preguntas.',
      cSettingDataSaved: "Configuración adicional guardada",
      userAccountInfo: "Información de cuenta",
      userGeneralInfoHeader: "Información general",
      userGeneralInfoDescription1: "Su perfil de usuario es donde puede administrar su cuenta y configuraciones.",
      userGeneralInfoDescription2: "Actualice su información personal, cambie su contraseña y ajuste sus preferencias de notificación.",
      profileNameFirst: "Nombre",
      profileNameLast: "Apellido",
      userSecuritySettingsHeader: "Configuración de seguridad",
      userSecuritySettingsDescription: "Para cambiar su contraseña, simplemente ingrese su nueva contraseña dos veces para confirmar.",
      profileEmailCurent: "Dirección de correo electrónico actual",
      profileEmailNew: "Nueva dirección de correo electrónico",
      profileNewPasswordConfirm: "Confirmar nueva contraseña",
      profileDeleteAccount: "Eliminar cuenta",
      profileDeleteAccountPopupHeader: "¿Eliminar cuenta?",
      profileDeleteAccountPopupDescription: '¿Está seguro de que desea eliminar su cuenta? Esta acción no se puede deshacer. Para confirmar la eliminación, escriba la palabra "ELIMINAR" en el campo a continuación.',
      profileDeleteAccountCheckedWord: "ELIMINAR",
      profileDeleteAccountPopupPlaceholder: "Escriba aquí",
      notificationSuccess: "¡Éxito!",
      notificationSuccessSaveAccount: "¡Su contraseña se ha cambiado exitosamente! Use una nueva contraseña para iniciar sesión nuevamente",
      notificationSuccessDeleteAccount: "¡Su contraseña se ha cambiado exitosamente! Use una nueva contraseña para iniciar sesión nuevamente",
      cardPanUnspecified: "No especificado",
      userBillingsDate: "Fecha",
      userBillingsCost: "Costo",
      userBillingsId: "ID",
      userBillingsMethod: "Método de pago",
      paymentSwitchToProHeader: "Cambiar a Pro",
      paymentSwitchToProDescription: "¡Encuentra el plan adecuado para ti! Mejora o reduce en cualquier momento; facilitamos la obtención del plan que necesitas.",
      paymentYourCurrentPlan: "Su plan actual:",
      paymentYourFuturePlan: "Su nuevo plan:",
      paymentPlanLeadsLimit: "Límite de leads por mes:",
      paymentPlanLeadsLimitForTest: "Límite de pruebas de su cuestionario:",
      paymentPlanLeadsLeft: "Leads restantes:",
      paymentPlanPrice: "Precio:",
      paymentPlanRenew: "Fecha de renovación de tarifa:",
      addNewSectionHeader: "Agregar nueva sección",
      addNewSectionList: "Lista",
      addNewSectionCustom: "Campo de entrada",
      addNewSectionCard: "Tarjetas con fotos",
      addNewSectionWithEmoji: "Lista con emoji",
      addNewSectionTags: "Etiquetas",
      addNewSectionSlider: "Deslizador",
      templatesBackToQuiz: "Volver al tablero de trabajo",
      constructorStepsSaved: "Todos los datos del cuestionario se han guardado exitosamente",
      constructorDesignSaved: "El diseño del cuestionario se ha guardado exitosamente",
      azureBlueWhite: "Estándar",
      azureBlue: "Noche de invierno",
      moderatePurplishRed: "Terciopelo rojo",
      violetBlueCrayola: "Brisa de invierno",
      wetRainforest: "Arce de verano",
      lavender: "Espacio",
      blueLilac: "Lavanda oscura",
      opalGreen: "Bosque de los Cárpatos",
      dustyBlue: "Tiffany",
      almondCrayola: "Arrecife de coral",
      periwinkleCrayolaLilac: "Lila",
      periwinkleCrayolaAqua: "Synevir",
      jazzJam: "Jazz",
      slateGrayBlue: "Niebla",
      turquoiseBlueCrayola: "Diia",
      blackPearlescentOpal: "Ceniza",
      pearlescentGentianBlue: "Zafiro",
      sapphireBlue: "Zafiro oscuro",
      errorUserDataUpdate: "Error en los datos actualizados: ",
      completeUserDataUpdate: "Datos de usuario actualizados",
      passwordDoNotCoincidence: "Las contraseñas no coinciden",
      passwordDoNotCoincidenceAgain: "Inténtelo de nuevo",
      nonBillingsYet: "Aún no tiene historial de facturación.",
      youCanChangePlan: "Puede cambiar el plan actual haciendo clic en el botón “Cambiar a PRO”",
      rangeMin: "Mín",
      rangeMax: "Máx",
      rangePositionStart: "Inicio de posición",
      rangePositionEnd: "Fin de posición",
      rangeStep: "Paso",
      rangeIsRange: "Rango",
      rangeIsRangeTrue: "Disponible",
      rangeIsRangeFalse: "Deshabilitar",
      recivePromotionNewsletter: "Recibir boletines por correo electrónico",
      reciveEmailCheckbox: "Casilla de recibir correo electrónico",
      additionalCheckbox: "Casilla adicional",
      contactPrivacyPolicyCheckbox: "Casilla de política de privacidad de contacto",
      contactAgreeIAgreeWith: "Estoy de acuerdo con",
      contactAgreePolicy: "la política de privacidad",
      contactAgreeAnd: "y",
      contactAgreeTermsOfUse: "los términos de uso",
      contactAgreeAgreementAdditionalWord: "",
      langDE: "Alemán",
      langEN: "Inglés",
      langIT: "Italiano",
      langKK: "Kazajo",
      langPL: "Polaco",
      langRU: "Ruso",
      langSP: "Español",
      langUA: "Ucraniano",
      langLT: "Lituano",
      langLV: "Letón",
      langET: "Estonio",
      langRO: "Rumano",
      leadsLeft: "Leads restantes",
      generateDomainTitle: "Ingrese el nombre de dominio",
      generateDomainDescription: "Esta es la dirección única donde su página estará accesible. Puede cambiar la dirección en cualquier momento o conectar su propia.",
      generateDomainDomainNameLabel: "Nombre de dominio",
      cancelAllAction: "Cancelar",
      confirmAllAction: "Confirmar",
      falseHasValidCharacters: "Caracteres no válidos en el dominio. Los caracteres válidos son letras, números y guiones.",
      falseHasValidLength: "La longitud del dominio debe estar entre 6 y 20 caracteres alfanuméricos.",
      falseDoesNotStartOrEndWithSpecialChar: "El dominio no debe comenzar ni terminar con un carácter especial.",
      falseDoesNotHaveConsecutiveSpecialChar: "El dominio no debe contener dos o más caracteres especiales consecutivos.",
      falseDoesNotHaveConsecutiveSameChar: "El dominio no debe contener tres o más caracteres idénticos consecutivos.",
      falseDoesNotContainForbiddenSubstrings: "El dominio no debe contener subcadenas prohibidas.",
      generateDomainTaken: "Este dominio ya está tomado. Pruebe otro",
      temporarilyUnavailable: "Temporalmente no disponible",
      csettingsAdmin: "Configuración para administradores",
      questionLogicSetupTitle: "Configuración de lógica de preguntas",
      questionLogicSetupDescription: "Agregue condiciones para personalizar la encuesta para cada encuestado y recopilar datos más precisos.",
      questionLogicConditionTitle: "Condición",
      questionLogicQuestion: "Pregunta",
      questionLogicSetupQuestionDisableOption: "Seleccione pregunta",
      questionLogicSetupAnswer: "Respuesta",
      questionLogicSetupLogicType: "Tipo",
      questionLogicSetupAnswerDisableOption: "Seleccione tipo de condición",
      questionLogicSetupRangeBetween: "Entre",
      questionLogicSetupRangeLess: "Menos",
      questionLogicSetupRangeMore: "Más",
      questionLogicSetupRangeEqualTo: "Igual a",
      questionLogicSetupRangeFrom: "Desde",
      questionLogicSetupRangeTo: "Hasta",
      questionLogicSetupVariantAll: "Todos",
      questionLogicSetupVariantAny: "Cualquiera",
      questionLogicSetupVariantNot: "Ninguno",
      questionLogicSetupCustomEqual: "Igual",
      questionLogicSetupCustomContains: "Contiene",
      questionLogicSetupCustomNotContains: "No contiene",
      questionLogicSetupRangeValue: "Valor",
      questionLogicSetupExpectedAnswer: "Respuestas esperadas",
      questionLogicSetupPlaceholder: "Escribe aquí",
      questionLogicAllMandatory: "Todas las condiciones anteriores son obligatorias",
      questionLogicAddConditionBtn: "Añadir condición",
      questionLogicNotAvailable: "La configuración de lógica no está disponible para la primera pregunta",
      questionLogicSaved: "La configuración de la lógica de visualización de pasos ha sido guardada",
      billingPaymentInfoTitle: "Información de tarifa",
      billingPaymentInfoChangeTarrif: "Cambiar tarifa",
      billingPaymentInfoDescription: "Cuando paga por cualquier tarifa, recibe una cierta cantidad de leads incluidos en el paquete que ha elegido. Estos leads se proporcionan por un mes. Si no ha usado sus leads dentro de un mes desde el momento del pago, serán cancelados.",
      billingGeneralName: "Su tarifa",
      billingGeneralCost: "Costo",
      billingMonth: "mes",
      billingGeneralExpired: "Válido hasta",
      billingGeneralLeadsLeft: "Leads restantes",
      billingGeneralAllTarifExpired: "Fecha de vencimiento de la tarifa",
      billingGeneralBonus: "Leads con código promocional",
      billingGeneralLeadsFromPastTarifs: "Leads de tarifas anteriores",
      billingAcordeonPaymentInfoTitle: "Información de pago",
      billingAcordeonPaymentInfoFormat: "Formato",
      billingAcordeonPaymentInfoSubscription: "Suscripción",
      billingAcordeonPaymentInfoPay: "Pago",
      billingAcordeonPaymentInfoPayMethod: "Método de pago",
      billingAcordeonPaymentInfoCancelSubscription: "Cancelar suscripción",
      billingAcordeonPromocode: "Código promocional",
      billingAcordeonPromocodeLabel: "Ingrese el código promocional",
      billingAcordeonPayHistory: "Historial de transacciones",
      paymentAllTarifInactive: "la tarifa está inactiva",
      subscriptionUnsubscribeModalTitle: "Darse de baja",
      subscriptionUnsubscribeModalDescription: "¿Está seguro de que desea darse de baja? Los leads acumulados para la suscripción estarán disponibles durante un mes desde la última fecha de facturación.",
      subscriptionIsNotDeleteTitle: "Suscripción no cancelada",
      subscriptionIsNotDeleteDescription: "Por favor, póngase en contacto con el soporte al cliente",
      subscriptionCanceledTitle: "Suscripción cancelada",
      modalSureUnsavedChangesTitle: "Tiene cambios sin guardar",
      modalSureUnsavedChangesDescription: "Antes de salir de la página actual, decida qué hacer con los cambios sin guardar",
      modalSureUnsavedChangesDontSave: "No guardar",
      modalSureUnsavedChangesSave: "Guardar",
      telegramTokenCopied: "El token del quiz ha sido copiado al portapapeles",
      cSettingsSettingQuizBackButtonTitle: "Botón de retroceso",
      cSettingsSettingQuizBackButtonDescription: "Puede habilitar o deshabilitar el botón de retroceso. Esto permitirá cambiar la respuesta dada anteriormente.",
      cSettingsCustomThemePrimaryColor: "Color de acentos",
      cSettingsCustomThemeSecondaryColor: "Color de fondo",
      cSettingsCustomThemeAdditionalColor: "Color de texto",
      cSettingsCustomThemeButtonTextColor: "Texto en el botón",
      cSettingsCustomThemeModeDark: "Oscuro",
      cSettingsCustomThemeModeLight: "Claro",
      allstepsPlaceholder: "Marcador de posición",
      allstepsCustomInput: "Entrada personalizada",
      allstepsCustomInputOn: "Disponible",
      allstepsCustomPlaceholder: "Otro",
      trashcanTitle: "Papelera",
      trashcanImportant: "¡Importante!",
      trashcanDescription: "El quiz se eliminará automáticamente de forma permanente 30 días después de ser movido a la papelera.",
      trashcanClearAll: "Borrar todo",
      quizSlotDeletedData: "Eliminado el",
      workboardLeads: "Leads",
      workboardDuplicate: "Duplicar",
      leadsTable: "Lista de Leads",
      exportInfo: "Exportar Leads",
      leadHide: "Ocultar",
      leadShowMore: "Mostrar más",
      poperTrashcan: "Papelera",
      restoreQuizSendedTitle: "Una tarea de recuperación está en curso",
      restoreSuizSendedImportant: "Importante",
      restoreQuizSendedDescription: "Dependiendo de cuántos leads haya en su quiz, la restauración puede tardar de unos segundos a 2 minutos. Después de la restauración, el quiz aparecerá en su tablero principal.",
      ok: "De acuerdo",
      month: "{n} month",
      discount: "Descuento de hasta",
      saveMoney: "Suscripción por un año",
      upProVersionMobile: "A PRO",
      upProVersionDesktop: "Cambiar a PRO",
      csettingsIntegrationSendPulse: "Integración - Send Pulse",
      csettingsIntegrationMake: "Integración - Make",
      csettingsIntegrationZapier: "Integración - Zapier",
      csettingsIntegrationCRMBitrix: "Integración - CRM Bitrix",
      csettingsIntegrationPipeDrive: "Integración - Pipe Drive",
      csettingsIntegrationRemOnline: "Integración - Rem Online",
      csettingsIntegrationKeyCRM: "Integración - Key CRM",
      csettingsIntegrationZapierAbout: "Pegue la URL de envío de datos proporcionada por Zapier en el campo de entrada.",
      csettingsIntegrationMakeAbout: "Pegue la URL de envío de datos proporcionada por Make en el campo de entrada.",
      csettingsIntegrationMakeAboutTextStep1: "Para configurar la integración con",
      csettingsIntegrationMakeAboutTextStep2: ", vea e",
      csettingsIntegrationMakeAboutTextStep3: "Para configurarlo, seleccione “Webhook” entre las integraciones en Make, luego elija “Webhook response”.",
      csettingsIntegrationMakeAboutTextStep4: "Copie el enlace generado y péguelo en el campo de abajo en ADS Quiz.",
      csettingsIntegrationMakeAboutTextStep5: "Para probar, vuelva a Make, haga clic en “Run” y complete el quiz enviando un lead.",
      csettingsIntegrationMakeAboutTextStep6: "Si la prueba es exitosa, guarde la integración y no olvide activar las actualizaciones.",
      csettingsIntegrationMakeAboutLink: "https://www.make.com/en/register?pc=adschool",
      csettingsIntegrationMakeAboutLinkVideo: "https://www.youtube.com/embed/3ZyagaBQmiI",
      csettingsIntegrationMakeAboutLinkVideoText: "video",
      csettingsIntegrationCRMBitrixAbout: "Pegue la URL de envío de datos proporcionada por CRM Bitrix en el campo de entrada.",

      csettingsIntegrationSendPulseAboutStart: "Puedes configurar la integración con el servicio ",
      csettingsIntegrationSendPulseAboutMiddle: ". Para hacerlo, ve a la configuración de tu cuenta y abre la pestaña ",
      csettingsIntegrationSendPulseAboutEnd: ". Copia los valores de los parámetros 'ID' y 'Secret', y pégalos en los campos correspondientes en el servicio ADS Quiz (en los campos a continuación). Luego, debes ingresar el ID del libro de direcciones. Para hacer esto, accede al libro de direcciones deseado y copia del URL el valor después de id/. Solo se necesitan números, sin caracteres adicionales. Guarda los cambios. Una guía más detallada está disponible en el ",
      csettingsIntegrationSendPulseAboutLinkSendPulse: "https://sendpulse.com/latam#60c9ccfd55f4b'",
      csettingsIntegrationSendPulseAboutLinkAPI: "https://login.sendpulse.com/login/#api",
      csettingsIntegrationSendPulseAboutLinkVideo: "https://www.youtube.com/watch?v=4mnhpbKkePs",
      csettingsIntegrationSendPulseAboutLinkVideoText: "video",

      csettingsIntegrationKeyCRMAbout: "Pegue la clave API y el PipelineID en los campos correspondientes.",
      csettingsIntegrationPipeDriveAbout: "Pegue el token y el dominio de la empresa en los campos correspondientes.",
      csettingsIntegrationRemOnlineAbout: "Pegue la clave API de Rem Online en el campo correspondiente.",
      csettingsIntegrationActiveIntegration: "Integración activa",
      cintegrationIntegrationData: "Datos de integración",
      cintegrationIntegrationUrl: "URL de integración",
      cintegrationWriteUrl: "Ingresar URL",
      cintegrationDataFBError: "Identificador de píxel incorrecto",
      cintegrationDataWebHookError: "Dirección de webhook no válida",
      cintegrationZapierDataSaved: "Datos de integración de Zapier guardados",
      cintegrationDataZapierError: "Dirección de integración no válida",
      cintegrationAPIxDriveDataSaved: "Datos de integración de APIxDrive guardados",
      cintegrationDataAPIxDriveError: "La cadena no es una dirección de integración de APIxDrive",
      cintegrationMakeDataSaved: "Datos de integración de Make guardados",
      cintegrationDataMakeError: "Dirección de integración no válida",
      cintegrationSendPulseDataSaved: "Datos de integración de SendPulse guardados",
      cintegrationDataSendPulseError: "Datos de integración no válidos o vacíos",
      cintegrationPipeDriveDataSaved: "Datos de integración de PipeDrive guardados",
      cintegrationDataPipeDriveError: "Datos de integración no válidos o vacíos",
      cintegrationRemOnlineDataSaved: "Datos de integración de RemOnline guardados",
      cintegrationDataRemOnlineError: "Datos de integración no válidos o vacíos",
      cintegrationKeyCRMDataSaved: "Datos de integración de KeyCRM guardados",
      cintegrationDataKeyCRMError: "Datos de integración no válidos o vacíos",
      cintegrationInputEmailUrl: "Ingrese el correo electrónico",
      cintegrationEmailDataSaved: "Correo electrónico guardado",
      cintegrationDataEmailError: "La cadena ingresada no es un correo electrónico",
      cintegrationCRMBitrixDataSaved: "Correo electrónico guardado",
      cintegrationDataCRMBitrixError: "La cadena ingresada no es un correo electrónico",
      cintegrationTelegramBotDataSaved: "Configuración de integración de Telegram guardada",
      cintegrationDataTelegramBotError: "Error al guardar",
      cintegrationGTMDataSaved: "ID de Google Tag Manager guardado",
      cintegrationDataGTMError: "ID no válido",
      cintegrationDataGTMErrorDescription: "El ID debe tener el formato GTM-XXXXXX",
      cintegrationGoogleAnalyticsDataSaved: "ID de Google Analytics guardado",
      cintegrationDataGoogleAnalyticsError: "ID incorrecto",
      cintegrationDataGoogleAnalyticsErrorDescription: 'El ID debe tener el formato "UA-XXXXXXX-X" o "G-XXXXXXXXXX-XX"',
      cintegrationTikTokDataSaved: "Píxel de Tik-Tok guardado",
      cintegrationDataTikTokError: "La cadena ingresada no es un píxel de Tik-Tok",
      cintegrationIntegrationToken: "Token de integración",
      cintegrationWriteToken: "Ingrese el token de integración",
      cintegrationDataYourAPIKey: "Ingrese su clave API",
      viewQuizInMobile: "Ver Quiz",
      notificationCSRFTokenMismatch: "Incompatibilidad de token CSRF",
      notificationReloadPage: "Por favor, recargue la página e intente nuevamente",
      csettingsIntegrationKeyRemOnline: "Pegue el token y el ID del canal en los campos correspondientes",
      cintegrationDataYourIntegrationToken: "Clave API",
      cintegrationDataLeadTypeId: "ID del canal",
      send_utm: "Enviar etiquetas UTM",
      send_quiz: "Enviar datos del quiz",
      send_answers: "Enviar respuestas",
      csettingsIntegrationKeyCrm: "Integración KeyCRM",
      cintegrationSendPulseClientId: "ID del cliente",
      cintegrationSendPulseClientSecret: "Secreto",
      cintegrationSendPulseAddressBookId: "ID de la libreta de direcciones",
      cintegrationSendPulseAction: "Acción con contacto",
      sendpulseActionAddContact: "Agregar contacto",
      sendpulseActionDeleteContact: "Eliminar contacto",
      cintegrationPipeDriveToken: "Token API",
      cintegrationPipeDriveDomain: "Su subdominio único",
      cintegrationKeyCrmApiKey: "Clave API",
      cintegrationKeyCrmPipelineId: "ID del pipeline",
      cintegrationMenuGoogleTagManager: "Google Tag Manager",
      cintegrationMenuGoogleAnalytics: "Google Analytics",
      cintegrationMenuFacebookPixel: "Píxel de Facebook",
      cintegrationMenuTikTokPixel: "Píxel de TikTok",
      cintegrationMenuWebHook: "Webhook",
      cintegrationMenuZapier: "Zapier",
      cintegrationMenuAPIxDrive: "APIx Drive",
      cintegrationMenuMake: "Make",
      cintegrationMenuSendPulse: "Send Pulse",
      cintegrationMenuEmail: "Correo electrónico",
      cintegrationMenuTelegramBot: "Bot de Telegram",
      cintegrationMenuPipeDrive: "PipeDrive",
      cintegrationMenuRemOnline: "Rem Online",
      cintegrationMenuKeyCRM: "Key CRM",
      cintegrationMenuCRMBitrix: "CRM Bitrix",
      trashcanRestoreQuiz: "Restaurar Quiz",
      cformValuePromotionAccepted: "Acepto el boletín de correo electrónico",
      constructorAddIntegration: "Agregar una nueva integración",
      cintegrationKeyCrmSourceId: "ID de fuente (opcional)",
      cintegrationKeyCrmManagerId: "ID del gerente (opcional)",
      cSettingsSettingQuizSpamFilterTitle: "Protección contra spam",
      cSettingsSettingQuizSpamFilterDescription: "Esta función está desactivada por defecto. Si encuentra leads de spam, active la protección. Después de eso, un solo usuario no podrá enviar más del número especificado de leads para este quiz.",
      cSettingsSettingQuizSpamFilterLimitPlaceholder: "Número de leads",
      langNO: "Noruego",
      loadMoreLeads: "Ver más leads",
      userAdditionalSettingsHeader: "Ajustes adicionales",
      userAdditionalSettingsDescription1: "Aquí puede configurar nuevas configuraciones avanzadas de perfil.",
      userAdditionalSettingsPagination: "Paginación en el tablero",
      benefitType: "Tipo de beneficio",
      benefitLabelPlaceholder: "Texto de bono",
      benefitDescriptionPlaceholderDiscount: "Código promocional o instrucción",
      benefitDescriptionPlaceholderVideo: "Enlace al video",
      benefitDescriptionPlaceholderTextfile: "Enlace al archivo de texto",
      benefitDescriptionPlaceholderGift: "Descripción del bono",
      benefitDescriptionPlaceholderPDF: "Enlace al PDF",
      benefitDescriptionPlaceholderLink: "Enlace",
      benefitDescriptionPlaceholderFile: "Enlace al archivo",
      benefitLabelDiscount: "Descuento",
      benefitLabelVideo: "Video",
      benefitLabelTextfile: "Archivo de texto",
      benefitLabelGift: "Regalo",
      benefitLabelPDF: "Archivo PDF",
      benefitLabelLink: "Enlace",
      benefitLabelFile: "Archivo",
      addBenefit: "Agregar beneficio",
      saveBenefit: "Guardar bono",
      cfinishSocialNetworks: "Redes sociales",
      saveSocialNet: "Guardar red social",
      networkLabelFacebook: "Facebook",
      networkLabelInstagram: "Instagram",
      networkLabelTelegram: "Telegram",
      networkLabelTiktok: "Tik-Tok",
      networkLabelYoutube: "YouTube",
      networkLabelLinkedin: "LinkedIn",
      networkLabelWhatsapp: "WhatsApp",
      socialNetDescriptionPlaceholderFacebook: "perfil_nikname",
      socialNetDescriptionPlaceholderInstagram: "perfil_nikname",
      socialNetDescriptionPlaceholderTelegram: "perfil/canal_nikname",
      socialNetDescriptionPlaceholderTiktok: "perfil_nikname",
      socialNetDescriptionPlaceholderYoutube: "nombre_del_canal",
      socialNetDescriptionPlaceholderLinkedin: '"in/name" o "company/name"',
      socialNetDescriptionPlaceholderWhatsapp: "+15555551234",
      addNewSectionEmptyPage: "Página de texto simple",
      csanyMakeImpasse: "Impasse",
      impaseIsImpaseTrue: "Encendido",
      impaseIsImpaseFalse: "Apagado",
      defaultNewEmptyQuestionDescription: "Puede pegar y formatear texto largo aquí (no recomendamos pegar más de 3000 caracteres).",
      cSettingsSettingQuizTimezoneTitle: "Zona horaria del quiz",
      cSettingsSettingQuizTimezoneDescription: "Ingrese la zona horaria en la que se registrará el tiempo de envío de leads. Esto es útil especialmente si usted y el cliente están en diferentes zonas.",
      cSettingsSettingQuizTimezonePlaceholder: "Zona horaria",
      userAdditionalSettingsTimezone: "Zona horaria del usuario",
      userAdditionalSettingsTimezonePlaceholder: "Zona horaria",
      langHE: "Hebreo",
      langCS: "Checo",
      cintegrationCAPIFBInstruction: "Para configurar la integración con la API de Conversión de Facebook, debe ingresar el ID de píxel y el token de acceso. Estos datos se obtienen en la configuración de Facebook Business Manager. Después de ingresar la información requerida, guarde la integración.",
      cintegrationCAPIFBInstructionTextTitle: "Nombres de eventos para FB Conversions:",
      cintegrationCAPIFBInstructionTextStart: " – visita a la página de inicio;",
      cintegrationCAPIFBInstructionTextQuestionN: " – visita al paso N (el número del paso se inserta dinámicamente);",
      cintegrationCAPIFBInstructionTextOpenContactPage: " – visita a la página de contacto;",
      cintegrationCAPIFBInstructionTextLead: " – envío exitoso del formulario de contacto;",
      cintegrationCAPIFBInstructionTextThankYou: " – visita a la página final.",
      cintegrationCAPIFBInstructionTextOptimizationAdvice: "Recomendamos ejecutar anuncios optimizados para el evento",
      cintegrationCAPIFBInstructionTextLeadTriggerCondition: "Este evento se activa",
      bold_capi_adsquiz_Start: "capi_adsquiz_Start",
      bold_capi_adsquiz_QuestionN: "capi_adsquiz_Question-N",
      bold_capi_adsquiz_OpenContactPage: "capi_adsquiz_OpenContactPage",
      bold_capi_adsquiz_Lead: "capi_adsquiz_Lead",
      bold_capi_adsquiz_ThankYou: "capi_adsquiz_ThankYou",
      bold_capi_adsquiz_OptimizationAdvice: "“capi_adsquiz_Lead”.",
      bold_capi_adsquiz_LeadTriggerCondition: "solo cuando un usuario envía un lead con éxito.",
      csettingsIntegrationFbCapiAbout: "La integración con la API de Conversión de Facebook le permite enviar datos de interacción del usuario directamente a Facebook para mejorar la efectividad de las campañas publicitarias.",
      cintegrationInputCapiAccessToken: "Ingrese el token de acceso",
      cintegrationInputCapiCustomparameter: "Ingrese los parámetros de evento personalizado",
      cintegrationDataFbCapiSaved: "Integración con la API de Conversión de Facebook guardada exitosamente",
      cintegrationComonRenovateHubInstructionTitle: "Instrucciones para configurar la integración con RenovateHub",
      cintegrationComonRenovateHubInstructionDescription: "Para configurar la integración con RenovateHub, proporcione la URL del webhook donde se enviarán los datos. Asegúrese de que la URL sea correcta y accesible para recibir datos.",
      cintegrationComonRenovateHubInstructionShort: "La integración con RenovateHub le permite enviar datos al CRM a través de un webhook para la automatización de procesos.",
      cintegrationRenovateHubUrl: "Ingrese la URL de RenovateHub",
      cintegrationInputRenovateHubUrl: "Especifique la URL del webhook para RenovateHub",
      cintegrationRenovateHubDataSaved: "Integración con RenovateHub guardada exitosamente",
      cintegrationDataRenovateHubError: "Error al guardar la integración con RenovateHub. Por favor, verifique que los datos ingresados sean correctos.",
      cintegrationMenuFacebookConvAPI: "API de Conversiones de Facebook",
      cintegrationMenuRenovateHub: "RenovateHub",
      csettingsIntegrationRenovateHub: "Integración de RenovateHub",
      csettingsIntegrationCAPIFB: "Integración de la API de Conversiones de Facebook",
      paymentPlanEnd: "Fecha de finalización del plan",
      paymentPlanLeadsLimitForYear: "Límite de leads por año:",
      paymentPlanLeadsLimitForPeriod: "Límite de leads por período:",
      EditTags: "Editar etiquetas",
      quizzesTemplatesNoTags: "Sin etiquetas",
      Monday: "Lunes",
      Tuesday: "Martes",
      Wednesday: "Miércoles",
      Thursday: "Jueves",
      Friday: "Viernes",
      Saturday: "Sábado",
      Sunday: "Domingo",
      datetimeDisplayMode: "Tipo de selector",
      datetimeDisplayModeSelect: "Seleccionar tipo",
      datetimeDisplayModeDate: "Fecha",
      datetimeDisplayModeTime: "Hora",
      datetimeDisplayModeDatetime: "Fecha y Hora",
      datetimeMinDate: "Fecha mínima",
      datetimeMinDateCustom: "Seleccione la fecha mínima",
      datetimeMaxDate: "Fecha máxima",
      datetimeMaxDateCustom: "Seleccione la fecha máxima",
      datetimeProhibitDaysOfWeek: "Restringir días",
      datetimeIsRange: "Habilitar rango",
      datetimeIsRangeTrue: "Rango habilitado",
      datetimeIsRangeFalse: "Rango deshabilitado",
      datetimeMinTime: "Hora mínima",
      datetimeMaxTime: "Hora máxima",
      datetimeIs24: "Formato de 24 horas",
      datetimeIs24True: "Formato de 24 horas habilitado",
      datetimeIs24False: "Formato de 24 horas deshabilitado",
      csanyIsExactDate: "¿Cómo le gustaría establecer la fecha?",
      csanyIsExactDateTrue: "Fecha exacta",
      csanyIsExactDateFalse: "Fecha relativa",
      csanyDatetimeMinMaxNotExactPlaceholder:'"x" o "-x" días desde hoy',
      addNewSectionDatetime: "Fecha/Hora",
      tariffsPromo: "Ofertas especiales",
      tariffsMonth: "Mensualmente",
      tariffsAnual: "Anualmente",
      leadsOn1Month: "por mes",
      leadsOn2Month: "por 2 meses",
      leadsOn3Month: "por 3 meses",
      leadsOn4Month: "por 4 meses",
      leadsOn5Month: "por 5 meses",
      leadsOn6Month: "por 6 meses",
      leadsOn7Month: "por 7 meses",
      leadsOn8Month: "por 8 meses",
      leadsOn9Month: "por 9 meses",
      leadsOn10Month: "por 10 meses",
      leadsOn11Month: "por 11 meses",
      leadsOn12Month: "por año",
      leadsOn24Month: "por 2 años",
      leads: "prospectos",
      tariffsWordPrice: "Precio",
      tariffsNoLimits: "Sin límites",
      tariffsNoLimitsQuizzesAndFunctions: "en encuestas y funcionalidades",
      billingTariffYear: "año",
      billingChangePlanSubscribe: "Suscribirse",
      paymentAllTariffInactive: "Tarifa no activa",
      subscriptionTariffLableOnly: "Disponible solo para suscripción",
      notExactBeforeYear: "hace un año",
      notExactBeforeHalfYear: "hace medio año",
      notExactBeforeQuarter: "hace un cuarto de año",
      notExactBeforeMonth: "hace un mes",
      notExactBeforeWeek: "hace una semana",
      notExactBeforeYesterday: "anteayer",
      notExactYesterday: "ayer",
      notExactToday: "hoy",
      notExactTomorrow: "mañana",
      notExactAfterTomorrow: "pasado mañana",
      notExactAfterWeek: "en una semana",
      notExactAfterMonth: "en un mes",
      notExactAfterQuarter: "en un cuarto de año",
      notExactAfterHalfYear: "en medio año",
      notExactAfterYear: "en un año",
      tipDefaultDateHeader: "Por defecto",
      tipDefaultDateText: "Si los interruptores de 'Fecha Exacta' y 'Fecha Relativa' están desactivados, el calendario permitirá seleccionar fechas desde el año 1800 hasta el 2200 de manera predeterminada.",
      tipExactDateHeader: "Interruptor de fecha exacta",
      tipExactDateText: "Activar este interruptor limitará el calendario a un rango de fechas fijo definido por la(s) fecha(s) exacta(s) seleccionada(s) en los campos de entrada a continuación.",
      tipRelativeDateHeader: "Interruptor de fecha relativa",
      tipRelativeDateText: "Activar este interruptor establecerá dinámicamente los límites del calendario según las fechas relativas calculadas en el momento de cargar la página. Estos límites son personalizados y se recalculan individualmente para cada usuario.",
      langPT: "Portugués",
      placeholderCompanyContactText: 'Por ejemplo: "Teléfono de la empresa: +34993322211"',
      integrationConnectService: "Conectar",
      leadtype_id: "Tipo de acción",
      selectVariant: "Seleccionar",
      sfirstAddCompanyContacts: "Texto con contactos",
      addNewSectionTextarea: "Campo de texto grande",
      allstepsVariantView: "Formato de aislamiento",
      tariffsDiscountSave: "Ahorra",
      cAdminSettingsTitle: "Configuraciones administrativas",
      cAdminSettingsInstruction: "Ingrese las etiquetas para el cuestionario en el campo a continuación. Las etiquetas pueden consistir en varias palabras, cada etiqueta se separa con una coma. Las etiquetas 'Servicios' y 'servicios' son etiquetas diferentes. Se recomienda usar etiquetas que comiencen con una letra mayúscula. También puede excluir el cuestionario de la lista de plantillas.",
      cAdminSettingsIsTemplate: "Es una plantilla",
      cIsTemplateStateAreSaved: "El estado de la plantilla se ha guardado",
      sfirstAddLogotype: "Añade un logotipo",
      logotypeSizeTip: "El logotipo se insertará en su tamaño real, pero no será mayor de 120 píxeles en el lado más largo.",
      cfirstLogotypeUpdated: "El logotipo ha sido añadido",
      cfirstLogotypeDeleted: "¡El logotipo ha sido eliminado con éxito!",


      cOffersPageSettings: "Configuraciones",
      offerPageTitlePlaceholder: "Ingrese el título",
      offerPageDescriptionPlaceholder: "Ingrese la descripción de la página",
      offerPageButtonTextPlaceholder: "Ingrese el texto del botón (opcional)",
      allstepsOffers: "Lista de ofertas",
      offerProposalHeader: "Oferta",
      offerAttachAddImg: "Agregar imagen",
      offerAttachAddVideo: "Agregar video",
      offerItemImg: "Imagen",
      offerItemTitle: "Título de la oferta",
      offerTitlePlaceholder: "Ingrese el título aquí",
      offerItemSubtitle: "Subtítulo (ej. precio)",
      offerSubtitlePlaceholder: "Ingrese el texto aquí",
      offerItemDescription: "Descripción de la oferta",
      offerDescriptionPlaceholder: "Ingrese la descripción aquí",
      offerItemButtonText: "Texto del botón de la oferta",
      offerBtnTextPlaceholder: "Ingrese el texto del botón",
      offerItemButtonLink: "Enlace del botón",
      offerBtnLinkPlaceholder: "El enlace debe comenzar con https://",
      csanyOfferLogic: "Lógica de visualización",
      offerAddNewOffer: "Agregar nueva oferta",
      offerLogicSetupTitle: "Configuración de lógica",
      OfferLogicSetupDescription: "Aquí puedes configurar qué respuestas mostrarán ciertas ofertas",
      cstepsOffersPage: "Página de resultados",
      addNewSectionResultsByLogic: "Ofertas",
      cformActionAfterContactOffersOr: "Página de ofertas / ",
      tolltipOffersAfterContact: "Si después de completar el cuestionario se cumple la lógica para mostrar al menos una oferta, se mostrará la página de ofertas; de lo contrario, se mostrará la página de agradecimiento.",
      videoCircleTextQuestion: "¿Cómo funciona esto?",
      offerPhotoOptimalRatio: "Para una visualización óptima de la imagen, recomendamos usar una proporción de 16:9.",
      tolltipThankYouAfterContact: "Después de completar el cuestionario, se mostrará una página de agradecimiento.",
      cformActionAfterContactOffers: "Página de resultados",
      addOfferPageInContact: "Habilitar página de resultados",
      offerPageHeader: "Encabezado para la página de ofertas",
      pumkinOrange: "Naranja",



    },
    PT: {
      madeInUkr: "Feito na Ucrânia",
      upProVersion: "Mudar para PRO",
      getLeads: "Obter leads",
      poperBillings: "Faturamento",
      poperTemplates: "Modelos",
      poperPartnership: "Parceria",
      poperProfile: "Perfil",
      poperLogout: "Sair",
      defaultNewQuestion: "Nova pergunta",
      defaultNewQuestionDescription: "Aqui você pode inserir uma breve descrição da pergunta (até 80 caracteres)",
      defaultNewVariant: "Nova opção",
      defaultBtnText: "Iniciar",
      navBillings: "Faturamento",
      navPartnership: "Parceria",
      navProfile: "Perfil",
      welcomeBack: "Bem-vindo de volta!",
      loginHeader: "Entrar",
      loginHeader2Part: "para continuar",
      loginEmailPlaceholder: "E-mail",
      loginPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○"	,
      loginBtnValue: "Entrar",
      loginWith: "Entrar com",
      passwordAreForgetPassword: "Esqueceu sua senha?",
      passwordResetPasswordHeader: "Redefinição de senha",
      passwordResetPassword: "Redefinir senha",
      passwordWeSentReset: "Siga as instruções no e-mail que enviamos para redefinir sua senha",
      passwordInputNewPassword: "Digite uma nova senha",
      passwordConfirmPassword: "Confirmar senha",
      passwordChangePassword: "Salvar senha",
      passwordResetedPasswordCompleted: "Sua senha foi alterada com sucesso!",
      passwordGoToWorkboard: "Ir para o painel de trabalho",
      loginSignup: "Cadastrar-se",
      helloHeader: "Olá!",
      signupHeader: "Cadastrar-se",
      signupHeader2Part: "para continuar",
      signupNamePlaceholder: "Oleksander",
      signupPhonePlaceholder: "número de telefone",
      signupEmailPlaceholder: "E-mail",
      signupPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○"	,
      signupBtnValue: "Criar uma conta",
      signupWith: "Cadastrar-se com",
      signupLogin: "Entrar em uma conta",
      signupTariffHeader: "Tarifa",
      signupTariffFree: "Grátis.",
      signupTariffPro: "Pro."	,
      signupTariffFreeDescription: "Número ilimitado de criação de novos questionários (páginas da web). Capacidade de gerar até 300 aplicações por mês gratuitamente.",
      signupTariffProPrice: "$20/mês",
      signupTariffProDescription: "Número ilimitado de criação de novos questionários (páginas da web). Capacidade de gerar ",
      signupTariffProDescriptionBold: "até 300 aplicações por mês.",
      userBackToQuiz: "Voltar para o painel de trabalho",
      userBackToSettings: "Voltar para Configurações",
      billingYourTariff: "Seu plano",
      billingYourPro: "Pro",
      billingYorFree: "Grátis",
      billingFreeLeads: "Leads gratuitos",
      billingInfinity: "ilimitado",
      billingTariffRenewData: "Data de renovação da tarifa",
      billingTariffEndData: "Data de término do plano",
      billingOtherTariffFree: "Grátis.",
      billingOtherTariffPro: "Pro."	,
      billingTariffFreeDescription: "Número ilimitado de criação de novos questionários (páginas da web). Capacidade de gerar até 300 aplicações por mês gratuitamente.",
      billingYouHavePro: "Se você está atualmente na versão Pro, por que dar um passo para trás?",
      billingYouHasFreeLeads: "Leads gratuitos",
      billingYouHasLeads: "Leads restantes",
      billingTariffProDescription: "Número ilimitado de novos questionários (páginas da web). Capacidade de gerar ",
      billingTariffProDescriptionBold: "mais de 300 aplicações.",
      billingYourFutureTariff: "Seu plano futuro",
      billingBtnChangeToPro: "mudar para ",
      billingBtnRenewPro: "Renovar",
      billingBillings: "Faturamento",
      billingYouHaveNotBillings: "Você ainda não tem pagamentos",
      billingPaymentsMethod: "Método de pagamento",
      billingPaymentCard: "Cartão",
      billingChangeData: "Alterar dados",
      billingDeletePayMethod: "Excluir",
      billingModalDeleteShure: "Tem certeza de que deseja remover este método de pagamento?",
      billingModalDeleteCancel: "Cancelar",
      billingModalDeleteOk: "Excluir",
      billingModalDeleteSuccess: "Você excluiu seu cartão com sucesso",
      billingModalRegularPaymentDeleteSuccess: "Pagamentos regulares foram cancelados",
      billingModalRegularPaymentDeleteOk: "OK",
      billingYourMonthPlan: "Sua tarifa mensal",
      billingLeads: "leads",
      billingChangePlan: "Alterar a tarifa",
      billingChangePlanDescription: "O preço corresponderá ao número desejado de aplicativos de todos os questionários em sua conta.",
      billingSelectPeriod: "Selecionar período",
      billingChangePlanDescriptionImportant: "Importante:",
      billingChangePlanDescriptionImportantMonth: "Leads podem ser usados 1 mês após a compra.",
      subscriptionTarifLable: "Assine nossa assinatura mensal automática. Você pode mudar seu plano a qualquer momento.",
      billingChangePlanWarning: "",
      billingTariffMonth: "mês.",
      billingChangePlanPay: "Mudar o plano",
      profileName: "Nome",
      profilePhone: "Número de telefone",
      profileEmail: "E-mail",
      profileStatus: "Status",
      profileStatusAgency: "Agência",
      profileStatusPlaceholder: "Qual te descreve melhor?",
      profileStatusFreelancer: "Freelancer",
      profileStatusBuisines: "Dono de negócio",
      profilePassword: "Senha",
      profileChangePassword: "Alterar senha",
      profileNewPassword: "Nova senha",
      profileSaveNewData: "Atualizar dados",
      workboardAddNewQuiz: "Criar projeto",
      workboardModalGiveQuizName: "Dê um nome ao questionário",
      workboardModalQuizNamePlaceholder: "Novo questionário",
      workboardModalNewQuizBtnValue: "adicionar",
      workboardAlertGiveAnyName: "Insira qualquer nome",
      workboardAlertNextTimeAddQuiz: "Bem, na próxima vez, atreva-se)",
      workboardChangeQuizName: "Mudar o nome",
      workboardQuizLink: "link",
      workboardQuizLinkCopied: "O link foi copiado para a área de transferência",
      workboardEditQuiz: "Editar",
      workboardCopyQuiz: "Copiar o questionário",
      workboardQuizCopied: "Questionário copiado com sucesso",
      workboardDeleteQuiz: "Excluir",
      workboardModalRemoveQuizTrash: "Este questionário será movido para a lixeira e excluído permanentemente após 1 mês.",
      workboardModalRemoveQuizSure: "Tem certeza de que deseja mover este questionário para a lixeira?",
      workboardModalDeleteQuizCancel: "Cancelar",
      workboardModalDeleteQuizDelete: "Excluir",
      workboardModalDeleteQuizSuccess: "Você excluiu este questionário com sucesso",
      workboardModalQuizDeleteOk: "OK",
      workboardStatsTitle: "Estatísticas",
      workboardModalLeadsAnswersCount: "Respostas às perguntas",
      workboardModalLeadsLeadSerial: "№",
      workboardModalLeadsLeadDate: "Data",
      workboardModalLeadsLeadEmail: "E-mail",
      workboardModalLeadsLeadName: "Nome",
      workboardModalLeadsLeadPhone: "Telefone",
      workboardModalLeadsLeadActions: "Ações",
      workboardModalLeadsLeadShowMore: "Ver mais",
      workboardModalLeadsLeadHideMore: "Ocultar",
      workboardModalLeadsLeadAnswerScipped: "Ignorado",
      workboardModalNoLeads: "Ainda não há leads",
      constructorLink: "link",
      constructorLinkCopied: "Copiado para a área de transferência:",
      constructorNavSteps: "Passos",
      constructorNavDesign: "Design",
      constructorNavSettings: "Configurações",
      constructorStep: "Etapa",
      cancel: "Cancelar",
      constructorCancel: "Cancelar",
      constructorSave: "Salvar",
      constructorextraNavStart: "S",
      constructorextraNavContact: "C",
      constructorextraNavFinish: "F",
      cstepsSection: "Seções",
      cstepsStartPage: "Página inicial",
      cstepsContactPage: "Formulário de contato",
      cstepsFinishPage: "Página final",
      cstepsEdit: "Editar",
      cstepsMove: "Mover",
      cstepsDeleteStep: "Excluir etapa",
      cstepsAddSection: "Adicionar seção",
      cdesignColorTheme: "Tema de cor",
      cdesignCustomTheme: "Tema de cor personalizado",
      cdesignDefaultThemes: "Nossos temas de cor",
      cdesignThemeStandart: "Padrão",
      cdesignThemeDark: "Escuro",
      cdesignThemeNud: "Nude",
      cdesignThemeGradient: "Gradiente",
      cdesignShapeDesign: "Design de formulário",
      cdesignShapeRound: "Arredondado",
      cdesignShapeSquare: "Angular",
      csettingsIntegration: "Integração",
      csettingsOweDomain: "Conectando domínio próprio",
      csettingsDocs: "Documentos legais",
      cintegrationIntegration: "Integração",
      cintegrationShareLeads: "Compartilhar leads",
      cintegrationTitleEmail: "E-MAIL",
      cintegrationInstruction: "Instrução",
      cintegrationInstructionDetail: "Detalhes",
      cintegrationEmailInstruction: "Dispare um e-mail para o respondente e notifique sua equipe. Colabore com sua equipe.",
      cintegrationPublishAfterEdit: "Salve o questionário após a edição",
      cintegrationSendNewLeadsTo: "Enviar mensagens sobre novos leads para:",
      cintegrationInputEmail: "Insira o e-mail",
      cintegrationAddEmail: "Adicionar e-mail",
      cintegrationEmailSaved: "E-mail salvo",
      cintegrationEmailDeleted: "E-mail excluído",
      cintegrationTitleFB: "Pixel FB",
      cintegrationIDPixel: "ID de pixel",
      cintegrationFBInstruction1: "Vá para o gerenciador de anúncios do Facebook e copie o ID do pixel na seção Gerenciador de Eventos.",
      cintegrationFBInstruction2: "Não copie o código inteiro, apenas o ID de 15-16 dígitos.",
      cintegrationFBInstruction3: "Copie e cole na caixa abaixo.",
      cintegrationFBInstruction: "O evento para o qual você precisa configurar a conversão é 'Lead'. Este evento será acionado assim que o usuário enviar uma aplicação no questionário.",
      cintegrationFBInstructionTextTitle: "Lista de todos os eventos que enviamos:",
      cintegrationFBInstructionTextTextStart: " – visita à página inicial;",
      cintegrationFBInstructionTextQuestionN: " – visita à etapa N;",
      cintegrationFBInstructionTextOpenContactPage: " – visita à página de contatos;",
      cintegrationFBInstructionTextLead: " – envio bem-sucedido do formulário de contato;",
      cintegrationFBInstructionTextThankYou: " – visita à página final.",
      bold_adsquiz_Start: "adsquiz_Start",
      bold_adsquiz_QuestionN: "Question_N",
      bold_adsquiz_OpenContactPage: "Open_Contact-Page",
      bold_adsquiz_Lead: "Lead",
      bold_adsquiz_ThankYou: "adsquiz_ThankYou",
      cintegrationInputIDPixel: "Insira o ID do pixel",
      cintegrationDataFBSaved: "Dados do pixel FB salvos",
      cintegrationAccesToken: "TOKEN DE ACESSO",
      cintegrationTitleGA: "Google analytics",
      cintegrationGAInstructionDescription: "Aprenda como as pessoas estão encontrando e interagindo com seu questionário. Obtenha os dados necessários para analisar campanhas, melhorar conversões e muito mais.",
      cintegrationGAInstruction: "Quando uma aplicação de questionário é recebida no Google Analytics, o evento 'generate_lead' será acionado.",
      cintegrationIDFolow: "ID de acompanhamento",
      cintegrationInputID: "Insira o ID",
      cintegrationDataGASaved: "Dados do Google Analytics salvos",
      cintegrationTitleGTM: "Gerenciador de tags do Google",
      cintegrationGTMInstructionDescription: "Descrição para o Gerenciador de tags do Google.",
      cintegrationGTMInstruction: "Copie o ID do Gerenciador de tags do Google e cole no campo abaixo.",
      cintegrationDataGTMSaved: "Dados do Gerenciador de tags do Google salvos",
      cintegrationTitleTG: "Telegram",
      cintegrationHowReciveTGLeadBot: "Como obter leads no Telegram?",
      cintegrationTGInstructionBot1: "1. Copie o token no campo abaixo.",
      cintegrationTGInstructionBot2: '2. Siga o link para o bot e clique no botão "START".',
      cintegrationTGInstructionBot3: "3. Envie o token copiado como mensagem para o nosso bot.",
      cintegrationHowReciveTGLeadChat: "Como obter aplicações no chat do Telegram?",
      cintegrationTGInstructionChat1: "1. Adicione nosso bot ao chat desejado e defina o status de administrador.",
      cintegrationTGInstructionChat2: "2. Copie o token no campo abaixo e envie para o chat desejado.",
      cintegrationTGInstructionChat3: "3. Aguarde a resposta `OK! Seu token de quiz foi salvo!”",
      cintegrationTGInstructionChat4: "4. Os leads agora serão enviados para este chat.",
      cintegrationTGInstructionWarning: "Importante:",
      cintegrationTGInstructionWarningText: "Observe que este deve ser um grupo, não um canal do Telegram. Os leads não serão enviados para o canal do Telegram.",
      cintegrationTGBotLink: "link do chatbot",
      cintegrationTGTitleToken: "TOKEN",
      cintegrationInputTGToken: "Insira o token do chatbot do Telegram",
      cintegrationAddIntegration: "Adicionar integração",
      cintegrationDataTGSaved: "Dados de integração do Telegram salvos",
      cintegrationTitleTT: "Pixel TT",
      cintegrationTTInstruction: "Para rastrear eventos na sua conta de anúncios do Tik-Tok, copie o ID do pixel e cole no campo abaixo.",
      cintegrationDataTTSaved: "Dados do pixel TT salvos",
      cintegrationTitleWH: "WebHook",
      cintegrationWHInstruction1: "Você pode notificar aplicativos de terceiros sobre leads recebidos. Para fazer isso, especifique o URL para o qual o WebHook será enviado.",
      cintegrationWHInstruction2: "Preparamos",
      cintegrationWHInstruction3: "instrução em vídeo",
      cintegrationWHInstruction4: "com uma descrição detalhada desta funcionalidade.",
      cintegrationWHInstruction5: "Ver vídeo.",
      cintegrationWHInstruction6: "Neste vídeo, veremos como passar um hook com base no envio de dados para uma planilha do Google e enviar dados para um sistema CRM do Trello.",
      cintegrationWHInstruction7: "Recomendamos o uso do serviço para este propósito.",
      cintegrationWHInstruction8: "Ao se registrar usando este link, nossos usuários recebem um bônus de 10.000 ações gratuitas para qualquer integração.",
      cintegrationWHInstruction9: "Link",
      cintegrationWHInstruction10: "para se registrar com um bônus.",
      cintegrationWHUrl: "As URLs do WebHook",
      cintegrationInputWHUrl: "Insira o URL do WebHook",
      cintegrationAddWH: "Adicionar um WebHook",
      cintegrationWebHookDataSaved: "Dados do WebHook salvos",
      cintegrationComonWHInstructionShort: "Você pode notificar aplicativos de terceiros sobre os leads recebidos. Especifique o URL do WebHook para transmissão de dados.",
      cintegrationComonWHInstruction1Title: "1. Insira o URL do WebHook no campo de entrada:",
      cintegrationComonWHInstruction1Description: "Cole o URL do WebHook fornecido pelo seu serviço ou aplicativo no campo de entrada.",
      cintegrationComonWHInstruction2Title: "2. Salvar alterações:",
      cintegrationComonWHInstruction2Description: 'Clique no botão "Salvar alterações" para salvar as configurações do WebHook.',
      cintegrationComonWHInstruction3Title: "3. Concluído:",
      cintegrationComonWHInstruction3Description: "Agora seu WebHook está configurado para enviar dados ao serviço selecionado.",
      cintegrationComonWHInstructionAdditional: "Os dados serão enviados ao WebHook com cada lead recebido. O método POST é usado para a transmissão. Um exemplo dos dados que serão enviados está localizado ",
      cintegrationComonWHInstructionAdditionalLink: "aqui",
      cintegrationComonWHInstructionVideo: "Instrução em vídeo detalhada",
      cintegrationApixDriveWHInstructionShort: "Você pode notificar o ApixDrive sobre os leads recebidos. Para fazer isso, especifique o URL para o qual o WebHook será enviado.",
      cintegrationApixDriveWHInstructionShort1: "Ao se registrar através de ",
      cintegrationApixDriveWHInstructionShort2: "este link",
      cintegrationApixDriveWHInstructionShort3: " nossos usuários recebem um bônus de 10.000 ações gratuitas para qualquer integração.",
      cintegrationApixDriveWHInstruction1Title: "1. Registre-se ou abra sua conta ApixDrive:",
      cintegrationApixDriveWHInstruction1Description1: "Link ",
      cintegrationApixDriveWHInstruction1Description2: "para se registrar com um bônus.",
      cintegrationApixDriveWHInstruction2Title: "2. Obtenha o endereço URL para o WebHook:",
      cintegrationApixDriveWHInstruction2Description1: "Vá para as configurações da conexão necessária e copie o endereço para o envio do WebHook:",
      cintegrationApixDriveWHInstruction2Description2: 'Você pode criar uma nova conexão clicando no botão "Criar Conexão" e na etapa de seleção do sistema, escolha "ADSQuiz".',
      cintegrationApixDriveWHInstruction3Title: "3. Insira o URL do WebHook:",
      cintegrationApixDriveWHInstruction3Description: "Cole o URL do WebHook fornecido pelo ApixDrive no campo de entrada.",
      cintegrationApixDriveWHInstruction4Title: "4. Salvar alterações:",
      cintegrationApixDriveWHInstruction4Description: 'Clique no botão "Salvar alterações" para salvar as configurações do WebHook.',
      cintegrationApixDriveWHInstruction5Title: "5. Concluído:",
      cintegrationApixDriveWHInstruction5Description: "Agora seu WebHook está configurado para enviar dados ao ApixDrive.",
      cintegrationApixDriveWHInstructionAdditional: "Os dados serão enviados ao WebHook com cada lead recebido. O método POST é usado para a transmissão. Um exemplo dos dados que serão enviados está localizado ",
      cintegrationApixDriveWHInstructionAdditionalLink: "aqui",
      cintegrationApixDriveWHInstructionVideo: "Instrução em vídeo detalhada",
      cintegrationConectOweDomain: "Conectando seu próprio domínio",
      cintegrationOweDomainInstruction1: "1. Para colocar seu quiz no domínio necessário, faça login na sua conta de hospedagem. Vá para as configurações de DNS e crie um registro A com o parâmetro IP: 185.25.117.21",
      cintegrationOweDomainInstruction2: "2. Insira seu domínio acima e salve o quiz.",
      cintegrationDomain: "Domínio",
      cintegrationInputYourDomain: "Insira seu domínio",
      cintegrationCheckDomenIsCorrect: "Verifique se o domínio inserido está correto",
      cintegrationDomainWhichCorrect: "Insira um domínio sem protocolos. Por exemplo: some.site.com",
      cintegrationInsertedDefaultDomain: "O domínio é definido por padrão",
      cintegrationDomainDataSaved: "Dados do domínio salvos",
      cintegrationPrivacyPolicy: "Política de privacidade",
      cintegrationPrivacyPolicyByDefault: "Por padrão, usamos nossa política de privacidade padrão. Recomendamos adicionar sua política de privacidade relevante para o seu negócio.",
      cintegrationPrivacyPolicyAdventage: "Isso deixará claro para seus clientes potenciais como você usa as informações de contato recebidas. Isso também é exigido por algumas plataformas de publicidade.",
      cintegrationPrivacyPolicyUrl: "Link para documentos",
      cintegrationPrivacyPolicyInputYourDocsLink: "Inserir link para documentos",
      cintegrationDocsDataSaved: "Link para seus documentos salvo",
      allstepsUpploadPhoto: "Carregar uma imagem",
      allstepsChangePhoto: "Alterar a imagem",
      allstepsDeletePhoto: "Excluir imagem",
      allstepsTitle: "Título",
      allstepsDescription: "Descrição",
      allstepsButtonText: "Texto do botão",
      allstepsOtherSettings: "Outras configurações",
      allstepsIsSaveBeforeRoute: "Salvar dados antes de mudar a rota?",
      cfirstFormat: "Formato",
      cfirstHeaderStartText: "Seu título deve estar aqui. Motive os usuários a completar o quiz até o final",
      cfirstAddMediafile: "Adicionar mídia (imagem)",
      cfirstBonus: "Bônus",
      cfirstAddBonus: "Adicionar bônus",
      cfirstStartPageDataUpdated: "Os dados da página inicial foram atualizados",
      csanyAnswerType: "Tipo de pergunta",
      csanyATCard: "cartão",
      csanyATList: "lista",
      csanyATCustom: "campo livre",
      csanyQuestion: "Pergunta",
      csanyAnswers: "Respostas",
      csanyAddAnswer: "Adicionar opção",
      csanyTitleForModalConfirm: "Esta ação é irreversível!",
      csanyQuestionForModalConfirm: "Você tem certeza de que deseja excluir esta pergunta?",
      csanyRequiredStep: "Etapa obrigatória",
      csanyAlowMultiple: "Permitir várias respostas",
      csanyAutoRedirect: "Próxima pergunta automática",
      csanyQuestionLogic: "Configuração de lógica da pergunta",
      csanyDataStepUpdated: "Etapa de dados atualizada",
      cformForm: "Formulário",
      cformValueName: "Nome",
      cformValueSurname: "Sobrenome",
      cformValuePhone: "Número de telefone",
      cformValueEmail: "E-mail",
      cformDataContactsPageUpdated: "Página de contatos de dados atualizada",
      cformRequiredInput: "Obrigatório",
      cfinishFinishPageTitle: "Página de conclusão",
      cfinishText: "Texto",
      cfinishDescription: "Descrição",
      cfinishAddImage: "Adicionar uma imagem",
      cfinishImageAdded: "Imagem adicionada",
      cfinishURL: "URL",
      cfinishButtonText: "Texto do botão",
      cfinishInsertNikname: "Inserir apelido",
      cfinishCheckDataIsCorrect: "Verifique se o ID inserido está correto",
      cfinishFbWhichCorrect: "Insira um ID sem outro endereço. Por exemplo: some.name",
      cfinishIgWhichCorrect: "Insira um ID sem outro endereço. Por exemplo: some.name",
      cfinishTgWhichCorrect: "Insira um ID sem outro endereço. Por exemplo: some.name",
      cfinishFinishPageDataUpdated: "Os dados da página de conclusão foram atualizados",
      adminUsersReg: "Usuários no sistema",
      adminNewUsers: "Novos usuários por mês",
      adminProAccounts: "Contas PRO",
      adminLTV: "LTV",
      adminSharePro: "PRO em relação a todos",
      adminReciveLeads: "Leads recebidos",
      adminLeadsForOne: "Leads por usuário",
      adminMadeQuiz: "Quizzes criados",
      adminQuizForOne: "Quiz por usuário",
      adminAvarageUsed: "Média de meses de uso",
      adminProfitMonth: "Mês de lucro",
      adminProfitAlltime: "Ganho de todos os tempos",
      adminUsersList: "Base de usuários",
      notificationErrorLogin: "Erro de autorização:",
      notificationLogin: "Entrar...",
      notificationWrongLoginData: "Detalhes de login incorretos",
      notificationNetworkError: "Verifique sua conexão com a internet",
      notificationInputLoginData: "Inserir dados de login",
      notificationCheckInputEmail: "Verifique se o endereço de e-mail está correto",
      notificationCheckInputPhone: "Verifique se o número de telefone está correto",
      notificationInputAllInputs: "Preencha todos os campos para registro",
      notificationAgreePrivacyPolicy: "Aceitar a política de privacidade",
      adminEditorForPartnership: "Editor do programa de afiliados",
      developmentPage: "A seção está em desenvolvimento",
      signupAgreeIAgreeWith: "Eu concordo com",
      signupAgreePolicy: "a política de privacidade",
      signupAgreeAnd: "e",
      signupAgreeOffer: "a oferta pública",
      signupAgreeService: "do serviço",
      workboardModalLeadsLeadReferrer: "Poco claro",
      workboardModalLeadsLeadReferrerClarification: "Dados de referência bloqueados pela fonte ou indefinidos",
      workboardModalLeadsLeadEmailCopied: "E-mail copiado para a área de transferência",
      workboardModalLeadsLeadPhoneCopied: "Telefone copiado para a área de transferência",
      workboardModalLeadsHaveNotSended: "Você tem leads não enviados",
      workboardModalLeadsLeadReload: "Receber",
      workboardModalLeadsLeadReloadedTitle: "Solicitação recebida",
      workboardModalLeadsLeadReloaded: "",
      cfirstStartPagePhotoUpdated: "A foto da página inicial foi atualizada",
      allstepsPhotoTooBig: "O arquivo é muito grande!",
      allstepsPhotoMustBeLess: "Deve ser menor que",
      cfirstStartPageBonusPhotoUpdated: "A foto do bônus foi atualizada",
      cstepsQuestionsOrderSaved: "Ordem das perguntas salva",
      csanyAnswerOrderSaved: "Ordem das respostas salva",
      allstepsPhotoAdaptation: "Adaptação da foto para dispositivos",
      allstepsPhotoFit: "Proporção da imagem",
      constructorYouHideStep: "Você ocultou essa etapa",
      cstepsHiddenStep: "Ocultar etapa",
      cstepsShowStep: "Mostrar etapa",
      cfinishFinishPageHiddenUpdated: "A página de finalização está oculta.",
      cfinishFinishPageHiddenUpdatedGoContact: "Vá para a página do formulário de contato",
      cfinishFinishPageHiddenUpdatedSetRedirect: "e configure o redirecionamento após o envio dos dados",
      cformActionAfterContactRecive: "Acompanhamento das informações de contato",
      cformActionAfterContactThankyou: "Página de agradecimento",
      cformActionAfterContactRedirect: "Redirecionar",
      cformAdditionalSettings: "Configurações adicionais",
      cformRedirectURL: "URL",
      cintegrationOweDomainInstructionImportant: "Importante: O endereço na sua conta de hospedagem e o campo acima devem coincidir. O tempo de aplicação das mudanças nas configurações de DNS e extração do certificado SSL é de até 3 horas após salvar.",
      cintegrationOweDomainInstructionVideo: "Assistir instrução em vídeo",
      workboardModalLeadsLeadLimit: "Seu limite de leads foi atingido.",
      workboardModalLeadsLeadLimitInstruction1: "Para ver novos leads,",
      workboardModalLeadsLeadLimitInstruction2: "mude para outra tarifa",
      workboardModalLeadsLeadLimitInstruction3: "renove a tarifa atual",
      workboardModalLeadsLeadLimitInstruction4: '(não se aplica a "Grátis") ou espere que a tarifa seja atualizada se você tiver a renovação automática conectada',
      billingBonusesTitle: 'Código promocional',
      billingBonusesDescription: 'Digite o código promocional que você recebeu no próximo campo',
      billingBonusesPlaceholder: 'Código promocional',
      billingBonusesBtn: 'Ativar',
      billingBonusesActivated: 'Código promocional ativado!',
      billingBonusesLeadIncreased: 'O número de leads disponíveis foi aumentado.',
      billingBonusesInvalid: 'Código promocional incorreto',
      billingBonusesNotAvailable: 'O código promocional não está mais disponível',
      billingBonusesAlreadyActivated: 'Este cupom é inválido',
      createQuizSPDescription: "Há espaço para a descrição curta",
      createQuizCPTitle: "Como podemos entrar em contato com você?",
      createQuizCPDescription: "Por favor, preencha o formulário abaixo",
      createQuizCPButton: "Feito!",
      createQuizFPTitle: "Obrigado!",
      createQuizFPDescription: "Agora estamos procurando a melhor solução para você!",
      createQuizFPButton: "Mostre-me algo!",
      selectInternationalPrefix: "Selecione o prefixo internacional",
      autoInternationalPrefix: "Determinar o país automaticamente",
      dovnloadCsvTitle: "Exportar dados para o período selecionado",
      dovnloadCsvSelectAllPeriod: "Escolher para todo o período",
      dovnloadCsvCancel: "Cancelar",
      dovnloadCsvExport: "Exportar",
      widgetEmbedVidgetToSite: "Incorporar quiz no próprio site",
      widgetEmbedVidgetSelectEmbedVariant: "Selecione a variante de incorporação",
      widgetEmbedVidgetSitecode: "No código do site",
      widgetEmbedVidgetPopup: "Pop-up",
      widgetEmbedVidgetChat: "Botão do quiz",
      widgetEmbedVidgetInProgres: "Em desenvolvimento",
      widgetEmbedVidgetToSiteCode: "Incorporar quiz no site",
      widgetSiteCodeInstruction0: "Para incorporar o quiz no seu site, adicione o código do primeiro campo na tag <head> do seu site ou no Google Tag Manager se estiver integrado no seu site. Se após incorporar o quiz no seu site você mudar o domínio do quiz, precisará atualizar o código de integração no site.",
      widgetSiteCodeInstruction1: "No local onde deseja integrar o quiz, insira o código do segundo campo.",
      widgetSiteCodeInstruction2: "O quiz pode ser incorporado em um contêiner de qualquer tamanho, até mesmo em barras laterais! O tamanho máximo do quiz, bem como em um domínio separado, é de 900x552 pixels. Pode ser adicionado a contêineres maiores, caso em que o quiz será centralizado.",
      widgetSiteCodeInstruction3: "Não seja tímido - experimente!",
      widgetSiteCodeCopyToHead: "Copie e cole no <head> do seu site",
      widgetSiteCodeCopyToBody: "Cole o código onde o quiz deve estar",
      workboardModalAddQuizHeader: "Criar novo quiz",
      workboardModalGiveQuizNameAndMethod: "Crie um nome para o seu quiz e escolha como deseja criá-lo",
      workboardModalNewQuizFromStart: "Criar do zero",
      workboardModalNewQuizFromTemplates: "Modelos prontos",
      QuizTemplatesSearchInput: "Nome do modelo e/ou categorias",
      QuizTemplatesSearchBtn: "Buscar",
      QuizTemplatesReadyTemplates: "Modelos prontos",
      QuizTemplateView: "Ver",
      QuizTemplateSelect: "Escolher",
      widgetPopupInstruction0: "Para instalar o quiz no seu site, adicione o código da caixa de código na tag <head> do seu site ou no Google Tag Manager se estiver integrado no site. Se, depois de já ter inserido um quiz no seu site, você alterar o domínio do quiz ou abrir os gatilhos (percentual de rolagem ou temporização), será necessário atualizar o código de integração no site.",
      widgetPopupInstruction1: "Logo antes da tag de fechamento </footer>, cole o código do segundo campo.",
      widgetPopupInstruction2: "Para programadores ou usuários avançados",
      widgetPopupInstruction3: "Se, por algum motivo, você não se sentir confortável em inserir um quiz antes da tag de fechamento </footer>, poderá colar o código do segundo campo em qualquer lugar conveniente, mas tenha em mente que o contêiner no qual você cola o código do segundo campo deve estar no topo de todas as janelas e, de preferência, ter a altura e largura máximas possíveis para a exibição.",
      widgetPopupCopyToHead: "Copie e cole no <head> do seu site",
      widgetPopupCopyToBody: "Cole o código antes da tag de fechamento </footer>",
      widgetPopupTimeToOpen: "Um período de tempo",
      secondReduction: "seg",
      widgetPopupTimeClarification: "O popup aparecerá com um atraso do tempo acima em segundos",
      widgetPopupScrollToOpen: "Porcentagem de rolagem do site",
      widgetPopupScrollClarification: "O popup aparecerá após o percentual de rolagem do seu site especificado acima",
      quizzesTemplatesAllTags: "Todos os modelos",
      widgetEmbedVidgetButton: "Construtor de botões",
      widgetButtonInstruction0: "Você pode configurar um quiz no seu site que abrirá quando você clicar no botão.",
      widgetButtonInstruction1: "Adicione uma borda, sombra e texto ao botão. Você também pode alterar a cor do botão e o texto interno.",
      widgetButtonInstruction2: "Copie o script colocado na primeira caixa de código e cole antes da tag de fechamento </head>.",
      widgetButtonInstruction3: "Copie o código do botão gerado no segundo campo de código e cole em qualquer lugar do seu site.",
      widgetButtonInstruction4: 'Você pode pular o código do segundo campo de botão e simplesmente adicionar id="button_open_quiz_popup" a qualquer elemento <input type="button"> ou <button></button> no site.',
      widgetRealTimeChange: "Você pode ver as alterações em tempo real no lado direito da tela",
      widgetButtonCornerRound: "Arredondamento de cantos do botão",
      widgetButtonShadow: "Sombra do botão",
      widgetButtonBgColor: "Cor do botão",
      widgetButtonTextColor: "Cor do texto",
      widgetButtonTextValue: "Texto do botão",
      openPopupQuizText: "Iniciar quiz",
      widgetButtonInsertBeforeAfterBody: "Insira o código do quiz imediatamente após a tag de abertura <body> ou imediatamente antes da tag de fechamento </body>",
      widgetSiteCodeCopyButton: "Cole o código do botão em qualquer lugar do site",
      footerContactUs: "Tem perguntas? Fique à vontade para nos contatar:",
      enterYourName: "Insira seu nome",
      enterYourPhone: "Insira seu número de telefone",
      enterYourEmail: "Insira seu e-mail",
      enterYourPassword: "Insira sua senha",
      selectOption: "Selecione a opção",
      createNewGroup: "Criar novo grupo",
      dontHaveAccount: "Não tem uma conta?",
      resetPasswordTip: "Se você tiver algum problema ou pergunta adicional, não hesite em contatar nossa equipe de suporte ao cliente. Estamos aqui para ajudar.",
      getBackAccount: "Voltar para sua conta",
      inMinutes: "em minutos",
      alreadyHaveAccount: "Já tem uma conta?",
      quizSlotLastEdit: "Última edição",
      workboardQuizzesTitle: "Meus quizzes",
      allstepsBack: "Voltar",
      constructorGetLink: "Link",
      allOr: "ou",
      allAnd: "e",
      acceptedFileType: "Formatos aceitos: ",
      conjunctionAnd: "e",
      acceptedFileSize: "Tamanho máximo: ",
      acceptedFileSizeMeasure: "MB",
      cfirstBonusDisabled: "Desabilitado",
      cfirstBonusAvailable: "Disponível",
      allstepsResetInHeader: "Redefinir",
      placeholderText: "Digite aqui",
      constructorSaveChanges: "Salvar",
      cformInputAvailable: "Disponível",
      cformInputDisabled: "Desabilitado",
      constructorBack: "Voltar",
      videoInstruction: "Instrução em vídeo",
      csettingsIntegrationFB: "Integração - Facebook Pixel",
      csettingsIntegrationTT: "Integração - TikTok",
      csettingsIntegrationGA: "Integração - Google Analytics",
      csettingsIntegrationGTM: "Integração - Google Tag Manager",
      csettingsIntegrationWH: "Integração - WebHook",
      csettingsIntegrationTG: "Integração - Telegram",
      csettingsIntegrationApixDrive: "Integração - APIxDrive",
      csettingsIntegrationEmail: "Integração - Email",
      csettingsIntegrationAbout: "Sobre",
      csettingsIntegrationFBAbout: "Vá para o gabinete de publicidade do Facebook Ads e copie o ID do Facebook Pixel na seção de Gerenciador de Eventos. Não copie o código completo, apenas o ID. Copie e cole na caixa abaixo.",
      csettingsIntegrationTTAbout: "Para rastrear eventos na sua conta de anúncios do Tik-Tok, copie o ID do pixel e cole-o na caixa abaixo.",
      csettingsIntegrationGAAbout: "Copie o ID do Google Analytics e cole no campo abaixo.",
      csettingsIntegrationGTMAbout: "Copie o ID do Google Tag Manager e cole no campo abaixo.",
      csettingsIntegrationWHAbout1: "Você pode notificar aplicativos de terceiros sobre leads recebidos. Para isso, especifique a URL para a qual o WebHook será enviado. Recomendamos o uso do serviço para este fim.",
      csettingsIntegrationWHAboutApix: "Apix-Drive",
      csettingsIntegrationWHAbout2: "Ao se registrar usando este link, nossos usuários recebem um bônus - 10.000 ações gratuitas para qualquer integração.",
      csettingsIntegrationTGAbout: "Importante: Observe que isso deve ser um grupo, não um canal do Telegram. Os leads não serão enviados para o canal do Telegram.",
      csettingsIntegrationADAbout: "Algo sobre APIxDrive",
      csettingsIntegrationEmailAbout: "Dispare um e-mail para o seu respondente e notifique sua equipe. Colabore com sua equipe.",
      cSettingsIntegrationNeedHelp: "Precisa de ajuda?",
      cSettingsIntegrationRefreshToken: "Atualizar token",
      csettingsSettingASettings: "Configurações avançadas",
      cSettingsSettingQuizLangTitle: "Idioma do quiz",
      cSettingsSettingQuizLangDescription: "Para garantir a melhor experiência, selecione o idioma desejado para o quiz nas opções abaixo. As notificações do sistema e outros textos constantes serão exibidos neste idioma ao completar o quiz.",
      selectQuizLanguage: "Selecionar idioma do quiz",
      cSettingsSettingQuizHideLabelTitle: "Ocultar rótulo",
      cSettingsSettingQuizHideLabelDescription: 'Ao habilitar esta opção, você pode desfrutar de uma interface sem o rótulo "feito por adsquiz", permitindo que você e seus visitantes foquem apenas nas perguntas.',
      cSettingDataSaved: "Configurações adicionais salvas",
      userAccountInfo: "Informações da conta",
      userGeneralInfoHeader: "Informações gerais",
      userGeneralInfoDescription1: "Seu perfil de usuário é onde você pode gerenciar sua conta e configurações.",
      userGeneralInfoDescription2: "Atualize suas informações pessoais, altere sua senha e ajuste suas preferências de notificação.",
      profileNameFirst: "Nome",
      profileNameLast: "Sobrenome",
      userSecuritySettingsHeader: "Configurações de segurança",
      userSecuritySettingsDescription: "Para alterar sua senha, basta inserir sua nova senha duas vezes para confirmar.",
      profileEmailCurent: "Endereço de e-mail atual",
      profileEmailNew: "Novo endereço de e-mail",
      profileNewPasswordConfirm: "Confirmar nova senha",
      profileDeleteAccount: "Excluir conta",
      profileDeleteAccountPopupHeader: "Excluir conta?",
      profileDeleteAccountPopupDescription: 'Tem certeza de que deseja excluir sua conta? Esta ação não pode ser desfeita. Para confirmar a exclusão, digite a palavra "EXCLUIR" no campo abaixo.',
      profileDeleteAccountCheckedWord: "EXCLUIR",
      profileDeleteAccountPopupPlaceholder: "Digite aqui",
      notificationSuccess: "Sucesso!",
      notificationSuccessSaveAccount: "Sua senha foi alterada com sucesso! Use uma nova senha para fazer login novamente",
      notificationSuccessDeleteAccount: "Sua senha foi alterada com sucesso! Use uma nova senha para fazer login novamente",
      cardPanUnspecified: "Não especificado",
      userBillingsDate: "Data",
      userBillingsCost: "Custo",
      userBillingsId: "ID",
      userBillingsMethod: "Método de pagamento",
      paymentSwitchToProHeader: "Mudar para Pro",
      paymentSwitchToProDescription: "Encontre o plano certo para você! Atualize ou faça downgrade a qualquer momento - facilitamos para você obter o plano que precisa.",
      paymentYourCurrentPlan: "Seu plano atual:",
      paymentYourFuturePlan: "Seu novo plano:",
      paymentPlanLeadsLimit: "Limite de leads por mês:",
      paymentPlanLeadsLimitForTest: "Limite de teste do seu quiz:",
      paymentPlanLeadsLeft: "Leads restantes:",
      paymentPlanPrice: "Preço:",
      paymentPlanRenew: "Data de renovação de tarifa:",
      addNewSectionHeader: "Adicionar nova seção",
      addNewSectionList: "Lista",
      addNewSectionCustom: "Campo de entrada",
      addNewSectionCard: "Cartões com fotos",
      addNewSectionWithEmoji: "Lista com emoji",
      addNewSectionTags: "Tags",
      addNewSectionSlider: "Slider",
      templatesBackToQuiz: "Voltar ao quadro de trabalho",
      constructorStepsSaved: "Todos os dados do quiz foram salvos com sucesso",
      constructorDesignSaved: "O design do quiz foi salvo com sucesso",
      azureBlueWhite: "Padrão",
      azureBlue: "Noite de inverno",
      moderatePurplishRed: "Veludo vermelho",
      violetBlueCrayola: "Brisa de inverno",
      wetRainforest: "Bordo de verão",
      lavender: "Espaço",
      blueLilac: "Lavanda escura",
      opalGreen: "Floresta dos Cárpatos",
      dustyBlue: "Tiffany",
      almondCrayola: "Recife de coral",
      periwinkleCrayolaLilac: "Lilás",
      periwinkleCrayolaAqua: "Synevir",
      jazzJam: "Jazz",
      slateGrayBlue: "Névoa",
      turquoiseBlueCrayola: "Diia",
      blackPearlescentOpal: "Cinza",
      pearlescentGentianBlue: "Safira",
      sapphireBlue: "Safira escura",
      errorUserDataUpdate: "Erro nos dados atualizados: ",
      completeUserDataUpdate: "Dados do usuário atualizados",
      passwordDoNotCoincidence: "As senhas não coincidem",
      passwordDoNotCoincidenceAgain: "Tente novamente",
      nonBillingsYet: "Você ainda não tem histórico de faturamento.",
      youCanChangePlan: "Você pode mudar o plano atual clicando no botão “Mudar para PRO”",
      rangeMin: "Mín",
      rangeMax: "Máx",
      rangePositionStart: "Início da posição",
      rangePositionEnd: "Fim da posição",
      rangeStep: "Passo",
      rangeIsRange: "Intervalo",
      rangeIsRangeTrue: "Disponível",
      rangeIsRangeFalse: "Desativar",
      recivePromotionNewsletter: "Receber newsletters por e-mail",
      reciveEmailCheckbox: "Caixa de receber e-mail",
      additionalCheckbox: "Caixa adicional",
      contactPrivacyPolicyCheckbox: "Caixa de política de privacidade de contato",
      contactAgreeIAgreeWith: "Eu concordo com",
      contactAgreePolicy: "a política de privacidade",
      contactAgreeAnd: "e",
      contactAgreeTermsOfUse: "os termos de uso",
      contactAgreeAgreementAdditionalWord: "",
      langDE: "Alemão",
      langEN: "Inglês",
      langIT: "Italiano",
      langKK: "Cazaque",
      langPL: "Polonês",
      langRU: "Russo",
      langSP: "Espanhol",
      langUA: "Ucraniano",
      langLT: "Lituano",
      langLV: "Letão",
      langET: "Estoniano",
      langRO: "Romeno",
      leadsLeft: "Leads restantes",
      generateDomainTitle: "Insira o nome de domínio",
      generateDomainDescription: "Este é o endereço exclusivo onde sua página estará acessível. Você pode alterar o endereço a qualquer momento ou conectar o seu próprio.",
      generateDomainDomainNameLabel: "Nome de domínio",
      cancelAllAction: "Cancelar",
      confirmAllAction: "Confirmar",
      falseHasValidCharacters: "Caracteres inválidos no domínio. Os caracteres válidos são letras, números e hífens.",
      falseHasValidLength: "O comprimento do domínio deve estar entre 6 e 20 caracteres alfanuméricos.",
      falseDoesNotStartOrEndWithSpecialChar: "O domínio não deve começar ou terminar com um caractere especial.",
      falseDoesNotHaveConsecutiveSpecialChar: "O domínio não deve conter dois ou mais caracteres especiais consecutivos.",
      falseDoesNotHaveConsecutiveSameChar: "O domínio não deve conter três ou mais caracteres idênticos consecutivos.",
      falseDoesNotContainForbiddenSubstrings: "O domínio não deve conter substrings proibidas.",
      generateDomainTaken: "Este domínio já está em uso. Tente outro",
      temporarilyUnavailable: "Temporariamente indisponível",
      csettingsAdmin: "Configurações para administradores",
      questionLogicSetupTitle: "Configuração de lógica de perguntas",
      questionLogicSetupDescription: "Adicione condições para ajudar a personalizar a pesquisa para cada respondente e coletar dados mais precisos.",
      questionLogicConditionTitle: "Condição",
      questionLogicQuestion: "Pergunta",
      questionLogicSetupQuestionDisableOption: "Selecione a pergunta",
      questionLogicSetupAnswer: "Resposta",
      questionLogicSetupLogicType: "Tipo",
      questionLogicSetupAnswerDisableOption: "Selecione o tipo de condição",
      questionLogicSetupRangeBetween: "Entre",
      questionLogicSetupRangeLess: "Menor",
      questionLogicSetupRangeMore: "Mais",
      questionLogicSetupRangeEqualTo: "Igual a",
      questionLogicSetupRangeFrom: "De",
      questionLogicSetupRangeTo: "Até",
      questionLogicSetupVariantAll: "Todos",
      questionLogicSetupVariantAny: "Qualquer",
      questionLogicSetupVariantNot: "Nenhum",
      questionLogicSetupCustomEqual: "Igual",
      questionLogicSetupCustomContains: "Contém",
      questionLogicSetupCustomNotContains: "Não contém",
      questionLogicSetupRangeValue: "Valor",
      questionLogicSetupExpectedAnswer: "Respostas esperadas",
      questionLogicSetupPlaceholder: "Digite aqui",
      questionLogicAllMandatory: "Todas as condições acima são obrigatórias",
      questionLogicAddConditionBtn: "Adicionar condição",
      questionLogicNotAvailable: "As configurações de lógica não estão disponíveis para a primeira pergunta",
      questionLogicSaved: "As configurações de lógica de exibição de etapas foram salvas",
      billingPaymentInfoTitle: "Informações de tarifa",
      billingPaymentInfoChangeTarrif: "Mudar tarifa",
      billingPaymentInfoDescription: "Quando você paga por qualquer tarifa, recebe um certo número de leads incluídos no pacote escolhido. Esses leads são fornecidos por um mês. Se você não usar seus leads dentro de um mês a partir do momento do pagamento, eles serão cancelados.",
      billingGeneralName: "Sua tarifa",
      billingGeneralCost: "Custo",
      billingMonth: "mês",
      billingGeneralExpired: "Válido até",
      billingGeneralLeadsLeft: "Leads restantes",
      billingGeneralAllTarifExpired: "Data de expiração da tarifa",
      billingGeneralBonus: "Leads com código promocional",
      billingGeneralLeadsFromPastTarifs: "Leads de tarifas anteriores",
      billingAcordeonPaymentInfoTitle: "Informações de pagamento",
      billingAcordeonPaymentInfoFormat: "Formato",
      billingAcordeonPaymentInfoSubscription: "Assinatura",
      billingAcordeonPaymentInfoPay: "Pagamento",
      billingAcordeonPaymentInfoPayMethod: "Método de pagamento",
      billingAcordeonPaymentInfoCancelSubscription: "Cancelar assinatura",
      billingAcordeonPromocode: "Código promocional",
      billingAcordeonPromocodeLabel: "Insira o código promocional",
      billingAcordeonPayHistory: "Histórico de transações",
      paymentAllTarifInactive: "a tarifa está inativa",
      subscriptionUnsubscribeModalTitle: "Cancelar assinatura",
      subscriptionUnsubscribeModalDescription: "Tem certeza de que deseja cancelar a assinatura? Os leads acumulados para a assinatura estarão disponíveis por um mês a partir da última data de faturamento.",
      subscriptionIsNotDeleteTitle: "Assinatura não cancelada",
      subscriptionIsNotDeleteDescription: "Por favor, entre em contato com o suporte ao cliente",
      subscriptionCanceledTitle: "Assinatura cancelada",
      modalSureUnsavedChangesTitle: "Você tem alterações não salvas",
      modalSureUnsavedChangesDescription: "Antes de sair da página atual, decida o que fazer com as alterações não salvas",
      modalSureUnsavedChangesDontSave: "Não salvar",
      modalSureUnsavedChangesSave: "Salvar",
      telegramTokenCopied: "O token do quiz foi copiado para a área de transferência",
      cSettingsSettingQuizBackButtonTitle: "Botão de voltar",
      cSettingsSettingQuizBackButtonDescription: "Você pode ativar ou desativar o botão de voltar. Isso permitirá alterar a resposta dada anteriormente.",
      cSettingsCustomThemePrimaryColor: "Cor dos acentos",
      cSettingsCustomThemeSecondaryColor: "Cor de fundo",
      cSettingsCustomThemeAdditionalColor: "Cor do texto",
      cSettingsCustomThemeButtonTextColor: "Texto no botão",
      cSettingsCustomThemeModeDark: "Escuro",
      cSettingsCustomThemeModeLight: "Claro",
      allstepsPlaceholder: "Espaço reservado",
      allstepsCustomInput: "Entrada personalizada",
      allstepsCustomInputOn: "Disponível",
      allstepsCustomPlaceholder: "Outro",
      trashcanTitle: "Lixeira",
      trashcanImportant: "Importante!",
      trashcanDescription: "O quiz será excluído permanentemente 30 dias após ser movido para a lixeira.",
      trashcanClearAll: "Limpar tudo",
      quizSlotDeletedData: "Excluído em",
      workboardLeads: "Leads",
      workboardDuplicate: "Duplicar",
      leadsTable: "Lista de Leads",
      exportInfo: "Exportar Leads",
      leadHide: "Ocultar",
      leadShowMore: "Mostrar mais",
      poperTrashcan: "Lixeira",
      restoreQuizSendedTitle: "Uma tarefa de recuperação está em andamento",
      restoreSuizSendedImportant: "Importante",
      restoreQuizSendedDescription: "Dependendo de quantos leads havia no seu quiz, a restauração pode levar de alguns segundos a 2 minutos. Após a restauração, o quiz aparecerá em seu painel principal.",
      ok: "Ok",
      month: "{n} months",
      discount: "Desconto de até",
      saveMoney: "Assinatura por um ano",
      upProVersionMobile: "Para PRO",
      upProVersionDesktop: "Mudar para PRO",
      csettingsIntegrationSendPulse: "Integração - Send Pulse",
      csettingsIntegrationMake: "Integração - Make",
      csettingsIntegrationZapier: "Integração - Zapier",
      csettingsIntegrationCRMBitrix: "Integração - CRM Bitrix",
      csettingsIntegrationPipeDrive: "Integração - Pipe Drive",
      csettingsIntegrationRemOnline: "Integração - Rem Online",
      csettingsIntegrationKeyCRM: "Integração - Key CRM",
      csettingsIntegrationZapierAbout: "Cole a URL de envio de dados fornecida pelo Zapier no campo de entrada.",
      csettingsIntegrationMakeAbout: "Cole a URL de envio de dados fornecida pelo Make no campo de entrada.",
      csettingsIntegrationMakeAboutTextStep1: "Para configurar a integração com",
      csettingsIntegrationMakeAboutTextStep2: ", assista ao",
      csettingsIntegrationMakeAboutTextStep3: "Para a configuração, selecione “Webhook” entre as integrações no Make, depois escolha “Webhook response”.",
      csettingsIntegrationMakeAboutTextStep4: "Copie o link gerado e cole no campo abaixo no ADS Quiz.",
      csettingsIntegrationMakeAboutTextStep5: "Para testar, volte ao Make, clique em “Run” e complete o quiz enviando um lead.",
      csettingsIntegrationMakeAboutTextStep6: "Se o teste for bem-sucedido, salve a integração e não se esqueça de ativar as atualizações.",
      csettingsIntegrationMakeAboutLink: "https://www.make.com/en/register?pc=adschool",
      csettingsIntegrationMakeAboutLinkVideo: "https://www.youtube.com/embed/3ZyagaBQmiI",
      csettingsIntegrationMakeAboutLinkVideoText: "vídeo",
      csettingsIntegrationCRMBitrixAbout: "Cole a URL de envio de dados fornecida pelo CRM Bitrix no campo de entrada.",

      csettingsIntegrationSendPulseAboutStart: "Você pode configurar a integração com o serviço ",
      csettingsIntegrationSendPulseAboutMiddle: ". Para isso, vá até as configurações da sua conta e abra a aba ",
      csettingsIntegrationSendPulseAboutEnd: ". Copie os valores dos parâmetros 'ID' e 'Secret', e depois cole-os nos campos correspondentes no serviço ADS Quiz (nos campos abaixo). Em seguida, você precisa inserir o ID do livro de endereços. Para isso, acesse o livro de endereços desejado e copie do URL o valor que aparece após id/. Apenas números são necessários, sem caracteres adicionais. Salve as alterações. Um guia mais detalhado está disponível no ",
      csettingsIntegrationSendPulseAboutLinkSendPulse: "https://sendpulse.com/br#60c9ccfd55f4b",
      csettingsIntegrationSendPulseAboutLinkAPI: "https://login.sendpulse.com/login/#api",
      csettingsIntegrationSendPulseAboutLinkVideo: "https://www.youtube.com/watch?v=4mnhpbKkePs",
      csettingsIntegrationSendPulseAboutLinkVideoText: "vídeo",

      csettingsIntegrationKeyCRMAbout: "Cole a chave API e o PipelineID nos respectivos campos.",
      csettingsIntegrationPipeDriveAbout: "Cole o token e o domínio da empresa nos respectivos campos.",
      csettingsIntegrationRemOnlineAbout: "Cole a chave API do Rem Online no campo correspondente.",
      csettingsIntegrationActiveIntegration: "Integração ativa",
      cintegrationIntegrationData: "Dados de integração",
      cintegrationIntegrationUrl: "URL de integração",
      cintegrationWriteUrl: "Inserir URL",
      cintegrationDataFBError: "Identificador de pixel incorreto",
      cintegrationDataWebHookError: "Endereço de webhook inválido",
      cintegrationZapierDataSaved: "Dados de integração do Zapier salvos",
      cintegrationDataZapierError: "Endereço de integração inválido",
      cintegrationAPIxDriveDataSaved: "Dados de integração do APIxDrive salvos",
      cintegrationDataAPIxDriveError: "A sequência não é um endereço de integração do APIxDrive",
      cintegrationMakeDataSaved: "Dados de integração do Make salvos",
      cintegrationDataMakeError: "Endereço de integração inválido",
      cintegrationSendPulseDataSaved: "Dados de integração do SendPulse salvos",
      cintegrationDataSendPulseError: "Dados de integração inválidos ou vazios",
      cintegrationPipeDriveDataSaved: "Dados de integração do PipeDrive salvos",
      cintegrationDataPipeDriveError: "Dados de integração inválidos ou vazios",
      cintegrationRemOnlineDataSaved: "Dados de integração do RemOnline salvos",
      cintegrationDataRemOnlineError: "Dados de integração inválidos ou vazios",
      cintegrationKeyCRMDataSaved: "Dados de integração do KeyCRM salvos",
      cintegrationDataKeyCRMError: "Dados de integração inválidos ou vazios",
      cintegrationInputEmailUrl: "Insira o e-mail",
      cintegrationEmailDataSaved: "E-mail salvo",
      cintegrationDataEmailError: "A sequência inserida não é um e-mail",
      cintegrationCRMBitrixDataSaved: "E-mail salvo",
      cintegrationDataCRMBitrixError: "A sequência inserida não é um e-mail",
      cintegrationTelegramBotDataSaved: "Configurações de integração do Telegram salvas",
      cintegrationDataTelegramBotError: "Erro ao salvar",
      cintegrationGTMDataSaved: "ID do Google Tag Manager salvo",
      cintegrationDataGTMError: "ID inválido",
      cintegrationDataGTMErrorDescription: "O ID deve ter o formato GTM-XXXXXX",
      cintegrationGoogleAnalyticsDataSaved: "ID do Google Analytics salvo",
      cintegrationDataGoogleAnalyticsError: "ID incorreto",
      cintegrationDataGoogleAnalyticsErrorDescription: 'O ID deve ter o formato "UA-XXXXXXX-X" ou "G-XXXXXXXXXX-XX"',
      cintegrationTikTokDataSaved: "Pixel do Tik-Tok salvo",
      cintegrationDataTikTokError: "A sequência inserida não é um pixel do Tik-Tok",
      cintegrationIntegrationToken: "Token de integração",
      cintegrationWriteToken: "Insira o token de integração",
      cintegrationDataYourAPIKey: "Insira sua chave API",
      viewQuizInMobile: "Ver Quiz",
      notificationCSRFTokenMismatch: "Incompatibilidade de token CSRF",
      notificationReloadPage: "Por favor, recarregue a página e tente novamente",
      csettingsIntegrationKeyRemOnline: "Cole o token e o ID do canal nos campos apropriados",
      cintegrationDataYourIntegrationToken: "Chave API",
      cintegrationDataLeadTypeId: "ID do canal",
      send_utm: "Enviar tags UTM",
      send_quiz: "Enviar dados do quiz",
      send_answers: "Enviar respostas",
      csettingsIntegrationKeyCrm: "Integração KeyCRM",
      cintegrationSendPulseClientId: "ID do cliente",
      cintegrationSendPulseClientSecret: "Segredo",
      cintegrationSendPulseAddressBookId: "ID da lista de contatos",
      cintegrationSendPulseAction: "Ação com contato",
      sendpulseActionAddContact: "Adicionar contato",
      sendpulseActionDeleteContact: "Excluir contato",
      cintegrationPipeDriveToken: "Token API",
      cintegrationPipeDriveDomain: "Seu subdomínio único",
      cintegrationKeyCrmApiKey: "Chave API",
      cintegrationKeyCrmPipelineId: "ID do pipeline",
      cintegrationMenuGoogleTagManager: "Google Tag Manager",
      cintegrationMenuGoogleAnalytics: "Google Analytics",
      cintegrationMenuFacebookPixel: "Pixel do Facebook",
      cintegrationMenuTikTokPixel: "Pixel do TikTok",
      cintegrationMenuWebHook: "Webhook",
      cintegrationMenuZapier: "Zapier",
      cintegrationMenuAPIxDrive: "APIx Drive",
      cintegrationMenuMake: "Make",
      cintegrationMenuSendPulse: "Send Pulse",
      cintegrationMenuEmail: "E-mail",
      cintegrationMenuTelegramBot: "Bot do Telegram",
      cintegrationMenuPipeDrive: "PipeDrive",
      cintegrationMenuRemOnline: "Rem Online",
      cintegrationMenuKeyCRM: "Key CRM",
      cintegrationMenuCRMBitrix: "CRM Bitrix",
      trashcanRestoreQuiz: "Restaurar Quiz",
      cformValuePromotionAccepted: "Aceito o boletim informativo por e-mail",
      constructorAddIntegration: "Adicionar uma nova integração",
      cintegrationKeyCrmSourceId: "ID de fonte (opcional)",
      cintegrationKeyCrmManagerId: "ID do gerente (opcional)",
      cSettingsSettingQuizSpamFilterTitle: "Proteção contra spam",
      cSettingsSettingQuizSpamFilterDescription: "Este recurso está desativado por padrão. Se você encontrar leads de spam, ative a proteção. Depois disso, um único usuário não poderá enviar mais do que o número especificado de leads para este quiz.",
      cSettingsSettingQuizSpamFilterLimitPlaceholder: "Número de leads",
      langNO: "Norueguês",
      loadMoreLeads: "Ver mais leads",
      userAdditionalSettingsHeader: "Configurações adicionais",
      userAdditionalSettingsDescription1: "Aqui você pode configurar novas configurações avançadas de perfil.",
      userAdditionalSettingsPagination: "Paginação no painel de trabalho",
      benefitType: "Tipo de benefício",
      benefitLabelPlaceholder: "Texto de bônus",
      benefitDescriptionPlaceholderDiscount: "Código promocional ou instrução",
      benefitDescriptionPlaceholderVideo: "Link para o vídeo",
      benefitDescriptionPlaceholderTextfile: "Link para o arquivo de texto",
      benefitDescriptionPlaceholderGift: "Descrição do bônus",
      benefitDescriptionPlaceholderPDF: "Link para o PDF",
      benefitDescriptionPlaceholderLink: "Link",
      benefitDescriptionPlaceholderFile: "Link para o arquivo",
      benefitLabelDiscount: "Desconto",
      benefitLabelVideo: "Vídeo",
      benefitLabelTextfile: "Arquivo de texto",
      benefitLabelGift: "Presente",
      benefitLabelPDF: "Arquivo PDF",
      benefitLabelLink: "Link",
      benefitLabelFile: "Arquivo",
      addBenefit: "Adicionar benefício",
      saveBenefit: "Salvar bônus",
      cfinishSocialNetworks: "Redes sociais",
      saveSocialNet: "Salvar rede social",
      networkLabelFacebook: "Facebook",
      networkLabelInstagram: "Instagram",
      networkLabelTelegram: "Telegram",
      networkLabelTiktok: "Tik-Tok",
      networkLabelYoutube: "YouTube",
      networkLabelLinkedin: "LinkedIn",
      networkLabelWhatsapp: "WhatsApp",
      socialNetDescriptionPlaceholderFacebook: "perfil_nikname",
      socialNetDescriptionPlaceholderInstagram: "perfil_nikname",
      socialNetDescriptionPlaceholderTelegram: "perfil/canal_nikname",
      socialNetDescriptionPlaceholderTiktok: "perfil_nikname",
      socialNetDescriptionPlaceholderYoutube: "nome_do_canal",
      socialNetDescriptionPlaceholderLinkedin: '"in/nome" ou "company/nome_empresa"',
      socialNetDescriptionPlaceholderWhatsapp: "+15555551234",
      addNewSectionEmptyPage: "Página de texto simples",
      csanyMakeImpasse: "Impass",
      impaseIsImpaseTrue: "Ligado",
      impaseIsImpaseFalse: "Desligado",
      defaultNewEmptyQuestionDescription: "Você pode colar e formatar texto longo aqui (não recomendamos colar mais de 3000 caracteres).",
      cSettingsSettingQuizTimezoneTitle: "Fuso horário do quiz",
      cSettingsSettingQuizTimezoneDescription: "Insira o fuso horário no qual o horário de envio de leads será registrado. Isso é especialmente útil se você e o cliente estiverem em fusos diferentes.",
      cSettingsSettingQuizTimezonePlaceholder: "Fuso horário",
      userAdditionalSettingsTimezone: "Fuso horário do usuário",
      userAdditionalSettingsTimezonePlaceholder: "Fuso horário",
      langHE: "Hebraico",
      langCS: "Tcheco",
      cintegrationCAPIFBInstruction: "Para configurar a integração com a API de Conversão do Facebook, é necessário inserir o ID do pixel e o token de acesso. Esses dados estão nas configurações do Facebook Business Manager. Após inserir as informações necessárias, salve a integração.",
      cintegrationCAPIFBInstructionTextTitle: "Nomes de eventos para FB Conversions:",
      cintegrationCAPIFBInstructionTextStart: " – visita à página inicial;",
      cintegrationCAPIFBInstructionTextQuestionN: " – visita à etapa N (o número da etapa é inserido dinamicamente);",
      cintegrationCAPIFBInstructionTextOpenContactPage: " – visita à página de contatos;",
      cintegrationCAPIFBInstructionTextLead: " – envio bem-sucedido do formulário de contato;",
      cintegrationCAPIFBInstructionTextThankYou: " – visita à página final.",
      cintegrationCAPIFBInstructionTextOptimizationAdvice: "Recomendamos executar anúncios otimizados para o evento",
      cintegrationCAPIFBInstructionTextLeadTriggerCondition: "Este evento é acionado",
      bold_capi_adsquiz_Start: "capi_adsquiz_Start",
      bold_capi_adsquiz_QuestionN: "capi_adsquiz_Question-N",
      bold_capi_adsquiz_OpenContactPage: "capi_adsquiz_OpenContactPage",
      bold_capi_adsquiz_Lead: "capi_adsquiz_Lead",
      bold_capi_adsquiz_ThankYou: "capi_adsquiz_ThankYou",
      bold_capi_adsquiz_OptimizationAdvice: "“capi_adsquiz_Lead”.",
      bold_capi_adsquiz_LeadTriggerCondition: "somente quando um lead é enviado com sucesso pelo usuário.",
      csettingsIntegrationFbCapiAbout: "A integração com a API de Conversão do Facebook permite enviar dados de interação do usuário diretamente para o Facebook para melhorar a eficácia das campanhas publicitárias.",
      cintegrationInputCapiAccessToken: "Insira o token de acesso",
      cintegrationInputCapiCustomparameter: "Insira os parâmetros de evento personalizado",
      cintegrationDataFbCapiSaved: "Integração com a API de Conversão do Facebook salva com sucesso",
      cintegrationComonRenovateHubInstructionTitle: "Instruções para configurar a integração com o RenovateHub",
      cintegrationComonRenovateHubInstructionDescription: "Para configurar a integração com o RenovateHub, forneça o URL do webhook onde os dados serão enviados. Certifique-se de que o URL está correto e acessível para receber dados.",
      cintegrationComonRenovateHubInstructionShort: "A integração com o RenovateHub permite enviar dados para o CRM através de um webhook para automação de processos.",
      cintegrationRenovateHubUrl: "Insira o URL do RenovateHub",
      cintegrationInputRenovateHubUrl: "Especifique o URL do webhook para o RenovateHub",
      cintegrationRenovateHubDataSaved: "Integração com o RenovateHub salva com sucesso",
      cintegrationDataRenovateHubError: "Erro ao salvar a integração com o RenovateHub. Verifique os dados inseridos para precisão.",
      cintegrationMenuFacebookConvAPI: "API de Conversões do Facebook",
      cintegrationMenuRenovateHub: "RenovateHub",
      csettingsIntegrationRenovateHub: "Integração do RenovateHub",
      csettingsIntegrationCAPIFB: "Integração com a API de Conversões do Facebook",
      paymentPlanEnd: "Data de término do plano",
      paymentPlanLeadsLimitForYear: "Limite de leads por ano:",
      paymentPlanLeadsLimitForPeriod: "Limite de leads por período:",
      EditTags: "Editar tags",
      quizzesTemplatesNoTags: "Sem tags",
      Monday: "Segunda-feira",
      Tuesday: "Terça-feira",
      Wednesday: "Quarta-feira",
      Thursday: "Quinta-feira",
      Friday: "Sexta-feira",
      Saturday: "Sábado",
      Sunday: "Domingo",
      datetimeDisplayMode: "Tipo de seletor",
      datetimeDisplayModeSelect: "Selecionar tipo",
      datetimeDisplayModeDate: "Data",
      datetimeDisplayModeTime: "Hora",
      datetimeDisplayModeDatetime: "Data e Hora",
      datetimeMinDate: "Data mínima",
      datetimeMinDateCustom: "Selecione a data mínima",
      datetimeMaxDate: "Data máxima",
      datetimeMaxDateCustom: "Selecione a data máxima",
      datetimeProhibitDaysOfWeek: "Restringir dias",
      datetimeIsRange: "Habilitar intervalo",
      datetimeIsRangeTrue: "Intervalo habilitado",
      datetimeIsRangeFalse: "Intervalo desabilitado",
      datetimeMinTime: "Hora mínima",
      datetimeMaxTime: "Hora máxima",
      datetimeIs24: "Formato de 24 horas",
      datetimeIs24True: "Formato de 24 horas ativado",
      datetimeIs24False: "Formato de 24 horas desativado",
      csanyIsExactDate: "Como você gostaria de definir a data?",
      csanyIsExactDateTrue: "Data exata",
      csanyIsExactDateFalse: "Data relativa",
      csanyDatetimeMinMaxNotExactPlaceholder: '"x" ou "-x" dias a partir de hoje',
      addNewSectionDatetime: "Data/Hora",
      tariffsPromo: "Ofertas especiais",
      tariffsMonth: "Mensalmente",
      tariffsAnual: "Anualmente",
      leadsOn1Month: "por mês",
      leadsOn2Month: "por 2 meses",
      leadsOn3Month: "por 3 meses",
      leadsOn4Month: "por 4 meses",
      leadsOn5Month: "por 5 meses",
      leadsOn6Month: "por 6 meses",
      leadsOn7Month: "por 7 meses",
      leadsOn8Month: "por 8 meses",
      leadsOn9Month: "por 9 meses",
      leadsOn10Month: "por 10 meses",
      leadsOn11Month: "por 11 meses",
      leadsOn12Month: "por ano",
      leadsOn24Month: "por 2 anos",
      leads: "leads",
      tariffsWordPrice: "Preço",
      tariffsNoLimits: "Sem limites",
      tariffsNoLimitsQuizzesAndFunctions: "em quizzes e funcionalidades",
      billingTariffYear: "ano",
      billingChangePlanSubscribe: "Assinar",
      paymentAllTariffInactive: "Tarifa não ativa",
      subscriptionTariffLableOnly: "Disponível apenas para assinatura",
      notExactBeforeYear: "há um ano",
      notExactBeforeHalfYear: "há meio ano",
      notExactBeforeQuarter: "há um quarto de ano",
      notExactBeforeMonth: "há um mês",
      notExactBeforeWeek: "há uma semana",
      notExactBeforeYesterday: "anteontem",
      notExactYesterday: "ontem",
      notExactToday: "hoje",
      notExactTomorrow: "amanhã",
      notExactAfterTomorrow: "depois de amanhã",
      notExactAfterWeek: "em uma semana",
      notExactAfterMonth: "em um mês",
      notExactAfterQuarter: "em um quarto de ano",
      notExactAfterHalfYear: "em meio ano",
      notExactAfterYear: "em um ano",
      tipDefaultDateHeader: "Por padrão",
      tipDefaultDateText: "Se as opções 'Data Exata' e 'Data Relativa' estiverem desativadas, o calendário permitirá a seleção de datas entre os anos 1800 e 2200 por padrão.",
      tipExactDateHeader: "Interruptor de data exata",
      tipExactDateText: "Ativar esta opção limitará o calendário a um intervalo de datas fixo definido pela(s) data(s) exata(s) selecionada(s) nos campos abaixo.",
      tipRelativeDateHeader: "Interruptor de data relativa",
      tipRelativeDateText: "Ativar esta opção definirá dinamicamente os limites do calendário com base em datas relativas calculadas no momento do carregamento da página. Esses limites são personalizados e recalculados individualmente para cada usuário.",
      langPT: "Português",
      placeholderCompanyContactText: 'Por exemplo: "Telefone da empresa: +351993322211"',
      integrationConnectService: "Conectar",
      leadtype_id: "Tipo de ação",
      selectVariant: "Selecionar",
      sfirstAddCompanyContacts: "Texto com contatos",
      addNewSectionTextarea: "Campo de entrada grande",
      allstepsVariantView: "Formato de isolamento",
      tariffsDiscountSave: "Economize",
      cAdminSettingsTitle: "Configurações administrativas",
      cAdminSettingsInstruction: "Insira as tags para o questionário no campo abaixo. As tags podem consistir em várias palavras, cada tag é separada por uma vírgula. As tags 'Serviços' e 'serviços' são diferentes. Recomenda-se usar tags que comecem com letra maiúscula. Também é possível excluir o questionário da lista de modelos.",
      cAdminSettingsIsTemplate: "É um modelo",
      cIsTemplateStateAreSaved: "O status do modelo foi salvo",
      sfirstAddLogotype: "Adicione um logotipo",
      logotypeSizeTip: "O logotipo será inserido no tamanho real, mas não maior que 120 pixels no lado mais longo.",
      cfirstLogotypeUpdated: "O logotipo foi adicionado",
      cfirstLogotypeDeleted: "O logotipo foi excluído com sucesso!",


      cOffersPageSettings: "Configurações",
      offerPageTitlePlaceholder: "Digite o título",
      offerPageDescriptionPlaceholder: "Digite a descrição da página",
      offerPageButtonTextPlaceholder: "Digite o texto do botão (opcional)",
      allstepsOffers: "Lista de ofertas",
      offerProposalHeader: "Oferta",
      offerAttachAddImg: "Adicionar imagem",
      offerAttachAddVideo: "Adicionar vídeo",
      offerItemImg: "Imagem",
      offerItemTitle: "Título da oferta",
      offerTitlePlaceholder: "Digite o título aqui",
      offerItemSubtitle: "Legenda (ex. preço)",
      offerSubtitlePlaceholder: "Digite o texto aqui",
      offerItemDescription: "Descrição da oferta",
      offerDescriptionPlaceholder: "Digite a descrição aqui",
      offerItemButtonText: "Texto do botão da oferta",
      offerBtnTextPlaceholder: "Digite o texto do botão",
      offerItemButtonLink: "Link do botão",
      offerBtnLinkPlaceholder: "O link deve começar com https://",
      csanyOfferLogic: "Lógica de exibição",
      offerAddNewOffer: "Adicionar nova oferta",
      offerLogicSetupTitle: "Configuração da lógica",
      OfferLogicSetupDescription: "Aqui você pode configurar quais respostas exibirão ofertas específicas",
      cstepsOffersPage: "Página de resultados",
      addNewSectionResultsByLogic: "Ofertas",
      cformActionAfterContactOffersOr: "Página de ofertas / ",
      tolltipOffersAfterContact: "Se, após concluir o quiz, a lógica de exibição de pelo menos uma oferta for atendida, a página de ofertas será exibida; caso contrário, a página de agradecimento será mostrada.",
      videoCircleTextQuestion: "Como isso funciona?",
      offerPhotoOptimalRatio: "Para uma exibição ideal da imagem, recomendamos o uso da proporção 16:9.",
      tolltipThankYouAfterContact: "Após concluir o questionário, uma página de agradecimento será exibida.",
      cformActionAfterContactOffers: "Página de resultados",
      addOfferPageInContact: "Ativar página de resultados",
      offerPageHeader: "Cabeçalho para a página de ofertas",
      pumkinOrange: "Laranja",
    }
  },
})
export default i18n