<template>
    <div class="disp-modal pw-modal" id="pw-modal">
        <div class="container">
            <div class="profile-modal-wrap">
            <div class="modal-overlay" @click="toggleProfileModal"></div>
            <div class="modal-content-wrap profile-modal-menu">
                <router-link class="billing" to="../billing">{{$t('poperBillings')}}</router-link>
                <input type="button" class="templates" :value="$t('poperTemplates')" @click=goToSelectQuizTemplate()>
                <a class="partnership" target="_blank" href="https://adsquiz.firstpromoter.com">{{$t('poperPartnership')}}</a>
                <router-link class="profile" to="../profile">{{$t('poperProfile')}}</router-link>
                <input v-if="trashcanLink" type="button" class="trashcan" :value="$t('poperTrashcan')" @click=openDeletedQuizModal()>
                <input class="logout" type="button" @click.prevent="logout()" :value="$t('poperLogout')">
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
data() {
    return {
    };
},
computed: {
   trashcanLink() {
    if(this.$router.currentRoute.value.fullPath == "/") {
        return true
    } else return false 
   }, 
},
methods: {
    toggleProfileModal() {
        this.$emit('togle-profile-modal', false);
    },
    openDeletedQuizModal() {
        this.toggleProfileModal()
        this.$emit('open-deleted-quiz-modal');
        console.log(this.trashcanLink)
    },
    goToSelectQuizTemplate() {
        if (localStorage.getItem('templateQuizName')) {
            localStorage.removeItem('templateQuizName');
        }
        this.$router.push({
            name: 'quiz-templates'
        });
        this.$emit('togle-profile-modal', false);
    },
    // logout() {
    // },
},
};
</script>
<style scoped>
.profile-modal-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.profile-modal-menu {
    background: #fff;
    margin-top: 60px;
    margin-right: 28px;
    border-radius: 5px;
    width: 180px;
    /* padding: 12px 10px; */
    display: flex;
    flex-direction: column;
    height: max-content;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.16);
}

.profile-modal-menu a,
.profile-modal-menu input {
    color: var(--color-main-black);
    padding: 16px 16px 16px 44px;
    background-position: 16px center;
    background-repeat: no-repeat;
    background-size: 16px;
    background-color: transparent;
    border: none;
    text-align: left;
    border-bottom: 1px solid var(--color-main-light-blue, #E6E8F1);
}


.profile-modal-menu .billing {
    background-image: url("~/public/img/icons/credit-card.svg");
}
.profile-modal-menu .templates {
    background-image: url("~/public/img/icons/templates.svg");
}
.profile-modal-menu .partnership {
    background-image: url("~/public/img/icons/partnership.svg");
}
.profile-modal-menu .profile {
    background-image: url("~/public/img/icons/profile_settings.svg");
}
.profile-modal-menu .trashcan {
    background-image: url("~/public/img/icons/trashcan.svg");
}
.profile-modal-menu .logout {
    background-image: url("~/public/img/icons/logout.svg");
    border-bottom: none;
}
</style>
